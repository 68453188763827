/* global google, widgetInit */
import { useRef, useCallback } from 'react'

const GoogleSignIn = ({ handleCallback, style }) => {
  const googleSignInRef = useRef()

  const renderButton = width => {
    google.accounts.id.renderButton(googleSignInRef.current, {
      theme: 'outline',
      size: 'large',
      type: 'standard',
      text: 'signin_with',
      shape: 'rectangular',
      logo_alignment: 'left',
      width,
    })
  }

  const signInRef = useCallback(
    ref => {
      if (ref !== googleSignInRef.current) {
        googleSignInRef.current = ref
        google.accounts.id.initialize({
          client_id: widgetInit.gapiClientId,
          callback: res => handleCallback(res),
        })
        // should be refactored in GX-2849
        try {
          renderButton('330px')
        } catch (_e) {
          // eslint-disable-next-line no-console
          console.error('Unable to render google sign in button with width in px')
          try {
            renderButton(330)
          } catch (_e) {
            // eslint-disable-next-line no-console
            console.error('Unable to render google sign in button with width as number')
            renderButton('330')
          }
        }
      }
    },
    [handleCallback]
  )

  if (!window.google?.accounts?.id) {
    // eslint-disable-next-line no-console
    console.error('Please initialise Google sdk before using this component')
    return null
  }

  return (
    <div style={style}>
      <div ref={signInRef} />
    </div>
  )
}

export default GoogleSignIn
