/* eslint react/prop-types: 0 */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { selectLanguageStrings, selectCalculatedLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { selectRequest } from '../../actions/navigation'
import { WidgetView, WidgetSection, WidgetViewContainer, WidgetLine, WidgetSectionFill } from '../../uiKit/WidgetView'
import { SetAlertButton } from '../components/SetAlertButton'
import SubmitRequestButton from '../components/SubmitRequestButton'
import { getInSearchWindow } from '../selectors'
import AdditionalResultsDisplay from './AdditionalResultsDisplay'
import Banner from './Banner'
import MainResultsDisplay from './MainResultsDisplay'
import VenueResultsDisplay from './VenueResultsDisplay'

const SearchResultsContainer = styled(WidgetViewContainer)`
  padding-top: 38px;
  outline: none;
`

const SearchResultsFill = styled(WidgetSectionFill)`
  background-color: ${props => props.theme.colorCellBackground};
`

class SearchResultsView extends React.Component {
  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
  }

  render() {
    const { partySize, priorityAlertsEnabled, priorityAlertsPartySizeMax, priorityAlertsPartySizeMin } = this.props

    return (
      // eslint-disable-next-line no-return-assign
      <SearchResultsContainer tabIndex="0" ref={comp => (this.initialFocusComponent = comp)}>
        <Banner />
        <WidgetView>
          {!this.props.isAllLocationsSearch && (
            <WidgetSection>
              <MainResultsDisplay />
              <WidgetLine />
            </WidgetSection>
          )}
          {!this.props.isAllLocationsSearch &&
            this.props.hasSelectedRequest &&
            this.props.requestsEnabled &&
            !this.props.inRequestWindowTime && (
              <WidgetSection>
                {priorityAlertsEnabled && partySize <= priorityAlertsPartySizeMax && partySize >= priorityAlertsPartySizeMin ? (
                  <SetAlertButton
                    textCantFind={this.props.textCantFind}
                    buttonText={this.props.resWidgetPriorityAlertsSetAlertButton}
                    onClick={this.props.selectRequest}
                  />
                ) : (
                  <SubmitRequestButton
                    textCantFind={this.props.textCantFind}
                    textSubmitRequest={this.props.textSubmitRequest}
                    onClick={this.props.selectRequest}
                  />
                )}
                <WidgetLine />
              </WidgetSection>
            )}
          {this.props.isMultiVenue && (
            <WidgetSection>
              <VenueResultsDisplay open={!this.props.inRequestWindowTime} />
              <WidgetLine />
            </WidgetSection>
          )}
          {!this.props.isAllLocationsSearch && (
            <WidgetSection>
              <AdditionalResultsDisplay open={!this.props.inRequestWindowTime} />
            </WidgetSection>
          )}
          <SearchResultsFill>
            <WidgetLine />
            <div />
          </SearchResultsFill>
        </WidgetView>
      </SearchResultsContainer>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  const { priorityAlertsPartySizeMin, priorityAlertsPartySizeMax } = state.venueInfo

  return {
    isAllLocationsSearch: state.searchResults.get('isAllLocationsSearch'),
    hasSelectedRequest: !_.isNil(state.searchResults.get('selectedRequest')),
    requestsEnabled: state.widgetSettings.enableRequests,
    priorityAlertsEnabled: state.venueInfo.priorityAlertsEnabled,
    inRequestWindowTime: state.searchResults.get('isAllLocationsSearch') ? false : getInSearchWindow(state),
    isMultiVenue: state.search.get('venueMap').keySeq().size > 2,
    textCantFind: calcLanguageStrings.textCantFind,
    textSubmitRequest: languageStrings.textSubmitRequest,
    resWidgetPriorityAlertsSetAlertButton: languageStrings.resWidgetPriorityAlertsSetAlertButton,
    partySize: state.search.get('partySize'),
    priorityAlertsPartySizeMin,
    priorityAlertsPartySizeMax,
  }
}

const mapDispatchToProps = dispatch => ({
  selectRequest: () => dispatch(selectRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsView)
