import { Buffer } from 'buffer'
import aesjs from 'aes-js'
import _ from 'lodash'

// VERY temporary encryption to deal with this CyberSin.
// Will be removed upon Spreedly integration.
export class CyberEncrypt {
  constructor() {
    this.chunk = 16
    this.key = this.bytes('dGhpc2lzaG9ycmlmeWluZw==', 'base64')
  }

  nonce(length) {
    let text = ''
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789'
    while (length--) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  pad(text) {
    const len = text.length
    const pad = this.chunk - (len % this.chunk)
    return _.padEnd(text, len + pad, String.fromCharCode(pad))
  }

  bytes(text, enc) {
    return [...Buffer.from(text, enc)]
  }

  encrypt(text) {
    text = aesjs.util.convertStringToBytes(this.pad(text))
    const nonce = this.nonce(this.chunk)
    const iv = this.bytes(nonce)
    const cbc = new aesjs.ModeOfOperation.cbc(this.key, iv)
    const encrypted = cbc.encrypt(text)

    const concat = new Uint8Array(iv.length + encrypted.length)
    concat.set(iv)
    concat.set(encrypted, iv.length)

    return Buffer.from(concat).toString('base64')
  }
}

export const CardCodes = {
  VISA: '001',
  MASTERCARD: '002',
  'AMERICAN EXPRESS': '003',
  DISCOVER: '004',
  'DINERS CLUB': '005',
  JCB: '007',
}
