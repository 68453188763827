import _ from 'lodash'
import { PROMO_CODE_ERRORS } from 'svr/constants'

export const currencyMult = (amount, percentage) => {
  const amountCents = amount * 100
  const amountWithDiscount = amountCents * percentage
  const roundedAmountCents = Math.round(amountWithDiscount)
  const roundedAmountDollars = roundedAmountCents / 100
  return roundedAmountDollars
}

export const currencySubtr = (amount, deduction) => {
  const amountCents = Math.round(amount * 100)
  const amountToDeductCents = Math.round(deduction * 100)
  const finalAmountCents = amountCents - amountToDeductCents
  const finalAmount = finalAmountCents / 100
  return finalAmount
}

export const currencyAdd = (amount, addition) => {
  const amountCents = Math.round(amount * 100)
  const amountToAddCents = Math.round(addition * 100)
  const finalAmountCents = amountCents + amountToAddCents
  const finalAmount = finalAmountCents / 100
  return finalAmount
}

export const currencyPercentage = amount => amount / 100

export const amountToCents = amount => amount * 100

export const currencyAddSeq = amounts => _.reduce(amounts, (runningSum, amount) => currencyAdd(runningSum, amount), 0)

export const roundToNearestHundredth = amount => Math.round(amount * 100) / 100

export class GatewayError extends Error {
  constructor(msg, code = 500, ...params) {
    super(...params)
    this.msg = msg || 'The system encountered an error.'
    this.code = code
  }
}

export const parsePromoError = errors => {
  if (errors[1]) {
    return errors[1]
  }

  switch (errors[0]) {
    case PROMO_CODE_ERRORS.DOES_NOT_EXIST:
      return 'Invalid promo code'
    case PROMO_CODE_ERRORS.INVALID_VENUE:
      return 'Promo is not available at this venue'
    case PROMO_CODE_ERRORS.EXPIRED:
      return 'This promotion is expired'
    case PROMO_CODE_ERRORS.EXHAUSTED:
      return 'This promotion is expired'
    case PROMO_CODE_ERRORS.MISSING_PLATFORM_TYPE:
      return 'Invalid promo code'
    // Below min should be handled by
    // accompanying message, fall through
    // if it's somewhow not.
    case PROMO_CODE_ERRORS.BELOW_MIN:
    default:
      return 'Unable to process promo code. Please try again.'
  }
}
