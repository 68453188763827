import { StyleRoot } from 'radium'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import { createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'
import { rootEpic, rootReducer } from './reducers/combineReducers'
import preloadedState from './utils/preloadedState'
import { WidgetLoadingWrapper } from './WidgetLoadingWrapper'

const epicMiddleware = createEpicMiddleware(rootEpic)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  enableBatching(rootReducer), // reducer
  preloadedState, // preloaded state
  composeEnhancers(
    // enhancers
    applyMiddleware(thunk, epicMiddleware)
  )
)

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <StyleRoot>
          <Component />
        </StyleRoot>
      </AppContainer>
    </Provider>,
    document.getElementById('dining-widget-app')
  )
}

render(WidgetLoadingWrapper)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./WidgetLoadingWrapper', () => {
    render(WidgetLoadingWrapper)
  })
}
