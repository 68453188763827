import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

const RedemptionItemWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RedemptionInfo = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.fontsColorPrimary};
  font-size: 13px;
`

const RemoveButton = styled.button`
  border: none;
  cursor: pointer;
  color: ${props => props.fontsColorCheckoutInactive};
  background: transparent;
`

export const RedemptionItem = ({ redemption, onRemove, fontsColorPrimary, fontsColorCheckoutInactive, currencySymbol }) => {
  const renderRemainingBalance = (redemptionCardBalance, amountToRedeem) => {
    if (_.isNumber(redemptionCardBalance) && _.isNumber(amountToRedeem)) {
      return (redemptionCardBalance - amountToRedeem).toFixed(2)
    }

    return (0).toFixed(2)
  }

  const { redemptionCardId, redemptionCardBalance, amountToRedeem } = redemption

  return (
    <RedemptionItemWrapper>
      <i
        style={{
          fontSize: '23px',
          display: 'inline-block',
          float: 'left',
          color: fontsColorPrimary,
        }}
        className="fa fa-check fa-fw"
        aria-hidden="true"
      />
      <RedemptionInfo fontsColorPrimary={fontsColorPrimary}>
        {currencySymbol}
        {amountToRedeem.toFixed(2)} applied to balance,&nbsp;
        {currencySymbol}
        {renderRemainingBalance(redemptionCardBalance, amountToRedeem)}
        &nbsp;left on gift card (ending in&nbsp;
        {redemptionCardId.slice(redemptionCardId.length - 4)})
      </RedemptionInfo>
      <RemoveButton type="button" onClick={onRemove} fontsColorCheckoutInactive={fontsColorCheckoutInactive}>
        Remove
      </RemoveButton>
    </RedemptionItemWrapper>
  )
}
