export const modalTypes = {
  DIET_TAG_SELECT: 'DIET_TAG_SELECT',
  OCCASIONS_TAG_SELECT: 'OCCASIONS_TAG_SELECT',
  BOOKING_POLICY: 'BOOKING_POLICY',
  CUSTOM_CHECKOUT_POLICY: 'CUSTOM_CHECKOUT_POLICY',
  VENUE_GROUP_MARKETING_OPT_IN_POLICY: 'VENUE_GROUP_MARKETING_OPT_IN_POLICY',
  VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY: 'VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY',
  VENUE_SMS_MARKETING_OPT_IN_POLICY: 'VENUE_SMS_MARKETING_OPT_IN_POLICY',
  RESERVATION_SMS_OPT_IN_POLICY: 'RESERVATION_SMS_OPT_IN_POLICY',
  TAILORED_COMMUNICATION_OPT_IN_POLICY: 'TAILORED_COMMUNICATION_OPT_IN_POLICY',
  PROMO_CODE_ADDED: 'PROMO_CODE_ADDED',
  PROMO_CODE_REMOVED: 'PROMO_CODE_REMOVED',
  SELECT_GRATUITY: 'SELECT_GRATUITY',
  ABOVE_AGE_CONSENT_POLICY: 'ABOVE_AGE_CONSENT_POLICY',
  DINING_ADDITIONAL_SELECTION: 'DINING_ADDITIONAL_SELECTION',
  RESERVATION_NOTES: 'RESERVATION_NOTES',
  SPINNER: 'SPINNER',
  UI_DISABLED: 'UI_DISABLED',
  ERROR_DISPLAY: 'ERROR_DISPLAY',
  WAITLIST_POLICY: 'WAITLIST_POLICY',
  WAITLIST_AGREE_POLICY: 'WAITLIST_AGREE_POLICY',
  SPECIAL_ATTENTION_MESSAGE: 'SPECIAL_ATTENTION_MESSAGE',
}

export const viewTypes = {
  SEARCH: 'SEARCH',
  EXPERIENCES: 'EXPERIENCES',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  LOGIN: 'LOGIN',
  CHECKOUT: 'CHECKOUT',
  CONFIRMATION: 'CONFIRMATION',
  REQUEST: 'REQUEST',
  UPSELLS: 'UPSELLS',
  ERROR: 'ERROR',
  VENUE_CLOSED: 'VENUE_CLOSED',
  WAITLIST_CLOSED: 'WAITLIST_CLOSED',
  CHOOSE_WAITLIST_EXPERIENCE: 'CHOOSE_WAITLIST_EXPERIENCE',
  WAITING_ROOM: 'WAITING_ROOM',
  CHECKOUT_WAITLIST: 'CHECKOUT_WAITLIST',
  LANDING_PAGE: 'LANDING_PAGE',
}

export const searchTypes = {
  DATE: 'DATE',
  PARTY_SIZE: 'PARTY_SIZE',
  DURATION: 'DURATION',
  TIME: 'TIME',
  REQUEST_TIME: 'REQUEST_TIME',
  REQUEST_END_TIME: 'REQUEST_END_TIME',
}

export const promoCodeTypes = {
  DOLLAR_DISCOUNT: 'DOLLAR_DISCOUNT',
  PERCENT_DISCOUNT: 'PERCENT_DISCOUNT',
  PERCENT_DISCOUNT_WITH_CAP: 'PERCENT_DISCOUNT_WITH_CAP',
}

export const promoCodeErrorTypes = {
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  EXHAUSTED: 'EXHAUSTED',
}

export const chargeTypes = {
  RESERVATION: 'reservation',
  RESERVATION_SLOT: 'reservation_slot',
  PERSON: 'person',
  PERSON_SLOT: 'person_slot',
}

export const upsellGratuityTypes = {
  SPECIFIC_GRATUITY: 'SPECIFIC_GRATUITY',
  DEFAULT_GRATUITY: 'DEFAULT_GRATUITY',
}

export const creditCardPaymentRuleTypes = {
  SAVE_FOR_LATER: 'save_for_later',
  ADVANCED_PAYMENT: 'advanced_payment',
}

export const promoComponentTypes = {
  BASE_PRICE: 'BASE_PRICE',
  UPGRADES: 'UPGRADES',
  ALL: 'ALL',
}

export const CLIENT_PREFERRED_LANGUAGE_COOKIE = 'CLIENT_PREFERRED_LANGUAGE_COOKIE'
