import styled from 'styled-components'

interface BrandingProps {
  cellColor: string
  fontColor: string
  fontFamily: string
  fontSize: string
  isWhitelabeled: string
  poweredByLabel?: string
}

function Branding({ cellColor, fontColor, fontFamily, fontSize, isWhitelabeled, poweredByLabel = 'powered by' }: BrandingProps) {
  return (
    isWhitelabeled !== 'true' && (
      <BrandingWrapper cellColor={cellColor}>
        <Brand fontColor={fontColor} fontFamily={fontFamily} fontSize={fontSize}>
          <PoweredByText fontWeight="200">{poweredByLabel}</PoweredByText>&nbsp;
          <SevenroomsLink data-test="sevenrooms-link" fontWeight="500" href="https://sevenrooms.com" target="_blank">
            SEVENROOMS
          </SevenroomsLink>
        </Brand>
      </BrandingWrapper>
    )
  )
}

const BrandingWrapper = styled.div<{ cellColor: string }>`
  width: 100%;
  height: 35px;
  background-color: ${props => props.cellColor};
`

const Brand = styled.div<{ fontColor: string; fontFamily: string; fontSize: string }>`
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.fontColor};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
`
const PoweredByText = styled.span<{ fontWeight: string }>`
  font-weight: ${props => props.fontWeight};
`

const SevenroomsLink = styled.a<{ fontWeight: string }>`
  font-weight: ${props => props.fontWeight};
  color: inherit;
  text-decoration: none;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`
export default Branding
