import _ from 'lodash'
import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import { shortDayFormat } from '../../../scripts/utils/constants'
import { selectCalculatedLanguageStrings, selectLanguageDateFields } from '../selectors/languageSelectors'

const getSearch = state => state.search.toJS()
const getUI = state => state.ui.toJS()
const getReservationHold = state => state.reservationHold.toJS()
const locale = state => state.venueInfo.locale
const textGuestLabel = state => selectCalculatedLanguageStrings(state).textGuestLabel
const languageDataFields = state => selectLanguageDateFields(state)

export const getQueryMoment = createSelector([getSearch], search => {
  const { dateMoment, timeMoment } = search
  return dateMoment.hour(timeMoment.hour()).minute(timeMoment.minute())
})

export const getBannerContents = createSelector(
  [getSearch, getReservationHold, locale, textGuestLabel, languageDataFields],
  (search, reservationHold, locale, textGuestLabel, languageDataFields) => {
    const { dateMoment, selectedTimeSlot } = languageDataFields
    const { expirationMoment } = reservationHold
    const partySizeStr = `${search.partySize} ${textGuestLabel}`
    const selectedDate = selectedTimeSlot || dateMoment
    const dateStr = selectedDate.format(shortDayFormat[locale] || shortDayFormat.default)
    const timeDisplayFormat = locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
    const timeStr = (selectedTimeSlot || dateMoment).format(timeDisplayFormat)
    return { dateStr, partySizeStr, timeStr, expirationMoment }
  }
)

export const getCurrentView = createSelector([getUI], ui => ui.viewSequence[ui.stage])

const getOrderedRequests = state => state.availability.get('orderedRequests')

export const getClosestRequest = createSelector([getOrderedRequests, getQueryMoment], (orderedRequests, queryMoment) => {
  if (_.isEmpty(orderedRequests)) {
    return null
  }
  let closestRequest = orderedRequests.get(0)
  _.eachRight(orderedRequests.toJS(), requestTime => {
    if (moment(requestTime).isSameOrAfter(queryMoment)) {
      closestRequest = requestTime
    }
  })
  return moment(closestRequest)
})

export const getSelectedTimeSlot = state => state.search.get('selectedTimeSlot')
export const getAccessPersistentId = state => state.search.get('accessPersistentId')
export const getTimeSlotVenue = state => state.searchResults.get('timeSlotVenue')
export const getAvailabilityLookup = state => state.availabilityLookup

export const selectTimeSlotEntity = createSelector(
  [getSelectedTimeSlot, getAccessPersistentId, getTimeSlotVenue, getAvailabilityLookup],
  (selectedTimeSlot, accessPersistentId, timeSlotVenue, availabilityLookup) => {
    const dateStr = selectedTimeSlot.format('Y-MM-DD')
    const timeStr = selectedTimeSlot.format('h:mm A')
    return availabilityLookup.getIn([timeSlotVenue, dateStr, timeStr, accessPersistentId])?.toJS() ?? {}
  }
)

const getValidPromoCode = state => state.formFields.get('validPromoCode')
const getPromoCodeEntities = state => state.entities.promoCode
export const selectPromoCodeEntity = createSelector([getValidPromoCode, getPromoCodeEntities], (validPromoCode, promoCodeEntities) =>
  promoCodeEntities.get(validPromoCode)
)
