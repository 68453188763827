import _ from 'lodash'
import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import { formatTimeOnly } from 'mgr/lib/utils/MomentUtils'
import { selectCalculatedLanguageStrings, selectLanguageDateFields } from 'widget/dining/selectors/languageSelectors'
import { shortDayFormat } from '../../../scripts/utils/constants'
import { SEARCHRESULTS_DISPLAY_LENGTH, ALL_LOCATIONS } from '../utils/convertData'

// SELECT BANNER INFO
const getSearchParams = state => {
  const { dateMoment } = selectLanguageDateFields(state)
  const timeMoment = state.search.get('timeMoment')
  const partySize = state.search.get('partySize')
  const { locale } = state.venueInfo
  const { textGuestLabel } = selectCalculatedLanguageStrings(state)
  return { dateMoment, timeMoment, partySize, textGuestLabel, locale }
}

const getReservationHoldExpirationMoment = state => state.reservationHold.get('expirationMoment')

export const deriveBannerContents = (searchParams, expirationMoment) => {
  const { dateMoment, timeMoment, partySize, textGuestLabel, locale } = searchParams
  const partySizeStr = `${partySize} ${textGuestLabel}`
  const dateStr = dateMoment.format(shortDayFormat[locale] || shortDayFormat.default)
  const timeDisplayFormat = locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
  const timeStr = timeMoment.format(timeDisplayFormat)
  return { dateStr, partySizeStr, timeStr, expirationMoment }
}

export const selectBannerContents = createSelector([getSearchParams, getReservationHoldExpirationMoment], deriveBannerContents)

// SELECT QUERY MOMENT
const getSearchDate = state => state.search.get('dateMoment')
const getSearchTime = state => state.search.get('timeMoment')
const getIsExperienceMode = state => state.experience.get('isExperienceMode')

export const deriveQueryMoment = (dateMoment, timeMoment) =>
  dateMoment.clone().hour(timeMoment.hour()).minute(timeMoment.minute()).second(0).millisecond(0)

export const selectQueryMoment = createSelector([getSearchDate, getSearchTime], deriveQueryMoment)

// SELECT FOUND NEARBY RESULTS
export const getInitialResults = state => {
  const initialResults = state.searchResults.get('initialResults').toObject()
  return _.mapValues(initialResults, resultSet => _.map(resultSet, result => moment(result)))
}
const getTargetTimeHalo = state => state.search.get('targetTimeHalo')

export const deriveFoundNearbyResult = (queryMoment, initialResults, timeHalo) => {
  if (_.isEmpty(initialResults)) {
    return false
  }
  if (initialResults.equalTo.length === 1) {
    return true
  }
  const minHaloTime = queryMoment.clone().subtract(timeHalo, 'minutes')
  const someBeforeWithinTarget = _.some(initialResults.before, result => result.isSameOrAfter(minHaloTime))
  if (someBeforeWithinTarget) {
    return true
  }
  const maxHaloTime = queryMoment.clone().add(timeHalo, 'minutes')
  return _.some(initialResults.after, result => result.isSameOrBefore(maxHaloTime))
}

export const selectFoundNearbyResult = createSelector([selectQueryMoment, getInitialResults, getTargetTimeHalo], deriveFoundNearbyResult)

const _createTimeToAccessRulesMap = (initialResults, availabilityTimeLookup) => {
  const { before, equalTo, after } = initialResults
  const timeToAccessRulesMap = {}
  const _updateTimeToAccessRuleMap = timeMoment => {
    const dateKey = timeMoment.format('Y-MM-DD')
    const timeKey = timeMoment.format('h:mm A')
    const toAdd = availabilityTimeLookup.get(dateKey).get(timeKey).toJS()
    timeToAccessRulesMap[timeMoment] = getOrderedResultsForSameTimeSlot(toAdd)
  }
  const _addTimeSlotsToAccessRuleMap = timeObj => {
    for (const timeMoment of timeObj) {
      _updateTimeToAccessRuleMap(timeMoment)
    }
  }
  _addTimeSlotsToAccessRuleMap(before)
  _addTimeSlotsToAccessRuleMap(equalTo)
  _addTimeSlotsToAccessRuleMap(after)
  return timeToAccessRulesMap
}

const _getNumTimeslotsAcrossRules = timeToAccessRulesMap =>
  _.reduce(Object.values(timeToAccessRulesMap), (numTimes, timeSlots) => numTimes + timeSlots.length, 0)

const getStartOfDayTime = state => state.venueInfo.startOfDayTime
const getWidgetSettings = state => state.widgetSettings

// SELECT INITIAL RESULTS FORMAT
// algo for pulling the closest times from initialResults obj
const findClosestResults = (initialResults, queryMoment, availabilityTimeLookup, isExperienceMode, startOfDayTime, widgetSettings) => {
  const { before, equalTo, after } = initialResults
  const timeToAccessRulesMap = _createTimeToAccessRulesMap(initialResults, availabilityTimeLookup)
  const numToReturn = isExperienceMode ? _getNumTimeslotsAcrossRules(timeToAccessRulesMap) : widgetSettings.maxSearchResults
  const results = []

  const _pushAllFromTime = timeMoment => {
    let toAdd = timeToAccessRulesMap[timeMoment]
    // this can cause unexpected results with multiple access rules at the same time
    // so we don't want to do it for experiences which is based on length
    if (!isExperienceMode) {
      toAdd = _.take(toAdd, numToReturn - results.length)
    }

    results.push(...toAdd)
  }

  const _unshiftAllFromTime = timeMoment => {
    let toAdd = timeToAccessRulesMap[timeMoment]
    if (!isExperienceMode) {
      toAdd = _.takeRight(toAdd, numToReturn - results.length)
    }
    results.unshift(...toAdd)
  }
  const queryMomentForCheck = queryMoment.clone()
  const queryMomentTime = formatTimeOnly(queryMoment)
  const startOfDayTimeForCheck = formatTimeOnly(moment(startOfDayTime, 'h:mm A'))
  if (queryMomentTime.isBefore(startOfDayTimeForCheck)) {
    queryMomentForCheck.add(1, 'day')
  }
  if (equalTo.length === 1) {
    _pushAllFromTime(equalTo[0])
  }
  let beforeIdx = before.length - 1
  let afterIdx = 0
  while (results.length < numToReturn) {
    if (beforeIdx < 0 && afterIdx === after.length) {
      break
    } else if (beforeIdx < 0) {
      _pushAllFromTime(after[afterIdx])
      afterIdx += 1
    } else if (afterIdx === after.length) {
      _unshiftAllFromTime(before[beforeIdx])
      beforeIdx -= 1
    } else {
      let beforeIdxForCheck = formatTimeOnly(before[beforeIdx])
      if (beforeIdxForCheck.isBefore(startOfDayTimeForCheck)) {
        beforeIdxForCheck = before[beforeIdx].clone()
        beforeIdxForCheck.add(1, 'day')
      } else {
        beforeIdxForCheck = before[beforeIdx].clone()
      }
      let afterIdxForCheck = formatTimeOnly(after[afterIdx])
      if (afterIdxForCheck.isBefore(startOfDayTimeForCheck)) {
        afterIdxForCheck = after[afterIdx].clone()
        afterIdxForCheck.add(1, 'day')
      } else {
        afterIdxForCheck = after[afterIdx].clone()
      }
      const beforeDiff = Math.abs(beforeIdxForCheck.diff(queryMomentForCheck))
      const afterDiff = Math.abs(afterIdxForCheck.diff(queryMomentForCheck))
      if (beforeDiff < afterDiff) {
        _unshiftAllFromTime(before[beforeIdx])
        beforeIdx -= 1
      } else {
        _pushAllFromTime(after[afterIdx])
        afterIdx += 1
      }
    }
  }
  return results
}

const findSplitResults = (initialResults, availabilityTimeLookup, widgetSettings) => {
  const numToReturn = widgetSettings.maxSearchResults

  if (_.isNil(availabilityTimeLookup)) {
    return _.fill(new Array(numToReturn), null)
  }
  const before = []
  _.takeRightWhile(initialResults.before, timeMoment => {
    const dateKey = timeMoment.format('Y-MM-DD')
    let toAdd = availabilityTimeLookup.get(dateKey).get(timeMoment.format('h:mm A')).toJS()
    toAdd = getOrderedResultsForSameTimeSlot(toAdd)
    before.unshift(...toAdd)
    return before.length < numToReturn
  })

  const after = []
  _.takeWhile(initialResults.after, timeMoment => {
    const dateKey = timeMoment.format('Y-MM-DD')
    let toAdd = availabilityTimeLookup.get(dateKey).get(timeMoment.format('h:mm A')).toJS()
    toAdd = getOrderedResultsForSameTimeSlot(toAdd)
    after.push(...toAdd)
    return after.length < numToReturn
  })

  const equalTo = _.isEmpty(initialResults.equalTo)
    ? [null]
    : getOrderedResultsForSameTimeSlot(
        availabilityTimeLookup.get(initialResults.equalTo[0].format('Y-MM-DD')).get(initialResults.equalTo[0].format('h:mm A')).toJS()
      )

  const combined = _.without([...before, ...equalTo, ...after], null)
  return _.take(combined, numToReturn)
}

const findAdditionalSplitResults = (initialResults, availabilityTimeLookup) => {
  const numToReturn = 5
  if (_.isNil(availabilityTimeLookup)) {
    return _.fill(new Array(numToReturn), null)
  }
  const numPerSide = (numToReturn - 1) / 2
  let before = []
  _.takeRightWhile(initialResults.before, timeMoment => {
    const dateKey = timeMoment.format('Y-MM-DD')
    let toAdd = availabilityTimeLookup.get(dateKey).get(timeMoment.format('h:mm A')).toJS()
    toAdd = getOrderedResultsForSameTimeSlot(toAdd)
    before.unshift(...toAdd)
    return before.length < numPerSide
  })
  before = _.drop(before, before.length - numPerSide)
  if (numPerSide > before.length) {
    before.unshift(..._.fill(new Array(numPerSide - before.length), null))
  }

  let after = []
  _.takeWhile(initialResults.after, timeMoment => {
    const dateKey = timeMoment.format('Y-MM-DD')
    let toAdd = availabilityTimeLookup.get(dateKey).get(timeMoment.format('h:mm A')).toJS()
    toAdd = getOrderedResultsForSameTimeSlot(toAdd)
    after.push(...toAdd)
    return after.length < numPerSide
  })
  after = _.dropRight(after, after.length - numPerSide)
  if (numPerSide > after.length) {
    after.push(..._.fill(new Array(numPerSide - after.length), null))
  }

  const equalTo = _.isEmpty(initialResults.equalTo)
    ? [null]
    : getOrderedResultsForSameTimeSlot(
        availabilityTimeLookup.get(initialResults.equalTo[0].format('Y-MM-DD')).get(initialResults.equalTo[0].format('h:mm A')).toJS()
      )
  const combined = [..._.takeRight(before, numPerSide - equalTo.length / 2 + 1), ...equalTo, ...after]
  return _.take(combined, numToReturn)
}

const getOrderedResultsForSameTimeSlot = accessRuleMap =>
  _.values(accessRuleMap).sort((a, b) =>
    !a.publicTimeSlotDescription && b.publicTimeSlotDescription
      ? -1
      : !b.publicTimeSlotDescription && a.publicTimeSlotDescription
      ? 1
      : a.publicTimeSlotDescription > b.publicTimeSlotDescription
  )

const getVenueAvailabilityTimeLookup = state => {
  const selectedVenue = state.search.get('selectedVenue')
  const venueKey = selectedVenue === ALL_LOCATIONS ? state.venueInfo.urlKey : selectedVenue
  return state.availabilityLookup.get(venueKey)
}

const getAllAvailabilityTimeLookup = state => state.availabilityLookup

const getInitialResultsFormat = state => selectInitialResultsFormat(state)
const getEnableRequests = state => state.widgetSettings.enableRequests

export const inSearchWindow = (results, enableRequests, searchDate) => {
  let inSearchWindowTime = false
  if (results.length > 0) {
    const searchWindowUpper = searchDate.clone().add('45', 'minutes').set({ second: 0, millisecond: 0 })
    const searchWindowLower = searchDate.clone().subtract('45', 'minutes').set({ second: 0, millisecond: 0 })
    for (let i = 0; i < results.length; i++) {
      if (results[i] === null) {
        continue
      }
      const { timeIso } = results[i]
      const timeIsoMoment = moment(timeIso)
      if (
        timeIsoMoment.isBetween(searchWindowLower, searchWindowUpper) ||
        timeIsoMoment.isSame(searchWindowLower) ||
        timeIsoMoment.isSame(searchWindowUpper)
      ) {
        inSearchWindowTime = true
        break
      }
    }
  }
  return inSearchWindowTime
}

export const getInSearchWindow = createSelector([getInitialResultsFormat, getEnableRequests, getSearchDate], inSearchWindow)

export const deriveInitialResultsFormat = (
  queryMoment,
  foundNearbyResults,
  initialResults,
  availabilityTimeLookup,
  isExperienceMode,
  startOfDayTime,
  widgetSettings
) => {
  const initialResultsIsEmpty =
    _.isEmpty(initialResults) || _.isEmpty(initialResults.before.concat(initialResults.equalTo).concat(initialResults.after))
  if (initialResultsIsEmpty) {
    return []
  } else if (foundNearbyResults) {
    return findClosestResults(initialResults, queryMoment, availabilityTimeLookup, isExperienceMode, startOfDayTime, widgetSettings)
  }
  return findSplitResults(initialResults, availabilityTimeLookup, widgetSettings)
}

export const selectInitialResultsFormat = createSelector(
  [
    selectQueryMoment,
    selectFoundNearbyResult,
    getInitialResults,
    getVenueAvailabilityTimeLookup,
    getIsExperienceMode,
    getStartOfDayTime,
    getWidgetSettings,
  ],
  deriveInitialResultsFormat
)

const getVenueResults = state => state.searchResults.get('venueResults').toArray()

const deriveVenueResults = (venueResults, availabilityTimeLookup) => {
  const momentResults = _.reduce(
    venueResults,
    (accum, venueResult) => {
      const { before, equalTo, after } = venueResult
      if (_.isEmpty(before.concat(equalTo).concat(after))) {
        return accum
      }
      return accum.concat(
        _.mapValues(venueResult, (resultSet, key) => {
          if (key === 'venueKey') {
            return resultSet
          }
          return _.map(resultSet, time => moment(time))
        })
      )
    },
    []
  )
  return _.map(momentResults, result => ({
    venueKey: result.venueKey,
    times: findAdditionalSplitResults(result, availabilityTimeLookup.get(result.venueKey)),
  }))
}

export const selectVenueResults = createSelector([getVenueResults, getAllAvailabilityTimeLookup], deriveVenueResults)

const getAdditionalResults = state => state.searchResults.get('additionalResults').toArray()

const deriveAdditionalResults = (additionalResults, availabilityTimeLookup) => {
  const momentResults = _.map(additionalResults, venueResult =>
    _.mapValues(venueResult, (resultSet, key) => {
      if (key === 'dateMoment') {
        return resultSet
      }
      return _.map(resultSet, time => moment(time))
    })
  )
  const derivedResult = _.reduce(
    momentResults,
    (finalResults, result) => {
      if (finalResults.length === SEARCHRESULTS_DISPLAY_LENGTH) {
        return finalResults
      }
      const times = findAdditionalSplitResults(result, availabilityTimeLookup)
      if (!_.some(times)) {
        return finalResults
      }
      return finalResults.concat({
        dateMoment: result.dateMoment,
        times,
      })
    },
    []
  )
  return _.sortBy(derivedResult, dateResult => dateResult.dateMoment.unix())
}

export const selectAdditionalResults = createSelector([getAdditionalResults, getVenueAvailabilityTimeLookup], deriveAdditionalResults)
