// /* @flow */
// DEPENDENCY: this component requires fort awesome widget package
import * as React from 'react'
import styled from 'styled-components'
import InteractiveEl from '../../Buttons/InteractiveEl'

/* type Event = SyntheticEvent<HTMLElement> */

/* type Props = {
  quantity: number,
  onChange: number => void,
  minQuantity?: number,
  maxQuantity?: number,
  customStepperWrapper?: Array<string | (() => mixed)>,
  customStepperButton?: Array<string | (() => mixed)>,
  customStepperNumber?: Array<string | (() => mixed)>,
} */

class QuantityStepper extends React.Component /* <Props> */ {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    customStepperWrapper: [],
    customStepperButton: [],
    customStepperNumber: [],
  }

  onPlusClick = (event /* : Event */) => {
    event.stopPropagation()
    this.props.onChange(this.props.quantity + 1)
  }

  onMinusClick = (event /* : Event */) => {
    event.stopPropagation()
    this.props.onChange(this.props.quantity - 1)
  }

  render() {
    const isMinusEnabled = (!this.props.minQuantity && this.props.minQuantity !== 0) || this.props.minQuantity < this.props.quantity
    const isPlusEnabled = (!this.props.maxQuantity && this.props.maxQuantity !== 0) || this.props.maxQuantity > this.props.quantity
    return (
      <StepperWrapper customStepperWrapper={this.props.customStepperWrapper}>
        <StepperButton
          disabled={!isMinusEnabled}
          onClick={isMinusEnabled && this.onMinusClick}
          icon="minus"
          customStepperButton={this.props.customStepperButton}
          aria-label="Decrement"
          data-test="sr-upgrade-decrement"
        />
        <StepperNumber customStepperNumber={this.props.customStepperNumber} data-test="sr-upgrade-quantity-label">
          {this.props.quantity}
        </StepperNumber>
        <StepperButton
          onClick={isPlusEnabled && this.onPlusClick}
          icon="plus"
          disabled={!isPlusEnabled}
          customStepperButton={this.props.customStepperButton}
          aria-label="Increment"
          data-test="sr-upgrade-increment"
        />
      </StepperWrapper>
    )
  }
}

const StepperWrapper = styled.div`
  width: 122px;
  height: 50px;
  padding: 10px 7px;
  text-align: center;
  font-family: Roboto;
  box-sizing: border-box;
  ${props => props.customStepperWrapper};
`

const StepperButton = styled.button`
  &:before {
    content: ${props => (props.icon === 'minus' ? "'\f036'" : "'\f035'")};
  }
  display: inline-block;
  height: 30px;
  line-height: 28px;
  width: 30px;
  margin: 0 3px;
  border: 1px solid black;
  border-radius: 50%;
  box-sizing: inherit;
  font-family: 'WidgetIcons';
  font-style: normal;
  text-rendering: auto;
  :focus-visible {
    opacity: 0.8;
    outline: 5px auto Highlight;
    outline: -webkit-focus-ring-color auto 1px;
  }
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1.0')};
  background-color: transparent;
  ${props => props.customStepperButton};
`

const StepperNumber = styled.div`
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
  margin: 0 3px;
  user-select: none;
  cursor: default;
  box-sizing: inherit;
  ${props => props.customStepperNumber};
`

export default QuantityStepper
