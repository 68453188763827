import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { changeFormField } from '../actions/forms'
import { toggleModalDisplay } from '../actions/navigation'
import styles from '../assets/styles/checkout'
import { selectTimeSlotEntity } from '../utils/selectors'
import { StripePaymentElementFields } from '@sevenrooms/payments'

class CheckoutPaymentStripe extends Component {
  constructor(props) {
    super(props)
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'cardFirstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'cardLastName')
  }

  render() {
    const {
      cardFirstName,
      cardLastName,
      cost,
      fontsColorPrimary,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorError,
      colorPrimary,
      colorCheckoutCellBackground,
      colorLines,
      paymentFields,
      topElementId,
      formErrors,
      venueInfo,
      fontFamily,
      textPayment,
      textCardRequired,
      textFirstName,
      textLastName,
      selectedLanguage,
      stripeIntentClientSecret,
      baseUrl,
      mediaUrl,
    } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    const stripeOptions = {
      style: {
        base: {
          fontFamily: styles.stripeIframe.fontFamily,
          fontSize: styles.stripeIframe.fontSizeBase,
          color: fontsColorCheckoutActive,
          lineHeight: styles.stripeIframe.lineHeight,
          '::placeholder': { color: fontsColorCheckoutInactive },
        },
        invalid: { color: colorError },
      },
    }
    return (
      <div id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{cost ? textPayment : textCardRequired}</span>
        </div>
        <div style={styles.infoForm}>
          <div
            style={[
              styles.stripePaymentElement,
              {
                backgroundColor: colorCheckoutCellBackground,
              },
            ]}
          >
            <StripePaymentElementFields
              stripe={this.props.stripe || null}
              clientSecret={stripeIntentClientSecret}
              locale={selectedLanguage}
              fonts={`${baseUrl}${mediaUrl}css/fonts.css`}
              appearance={{
                variables: {
                  fontFamily,
                  fontSizeBase: styles.stripeIframe.fontSizeBase,
                  fontSizeSm: styles.stripeIframe.fontSizeBase,
                  fontSizeXs: styles.stripeIframe.fontSize,
                  colorPrimary,
                  colorTextPlaceholder: fontsColorCheckoutInactive,
                  colorTextSecondary: fontsColorCheckoutActive,
                  colorText: fontsColorPrimary,
                  tabIconSelectedColor: fontsColorPrimary,
                },
                rules: {
                  '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                    backgroundColor: 'transparent',
                    boxShadow: `0 0 1px 1px ${colorLines}`,
                    border: `1px solid ${colorLines}`,
                  },
                  '.Tab--selected': {
                    border: `1px solid ${fontsColorPrimary}`,
                    boxShadow: `0 0 1px 1px ${colorLines}`,
                  },
                  '.TabLabel': {
                    fontSize: styles.stripeIframe.fontSizeBase,
                    fontWeight: 'normal',
                    color: fontsColorPrimary,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { formFields, widgetSettings, venueEntities, searchResults } = state
  const selectedVenue = venueEntities[searchResults.get('timeSlotVenue')]
  const languageStrings = selectLanguageStrings(state)
  return {
    cardFirstName: formFields.get('cardFirstName'),
    cardLastName: formFields.get('cardLastName'),
    cost: selectTimeSlotEntity(state).cost,
    formErrors: formFields.get('formErrors').toJS(),
    venueInfo: state.venueInfo,
    fontFamily: widgetSettings.font,
    fontsColorPrimary: widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: widgetSettings.fontsColorCheckoutActive,
    colorError: widgetSettings.colorError,
    colorPrimary: widgetSettings.colorPrimary,
    colorCheckoutCellBackground: widgetSettings.colorCheckoutCellBackground,
    colorLines: widgetSettings.colorLines,
    baseUrl: widgetSettings.baseUrl,
    mediaUrl: widgetSettings.mediaUrl,
    paymentSystem: selectedVenue.paymentSystem,
    // text
    textPayment: languageStrings.textPayment,
    textCardRequired: languageStrings.textCardRequired,
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textExpYear: languageStrings.textExpYear,
    textCreditCardExpYearDefaultLabel: languageStrings.textCreditCardExpYearDefaultLabel,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
})

CheckoutPaymentStripe = connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPaymentStripe))

export default CheckoutPaymentStripe
