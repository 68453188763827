import styled from 'styled-components'

export const WidgetButton = styled.button`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  ${props => !props.withDefaultOutline && 'outline: none;'} font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  :focus-visible {
    opacity: 0.8;
    outline: 5px auto Highlight;
    outline: -webkit-focus-ring-color auto 1px;
  }
`
