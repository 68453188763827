const styles = {
  footerWrapper: {
    width: '100%',
    backgroundColor: 'grey',
  },
  fixedFooter: {
    position: 'fixed',
    bottom: '0',
    left: '2px',
    right: '2px',
    padding: '10px 15px',
    maxWidth: '696px',
    margin: '0 auto',
  },
  relativeFooter: {
    position: 'relative',
    padding: '10px 15px',
  },
  footerButton: {
    height: '42px',
    width: '100%',
    color: 'white',
    fontSize: '16px',
    letterSpacing: '0.3px',
  },
  leadText: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: '14px',
    marginBottom: '10px',
    width: '100%',
  },
}

export default styles
