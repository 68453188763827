const styles = {
  cellBox: {
    display: 'table-cell',
    height: '100px',
  },
  viewWrapper: {
    display: 'table',
    height: '100%',
    width: '100%',
    minHeight: '20vh',
  },
  viewGroup: {
    display: 'table-row-group',
  },
  viewRow: {
    display: 'table-row',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
  },
  fullHeight: {
    height: '100%',
  },
  imageWrapper: {
    width: '100%',
    height: '0px',
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width: 399px)': {
      height: '171px',
    },
    '@media (min-width: 400px)': {
      paddingBottom: '42.85714%',
    },
  },
  imageContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  imageContent: {
    position: 'absolute',
    top: '-9999px',
    bottom: '-9999px',
    left: '-9999px',
    right: '-9999px',
    margin: 'auto',
    width: '100%',
    minHeight: '100%',
    minWidth: '400px',
  },
  lineSeperator: {
    borderStyle: 'solid',
    borderWidth: '1px',
    margin: '0',
  },
  stepRow: {
    position: 'relative',
    outline: 'none',
  },
  hiddenInput: {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    zIndex: '-99999',
    opacity: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50px',
    fontSize: '20px',
  },
  widgetTitle: {
    textAlign: 'center',
    padding: '3px 0',
    fontWeight: '500',
  },
  hijacked: {
    padding: '60px 80px',
    textAlign: 'center',
    '@media (max-width: 500px)': {
      padding: '40px 48px',
    },
  },
  infoIcon: {
    fontSize: '13px',
    cursor: 'pointer',
  },
  buttonInfoIcon: {
    backgroundColor: 'inherit',
    border: '0',
    padding: '0 0 0 8px',
  },
}

export default styles
