// /* @flow */
import * as React from 'react'
import './utils/flags.css'
import styled, { css } from 'styled-components'
import downArrow from './down-arrow.gif'
import upArrow from './up-arrow.gif'

/* type Props = {
  onDropDownItemClick: string => mixed,
  onDropDownListClick: void => mixed,
  countryCode: string,
  countryName: string,
  dialCode: string,
  selectedCountryCode: string,
  hideFlag: boolean,
} */

class FlagListItem extends React.Component /* <Props> */ {
  flagChangeHandler = (countryCode /* : string */) => {
    this.props.onDropDownItemClick(countryCode)
  }

  generateFlag = (showDefault = false) => {
    if (this.props.hideFlag) {
      return showDefault ? (
        <NoFlagIconContainer>
          <i className="fa fa-globe" />
        </NoFlagIconContainer>
      ) : (
        <div />
      )
    }
    return <Flag className={`flag flag-${this.props.countryCode}`} />
  }

  flagChange = this.flagChangeHandler.bind(this, this.props.countryCode)

  generateArrow = () => {
    if (!this.props.isDropDownOpen) {
      return (
        <Arrow
          customArrow={css`
            background-image: url('${downArrow}');
          `}
        />
      )
    }
    return (
      <Arrow
        customArrow={css`
          background-image: url('${upArrow}');
        `}
      />
    )
  }

  render() {
    const isSelected = this.props.countryCode === this.props.selectedCountryCode
    const isPreferredCountry = this.props.countryCode === 'ca'
    return (
      <FlagItem>
        {this.props.isDropDown ? (
          <CountryDropDownItem onClick={this.flagChange} isPreferredCountry={isPreferredCountry} isSelected={isSelected}>
            {this.generateFlag()}
            <CountryName>{this.props.countryName}</CountryName>
            <DialCode>+{this.props.dialCode}</DialCode>
          </CountryDropDownItem>
        ) : (
          <SelectedCountry onClick={this.props.onDropDownListClick}>
            {this.generateFlag(true)}
            {this.generateArrow()}
          </SelectedCountry>
        )}
      </FlagItem>
    )
  }
}

FlagListItem.defaultProps = {
  customArrow: [],
  customFlagDropDownList: [],
}

export const FlagListHeader = ({ headerText }) => (
  <FlagItem>
    <HeaderDropDownItem>{headerText}</HeaderDropDownItem>
  </FlagItem>
)

const FlagItem = styled.div`
  list-style-position: inside;
  font-size: 12px;
`

const CountryDropDownItem = styled.div`
  padding: 5px 10px;
  display: inline-block;
  width: 250px;
  background-color: ${props => (props.isSelected ? '#c7e2f1' : 'white')};
  border-bottom: ${props => (props.isPreferredCountry ? '1pt solid black' : 'none')};
  :hover {
    background-color: #c7e2f1;
    cursor: pointer;
  }
  :active {
    background-color: white;
  }
`

const HeaderDropDownItem = styled(CountryDropDownItem)`
  background-color: white;
  border-bottom: 1pt solid black;
  :hover {
    background-color: white;
    cursor: default;
  }
`

const CountryName = styled.span`
  font-size: 12px;
`

const DialCode = styled.span`
  color: #999;
  font-size: 12px;
  padding-left: 5px;
`

const SelectedCountry = styled.div`
  overflow: hidden;
`

const Flag = styled.div`
  width: 16px;
  height: 11px;
  margin: 3px 5px 4px 0;
  display: inline-block;
  float: left;
`

const NoFlagIconContainer = styled.div`
  height: 15px;
  margin: -1px 5px 5px 0;
  float: left;
  font: 15px Roboto;
`

const Arrow = styled.div`
  width: 7px;
  height: 10px;
  margin-left: 3px;
  overflow: hidden;
  ${props => props.customArrow};
`

export default FlagListItem
