const styles = {
  cover: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '25',
    margin: '0 auto',
    // maxWidth: '700px',
  },
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '92%',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    borderRadius: '7px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  body: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px',
    padding: '14px',
  },
  text: {
    fontSize: '16px',
    display: 'block',
    width: '100%',
    maxWidth: '150px',
    margin: '0 auto',
    paddingBottom: '14px',
    textAlign: 'center',
    color: '#333333',
    wordWrap: 'break-word',
  },
  gif: {
    display: 'block',
    margin: '0 auto',
    height: '60px',
  },
  okButton: {
    display: 'block',
    fontSize: '16px',
    padding: '7px 10px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    color: '#fff',
    border: '0',
    width: '81%',
    margin: 'auto',
  },
  hiddenAlert: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
}

export default styles
