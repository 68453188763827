export const colorLuminance = (hexVal, lumVal) => {
  let hex = hexVal
  let lum = lumVal
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0
  let rgb = '#'
  let c
  let i
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += `00${c}`.substr(c.length)
  }
  return rgb
}

export const hexToRgb = (hex, alpha) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const resultAlpha = alpha === undefined ? 1 : alpha
  if (result) {
    const rgbArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    return `rgba(${rgbArr.join(', ')}, ${resultAlpha})`
  }
  return null
}

export const getContrastYIQ = (hexColor, darkColorDefault, lightColorDefault) => {
  const darkColor = darkColorDefault || 'black'
  const lightColor = lightColorDefault || 'white'
  // * Source: http://24ways.org/2010/calculating-color-contrast
  const r = parseInt(hexColor.substr(1, 2), 16)
  const g = parseInt(hexColor.substr(3, 2), 16)
  const b = parseInt(hexColor.substr(5, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? darkColor : lightColor
}

export const getContrastingColor = getContrastYIQ
