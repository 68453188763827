import _ from 'lodash'
import moment from 'moment-timezone'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import type { ORDER_METHOD_TYPE } from 'mgr/pages/single-venue/settings/types/ordering/Order.types'

type ORDER_METHODS_TYPE = Record<ORDER_METHOD_TYPE, string>

export const FULFILLMENT_METHOD = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
  PICKUP_OR_DELIVERY: 'PICKUP_OR_DELIVERY',
  PICKUP_OR_ROOM_SERVICE: 'PICKUP_OR_ROOM_SERVICE',
  ON_PREMISE: 'ON_PREMISE',
  ROOM_SERVICE: 'ROOM_SERVICE',
}

export const FULFILLMENT_METHOD_ORDERED_FOR_SITE_FORM = [
  FULFILLMENT_METHOD.PICKUP,
  FULFILLMENT_METHOD.PICKUP_OR_DELIVERY,
  FULFILLMENT_METHOD.DELIVERY,
  FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE,
  FULFILLMENT_METHOD.ROOM_SERVICE,
  FULFILLMENT_METHOD.ON_PREMISE,
]

export const FULFILLMENT_METHOD_TITLE = {
  [FULFILLMENT_METHOD.PICKUP]: 'Pickup Only',
  [FULFILLMENT_METHOD.DELIVERY]: 'Delivery Only',
  [FULFILLMENT_METHOD.ROOM_SERVICE]: 'Room Service Only',
  [FULFILLMENT_METHOD.PICKUP_OR_DELIVERY]: 'Pickup and Delivery',
  [FULFILLMENT_METHOD.ON_PREMISE]: 'On-Premise',
  [FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE]: 'Pickup and Room Service',
}

export const ORDER_METHOD_TITLE = {
  [FULFILLMENT_METHOD.PICKUP]: 'Pickup',
  [FULFILLMENT_METHOD.DELIVERY]: 'Delivery',
  [FULFILLMENT_METHOD.ROOM_SERVICE]: 'Room Service',
  [FULFILLMENT_METHOD.ON_PREMISE]: 'On-Premise',
}

export const WEEK_INDEX_TO_TITLE = _.fromPairs(_.range(7).map(i => [i, moment.weekdays(i + 1)]))
export const WEEK_INDEX_TO_TITLE_SHORT = _.fromPairs(_.range(7).map(i => [i, moment.weekdaysShort(i + 1)]))
export const WEEK_INDEX_TO_TITLE_MIN = _.fromPairs(_.range(7).map(i => [i, moment.weekdaysMin(i + 1)]))

export const PROMO_CODE_ERRORS = {
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  INVALID_VENUE: 'INVALID_VENUE',
  EXPIRED: 'EXPIRED',
  EXHAUSTED: 'EXHAUSTED',
  MISSING_PLATFORM_TYPE: 'MISSING_PLATFORM_TYPE',
  BELOW_MIN: 'BELOW_MIN',
}

export const ORDER_METHODS: ORDER_METHODS_TYPE = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
  PRE_ORDER: 'PRE_ORDER',
  ON_PREMISE: 'ON_PREMISE',
  ROOM_SERVICE: 'ROOM_SERVICE',
}

export const ORDER_STATUSES_OPTIONS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  VOID: 'VOID',
  FAILED_INJECTION: 'FAILED_INJECTION',
  QUEUED: 'QUEUED',
  WORKING_ON_ORDER: 'WORKING_ON_ORDER',
  READY_PICKUP: 'READY_PICKUP',
  READY_DRIVER: 'READY_DRIVER',
  WITH_DRIVER: 'WITH_DRIVER',
}

export const ORDER_STATUSES_TITLES = {
  [ORDER_STATUSES_OPTIONS.OPEN]: 'Open',
  [ORDER_STATUSES_OPTIONS.CLOSED]: 'Closed',
  [ORDER_STATUSES_OPTIONS.VOID]: 'Void',
  [ORDER_STATUSES_OPTIONS.FAILED_INJECTION]: 'Failed injection',
  [ORDER_STATUSES_OPTIONS.QUEUED]: 'Queued',
  [ORDER_STATUSES_OPTIONS.WORKING_ON_ORDER]: 'Working on Order',
  [ORDER_STATUSES_OPTIONS.READY_PICKUP]: 'Ready for Pickup',
  [ORDER_STATUSES_OPTIONS.READY_DRIVER]: 'Ready for Driver',
  [ORDER_STATUSES_OPTIONS.WITH_DRIVER]: 'With Driver',
}

export const ORDER_STATUSES_INITIALS = {
  [ORDER_STATUSES_OPTIONS.OPEN]: 'O',
  [ORDER_STATUSES_OPTIONS.CLOSED]: 'C',
  [ORDER_STATUSES_OPTIONS.VOID]: 'V',
  [ORDER_STATUSES_OPTIONS.FAILED_INJECTION]: 'FI',
  [ORDER_STATUSES_OPTIONS.QUEUED]: 'Q',
  [ORDER_STATUSES_OPTIONS.WORKING_ON_ORDER]: 'W',
  [ORDER_STATUSES_OPTIONS.READY_PICKUP]: 'R',
  [ORDER_STATUSES_OPTIONS.READY_DRIVER]: 'R',
  [ORDER_STATUSES_OPTIONS.WITH_DRIVER]: 'D',
}
export const ORDER_STATUSES_BG_COLOR = {
  [ORDER_STATUSES_OPTIONS.OPEN]: '#47b599',
  [ORDER_STATUSES_OPTIONS.CLOSED]: '#cacbcc',
  [ORDER_STATUSES_OPTIONS.VOID]: '#7c66f0',
  [ORDER_STATUSES_OPTIONS.FAILED_INJECTION]: '#ee1b56',
  [ORDER_STATUSES_OPTIONS.QUEUED]: '#eeb41b',
  [ORDER_STATUSES_OPTIONS.WORKING_ON_ORDER]: '#FFBD3D',
  [ORDER_STATUSES_OPTIONS.READY_PICKUP]: '#347baf',
  [ORDER_STATUSES_OPTIONS.READY_DRIVER]: '#347baf',
  [ORDER_STATUSES_OPTIONS.WITH_DRIVER]: '#1D2CB1',
}

export const ORDER_ACCOUNT_TYPES_ENABLED = new Set([AccountTypes.STRIPE, AccountTypes.FREEDOMPAY])

export const SALUTATIONS_DEFAULT = ['', 'Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.']
export const SALUTATIONS_MIDDLE_EAST = [
  'Company & Group',
  'H.E.',
  'H.E. Sheikh',
  'H.E. Sheikha',
  'H.H.',
  'H.H. Sheikh',
  'H.H. Sheikha',
  'H.R.H',
  'Sheikh',
]
export const SALUTATIONS_GB = [
  'Ambassador',
  'Baron',
  'Baroness',
  'Captain',
  'Colonel',
  'Countess',
  'Dame',
  'Duchess',
  'Lady',
  'Lieutenant',
  'Lord',
  'H.E.',
  'H.M.',
  'H.R.H',
  'Hon',
  'Major',
  'Minister',
  'President',
  'Prince',
  'Princess',
  'Prof',
  'Rev',
  'Sheikh',
  'Sheikha',
  'Sir',
  'Sir & Lady',
  'Them',
  'They',
]
export const SALUTATIONS_EU_GB = ['Sir', 'Dame', 'Lord', 'Lady']
export const SALUTATIONS_SPANISH = ['Sr', 'Sra', 'Stra', 'Srta']

export const SALUTATIONS_SBM_MONACO_EU = [
  'Frau',
  'Fraulein',
  'Gospodin',
  'Gospoja',
  'Herr',
  'Madame',
  'Mademoiselle',
  'Monsieur',
  'Senor',
  'Senora',
  'Senorita',
  'Signor',
  'Signora',
  'Signorina',
]

export const SALUTATIONS_WITH_MX = ['Mx.']

export const SALUTATIONS_RITZ_LONDON = [
  'Mx.',
  'Prof.',
  'Baron',
  'Baroness',
  'Countess',
  'Dame',
  'Duchess',
  'Sir',
  'Lord',
  'Lady',
  'Hon.',
  'Rev.',
  'Major',
  'Lieutenant',
  'Captain',
  'Colonel',
]
