// /* @flow */
import { minQuantityTypes, maxQuantityTypes } from '../constants'
/* import type { MinQuantityTypes, MaxQuantityTypes } from '../constants' */

export const getTruncateSize = isMobile => (isMobile ? 40 : 130)

export const convertFromBlockToInline = inventoryEntityDescription =>
  inventoryEntityDescription.replace('<p>', '<span>').replace('</p>', '</span>')

export const extractEmptyTextFromHtmlTag = inventoryEntityDescription =>
  inventoryEntityDescription.replace('<p>', '').replace('</p>', '').replace('<br>', '')

export const getCategoryMinQuantity = (
  minQuantityType /* : MinQuantityTypes */,
  minQuantityInt /* : number */,
  partySize /* : number */
) => {
  if (minQuantityType === minQuantityTypes.NONE_REQUIRED) {
    return 0
  }
  if (minQuantityType === minQuantityTypes.NUMBER_OF_GUESTS) {
    return partySize
  }
  return minQuantityInt
}

export const getCategoryMaxQuantity = (
  maxQuantityType /* : MaxQuantityTypes */,
  maxQuantityInt /* : number */,
  partySize /* : number */
) => {
  if (maxQuantityType === maxQuantityTypes.UNLIMITED) {
    return Infinity
  }
  if (maxQuantityType === maxQuantityTypes.NUMBER_OF_GUESTS) {
    return partySize
  }
  return maxQuantityInt
}
