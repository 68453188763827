import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ModalContainer, ModalShadow, ModalHeader, ModalClose } from 'mgr/layout/StyledComponentUtils'
import type { WidgetTheme } from 'svr/widget/theme'

const RowWrapper = styled.div<{ label?: string }>`
  position: relative;
  width: 100%;
  padding: ${props => (props.label ? '10px 0' : '20px 0')};
  background-color: ${props => props.theme.white};
`

const RowLabel = styled.span`
  display: inline-block;
  padding: 0 3.2345%;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.black};
`

const RowDescription = styled.span`
  display: block;
  padding: 0 3.2345%;
  margin-bottom: 5px;
  font-size: 15px;
  color: ${props => props.theme.darkGrey};
`

const ModalHeaderImage = styled.img`
  width: 100%;
  height: auto;
`

const ModalLabel = styled(RowLabel)<{ theme: WidgetTheme }>`
  display: block;
  padding: 0;
  color: ${props => props.theme.fontsColorSummaryBar};
`

const ModalDescription = styled(RowDescription)`
  padding: 1em 3.2345%;
  color: ${props => props.theme.black};
`

const StyledModalClose = styled(ModalClose)<{ theme: WidgetTheme }>`
  color: ${props => props.theme.fontsColorSummaryBar};
`

const InnerRow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`
export const StyledModalHeader = styled(ModalHeader)<{ theme: WidgetTheme }>`
  background-color: ${props => props.theme.colorSummaryBar};
  color: ${props => props.theme.fontsColorSummaryBar};
`

interface SideScrollRowProps {
  children: never
  label?: string
  crossSellVenueShortDescription?: string
  crossSellVenueLongDescription?: string
  headerImg?: string
}

function SideScrollRow({ children, label, crossSellVenueShortDescription, crossSellVenueLongDescription, headerImg }: SideScrollRowProps) {
  const innerRawRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const innerRowWidth = innerRawRef.current?.getBoundingClientRect().width || 0
    const scrollRowWidth = 555 // precomputed
    if (innerRawRef.current) {
      innerRawRef.current.scrollLeft = (scrollRowWidth - innerRowWidth) / 2
    }
  }, [])

  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false)
  return (
    <RowWrapper label={label}>
      {label && (
        <RowLabel>
          {label}
          {crossSellVenueLongDescription && (
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ marginLeft: '4px', cursor: 'pointer' }}
              className="fa-sm"
              onClick={() => {
                setIsDetailedModalOpen(true)
              }}
            />
          )}
        </RowLabel>
      )}
      {crossSellVenueShortDescription && <RowDescription>{crossSellVenueShortDescription}</RowDescription>}
      <InnerRow ref={innerRawRef}>{children}</InnerRow>
      {isDetailedModalOpen && (
        <>
          <ModalContainer>
            <StyledModalHeader>
              {label && <ModalLabel>{label}</ModalLabel>}
              <StyledModalClose onClick={() => setIsDetailedModalOpen(false)} />
            </StyledModalHeader>
            {headerImg && <ModalHeaderImage data-test="sr-modal-header-image" src={headerImg} />}
            <ModalDescription>{crossSellVenueLongDescription}</ModalDescription>
          </ModalContainer>
          <ModalShadow onClick={() => setIsDetailedModalOpen(false)} />
        </>
      )}
    </RowWrapper>
  )
}

export default SideScrollRow
