import _ from 'lodash'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'

const initialState = {
  redemptions: [],
  redemptionCardBalance: 0,
  isViewBalanceVisible: false,
  isRedemptionFormVisible: false,
  redemptionError: null,
  isFormDisabled: false,
}

export const redemptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_REDEMPTION_FORM:
    case ActionTypes.CLEAR_REDEMPTION_FORM: {
      return {
        ...state,
        redemptionCardBalance: 0,
        isViewBalanceVisible: false,
        isRedemptionFormVisible: true,
        redemptionError: null,
      }
    }
    case ActionTypes.CANCEL_REDEMPTION_FORM: {
      return {
        ...state,
        redemptionCardBalance: 0,
        isViewBalanceVisible: false,
        isRedemptionFormVisible: false,
        redemptionError: null,
      }
    }
    case ActionTypes.VIEW_REDEMPTION_BALANCE: {
      const { redemptionCardBalance } = action
      return {
        ...state,
        redemptionCardBalance,
        redemptionError: null,
        isViewBalanceVisible: true,
      }
    }
    case ActionTypes.APPLY_REDEMPTION: {
      if (action.redemption) {
        return {
          ...state,
          redemptionCardBalance: 0,
          isViewBalanceVisible: false,
          isRedemptionFormVisible: false,
          redemptionError: null,
          redemptions: _.concat(state.redemptions, [action.redemption]),
        }
      }

      return state
    }
    case ActionTypes.REMOVE_REDEMPTION: {
      const { redemptions } = state

      return {
        ...state,
        redemptionError: null,
        redemptions: _.filter(redemptions, (_redemption, index) => index !== action.index),
      }
    }
    case ActionTypes.SHOW_REDEMPTION_ERROR: {
      return {
        ...state,
        redemptionError: action.redemptionError,
        isFormDisabled: action.isFormDisabled,
      }
    }
    case ActionTypes.POST_CHECKOUT_FAILURE:
    case ActionTypes.CLEAR_REDEMPTIONS_ON_PROMO_CODE: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
