import _ from 'lodash'
import moment from 'moment-timezone'
import Radium from 'radium'
import React from 'react'
import styled, { css } from 'styled-components'
import styles from '../assets/styles/calendar'
import CalendarCell from './CalendarCell'

const NUM_WEEKDAYS = 7

const CalendarBodyWrapper = styled.div`
  position: relative;
  height: 0px;
  font-weight: 300;
  ${props => props.aspectRatio};
`

const calContianerAspectRatioShort = css`
  @media (max-width: 399px) {
    padding-bottom: 86%;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    padding-bottom: 72%;
  }
  @media (min-width: 500px) and (max-width: 599px) {
    padding-bottom: 65%;
  }
  @media (min-width: 600px) {
    padding-bottom: 57%;
  }
`

const calContianerAspectRatioLong = css`
  @media (max-width: 399px) {
    padding-bottom: 100%;
  }
  @media (min-width: 400px) and (max-width: 499px) {
    padding-bottom: 85%;
  }
  @media (min-width: 500px) and (max-width: 599px) {
    padding-bottom: 76%;
  }
  @media (min-width: 600px) {
    padding-bottom: 67%;
  }
`

const CalendarBody = ({
  calendarMoment,
  currentMoment,
  selectDate,
  availability,
  bookingEnd,
  saleStartDate,
  saleEndDate,
  colorHover,
  colorCellBackground,
  fontsColorInactive,
  fontsColorPrimary,
  selectedMoment,
  colorPrimary,
  fontColorButton,
  selectedLanguageLocale,
}) => {
  const numEmptyCells = calendarMoment.clone().set('date', 1).day()
  const numDays = calendarMoment.daysInMonth()
  const numCells = numEmptyCells + numDays
  const numRows = Math.ceil(numCells / 7)

  const dayHeader = _.times(NUM_WEEKDAYS, idx => {
    const dayShortMoment = moment()
    if (selectedLanguageLocale) {
      dayShortMoment.locale(selectedLanguageLocale)
    }
    const dayShort = dayShortMoment.day(idx).format('ddd').toUpperCase()
    return (
      <th key={dayShort} style={styles.dayLabel}>
        {dayShort}
      </th>
    )
  })

  const calCells = []
  let rowCells = []
  let rowCtr = 0
  _.times(numCells, idx => {
    if (idx < numEmptyCells) {
      return rowCells.push(<CalendarCell key={`e.${idx}`} />)
    }
    const date = idx - numEmptyCells + 1
    if (idx % 7 === 0) {
      calCells.push(
        <tr key={`r${rowCtr}`} style={styles.fontRoboto}>
          {rowCells}
        </tr>
      )
      rowCtr += 1
      rowCells = []
    }

    const cellMoment = calendarMoment.clone().set('date', date)
    const cellDate = cellMoment.format('YYYY-MM-DD')

    const isSelected =
      selectedMoment.date() === cellMoment.date() &&
      selectedMoment.month() === cellMoment.month() &&
      selectedMoment.year() === cellMoment.year()

    const isAvailable =
      saleStartDate && saleEndDate
        ? cellMoment.isSameOrAfter(saleStartDate) && cellMoment.isSameOrBefore(saleEndDate)
        : Boolean(availability[cellDate]) && (!bookingEnd || cellMoment.isSameOrBefore(bookingEnd, 'day'))
    return rowCells.push(
      <CalendarCell
        key={cellDate}
        cellMoment={cellMoment}
        currentMoment={currentMoment}
        selectDate={selectDate}
        isAvailable={isAvailable}
        isSelected={isSelected}
        colorHover={colorHover}
        colorCellBackground={colorCellBackground}
        fontsColorInactive={fontsColorInactive}
        fontsColorPrimary={fontsColorPrimary}
        colorPrimary={colorPrimary}
        fontColorButton={fontColorButton}
      />
    )
  })
  calCells.push(
    <tr key={`r${rowCtr}`} style={styles.fontRoboto}>
      {rowCells}
    </tr>
  )

  const aspectRatio = numRows === 6 ? calContianerAspectRatioLong : calContianerAspectRatioShort
  return (
    <CalendarBodyWrapper aspectRatio={aspectRatio}>
      <div style={styles.calWrapper}>
        <table role="presentation" style={styles.calTable}>
          <tbody>
            <tr style={[styles.dayHeader, { color: fontsColorPrimary }]}>{dayHeader}</tr>
            {calCells}
          </tbody>
        </table>
      </div>
    </CalendarBodyWrapper>
  )
}

CalendarBody.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  calendarMoment: React.PropTypes.object.isRequired,
  currentMoment: React.PropTypes.object.isRequired,
  selectedMoment: React.PropTypes.object,
  bookingEnd: React.PropTypes.object,
  selectDate: React.PropTypes.func,
  availability: React.PropTypes.objectOf(React.PropTypes.bool),
  /* eslint-enable */
  colorHover: React.PropTypes.string,
  colorCellBackground: React.PropTypes.string,
  fontsColorInactive: React.PropTypes.string,
  fontsColorPrimary: React.PropTypes.string,
  colorPrimary: React.PropTypes.string,
  fontColorButton: React.PropTypes.string,
  selectedLanguageLocale: React.PropTypes.string,
}

CalendarBody.defaultProps = {
  selectedMoment: null,
  selectDate: () => {},
  availability: {},
  colorHover: 'rgba(50, 70, 84, 1.00)',
  colorCellBackground: 'rgba(50, 70, 84, 1.00)',
  fontsColorInactive: 'rgba(50, 70, 84, 1.00)',
  fontsColorPrimary: 'rgba(50, 70, 84, 1.00)',
  colorPrimary: 'rgba(50, 70, 84, 1.00)',
  selectedLanguageLocale: '',
}

export default Radium(CalendarBody)
