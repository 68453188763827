// /* @flow */
import _ from 'lodash'
import { createSelector } from 'reselect'
import { snakeCaseObject } from 'svr/common/ObjectUtils'
import { calculateCategoryFees } from '../../dining/src/utils/calculateFees'
import { getCategoryMinQuantity } from './utils/upsellsHelpers'

const getUpsellCategories = state => state.upsells.main.upsellCategories
const getCategoryEntities = state => state.upsells.entities.categories

export const selectCategoryEntities = createSelector([getUpsellCategories, getCategoryEntities], (upsellCategories, categoryEntities) =>
  _.sortBy(
    _.reduce(
      upsellCategories,
      (result, categoryId) => {
        const categoryEntity = categoryEntities[categoryId]
        return categoryEntity && !_.isEmpty(categoryEntity.inventories) ? [...result, categoryEntity] : result
      },
      []
    ),
    // negative sort order is used here as a reverse
    entity => -entity.sortOrder
  )
)

export const getUpsellInventoryEntities = state => state.upsells.entities.inventories
export const getUpsellCategoriesEntities = state => state.upsells.entities.categories
export const getInventoryEdits = state => state.upsells.inventoryEdits
export const getIsFeesInPriceDisplayed = state => state.widgetSettings.isFeesInPriceDisplayed
export const getDefaultServiceCharge = state => state.venueInfo.defaultServiceCharge
export const getDefaultGratuity = state => state.venueInfo.defaultGratuity

export const selectUpsellTotal = createSelector(
  [
    getUpsellInventoryEntities,
    getInventoryEdits,
    getUpsellCategoriesEntities,
    getIsFeesInPriceDisplayed,
    getDefaultServiceCharge,
    getDefaultGratuity,
  ],
  (upsellInventories, inventoryEdits, upsellCategories, isFeesInPriceDisplayed, defaultServiceCharge, defaultGratuity) =>
    _.reduce(
      inventoryEdits,
      (total, inventoryEdit, inventoryId) => {
        const { quantity } = inventoryEdit
        let { price } = upsellInventories[inventoryId]
        if (isFeesInPriceDisplayed && upsellCategories[upsellInventories[inventoryId].categoryId]) {
          const category = upsellCategories[upsellInventories[inventoryId].categoryId]
          price += calculateCategoryFees(isFeesInPriceDisplayed, price, category, defaultServiceCharge, defaultGratuity)
        }
        const priceForQuantity = price * quantity
        return {
          quantity: total.quantity + quantity,
          price: total.price + priceForQuantity,
        }
      },
      { price: 0, quantity: 0 }
    )
)

const hasInventorySelected = (inventoryIds, inventoryEdits) => _.some(inventoryIds, id => inventoryEdits[id].quantity > 0)

const sumCostOfSelectedInventory = (inventoryIds, upsellInventories, inventoryEdits) =>
  _.sum(_.map(inventoryIds, id => upsellInventories[id].price * inventoryEdits[id].quantity))

export const selectedUpsellInventoriesSortedPrices = createSelector(
  [selectCategoryEntities, getUpsellInventoryEntities, getInventoryEdits, selectUpsellTotal],
  (upsellCategories, upsellInventories, inventoryEdits, upsellTotal) => {
    const upsellInventoriesArray = []
    upsellCategories.forEach(upsellCategory => {
      upsellCategory.inventories.forEach(upsellInventoryId => {
        if (inventoryEdits[upsellInventoryId].quantity) {
          const upsellInventory = upsellInventories[upsellInventoryId]
          const upsell = {
            ...upsellInventory,
            categoryObj: upsellCategory,
            quantity: inventoryEdits[upsellInventoryId].quantity,
            upsellTotal,
          }
          upsellInventoriesArray.push(upsell)
        }
      })
    })
    const upsellInventoriesSortedPrices = _.sortBy(upsellInventoriesArray, 'price')
    return upsellInventoriesSortedPrices
  }
)

export const selectUpsellPricingData = createSelector(
  [selectCategoryEntities, getUpsellInventoryEntities, getInventoryEdits, selectUpsellTotal],
  (upsellCategories, upsellInventories, inventoryEdits, upsellTotal) => {
    const upsellPricingMap = _.reduce(
      upsellCategories,
      (result, upsellCategory) => {
        if (hasInventorySelected(upsellCategory.inventories, inventoryEdits)) {
          return {
            ...result,
            [upsellCategory.id]: {
              cost: sumCostOfSelectedInventory(upsellCategory.inventories, upsellInventories, inventoryEdits),
              name: upsellCategory.name,
              isChargingTax: upsellCategory.isChargingTax,
              isChargingGratuity: upsellCategory.isChargingGratuity,
              gratuityChargeType: upsellCategory.gratuityChargeType,
              gratuityPercentage: upsellCategory.gratuityPercentage,
              taxGroupId: upsellCategory.taxGroupId,
            },
          }
        }
        return result
      },
      {}
    )
    // using negative cost for reverse sort order
    const upsellPricingOrder = _.sortBy(_.keys(upsellPricingMap), id => -upsellPricingMap[id].cost)
    const upsellBaseAmount = upsellTotal.price
    return { upsellPricingOrder, upsellPricingMap, upsellBaseAmount }
  }
)

const getPartySize = state => state.upsells.main.partySize

export const selectAreAllMinQuantitiesReached = createSelector(
  [getInventoryEdits, selectCategoryEntities, getPartySize],
  (inventoryEdits, categoryEntities, partySize) =>
    _.every(categoryEntities, categoryEntity => {
      const { minQuantityType, minQuantityInt } = categoryEntity
      const categoryMinQuantity = getCategoryMinQuantity(minQuantityType, minQuantityInt, partySize)
      let sum = 0
      return _.some(categoryEntity.inventories, inventoryId => {
        sum += inventoryEdits[inventoryId].quantity
        return sum >= categoryMinQuantity
      })
    })
)

export const selectUpsellRequestData = createSelector(
  [getInventoryEdits, getUpsellInventoryEntities, getCategoryEntities],
  (inventoryEdits, inventoryEntities, categoryEntities) => {
    const selectedCategories = {}
    const selectedInventories = {}
    _.each(inventoryEdits, (inventoryEdit, inventoryId) => {
      if (inventoryEdit.quantity) {
        const inventoryEntity = inventoryEntities[inventoryId]
        const inventoryData = {
          price: inventoryEntity.price,
          quantity: inventoryEdit.quantity,
        }
        selectedInventories[inventoryId] = snakeCaseObject(inventoryData)
        const { categoryId } = inventoryEntity
        if (!selectedCategories[categoryId]) {
          const categoryData = _.pick(categoryEntities[categoryId], [
            'gratuityChargeType',
            'gratuityPercentage',
            'isChargingGratuity',
            'isChargingTax',
            'taxGroupId',
          ])
          selectedCategories[categoryId] = snakeCaseObject(categoryData)
        }
      }
    })
    return {
      selected_categories: selectedCategories,
      selected_inventories: selectedInventories,
    }
  }
)
