// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled, { css } from 'styled-components'
import InteractiveEl from 'svr/component-lib/Widget/Buttons/InteractiveEl'
import QuantityStepper from 'svr/component-lib/Widget/Inputs/QuantityStepper'
/* import type { UpsellInventoryEntity } from '../reducers/entities' */
/* import type {
  ClickInventoryAction,
  ChangeInventoryQuantityAction,
  ClickInventoryDescriptionAction,
} from '../flowTypes' */
import { getTruncateSize, convertFromBlockToInline, extractEmptyTextFromHtmlTag } from '../utils/upsellsHelpers'
import { calculateCategoryFees } from '../../../dining/src/utils/calculateFees'

/* type Props = {
  inventoryEntity: UpsellInventoryEntity,
  inventoryEdit: { quantity: number },
  isSelected: boolean,
  maxQuantityReached: boolean,
  currencySymbol: string,
  onInventoryClick: () => ClickInventoryAction,
  onInventoryDescriptionClick: boolean => ClickInventoryDescriptionAction,
  onQuantityChange: number => ChangeInventoryQuantityAction,
} */

class InventoryItem extends React.Component /* <Props> */ {
  onInventoryDescriptionClick = (event /* : Event */) => {
    event.stopPropagation()
    this.props.onInventoryDescriptionClick(this.props.inventoryEdit.expandReadMore)
  }

  render() {
    const fees = calculateCategoryFees(
      this.props.isFeesInPriceDisplayed,
      this.props.inventoryEntity.price,
      this.props.categoryEntity,
      this.props.defaultServiceCharge,
      this.props.defaultGratuity
    )
    const pricePerPerson = formatPrice(this.props.inventoryEntity.price + fees)
    const priceForQuantity = formatPrice((this.props.inventoryEntity.price + fees) * this.props.inventoryEdit.quantity)

    const truncateDescriptionSize = getTruncateSize()
    let inventoryEntityDescription = this.props.inventoryEntity.description
    const hasMoreText = inventoryEntityDescription.length >= truncateDescriptionSize
    let readMoreLabel = ''
    let customInventoryItemHeightLabel = 'min-height'
    let customInventoryItemHeight = '170px'
    if (!this.props.inventoryEdit.expandReadMore && hasMoreText) {
      inventoryEntityDescription =
        inventoryEntityDescription.length <= truncateDescriptionSize
          ? inventoryEntityDescription
          : inventoryEntityDescription.slice(0, truncateDescriptionSize)

      inventoryEntityDescription =
        inventoryEntityDescription.lastIndexOf(' ') === -1
          ? inventoryEntityDescription
          : inventoryEntityDescription.substr(0, Math.min(inventoryEntityDescription.length, inventoryEntityDescription.lastIndexOf(' ')))
      inventoryEntityDescription = convertFromBlockToInline(inventoryEntityDescription)
      readMoreLabel = 'Read more'
    }

    if (this.props.inventoryEdit.expandReadMore && hasMoreText) {
      inventoryEntityDescription = convertFromBlockToInline(inventoryEntityDescription)
      readMoreLabel = 'Read less'
    }
    if (
      _.isEmpty(this.props.inventoryEntity.previewImageUrlKey) &&
      _.isEmpty(extractEmptyTextFromHtmlTag(inventoryEntityDescription)) &&
      ((this.props.inventoryEntity.name.length < 60 && !this.props.isMobile) ||
        (this.props.inventoryEntity.name.length < 30 && this.props.isMobile))
    ) {
      customInventoryItemHeight = '120px'
      customInventoryItemHeightLabel = 'height'
    }
    const renderInventoryEntityDescription = inventoryEntityDescription && (
      <InventoryDescriptionSection>
        <InventoryDescription
          dangerouslySetInnerHTML={{
            __html: inventoryEntityDescription,
          }}
        />
        <ReadMore onClick={this.onInventoryDescriptionClick}>{readMoreLabel}</ReadMore>
      </InventoryDescriptionSection>
    )
    const showPrice = !this.props.inventoryEntity.isPriceHidden && this.props.inventoryEntity.price >= 0
    return (
      <InteractiveEl
        render={renderArgs => (
          <InventoryItemWrapper
            customWrapperHeight={css`
              ${customInventoryItemHeightLabel}: ${customInventoryItemHeight};
            `}
            isSelected={this.props.isSelected}
            role={renderArgs.role}
            tabIndex={renderArgs.tabIndex}
            onKeyDown={renderArgs.keyDownHandler}
            data-test="sr-upgrade-item"
          >
            <InventoryLabel>{this.props.inventoryEntity.name}</InventoryLabel>
            {this.props.inventoryEntity.price > 0 && this.props.inventoryEdit.quantity > 0 && (
              <InventoryPrice>{`${this.props.currencySymbol}${priceForQuantity}`}</InventoryPrice>
            )}
            {showPrice && (
              <InventorySubLabel>
                {`${this.props.currencySymbol}${pricePerPerson} / ${this.props.textUpgradeEachLabel}`}
                {fees !== 0 && ` (Includes ${this.props.currencySymbol}${formatPrice(fees)} in fees)`}
              </InventorySubLabel>
            )}
            <InventoryAlert role="alert">{`${this.props.inventoryEntity.name}, quantity ${this.props.inventoryEdit.quantity}`}</InventoryAlert>
            <SelectedInventorySection>
              {this.props.inventoryEntity.previewImageUrlKey && (
                <InventoryImage src={`/.h/download/${this.props.inventoryEntity.previewImageUrlKey.replace(/^"(.*)"$/, '$1')}`} />
              )}
              <QuantityStepper
                onChange={this.props.onQuantityChange}
                quantity={this.props.inventoryEdit.quantity}
                minQuantity={0}
                maxQuantity={this.props.maxQuantityReached && this.props.inventoryEdit.quantity}
                customStepperNumber={css`
                  color: ${props => props.theme.fontsColorPrimary};
                `}
                customStepperButton={css`
                  color: ${props => props.theme.fontsColorPrimary};
                  border-color: ${props => props.theme.fontsColorPrimary};
                `}
              />
              {renderInventoryEntityDescription}
            </SelectedInventorySection>
          </InventoryItemWrapper>
        )}
      />
    )
  }
}
function formatPrice(price /* : number */) {
  return price % 1 > 0 ? price.toFixed(2) : price
}

const InventoryItemWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 10px 20px;
  outline: none;
  border-top: 1px solid ${props => props.theme.colorLines};
  background-color: ${props => props.theme.colorCellBackground};
  ${props => props.customWrapperHeight};
`

const ReadMore = styled.div`
  font-weight: bold;
  padding-left: 5px;
  cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
  color: ${props => props.theme.fontsColorSecondary};
`

const InventoryLabel = styled.span`
  width: 80%;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.fontsColorPrimary};
`

const InventoryAlert = styled.span`
  position: absolute;
  top: -9999px;
  left: -9999px;
`

const InventorySubLabel = styled.span`
  width: 100%;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  height: 24px;
  font-weight: 300;
  color: ${props => props.theme.fontsColorSecondary};
`

const InventoryPrice = styled.span`
  display: inline-block;
  width: 20%;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  color: ${props => props.theme.fontsColorPrimary};
`

const SelectedInventorySection = styled.div`
  min-height: 80px;
  margin-top: 10px;
`

const InventoryImage = styled.img.attrs(props => ({
  src: props.src,
}))`
  display: block;
  float: right;
  width: 130px;
  height: 97px;
  object-fit: cover;
`

const InventoryDescription = styled.span`
  display: inline;
  color: ${props => props.theme.fontsColorSecondary};
`

const InventoryDescriptionSection = styled.div`
  width: 72%;
  font-size: 14px;
  font-weight: 300;
`

export default InventoryItem
