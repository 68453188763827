import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { WidgetButton } from '../../../../../lib/Buttons/WidgetButton'
import { revertStage } from '../../actions/navigation'
import { selectBannerContents } from '../selectors'

const BannerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  margin: 0 auto;
  max-width: 700px;
  min-width: 300px;
  width: 100%;
  z-index: 20;
  padding: 7px;
  font-size: 12px;
  text-align: center;
  color: white;
  line-height: 26px;
  background-color: ${props => props.theme.colorSummaryBar};
  color: ${props => props.theme.fontsColorSummaryBar};
`

const BannerClickBox = styled(WidgetButton)`
  height: 26px;
  float: left;
  width: 26px;
  background-color: transparent;
  color: white;
  font-size: 22px;
  padding: 0;
  position: relative;
  outline: unset;
`

const BannerArrow = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${props => props.theme.fontsColorSummaryBar};
`

export const Banner = ({ bannerInfo, onBackClick }) => {
  const { dateStr, partySizeStr, timeStr } = bannerInfo
  const infoStr = [dateStr, partySizeStr, timeStr].join('\u00A0\u00A0·\u00A0\u00A0')
  return (
    <BannerWrapper>
      <BannerClickBox onClick={onBackClick} data-test="back-arrow" aria-label="Back">
        <BannerArrow className="fa fa-angle-left fa-fw" aria-hidden />
      </BannerClickBox>
      <div data-test="banner-info">{infoStr}</div>
    </BannerWrapper>
  )
}

const mapStateToProps = state => ({
  bannerInfo: selectBannerContents(state),
})

const mapDispatchToProps = dispatch => ({
  onBackClick: () => {
    dispatch(revertStage(true))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
