import { INITIALIZE_VENUE_ENTITIES, INITIALIZE_VENUE_INFO } from '../actions/ActionTypes'

export const venueInfo = (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE_VENUE_INFO:
      return {
        ...state,
        ...action.venueInfo,
      }
    default:
      return state
  }
}

export const venueEntities = (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE_VENUE_ENTITIES:
      return {
        ...state,
        ...action.venueEntities,
      }
    default:
      return state
  }
}
