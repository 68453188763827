import {
  SET_DATA_FOR_3D_SECURE,
  SET_PAYMENT_ENGINE,
  SET_STRIPE_ADDITIONAL_PAYMENT_METHODS_AVAILABLE,
  SET_STRIPE_ELEMENTS,
  SET_STRIPE_PAYMENT_METHOD_EVENT,
  SET_STRIPE_WALLET_PAYMENT_TYPE,
  SET_STRIPE_INTENT_CLIENT_SECRET,
  SET_STRIPE_PAYMENT_TYPE,
} from 'svr/component-lib/Widget/Payments/ActionTypes'

const initialState = {
  dataFor3Dsecure: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_FOR_3D_SECURE:
      return {
        ...state,
        dataFor3Dsecure: action.dataFor3Dsecure,
      }
    case SET_PAYMENT_ENGINE:
      return {
        ...state,
        paymentEngine: action.paymentEngine,
      }
    case SET_STRIPE_ELEMENTS:
      return {
        ...state,
        elements: action.elements,
        paymentRequest: action.paymentRequest,
      }
    case SET_STRIPE_WALLET_PAYMENT_TYPE:
      return {
        ...state,
        isStripeWalletPayment: action.isStripeWalletPayment,
        stripe: action.stripe,
      }
    case SET_STRIPE_ADDITIONAL_PAYMENT_METHODS_AVAILABLE:
      return {
        ...state,
        additionalPaymentMethodsAvailable: action.additionalPaymentMethodsAvailable,
      }
    case SET_STRIPE_PAYMENT_METHOD_EVENT:
      return {
        ...state,
        paymentMethodEvent: action.paymentMethodEvent,
      }
    case SET_STRIPE_INTENT_CLIENT_SECRET:
      return {
        ...state,
        stripeIntentClientSecret: action.stripeIntentClientSecret,
      }
    case SET_STRIPE_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypeMessage: action.paymentTypeMessage,
      }
    default:
      return state
  }
}

export default reducer
