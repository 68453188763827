import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { WidgetButton } from '../../../../../lib/Buttons/WidgetButton'

const SetAlertButtonContainer = styled.div`
  position: relative;
  padding: 10px 15px 15px;
  background-color: ${props => props.theme.colorCellBackground};
`
const SetAlertButtonMessage = styled.div`
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  width: 100%;
  color: ${props => props.theme.fontsColorPrimary};
`

const AlertButton = styled(WidgetButton)`
  background-color: rgb(133, 21, 36);
  color: rgb(255, 255, 255);
  height: 42px;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.3px;
  background-color: ${props => props.theme.colorPrimary};
  color: ${props => props.theme.fontsColorButton};
  &:focus {
    border-color: #347baf;
  }
`

export const SetAlertButton = ({ onClick, textCantFind, buttonText }) => (
  <SetAlertButtonContainer>
    <SetAlertButtonMessage>{textCantFind}</SetAlertButtonMessage>
    <AlertButton type="submit" onClick={onClick} data-test="sr-set-alert-button">
      {buttonText}
    </AlertButton>
  </SetAlertButtonContainer>
)

SetAlertButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  textCantFind: PropTypes.string.isRequired,
}
