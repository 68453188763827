import styled, { css } from 'styled-components'

export const VmsIcons = {
  None: '',
  // Global Icons
  Search: String.fromCharCode(0xf002),
  UploadCloud: String.fromCharCode(0xf965),
  Chevron: String.fromCharCode(0xf003),
  ChevronUp: String.fromCharCode(0xf979),
  ChevronDown: String.fromCharCode(0xf977),
  Info: String.fromCharCode(0xf004),
  More: String.fromCharCode(0xf005),
  Arrow: String.fromCharCode(0xf006),
  Check: String.fromCharCode(0xf007),
  Close: String.fromCharCode(0xf008),
  CreditCard: String.fromCharCode(0xf50b),
  Plus: String.fromCharCode(0xf009),
  Minus: String.fromCharCode(0xf010),
  Menu: String.fromCharCode(0xf011),
  Filter: String.fromCharCode(0xf012),
  BadgeEmpty: String.fromCharCode(0xf013),
  BadgeCheck: String.fromCharCode(0xf014),
  BadgeError: String.fromCharCode(0xf015),
  BadgePlus: String.fromCharCode(0xf016),
  BadgeWarning: String.fromCharCode(0xf017),
  Move: String.fromCharCode(0xf018),
  Combo: String.fromCharCode(0xf019),
  Logout: String.fromCharCode(0xf020),
  Unlock: String.fromCharCode(0xf021),
  Lock: String.fromCharCode(0xf022),
  Refresh: String.fromCharCode(0xf023),
  RefreshFail: String.fromCharCode(0xf024),
  BadgeHelp: String.fromCharCode(0xf025),
  Drag: String.fromCharCode(0xf026),
  DragIndicator: String.fromCharCode(0xf992),
  Swap: String.fromCharCode(0xf027),
  Shuffle: String.fromCharCode(0xf028),
  Booked: String.fromCharCode(0xf029),
  LeftMessage: String.fromCharCode(0xf030),
  NoAnswer: String.fromCharCode(0xf031),
  WrongNumber: String.fromCharCode(0xf032),
  Confirmed: String.fromCharCode(0xf033),
  Arrived: String.fromCharCode(0xf034),
  PartiallyArrived: String.fromCharCode(0xf035),
  Late: String.fromCharCode(0xf036),
  Repeat: String.fromCharCode(0xf037),
  SortAz: String.fromCharCode(0xf038),
  SortZa: String.fromCharCode(0xf039),
  PartiallySeated: String.fromCharCode(0xf040),
  Seated: String.fromCharCode(0xf041),
  OrderPlaced: String.fromCharCode(0xf042),
  FirstCourse: String.fromCharCode(0xf043),
  SecondCourse: String.fromCharCode(0xf044),
  ThirdCourse: String.fromCharCode(0xf045),
  FourthCourse: String.fromCharCode(0xf046),
  Dessert: String.fromCharCode(0xf047),
  CheckDropped: String.fromCharCode(0xf048),
  Paid: String.fromCharCode(0xf049),
  BusTable: String.fromCharCode(0xf050),
  Left: String.fromCharCode(0xf051),
  Caret: String.fromCharCode(0xf052),
  Link: String.fromCharCode(0xf053),
  Show: String.fromCharCode(0xf054),
  Hide: String.fromCharCode(0xf055),
  Sort12: String.fromCharCode(0xf056),
  Sort21: String.fromCharCode(0xf057),
  Unlink: String.fromCharCode(0xf058),
  Attachment: String.fromCharCode(0xf059),
  List: String.fromCharCode(0xf060),
  ToggleOn: String.fromCharCode(0xf95c),
  ToggleOff: String.fromCharCode(0xf95d),

  // Fill/Line Icons
  Clients: String.fromCharCode(0xf01a),
  ClientsLine: String.fromCharCode(0xf01b),
  Book: String.fromCharCode(0xf02a),
  BookLine: String.fromCharCode(0xf02b),
  Floorplan: String.fromCharCode(0xf03a),
  FloorplanLine: String.fromCharCode(0xf03b),
  Guestlist: String.fromCharCode(0xf04a),
  GuestlistLine: String.fromCharCode(0xf04b),
  Waitlist: String.fromCharCode(0xf05a),
  WaitlistLine: String.fromCharCode(0xf05b),
  Tally: String.fromCharCode(0xf06a),
  TallyLine: String.fromCharCode(0xf06b),
  Server: String.fromCharCode(0xf07a),
  ServerLine: String.fromCharCode(0xf07b),
  Livespend: String.fromCharCode(0xf08a),
  LivespendLine: String.fromCharCode(0xf08b),
  Settings: String.fromCharCode(0xf09a),
  SettingsLine: String.fromCharCode(0xf09b),
  MenuLine: String.fromCharCode(0xf964),
  Tag: String.fromCharCode(0xf10a),
  TagLine: String.fromCharCode(0xf10b),
  TagMulti: String.fromCharCode(0xf11a),
  TagMultiLine: String.fromCharCode(0xf11b),
  BookAdd: String.fromCharCode(0xf12a),
  BookAddLine: String.fromCharCode(0xf12b),
  WalkinAdd: String.fromCharCode(0xf13a),
  WalkinAddLine: String.fromCharCode(0xf13b),
  ServerAdd: String.fromCharCode(0xf14a),
  ServerAddLine: String.fromCharCode(0xf14b),
  GuestlistAdd: String.fromCharCode(0xf15a),
  GuestlistAddLine: String.fromCharCode(0xf15b),
  TallyAdd: String.fromCharCode(0xf16a),
  TallyAddLine: String.fromCharCode(0xf16b),
  Flow: String.fromCharCode(0xf17a),
  FlowLine: String.fromCharCode(0xf17b),
  Note: String.fromCharCode(0xf18a),
  NoteLine: String.fromCharCode(0xf18b),
  NoteAdd: String.fromCharCode(0xf19a),
  NoteAddLine: String.fromCharCode(0xf19b),
  Grid: String.fromCharCode(0xf20a),
  GridLine: String.fromCharCode(0xf20b),
  Help: String.fromCharCode(0xf21a),
  HelpLine: String.fromCharCode(0xf21b),
  Marketing: String.fromCharCode(0xf22a),
  MarketingLine: String.fromCharCode(0xf22b),
  Reporting: String.fromCharCode(0xf23a),
  ReportingLine: String.fromCharCode(0xf23b),
  Insights: String.fromCharCode(0xf9a3),
  InsightsLine: String.fromCharCode(0xf9a4),
  Request: String.fromCharCode(0xf24a),
  RequestLine: String.fromCharCode(0xf24b),
  Share: String.fromCharCode(0xf25a),
  ShareLine: String.fromCharCode(0xf25b),
  Print: String.fromCharCode(0xf26a),
  PrintLine: String.fromCharCode(0xf26b),
  Client: String.fromCharCode(0xf27a),
  ClientLine: String.fromCharCode(0xf27b),
  ClientAdd: String.fromCharCode(0xf28a),
  ClientAddLine: String.fromCharCode(0xf28b),
  Birthday: String.fromCharCode(0xf29a),
  BirthdayLine: String.fromCharCode(0xf29b),
  Anniversary: String.fromCharCode(0xf30a),
  AnniversaryLine: String.fromCharCode(0xf30b),
  Excel: String.fromCharCode(0xf31a),
  ExcelLine: String.fromCharCode(0xf31b),
  Pdf: String.fromCharCode(0xf32a),
  PdfLine: String.fromCharCode(0xf32b),
  Email: String.fromCharCode(0xf33a),
  EmailLine: String.fromCharCode(0xf33b),
  NoteEdit: String.fromCharCode(0xf34a),
  NoteEditLine: String.fromCharCode(0xf34b),
  RequestAdd: String.fromCharCode(0xf35a),
  RequestAddLine: String.fromCharCode(0xf35b),
  Calendar: String.fromCharCode(0xf36a),
  CalendarLine: String.fromCharCode(0xf36b),
  ShiftBlackout: String.fromCharCode(0xf37a),
  ShiftBlackoutLine: String.fromCharCode(0xf37b),
  Block: String.fromCharCode(0xf38a),
  BlockLine: String.fromCharCode(0xf38b),
  ClientImport: String.fromCharCode(0xf39a),
  ClientImportLine: String.fromCharCode(0xf39b),
  GuestlistMultiAdd: String.fromCharCode(0xf40a),
  GuestlistMultiAddLine: String.fromCharCode(0xf40b),
  BookBlackout: String.fromCharCode(0xf41a),
  BookBlackoutLine: String.fromCharCode(0xf41b),
  GuestlistBlackout: String.fromCharCode(0xf42a),
  GuestlistBlackoutLine: String.fromCharCode(0xf42b),
  TableRound: String.fromCharCode(0xf43a),
  TableRoundLine: String.fromCharCode(0xf43b),
  TableSquare: String.fromCharCode(0xf44a),
  TableSquareLine: String.fromCharCode(0xf44b),
  Edit: String.fromCharCode(0xf45a),
  EditLine: String.fromCharCode(0xf45b),
  Trash: String.fromCharCode(0xf46a),
  TrashLine: String.fromCharCode(0xf46b),
  Event: String.fromCharCode(0xf47a),
  EventLine: String.fromCharCode(0xf47b),
  Editpencil: String.fromCharCode(0xf48a),
  EditpencilLine: String.fromCharCode(0xf48b),
  CalendarAdd: String.fromCharCode(0xf49a),
  CalendarAddLine: String.fromCharCode(0xf49b),
  PaymentAdd: String.fromCharCode(0xf50a),
  PaymentAddLine: String.fromCharCode(0xf50b),
  Source: String.fromCharCode(0xf51a),
  SourceLine: String.fromCharCode(0xf51b),
  Add: String.fromCharCode(0xf52a),
  AddLine: String.fromCharCode(0xf52b),
  Phone: String.fromCharCode(0xf99b),
  PhoneLine: String.fromCharCode(0xf53b),
  Safari: String.fromCharCode(0xf54a),
  SafariLine: String.fromCharCode(0xf54b),
  Message: String.fromCharCode(0xf55a),
  MessageLine: String.fromCharCode(0xf55b),
  MessageSquare: String.fromCharCode(0xf9ae),
  MessageSquareLine: String.fromCharCode(0xf9af),
  Comp: String.fromCharCode(0xf56a),
  CompLine: String.fromCharCode(0xf56b),
  Money: String.fromCharCode(0xf57a),
  MoneyLine: String.fromCharCode(0xf57b),
  Clear: String.fromCharCode(0xf58a),
  ClearLine: String.fromCharCode(0xf58b),
  Checked: String.fromCharCode(0xf59a),
  CheckedLine: String.fromCharCode(0xf59b),
  Clock: String.fromCharCode(0xf60a),
  ClockLine: String.fromCharCode(0xf60b),
  Timer: String.fromCharCode(0xf61a),
  TimerLine: String.fromCharCode(0xf61b),
  Layer: String.fromCharCode(0xf62a),
  LayerLine: String.fromCharCode(0xf62b),
  Chair: String.fromCharCode(0xf63a),
  ChairLine: String.fromCharCode(0xf63b),
  Company: String.fromCharCode(0xf64a),
  CompanyLine: String.fromCharCode(0xf64b),
  Briefcase: String.fromCharCode(0xf65a),
  BriefcaseLine: String.fromCharCode(0xf65b),
  Star: String.fromCharCode(0xf66a),
  StarLine: String.fromCharCode(0xf70e),
  ArrowMinimum: String.fromCharCode(0xf67a),
  ArrowMinimumLine: String.fromCharCode(0xf67b),
  Gender: String.fromCharCode(0xf68a),
  GenderLine: String.fromCharCode(0xf68b),
  Walkin: String.fromCharCode(0xf69a),
  WalkinLine: String.fromCharCode(0xf69b),
  OrderLine: String.fromCharCode(0xf9a1),
  OrderFill: String.fromCharCode(0xf9a2),
  UpgradeFill: String.fromCharCode(0xf9a7),
  UpgradeLine: String.fromCharCode(0xf9a8),
  Oval: String.fromCharCode(0xf70a),
  OvalLine: String.fromCharCode(0xf70b),
  Pause: String.fromCharCode(0xf71a),
  PauseLine: String.fromCharCode(0xf71b),
  Warning: String.fromCharCode(0xf72a),
  WarningLine: String.fromCharCode(0xf72b),
  Stop: String.fromCharCode(0xf73a),
  StopLine: String.fromCharCode(0xf73b),
  Location: String.fromCharCode(0xf73e),
  LocationLine: String.fromCharCode(0xf74b),
  Address: String.fromCharCode(0xf75a),
  AddressLine: String.fromCharCode(0xf75b),
  Shift: String.fromCharCode(0xf76a),
  ShiftLine: String.fromCharCode(0xf76b),
  Inbox: String.fromCharCode(0xf77a),
  InboxLine: String.fromCharCode(0xf77b),
  Promo: String.fromCharCode(0xf78a),
  PromoLine: String.fromCharCode(0xf78a),
  Canceled: String.fromCharCode(0xf58a),
  NoShow: String.fromCharCode(0xf73a),
  NoEntry: String.fromCharCode(0xf73b),
  Hold: String.fromCharCode(0xf71a),
  ExternalLink: String.fromCharCode(0xf061),
  OpenInNew: String.fromCharCode(0xf98a),
  Code: String.fromCharCode(0xf960),
  CodeArray: String.fromCharCode(0xf983),
  SiteMap: String.fromCharCode(0xf976),
  SiteMapLine: String.fromCharCode(0xf975),
  LocalOffer: String.fromCharCode(0xf97d),
  LocalOfferLine: String.fromCharCode(0xf984),
  SwapHoriz: String.fromCharCode(0xf97b),
  Delete: String.fromCharCode(0xf97e),
  EditPencil: String.fromCharCode(0xf48a),
  ReplyAll: String.fromCharCode(0xf96f),
  GoToNow: String.fromCharCode(0xf987),
  NotInterested: String.fromCharCode(0xf988),
  CheckBoxOutlineBlank: String.fromCharCode(0xf972),
  CheckBoxSelected: String.fromCharCode(0xf973),
  IndeterminateCheckBox: String.fromCharCode(0xf974),
  Target: String.fromCharCode(0xf98d),
  Receipt: String.fromCharCode(0xf98c),
  LinkMDI: String.fromCharCode(0xf981),
  LinkVariant: String.fromCharCode(0xf989),
  Translate: String.fromCharCode(0xf97f),
  Copy: String.fromCharCode(0xf062),
  StoreFront: String.fromCharCode(0xf995),
  Door: String.fromCharCode(0xf99e),
  Globe: String.fromCharCode(0xf9a0),
  AccessRule: String.fromCharCode(0xf9a6),
  Smile: String.fromCharCode(0xf9ac),
  Programs: String.fromCharCode(0xf9ad),

  // Logos
  LogoSymbol: String.fromCharCode(0xf001),
  TripAdvisor: String.fromCharCode(0xf076),
  Yelp: String.fromCharCode(0xf075),
  Facebook: String.fromCharCode(0xf074),
  Google: String.fromCharCode(0xf073),
  OpenTable: String.fromCharCode(0xf950),
  Grubhub: String.fromCharCode(0xf98b),
  TheFork: String.fromCharCode(0xf99f),
  Resy: String.fromCharCode(0xf9a9),
}

export default VmsIcons

export const VmsIconBaseStyle = {
  display: 'inline-block',
  fontSize: '40px',
  lineHeight: '1',
  textRendering: 'auto',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontFamily: 'VmsIcons',
  WebkitFontSmoothing: 'antialiased',
  userSelect: 'none',
}

export const VmsIconSizes = {
  xxsmall: { ...VmsIconBaseStyle, fontSize: '12px', badgeSize: '6px' },
  xsmall: { ...VmsIconBaseStyle, fontSize: '14px', badgeSize: '7px' },
  small: { ...VmsIconBaseStyle, fontSize: '20px', badgeSize: '10px' },
  medium: { ...VmsIconBaseStyle, fontSize: '40px', badgeSize: '20px' },
  large: { ...VmsIconBaseStyle, fontSize: '60px', badgeSize: '30px' },
  subheader: { ...VmsIconBaseStyle, fontSize: '28px', badgeSize: '14px' },
}

export const IconFontCss = css`
  display: ${VmsIconBaseStyle.display};
  text-rendering: ${VmsIconBaseStyle.textRendering};
  font-style: ${VmsIconBaseStyle.fontStyle};
  font-variant: ${VmsIconBaseStyle.fontVariant};
  font-weight: ${VmsIconBaseStyle.fontWeight};
  font-stretch: ${VmsIconBaseStyle.fontStretch};
  font-family: ${VmsIconBaseStyle.fontFamily};
`
export const StyledVmsIconBase = styled.div`
  ${IconFontCss} position: relative;
  user-select: none;
  -webkit-font-smoothing: ${VmsIconBaseStyle.WebkitFontSmoothing};
`
const buildIcon = (IconStyle, size) => styled(IconStyle)`
  min-height: ${size};
  min-width: ${size};
  font-size: ${size};
  line-height: ${size};
`

export const customIcon = size => buildIcon(StyledVmsIconBase, size)

export const StyledVmsIconXXS = buildIcon(StyledVmsIconBase, VmsIconSizes.xxsmall.fontSize)
export const StyledVmsIconXS = buildIcon(StyledVmsIconBase, VmsIconSizes.xsmall.fontSize)
export const StyledVmsIconS = buildIcon(StyledVmsIconBase, VmsIconSizes.small.fontSize)
export const StyledVmsIconM = buildIcon(StyledVmsIconBase, VmsIconSizes.medium.fontSize)
export const StyledVmsIconL = buildIcon(StyledVmsIconBase, VmsIconSizes.large.fontSize)

export const StyledVmsIconSH = buildIcon(StyledVmsIconBase, VmsIconSizes.subheader.fontSize)

export const StyledVmsBadgeIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  text-rendering: optimizeLegibility;
  border-radius: 50%;
  background: #fff;
`

export const StyledVmsBadgeIconXXS = buildIcon(StyledVmsBadgeIcon, VmsIconSizes.xxsmall.badgeSize)
export const StyledVmsBadgeIconXS = buildIcon(StyledVmsBadgeIcon, VmsIconSizes.xsmall.badgeSize)
export const StyledVmsBadgeIconS = buildIcon(StyledVmsBadgeIcon, VmsIconSizes.small.badgeSize)
export const StyledVmsBadgeIconM = buildIcon(StyledVmsBadgeIcon, VmsIconSizes.medium.badgeSize)
export const StyledVmsBadgeIconL = buildIcon(StyledVmsBadgeIcon, VmsIconSizes.large.badgeSize)

export const StyledIcons = {
  XXS: StyledVmsIconXXS,
  XS: StyledVmsIconXS,
  S: StyledVmsIconS,
  M: StyledVmsIconM,
  L: StyledVmsIconL,
  BadgeXXS: StyledVmsBadgeIconXXS,
  BadgeXS: StyledVmsBadgeIconXS,
  BadgeS: StyledVmsBadgeIconS,
  BadgeM: StyledVmsBadgeIconM,
  BadgeL: StyledVmsBadgeIconL,
}

export const BorderedVmsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.black};
  border-radius: 4px;
  padding: 11px;
  transition: 0.3s opacity, 0.3s background-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    background-color: ${props => props.theme.color.greyMuted};
  }
`
