export const PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE = 'PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE'
export const PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE = 'PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE'
export const CLEAN_PAYMENT_INFORMATION = 'CLEAN_PAYMENT_INFORMATION'

export const PayerAuthStep = {
  SETUP: 'SETUP',
  ENROLLMENT: 'ENROLLMENT',
}

export const cleanCybersourcePaymentInformation = () => ({
  type: CLEAN_PAYMENT_INFORMATION,
})

export const payerAuthenticationSetupComplete = data => ({
  type: PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE,
  data: {
    payerAuthenticationServiceData: data.consumer_authentication_information,
    token: data.token,
    cardData: data.card_data,
    url: data.url,
  },
})

export const payerAuthenticationCheckEnrollmentComplete = data => ({
  type: PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE,
  data: data.enrollment_result,
})
