/* eslint no-shadow: 0 */
import { of as RxOf } from 'rxjs/observable/of'
import { empty as RxEmpty } from 'rxjs/observable/empty'
import { fromPromise as RxFromPromise } from 'rxjs/observable/fromPromise'
import 'rxjs/add/operator/concatMap'
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/retry'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import { convertValidDatesFromApi, convertExperienceAvailabilityFromApi } from '../utils/convertData'
import { INITIALIZE, INITIALIZE_EXPERIENCE_MODE, SELECT_TIME_SLOT, SELECT_REQUEST } from './ActionTypes'
import { getValidDatesSuccess, getValidExperienceAvailability, getValidExperienceAvailabilityDone } from './services'

const getJson = url => RxFromPromise(fetch(url)).concatMap(response => RxFromPromise(response.json()))

export const fetchValidDates = (action$, store) => {
  const state = store.getState()
  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const isWaitlistMode = state.waitlist.get('isWaitlistMode')
  const venueKey = state.venueInfo.venueUrlKey
  const baseURL = state.widgetSettings.baseUrl
  const maxDaysOut = state.search.get('maxDaysOut')

  const NUM_DAYS_PER_REQUEST = 15
  let numRequests
  if (isWaitlistMode) {
    numRequests = 0
  } else if (maxDaysOut) {
    numRequests = Math.ceil(maxDaysOut / NUM_DAYS_PER_REQUEST)
  } else {
    numRequests = 15
  }

  const getUrl = `${baseURL}/api-yoa/availability/dates?venue=${venueKey}`

  let iterMoment = state.search.get('venueToday').clone()
  const urlWithParams = _.map(Array(numRequests), (_, idx) => {
    let numDays = NUM_DAYS_PER_REQUEST
    if (maxDaysOut && (idx + 1) * NUM_DAYS_PER_REQUEST > maxDaysOut) {
      numDays = maxDaysOut % NUM_DAYS_PER_REQUEST
    }
    const numDaysParam = `&num_days=${numDays}`
    const startDateParam = `&start_date=${iterMoment.format('MM-DD-YYYY')}`
    iterMoment = iterMoment.add(NUM_DAYS_PER_REQUEST, 'days')
    const actualIdParam = isModifyResMode ? `&actual_id=${state.modifyReservation.get('actualId')}` : ''
    return getUrl + numDaysParam + startDateParam + actualIdParam
  })

  return action$
    .ofType(INITIALIZE)
    .concatMap(() =>
      RxOf(...urlWithParams)
        .concatMap(url => getJson(url))
        .retry(3)
        .catch(() => RxEmpty)
        .map(responseJson => convertValidDatesFromApi(responseJson.data.valid_dates))
        .takeUntil(action$.filter(action => action.type === SELECT_TIME_SLOT || action.type === SELECT_REQUEST))
    )
    .map(data => getValidDatesSuccess(data))
}

export const fetchExperienceValidDates = (action$, store) => {
  const state = store.getState()
  const venueKey = state.venueInfo.venueUrlKey
  const experienceId = state.experience.get('id')
  const baseURL = state.widgetSettings.baseUrl
  const NUM_DAYS_PER_REQUEST = 7
  const NUM_REQUESTS = Math.ceil(state.venueInfo.availabilityCacheDays / NUM_DAYS_PER_REQUEST)

  let getUrl = `${baseURL}/api-yoa/availability/experience/dates?venue=${venueKey}&experience_id=${experienceId}`
  if (state.app.requestParams.rebuild) {
    getUrl += `&rebuild=True`
  }
  const { clientId } = widgetInit
  if (clientId) {
    getUrl += `&audience=${clientId}`
  }

  let iterMoment = state.search.get('venueToday').clone()
  const urlWithParams = _.map(Array(NUM_REQUESTS), () => {
    const startDateParam = `&start_date=${iterMoment.format('MM-DD-YYYY')}`
    iterMoment = iterMoment.add(NUM_DAYS_PER_REQUEST, 'days')
    return getUrl + startDateParam
  })
  urlWithParams.push(null)

  return action$
    .ofType(INITIALIZE_EXPERIENCE_MODE)
    .concatMap(() =>
      RxOf(...urlWithParams)
        .concatMap(url => (url ? getJson(url) : null))
        .retry(3)
        .catch(() => RxEmpty)
        .map(responseJson => (responseJson ? convertExperienceAvailabilityFromApi(responseJson.data.availability) : null))
    )
    .map(data => (data === null ? getValidExperienceAvailabilityDone() : getValidExperienceAvailability(data)))
}
