import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  postCheckoutFailure,
  postCheckoutPageReloadOnFailure,
  submitFreedompayPaymentRequest,
  fetchOrRefreshFreedompayDataForIframe,
  setFreedompayVisible,
} from '../actions/services'
import styles from '../assets/styles/checkout'

class CheckoutPaymentFreedompay extends Component {
  constructor(props) {
    super(props)
    this.success_3ds_validation = false
    this.failed_authentication = false
    this.bypassed_3ds = false
  }

  componentDidMount() {
    window.addEventListener('message', this.handleFreedompayMessage)
    const iframeStyles = `
      button { border: none; background-color: rgb(0, 0, 255);}
      button:hover { background-color: rgb(0, 0, 255); }
    `
    this.props.fetchOrRefreshFreedompayDataForIframe(this.props.venueId, iframeStyles)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFreedompayMessage)
  }

  handleFreedompayMessage = e => {
    const url = new URL(document.getElementById('freedompay-src').src)
    if (e.origin !== `https://${url.hostname}`) {
      return
    }
    const message = e.data
    const { data } = message
    const FP_ERROR = 1
    const FP_CHANGE_HEIGHT = 2
    const FP_SUBMITTED = 3
    const FP_THREEDS_VALIDATION = 16
    switch (message.type) {
      case FP_ERROR:
        this.handleErrors(data)
        break
      case FP_CHANGE_HEIGHT:
        this.setFrameHeight(data)
        break
      case FP_SUBMITTED:
        this.submitPayment(data)
        break
      case FP_THREEDS_VALIDATION:
        this.handleThreeDSValidation(data)
        break
      default:
        break
    }
  }

  handleErrors(data) {
    const errors = _.map(data.errors, error => error.message)
    this.props.postCheckoutFailure(errors.join('\n'))
    if (data.errors[0].messageCode === 'consumerAuthCanceled') {
      return
    }
    this.props.fetchOrRefreshFreedompayDataForIframe(this.props.venueId)
  }

  handleThreeDSValidation(data) {
    const failedECIFlagValues = ['00', '07']
    if (this.props.isBypass3dsEnabled) {
      if (data?.Payment?.ExtendedData?.Enrolled === 'B') {
        this.bypassed_3ds = true
      } else if (data.CmpiLookup?.Enrolled === 'B') {
        this.bypassed_3ds = true
      }

      if (this.bypassed_3ds) {
        return
      }
    }
    if (data?.Payment?.ExtendedData) {
      const extendedData = data.Payment.ExtendedData
      if (!extendedData.CAVV && failedECIFlagValues.includes(extendedData.ECIFlag)) {
        this.props.postCheckoutFailure('3DS validation has failed - Failed StepUp Authentication')
        this.failed_authentication = true
        return
      }
    } else if (data?.CmpiLookup && this.props.isFrictionlessHandlingEnabled) {
      const cmpiLookupData = data.CmpiLookup
      if (!cmpiLookupData.Cavv && failedECIFlagValues.includes(cmpiLookupData.EciFlag)) {
        this.props.postCheckoutFailure('3DS validation has failed - Failed Frictionless Authentication')
        this.failed_authentication = true
        return
      }
    }
    this.success_3ds_validation = true
  }

  setFrameHeight(data) {
    document.getElementById('sr-checkout-payment').getElementsByTagName('iframe')[0].height = data.height
  }

  submitPayment(freedompayData) {
    const bypassSuccessful = this.bypassed_3ds && this.props.isBypass3dsEnabled
    if (this.success_3ds_validation || !this.props.isFrictionlessHandlingEnabled || bypassSuccessful) {
      this.props.submitFreedompayPaymentRequest(this.props.venueId, freedompayData)
    } else if (!this.failed_authentication && this.props.isFrictionlessHandlingEnabled) {
      window.addEventListener('click', event => {
        window.location.reload()
      })
      this.props.setFreedompayVisible(false)
      this.props.postCheckoutPageReloadOnFailure(
        "Please use a card that supports 3D Secure authentication, as it's required for transactions at this venue. Try a different card to continue.",
        'Reload',
        () => window.location.reload()
      )
    }
  }

  render() {
    const { topElementId, freedompay } = this.props
    if (!freedompay.initialData) {
      return null
    }
    return (
      <PaymentDiv id={topElementId}>
        <PaymentInterface>
          <CloseButton
            onClick={() => {
              this.props.setFreedompayVisible(false)
            }}
          >
            &times;
          </CloseButton>
          <PaymentIframe style={styles.infoForm} dangerouslySetInnerHTML={{ __html: freedompay.initialData.iframe }} />
        </PaymentInterface>
        <Cover />
      </PaymentDiv>
    )
  }
}

const PaymentDiv = styled.div`
  width: auto;
  height: auto
  margin: 0 auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: white;
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const PaymentIframe = styled.div`
  z-index: 22;
  top: 30%;
  background: white;
  border-radius: 0px !important;
  box-shadow: none !important;
`

const Cover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`

const PaymentInterface = styled.div`
  position: fixed;
  top: 10vh;
  left: 50%;
  margin-left: -174px;
  border-radius: 12px;
  padding: 24px;
  background: white;
  z-index: 22;
  display: block;
  width: 350px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  color: #999;
  cursor: pointer;
  z-index: 97;
  font-size: 25px;
`

const mapStateToProps = state => ({
  isFrictionlessHandlingEnabled: state.venueInfo.isFreedompayHpcFrictionlessHandlingEnabled,
  isBypass3dsEnabled: state.venueInfo.isFreedompayBypass3DsEnabled,
  venueId: state.venueInfo.id,
  freedompay: state.payment.freedompay,
})

const mapDispatchToProps = {
  submitFreedompayPaymentRequest,
  postCheckoutFailure,
  postCheckoutPageReloadOnFailure,
  fetchOrRefreshFreedompayDataForIframe,
  setFreedompayVisible,
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPaymentFreedompay))
