/* eslint react/prop-types: 0 */
import React from 'react'
import styles from './styles'

const Spinner = ({ mediaUrl, label = '' }) => (
  <div style={styles.body}>
    {label && <span style={styles.text}>{label}</span>}
    <img alt="sevenrooms-spinner" style={styles.gif} src={`${mediaUrl}images/widget/sr_spinner.gif`} />
  </div>
)

Spinner.propTypes = {
  mediaUrl: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
}

Spinner.defaultProps = {
  label: '',
}

export default Spinner
