import { CHANGE_VENUE_LANGUAGE } from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'
import { WidgetLanguageService } from '@sevenrooms/core/api'
import * as AnalyticsEvents from '../utils/analyticsEvents'

export const getVenueLanguageCodeInformation = (venueEntities, venueInfo, selectedVenue) => {
  let enabledLanguageCodes
  let defaultLanguageCode
  if (selectedVenue in venueEntities) {
    enabledLanguageCodes = venueEntities[selectedVenue].enabledLanguageCodes
    defaultLanguageCode = venueEntities[selectedVenue].defaultLanguageCode
  } else {
    enabledLanguageCodes = venueInfo.enabledLanguageCodes
    defaultLanguageCode = venueInfo.selectedLanguage
  }
  return {
    enabledLanguageCodes,
    defaultLanguageCode,
  }
}

export const venueLanguageChanged = languageCode => (dispatch, getState) => {
  const state = getState()
  const { selectedVenue } = state.search.toJS()
  const { venueEntities, venueInfo } = state
  const { defaultLanguageCode, enabledLanguageCodes } = getVenueLanguageCodeInformation(venueEntities, venueInfo, selectedVenue)
  AnalyticsEvents.changePreferredLanguage(languageCode)
  dispatch({
    type: CHANGE_VENUE_LANGUAGE,
    languageCode,
    defaultLanguageCode,
    enabledLanguageCodes,
  })
}

export const getLanguageStringsStart = () => ({
  type: ActionTypes.GET_REMAINING_LANGUAGE_STRINGS_START,
})

export const getLanguageStringsSuccess = (state, remainingLanguageStrings, tagLanguageStrings, selectedVenue) => {
  const { venueEntities, venueInfo } = state
  const { defaultLanguageCode, enabledLanguageCodes } = getVenueLanguageCodeInformation(venueEntities, venueInfo, selectedVenue)
  return {
    type: ActionTypes.GET_REMAINING_LANGUAGE_STRINGS_SUCCESS,
    remainingLanguageStrings,
    tagLanguageStrings,
    defaultLanguageCode,
    enabledLanguageCodes,
    selectedVenue,
  }
}

export const getLanguageStringsFailure = () => ({
  type: ActionTypes.GET_REMAINING_LANGUAGE_STRINGS_FAILURE,
})

export const tryGetWidgetLanguageStrings = venueKey => async (dispatch, getState) => {
  const state = getState()
  const { selectedLanguage } = state.languages
  dispatch(getLanguageStringsStart())
  try {
    const { languageStrings = {}, tagLanguageStrings = {} } = await WidgetLanguageService.getLanguageStrings({
      venueUrlKey: venueKey,
      languageCode: selectedLanguage,
      widgetType: 'base_res_widget',
      skipStringsFormatting: true,
    })
    dispatch(getLanguageStringsSuccess(state, languageStrings, tagLanguageStrings, venueKey))
  } catch {
    dispatch(getLanguageStringsFailure())
  }
}
