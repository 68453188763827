import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { fetchSaferpayInitialData } from 'svr/component-lib/Widget/Payments/SaferPay/services'

class SaferpayFormRender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saferpay: {
        cvc: { isValid: undefined },
        expiration: { isValid: undefined },
        cardnumber: { isValid: undefined },
        holdername: { isValid: undefined },
      },
    }
  }

  componentDidMount() {
    SaferpayFields.init({
      accessToken: this.props.saferpayInitialData.accessToken,
      url: this.props.saferpayInitialData.urlFields,
      style: {
        '.input-large': 'background-color: rgba(255, 255, 255, 0.5);',
        '.input-small': 'background-color: rgba(255, 255, 255, 0.5);',
      },
      paymentMethods: this.props.saferpayInitialData.paymentMethods.split(','),
      onError(evt) {
        console.error(evt)
      },
      placeholders: {
        holdername: 'Card holder',
        cardnumber: '0000 0000 0000 0000',
        expiration: 'MM/YY',
        cvc: 'CVC',
      },
      onValidated: this.handlerOnValidate,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.dataFor3Dsecure) && !_.isEmpty(nextProps.dataFor3Dsecure)) {
      window.location = nextProps.dataFor3Dsecure
    }
  }

  handlerOnValidate = event => {
    this.setState(prevState => ({
      ...prevState,
      saferpay: {
        ...prevState.saferpay,
        [event.fieldType]: { isValid: event.isValid },
      },
    }))
    const isValid =
      this.state.saferpay.cvc.isValid &&
      this.state.saferpay.expiration.isValid &&
      this.state.saferpay.cardnumber.isValid &&
      this.state.saferpay.holdername.isValid
    this.props.onValidate(isValid)
  }

  render() {
    return (
      <div>
        <div id={this.props.topElementId}>
          <div className="row">
            <div className="col-md-12 field">
              <div id="fields-holder-name" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 field">
              <div id="fields-card-number" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 field">
              <div id="fields-expiration" />
            </div>
            <div className="col-md-5 field">
              <div id="fields-cvc" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SaferpayFormRender.propTypes = {
  dataFor3Dsecure: PropTypes.object,
  saferpayInitialData: PropTypes.object,
  topElementId: PropTypes.string,
  onValidate: PropTypes.func,
}

class SaferpayFormFetch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saferpayInitialData: null,
    }
  }

  componentDidMount() {
    fetchSaferpayInitialData(this.props.venueId).then(r =>
      this.setState({
        saferpayInitialData: r,
      })
    )
  }

  render() {
    if (!this.state.saferpayInitialData) {
      return null
    }
    return (
      <>
        <SaferpayFormRender
          topElementId={this.props.topElementId}
          dataFor3Dsecure={this.props.dataFor3Dsecure}
          key={this.props.venueId}
          saferpayInitialData={this.state.saferpayInitialData}
          onValidate={this.props.onValidate}
        />
      </>
    )
  }
}

SaferpayFormFetch.propTypes = {
  dataFor3Dsecure: PropTypes.object,
  venueId: PropTypes.string,
  topElementId: PropTypes.string,
  onValidate: PropTypes.func,
}

export default SaferpayFormFetch
