/* eslint-disable no-param-reassign */
import _ from 'lodash'
import Radium from 'radium'
import PhoneNumber from 'svr/component-lib/Widget/PhoneNumber'
import { ErrorBoundary } from 'svr/widget/universal/src/components/error'
import { validateNotEmpty, validateEmail } from '../../../../common/Validators'
import TextInput from '../../../../lib/TextInputs/TextInput'
import styles from '../assets/styles/checkout'
import { searchTypes } from '../utils/constantTypes'
import ItemPicker from './ItemPicker'
import CalendarStar from '../../../../../images/calendar-star.svg'
import React from 'react'

export const RequestForm = ({
  partySize,
  queryDate,
  startTime,
  firstName,
  note,
  lastName,
  email,
  isMobile,
  displaySearchType,
  phoneNumber,
  phoneNumberLocale,
  dialCode,
  changePhoneNum,
  changeFlag,
  formErrors,
  colorCheckoutCellBackground,
  colorLines,
  colorPrimary,
  fontsColorPrimary,
  fontsColorCheckoutInactive,
  fontsColorCheckoutActive,
  fontFamily,
  colorCellBackground,
  colorHover,
  fontsColorButton,
  textFirstName,
  textLastName,
  textEmailAddress,
  textPhoneNumber,
  textSelectTimeDropdown,
  textStartTime,
  textRequestDetails,
  textGuestLabel,
  textReservationNotes,
  isAndroid,
  onFirstNameChange,
  onLastNameChange,
  onTimeRowClick,
  onToggleReservationNotesModal,
  onToggleTimeSelect,
  requestTimes,
  onTimeChange,
  textInputProps,
  onEmailChange,
  inputFields,
  experienceName = null,
}) => (
  <div style={styles.sectionWrapper}>
    <div style={styles.labelWrapper}>
      <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{textRequestDetails}</span>
    </div>
    <div style={styles.infoForm}>
      <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
        <div style={styles.formBox}>
          <TextInput
            placeholder={`${textFirstName} *`}
            limitType="name"
            value={firstName}
            validator={validateNotEmpty}
            isValid={!formErrors.firstName}
            onChange={onFirstNameChange}
            style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
            ariaLabel="First Name"
            ref={input => {
              inputFields.firstName = input
            }}
            {...textInputProps}
          />
        </div>
        <div style={[styles.formBox, styles.padLeft]}>
          <TextInput
            placeholder={`${textLastName} *`}
            limitType="name"
            value={lastName}
            validator={validateNotEmpty}
            isValid={!formErrors.lastName}
            onChange={onLastNameChange}
            style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
            ariaLabel="Last Name"
            ref={input => {
              inputFields.lastName = input
            }}
            {...textInputProps}
          />
        </div>
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
        <TextInput
          placeholder={`${textEmailAddress} *`}
          limitType="email"
          value={email}
          validator={validateEmail}
          isValid={!formErrors.email}
          onChange={onEmailChange}
          style={styles.singleFieldInput}
          ariaLabel="Email"
          ref={input => {
            inputFields.email = input
          }}
          {...textInputProps}
        />
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      <div
        style={[
          styles.formLine,
          formErrors.phoneNumber ? styles.invalidPhoneNumber : null,
          { backgroundColor: colorCheckoutCellBackground },
        ]}
      >
        <PhoneNumber
          onPhoneNumberChange={changePhoneNum}
          onFlagChange={changeFlag}
          customPhoneNumberWrapper={[styles.singleFieldInput]}
          dialCode={dialCode}
          fontsColorCheckoutActive={fontsColorCheckoutActive}
          fontsColorCheckoutInactive={fontsColorCheckoutInactive}
          fontFamily={fontFamily}
          isValid={!formErrors.phoneNumber}
          phoneNumber={phoneNumber}
          selectedCountryCode={phoneNumberLocale}
          placeholderText={textPhoneNumber}
          autoComplete="random-bits-of-words-to-break-autocomplete"
          ariaLabel="Phone Number"
          ref={input => {
            inputFields.phoneNumber = input
          }}
          {...textInputProps}
        />
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      {experienceName && (
        <>
          <div
            style={[
              styles.requestLine,
              styles.offerName,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
            ]}
          >
            <img src={CalendarStar} alt="offer" style={styles.calendarStar} />
            <span style={[styles.summaryText, styles.requestFieldItem]}>{experienceName}</span>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        </>
      )}
      <div style={[styles.requestLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
        <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-calendar fa-fw" aria-hidden="true" />
        <span style={[styles.summaryText, styles.requestFieldItem]}>{queryDate}</span>
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      <div
        key="sr-request-start-time"
        onKeyUp={requestTimes.length > 1 && onTimeRowClick}
        onClick={requestTimes.length > 1 && onTimeRowClick}
        style={[
          styles.requestLine,
          { color: fontsColorCheckoutActive },
          { backgroundColor: colorCheckoutCellBackground },
          requestTimes.length > 1 && styles.activeLine,
        ]}
      >
        <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-time-8 fa-fw" aria-hidden="true" />
        <TextInput placeholder={textStartTime} value={startTime} disabled style={styles.requestInputItem} {...textInputProps} />
        {(!isMobile || isAndroid) && displaySearchType === searchTypes.REQUEST_TIME && requestTimes.length > 1 && (
          <ItemPicker
            headerText={textSelectTimeDropdown}
            selectedValue={startTime}
            valueSet={requestTimes}
            onItemClick={onTimeChange}
            onOutsideClick={onToggleTimeSelect}
            style={styles.requestItemPicker}
            fontsColorPrimary={fontsColorPrimary}
            colorCellBackground={colorCellBackground}
            colorHover={colorHover}
            colorHighlightRow={colorPrimary}
            colorHighlightRowText={fontsColorButton}
          />
        )}
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      <div style={[styles.requestLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
        <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-users fa-fw" aria-hidden="true" />
        <span style={[styles.summaryText, styles.requestFieldItem]}>
          {partySize} {textGuestLabel}
        </span>
      </div>
      <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
      <div
        onKeyUp={onToggleReservationNotesModal}
        onClick={onToggleReservationNotesModal}
        style={[
          styles.requestLine,
          styles.bottomBorderRadius,
          styles.activeLine,
          { height: 'auto', overflow: 'auto' },
          { color: fontsColorCheckoutActive },
          { backgroundColor: colorCheckoutCellBackground },
        ]}
      >
        <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-notes fa-fw" aria-hidden="true" />
        <span style={[styles.summaryText, styles.requestFieldItem]}>{textReservationNotes}</span>
        {note && <div style={[styles.noteDisplay, styles.requestNotePadding]}>{note}</div>}
      </div>
    </div>
  </div>
)

export default Radium(RequestForm)
