// /* @flow */
import * as actionTypes from './actionTypes'

export const loadUpsellsModule = (upsellCategories /* : Array<string> */, partySize /* : number */, resetModuleState /* : boolean */) => ({
  type: actionTypes.LOAD_UPSELLS_MODULE,
  upsellCategories,
  partySize,
  resetModuleState,
})

export const clickInventory = (inventoryId /* : string */) => ({
  type: actionTypes.CLICK_INVENTORY,
  inventoryId,
})

export const clickInventoryDescription = (inventoryId /* : string */, expandReadMore /* : boolean */) => ({
  type: actionTypes.CLICK_INVENTORY_DESCRIPTION,
  inventoryId,
  expandReadMore,
})

export const changeInventoryQuantity = (inventoryId /* : string */, quantity /* : number */) => ({
  type: actionTypes.CHANGE_INVENTORY_QUANTITY,
  inventoryId,
  quantity,
})
