import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Info, InfoTitle, InfoData, Icon } from 'widget/dining/containers/WaitingRoom'
import styles from '../assets/styles/checkout'

const PartySizeContainer = styled.div`
  display: flex;
  padding: 40px 40px 20px 40px;

  @media (max-width: 500px) {
    padding: 20px 3% 10px 3%;
  }
`
const BlocksContainer = styled.div`
  display: flex;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.39) 0px 0px 12px -2px;

  & > :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > :last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const WaitTimeDisplay = styled.span`
  font-size: 35px;
`

const WaitTimeUnitDisplay = styled.span`
  font-size: 16px;
  padding: 0 5px;
`

const PartySizeInfo = styled(Info)`
  background-color: ${props => props.colorCheckoutCellBackground};
  width: ${props => (props.hideQuoteTimes ? '100%' : '50%')};
`

const PartySizeInfoData = styled(InfoData)`
  display: flex;
  align-items: center;
  font-size: 40px;
  color: ${props => props.fontsColorCheckoutActive};
`

const Data = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 35px;
  line-height: 35px;
  padding: 0 2px;
`

const PartySizeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 35px;
  line-height: 35px;
  padding: 0 10px;
`

const Title = styled(InfoTitle)`
  color: ${props => props.fontsColorCheckoutInactive};
  @media (max-width: 400px) {
    font-size: 10px;
    margin-bottom: 8px;
  }
`

const getWaitTimeDisplay = (quotedWaitTime, minimumWaitShown, waitTimeBuffer) => {
  let waitText = ''
  if (quotedWaitTime === null || quotedWaitTime === -1) {
    waitText = '--'
  } else if (quotedWaitTime < minimumWaitShown) {
    if (waitTimeBuffer) {
      waitText = `${minimumWaitShown} - ${minimumWaitShown + waitTimeBuffer}`
    } else if (minimumWaitShown === 0) {
      waitText = minimumWaitShown
    } else {
      waitText = `< ${minimumWaitShown}`
    }
  } else if (waitTimeBuffer) {
    waitText = `${quotedWaitTime} - ${quotedWaitTime + waitTimeBuffer}`
  } else {
    waitText = quotedWaitTime
  }
  return waitText
}

const WaitlistPartySize = ({
  waitlist,
  incrementPartySize,
  decrementPartySize,
  colorCheckoutCellBackground,
  fontsColorCheckoutActive,
  fontsColorCheckoutInactive,
  minGuests,
  maxGuests,
  minimumWaitShown,
  waitTimeBuffer,
  hideQuoteTimes,
  textSelectPartySize,
  textEstimatedWaitTime,
  waitlistWidgetMinsText,
}) => {
  const quotedWaitTime = waitlist.quoted_wait_time
  const showIcons = !(quotedWaitTime > 60 && !waitTimeBuffer)
  let waitHour = ''
  let waitMin = ''
  if (quotedWaitTime > 60) {
    waitHour = Math.floor(quotedWaitTime / 60)
    waitMin = quotedWaitTime % 60
  }
  const waitText = getWaitTimeDisplay(quotedWaitTime, minimumWaitShown, waitTimeBuffer)

  const actionIconStyle = {
    cursor: 'pointer',
    transition: 'opacity 0.3s',
  }

  return (
    <PartySizeContainer>
      <BlocksContainer>
        <PartySizeInfo colorCheckoutCellBackground={colorCheckoutCellBackground} hideQuoteTimes={hideQuoteTimes}>
          <Title fontsColorCheckoutInactive={fontsColorCheckoutInactive}>{textSelectPartySize || 'SELECT PARTY SIZE'}</Title>
          <PartySizeInfoData fontsColorCheckoutActive={fontsColorCheckoutActive}>
            <button
              style={_.assign(
                {},
                actionIconStyle,
                styles.infoIcon,
                styles.buttonInfoIcon,
                minGuests === waitlist.party_size
                  ? {
                      color: `${fontsColorCheckoutInactive}`,
                    }
                  : {
                      color: `${fontsColorCheckoutActive}`,
                    }
              )}
              className="fa fa-minuscircle-line"
              onClick={e => {
                e.preventDefault()
                decrementPartySize(minGuests)
              }}
              aria-hidden="true"
              aria-label="decrement party size"
            />
            <PartySizeData>{waitlist.party_size}</PartySizeData>
            <button
              style={_.assign(
                {},
                actionIconStyle,
                styles.infoIcon,
                styles.buttonInfoIcon,
                maxGuests === waitlist.party_size
                  ? {
                      color: `${fontsColorCheckoutInactive}`,
                    }
                  : {
                      color: `${fontsColorCheckoutActive}`,
                    }
              )}
              className="fa fa-add-line"
              onClick={e => {
                e.preventDefault()
                incrementPartySize(maxGuests)
              }}
              aria-hidden="true"
              aria-label="increment party size"
            />
          </PartySizeInfoData>
        </PartySizeInfo>
        {!hideQuoteTimes && (
          <PartySizeInfo colorCheckoutCellBackground={colorCheckoutCellBackground}>
            <Title fontsColorCheckoutInactive={fontsColorCheckoutInactive}>{textEstimatedWaitTime || 'ESTIMATED WAIT TIME'}</Title>
            <PartySizeInfoData fontsColorCheckoutActive={fontsColorCheckoutActive}>
              <Icon fontSize="20px" className="fa fa-timer-line fa-fw" style={{ marginLeft: '10px' }} />
              {quotedWaitTime > 60 && !waitTimeBuffer ? (
                <Data>
                  <WaitTimeDisplay>{waitHour}</WaitTimeDisplay>
                  <WaitTimeUnitDisplay>hr</WaitTimeUnitDisplay>
                  <WaitTimeDisplay>{waitMin}</WaitTimeDisplay>
                  <WaitTimeUnitDisplay>{waitlistWidgetMinsText}</WaitTimeUnitDisplay>
                </Data>
              ) : waitTimeBuffer ? (
                <Data>
                  <WaitTimeDisplay>{waitText}</WaitTimeDisplay>
                </Data>
              ) : (
                <Data>{waitText}</Data>
              )}
              {showIcons && <WaitTimeUnitDisplay>{waitlistWidgetMinsText}</WaitTimeUnitDisplay>}
            </PartySizeInfoData>
          </PartySizeInfo>
        )}
      </BlocksContainer>
    </PartySizeContainer>
  )
}

export default WaitlistPartySize
