// /* @flow */

// Upsell Module API - 'widget/module/upsells'
import * as constants from './constants'
import MainView from './containers/MainView'
import reducer from './rootReducer'
import {
  selectUpsellTotal,
  selectUpsellPricingData,
  selectUpsellRequestData,
  getInventoryEdits,
  getUpsellInventoryEntities,
  getUpsellCategoriesEntities,
  getDefaultServiceCharge,
  getDefaultGratuity,
} from './selectors'

const selectors = {
  selectUpsellTotal,
  selectUpsellPricingData,
  selectUpsellRequestData,
  getInventoryEdits,
  getUpsellInventoryEntities,
  getUpsellCategoriesEntities,
  getDefaultServiceCharge,
  getDefaultGratuity,
}

const containers = { MainView }

export { constants, reducer, containers, selectors }
