import _ from 'lodash'
import Radium from 'radium'
import { Component } from 'react'
import { connect } from 'react-redux'
import { selectEnabledLanguages } from 'mgr/pages/single-venue/settings/selectors/language'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { validateEmail, validateNotEmpty, validateBirthdayDayMonthFormat } from '../../../../common/Validators'
import PhoneNumber from '../../../../component-lib/Widget/PhoneNumber'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { getLocaleDateFormat } from '../../../events/src/utils/date'
import { changePhoneNum, changeFormField, changeFlag } from '../actions/forms'
import { clientChangedPreferredLanguage } from '../actions/navigation'
import styles from '../assets/styles/checkout'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { ValidatorTypes } from 'mgr/lib/forms/TextInput'

const ariaLabelBlankFeedback = (text, errored) => (errored ? `${text} Can't Be Blank` : text)

const ariaLabelFormatFeedback = (text, errored, value) => {
  if (errored) {
    if (value === '') {
      return `${text} Can't Be Blank`
    }
    return `${text} Is Not Valid`
  }
  return text
}

const birthdayValidator = (birthday, locale) => validateBirthdayDayMonthFormat(birthday, getLocaleDateFormat(locale))

class CheckoutInformation extends Component {
  constructor(props) {
    super(props)
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'firstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'lastName')
    this.handleBirthdayTypeChange = this.props.changeFormField.bind(this, 'birthday')
    this.handlePostalCodeTypeChange = this.props.changeFormField.bind(this, 'postalCode')
    this.handleSalutationTypeChange = this.props.changeFormField.bind(this, 'salutation')
  }

  componentDidMount() {
    const {
      isModifyResMode,
      phoneLocked,
      reservationToBeModified,
      changePhoneNum,
      firstName,
      lastName,
      isRequestBookingMode,
      reservationRequest,
      email,
      salutationType,
    } = this.props
    if (salutationType !== 'Hidden') {
      document.getElementById('salutation').querySelector('div[role="button"]')?.focus?.()
    } else {
      document.getElementById('sr-last-name *')?.focus?.()
    }

    if (isModifyResMode || isRequestBookingMode) {
      this.handleFirstNameChange(firstName)
      this.handleLastNameChange(lastName)
      this.handleEmailChange(email)
    }

    if ((isModifyResMode || isRequestBookingMode) && !phoneLocked) {
      let phoneNumber = isModifyResMode ? reservationToBeModified.get('phone_number') : reservationRequest.get('phoneNumber')
      const phoneNumberLocale =
        (isModifyResMode && reservationToBeModified?.get('phone_number_locale')?.toLowerCase()) ||
        (isRequestBookingMode && reservationRequest?.get('phoneNumberLocale')?.toLowerCase()) ||
        null
      if (phoneNumber && phoneNumberLocale && phoneNumberLocale !== 'intl') {
        const dialCode = this.getDialCode(phoneNumberLocale)
        if (dialCode && phoneNumber && phoneNumber[0] === '+') {
          phoneNumber = phoneNumber.replace(`+${dialCode}`, '')
        }
        phoneNumber = phoneNumber.replace(/-/g, '')
        changePhoneNum(phoneNumber, dialCode)
      }
    }
  }

  handleEmailChange = value => {
    const strippedValue = value.replace(/\s+/g, '')
    this.props.changeFormField('email', strippedValue)
  }

  getDialCode(countryCode) {
    return allCountries[iso2Lookup[countryCode.toLowerCase()]].dialCode
  }

  generateBirthdayAndPhoneContent = () => {
    const {
      birthdayType,
      postalCodeType,
      colorLines,
      formErrors,
      colorError,
      colorCheckoutCellBackground,
      postalCode,
      birthday,
      textBirthday,
      textPostalCode,
      infoFields,
      fontFamily,
      fontsColorCheckoutActive,
      fontsColorCheckoutInactive,
      venueLocale,
    } = this.props

    if (birthdayType === 'Hidden' && postalCodeType === 'Hidden') {
      return undefined
    }

    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    if (birthdayType !== 'Hidden' && postalCodeType !== 'Hidden') {
      return (
        <>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
            <div style={[styles.formBox]}>
              <TextInput
                placeholder={`${textBirthday}${birthdayType === 'Required' ? ` * ` : ''}`}
                onFocusPlaceholder={getLocaleDateFormat(venueLocale)}
                style={styles.firstNameInput}
                value={birthday}
                charLimit={5}
                validator={birthday => birthdayValidator(birthday, venueLocale)}
                isValid={!formErrors.birthday}
                onChange={this.handleBirthdayTypeChange}
                testId="birthday-field"
                ariaLabel="Birthdate"
                ref={input => {
                  infoFields.birthday = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={[styles.formBox]}>
              <TextInput
                placeholder={`${textPostalCode}${postalCodeType === 'Required' ? ' *' : ''}`}
                style={styles.lastNameInput}
                value={postalCode}
                charLimit={15}
                onChange={this.handlePostalCodeTypeChange}
                validator={input => !!input}
                isValid={!formErrors.postalCode}
                ariaRequired
                ariaLabel="postal-code-field"
                testId="postal-code-field"
                ref={input => {
                  infoFields.postalCode = input
                }}
                {...textInputProps}
              />
            </div>
          </div>
        </>
      )
    }
    return birthdayType === 'Hidden' ? (
      <>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
          <TextInput
            placeholder={`${textPostalCode}${postalCodeType === 'Required' ? ' *' : ''}`}
            style={styles.singleFieldInput}
            value={postalCode}
            charLimit={15}
            onChange={this.handlePostalCodeTypeChange}
            validator={input => !!input}
            isValid={!formErrors.postalCode}
            ariaRequired
            ariaLabel="postal-code-field"
            testId="postal-code-field"
            ref={input => {
              infoFields.postalCode = input
            }}
            {...textInputProps}
          />
        </div>
      </>
    ) : (
      <>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
          <TextInput
            placeholder={`${textBirthday}${birthdayType === 'Required' ? ` * ` : ''}`}
            onFocusPlaceholder={getLocaleDateFormat(venueLocale)}
            style={styles.singleFieldInput}
            value={birthday}
            charLimit={5}
            validator={birthday => birthdayValidator(birthday, venueLocale)}
            isValid={!formErrors.birthday}
            onChange={this.handleBirthdayTypeChange}
            ariaLabel="Birthdate"
            testId="birthday-field"
            ref={input => {
              infoFields.birthday = input
            }}
            {...textInputProps}
          />
        </div>
      </>
    )
  }

  validateFields() {
    return _.reduce(this.inputs, (invalidInputs, input, field) => _.assign(invalidInputs, { [field]: input.validate() }), {})
  }

  bottomBorderStyle(lastField) {
    return lastField ? styles.bottomBorderRadius : null
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumberLocale,
      dialCode,
      birthdayType,
      fontsColorPrimary,
      colorCellBackground,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      infoFields,
      topElementId,
      formErrors,
      fontFamily,
      textYourInformation,
      textFirstName,
      textLastName,
      textEmailAddress,
      textPhoneNumber,
      textSelectButtonLabel,
      textSalutation,
      isModifyResMode,
      isRequestBookingMode,
      firstNameLocked,
      lastNameLocked,
      emailLocked,
      phoneLocked,
      languages = [],
      selectedLanguage,
      clientChangedPreferredLanguage,
      clientPreferredLanguage,
      textWidgetPreferredLanguageLabel,
      widgetTemplateTheme,
      colorPrimary,
      colorBackground,
      colorWidgetBackground,
      hideCountryFlags,
      salutationType,
      salutation,
      salutationOptions,
    } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }
    const showLanguageList = !_.isNil(languages) && !_.isNil(selectedLanguage) && languages.length > 1
    const styledLanguages = languages.map(language => ({
      name: language.name,
      is_default: language.is_default,
      is_enabled: language.is_enabled,
      value: language.value,
      style: {
        cursor: 'pointer',
        borderBottom: widgetTemplateTheme === 'LIGHT' ? '1px solid #f7f7f7' : 'none',
      },
    }))
    const isResWidgetDark = widgetTemplateTheme === 'DARK' && colorCellBackground === 'rgba(0, 0, 0, 1)'
    // Override customer preferred communication dropdown option background color
    const readOnlyFirstNameField = isModifyResMode || isRequestBookingMode || firstNameLocked
    const readOnlyLastNameField = isModifyResMode || isRequestBookingMode || lastNameLocked
    const readOnlyEmailField = isModifyResMode || isRequestBookingMode || emailLocked
    let hasPresetCustomSalutation = false
    const salutationOptionsWithCustomChoices = salutationOptions.map(salutationOption => ({
      name: salutationOption,
      value: salutationOption,
    }))
    if (salutation && !salutationOptionsWithCustomChoices.some(salutationOption => salutationOption.value === salutation)) {
      hasPresetCustomSalutation = true
      salutationOptionsWithCustomChoices.push({ name: salutation, value: salutation })
    }
    return (
      <div id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{textYourInformation}</span>
        </div>
        <div style={styles.infoForm}>
          <form autoComplete="on" className="contact-form">
            {salutationType !== 'Hidden' && (
              <>
                <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
                  <div style={[formErrors.salutation && styles.invalidPhoneNumber]}>
                    <div style={[styles.languageBox, formErrors.salutation && styles.invalidPhoneNumber]}>
                      <div
                        style={[
                          styles.languageDropDownLabel,
                          { color: formErrors.salutation ? colorError : fontsColorCheckoutInactive, fontSize: '14px' },
                          styles.tableCell,
                        ]}
                      >
                        {`${textSalutation}${salutationType === 'Required' ? ' *' : ''}`} :
                      </div>
                      <div style={[styles.tableCell]}>
                        <DropdownArrowsPicker
                          key="salutation"
                          id="salutation"
                          disabled={hasPresetCustomSalutation}
                          name={`${textSalutation}${salutationType === 'Required' ? ' *' : ''}`}
                          placeholder={textSelectButtonLabel}
                          style={{
                            textAlign: 'left',
                            color: fontsColorCheckoutInactive,
                            outline: '0',
                            border: 'none',
                          }}
                          choices={salutationOptionsWithCustomChoices}
                          value={salutation}
                          onValueChange={value => this.setState({ selected: value })}
                          selectedValue={this.state.selected}
                          validator={salutationType === 'Required' ? ValidatorTypes.notEmpty : undefined}
                          onChangeHandler={this.handleSalutationTypeChange}
                          showCaret
                          noHeader
                          height={31}
                          ariaDescriptor="Select title"
                          customStyle={{
                            colorLines,
                            colorBackground,
                            fontsColorPrimary,
                            colorWidgetBackground,
                            colorPrimary,
                            widgetTemplateTheme,
                            colorCellBackground,
                            isResWidgetDark,
                          }}
                          borderAreaStyle={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            borderRadius: '0px',
                          }}
                          optionsContainerStyle={{
                            border: 'none',
                          }}
                          ref={input => {
                            infoFields.salutation = input?.instanceRef
                          }}
                          testId="sr-salutation-field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              </>
            )}
            <div
              style={[
                styles.formLine,
                salutationType === 'Hidden' && styles.topBorderRadius,
                { backgroundColor: colorCheckoutCellBackground },
              ]}
            >
              <div style={[styles.formBox]}>
                <TextInput
                  placeholder={readOnlyFirstNameField ? '' : `${textFirstName} *`}
                  limitType="name"
                  value={firstName}
                  charLimit={50}
                  validator={validateNotEmpty}
                  isValid={!formErrors.firstName}
                  onChange={this.handleFirstNameChange}
                  style={[styles.firstNameInput]}
                  inputStyle={readOnlyFirstNameField ? styles.readOnlyInputText : {}}
                  autoComplete="given-name"
                  ariaLabel={ariaLabelBlankFeedback('First Name', formErrors.firstName)}
                  ariaRequired
                  readOnly={readOnlyFirstNameField}
                  ref={input => {
                    infoFields.firstName = input
                  }}
                  {...textInputProps}
                  data-test="sr-first-name-field"
                />
              </div>

              <div style={[styles.formBox]}>
                <TextInput
                  placeholder={readOnlyLastNameField ? '' : `${textLastName} *`}
                  limitType="name"
                  value={lastName}
                  ariaRequired
                  charLimit={50}
                  validator={validateNotEmpty}
                  isValid={!formErrors.lastName}
                  onChange={this.handleLastNameChange}
                  style={[styles.lastNameInput]}
                  inputStyle={readOnlyLastNameField ? styles.readOnlyInputText : {}}
                  autoComplete="family-name"
                  ariaLabel={ariaLabelBlankFeedback('Last Name', formErrors.lastName)}
                  readOnly={readOnlyLastNameField}
                  ref={input => {
                    infoFields.lastName = input
                  }}
                  {...textInputProps}
                  data-test="sr-last-name-field"
                />
              </div>
            </div>
            <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
              <TextInput
                placeholder={readOnlyEmailField ? '' : `${textEmailAddress} *`}
                limitType="email"
                value={email}
                ariaRequired
                charLimit={100}
                validator={validateEmail}
                isValid={!formErrors.email}
                onChange={this.handleEmailChange}
                style={styles.singleFieldInput}
                inputStyle={readOnlyEmailField ? styles.readOnlyInputText : {}}
                readOnly={readOnlyEmailField}
                autoComplete="email"
                ariaLabel={ariaLabelFormatFeedback('Email', formErrors.email, email)}
                ref={input => {
                  infoFields.email = input
                }}
                {...textInputProps}
                data-test="sr-email-field"
              />
            </div>
            <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            <div
              style={[
                styles.formLine,
                formErrors.phoneNumber ? styles.invalidPhoneNumber : null,
                formErrors.phoneNumber ? { borderColor: colorError } : null,
                { backgroundColor: colorCheckoutCellBackground },
              ]}
            >
              <div>
                <PhoneNumber
                  onPhoneNumberChange={this.props.changePhoneNum}
                  onFlagChange={this.props.changeFlag}
                  customStyles={[styles.phoneNumberInputContainer, phoneLocked ? styles.readOnlyInputText : null]}
                  customInputStyles={phoneLocked ? styles.readOnlyInputText : null}
                  readOnly={phoneLocked}
                  dialCode={dialCode}
                  fontsColorCheckoutActive={this.props.fontsColorCheckoutActive}
                  fontsColorCheckoutInactive={this.props.fontsColorCheckoutInactive}
                  fontFamily={fontFamily}
                  ariaRequired
                  isValid={!formErrors.phoneNumber}
                  phoneNumber={phoneNumber}
                  selectedCountryCode={phoneNumberLocale.toLowerCase()}
                  placeholderText={textPhoneNumber}
                  labelText={textPhoneNumber}
                  autoComplete="random-bits-of-words-to-break-autocomplete"
                  ariaLabel={ariaLabelFormatFeedback('Phone Number', formErrors.phoneNumber, textPhoneNumber)}
                  ref={input => {
                    infoFields.phoneNumber = input
                  }}
                  {...textInputProps}
                  isModifyResMode={isModifyResMode}
                  hideFlags={hideCountryFlags}
                  data-test="sr-phone-number-field"
                />
              </div>
            </div>
            {this.generateBirthdayAndPhoneContent()}
            {showLanguageList && (
              <div
                style={[
                  styles.languageBox,
                  {
                    backgroundColor: colorCheckoutCellBackground,
                  },
                  this.bottomBorderStyle(birthdayType === 'Hidden'),
                ]}
              >
                <div style={[styles.languageDropDownLabel, { color: fontsColorCheckoutInactive }]}>{textWidgetPreferredLanguageLabel}</div>
                <VenueSupportedLanguageDropDown
                  languages={styledLanguages}
                  selectedLanguage={clientPreferredLanguage || selectedLanguage}
                  onChange={clientChangedPreferredLanguage}
                  style={{
                    textAlign: 'left',
                    color: fontsColorCheckoutInactive,
                    outline: '0',
                    border: 'none',
                  }}
                  customStyle={{
                    colorLines,
                    colorBackground,
                    fontsColorPrimary,
                    colorWidgetBackground,
                    colorPrimary,
                    widgetTemplateTheme,
                    colorCellBackground,
                    isResWidgetDark,
                  }}
                  height={31}
                  showCaret
                  noHeader
                  borderAreaStyle={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    borderRadius: '0px',
                  }}
                  optionsContainerStyle={{
                    border: 'none',
                  }}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const selectedVenue = state.venueEntities[state.searchResults.get('timeSlotVenue')]
  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const reservationToBeModified = state.modifyReservation.get('actual')
  const isRequestBookingMode = state.reservationRequest.get('isRequestBookingMode')
  const reservationRequest = state.reservationRequest.get('reservationRequest')

  let firstName = state.formFields.get('firstName')
  let lastName = state.formFields.get('lastName')
  let email = state.formFields.get('email')

  if (isModifyResMode) {
    firstName = reservationToBeModified.get('first_name')
    lastName = reservationToBeModified.get('last_name')
    email = reservationToBeModified.get('email_address_raw')
  }

  if (isRequestBookingMode) {
    firstName = reservationRequest.get('firstName')
    lastName = reservationRequest.get('lastName')
    email = reservationRequest.get('email')
  }

  const phoneNumber = state.formFields.get('phoneNumber')
  const phoneNumberLocale = state.formFields.get('phoneNumberLocale')
  let firstNameLocked = false
  let lastNameLocked = false
  let emailLocked = false
  let phoneLocked = false
  if (state.clientInfo.lockedFields) {
    for (const attrib of Object.values(state.clientInfo.lockedFields)) {
      if (attrib === 'first_name') {
        firstNameLocked = true
      } else if (attrib === 'last_name') {
        lastNameLocked = true
      } else if (attrib === 'email') {
        emailLocked = true
      } else if (attrib === 'phone_number') {
        phoneLocked = true
      }
    }
  }
  const languageStrings = selectLanguageStrings(state)
  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    firstNameLocked,
    lastNameLocked,
    emailLocked,
    phoneLocked,
    birthday: state.formFields.get('birthday'),
    postalCode: state.formFields.get('postalCode'),
    salutation: state.formFields.get('salutation'),
    phoneNumberLocale,
    dialCode: state.formFields.get('dialCode'),
    formErrors: state.formFields.get('formErrors').toJS(),
    mediaUrl: state.widgetSettings.mediaUrl,
    fontFamily: state.widgetSettings.font,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    colorError: state.widgetSettings.colorError,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    colorLines: state.widgetSettings.colorLines,
    widgetTemplateTheme: state.widgetSettings.widgetTemplateTheme,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorBackground: state.widgetSettings.colorBackground,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    // text
    textYourInformation: languageStrings.textYourInformation,
    textFirstName: languageStrings.textFirstName,
    textLastName: languageStrings.textLastName,
    textEmailAddress: languageStrings.textEmailAddress,
    textPhoneNumber: languageStrings.textPhoneNumber,
    textBirthday: languageStrings.textBirthday,
    textPostalCode: languageStrings.textPostalCode,
    textWidgetPreferredLanguageLabel: languageStrings.textWidgetPreferredLanguageLabel,
    textSalutation: languageStrings.textSalutation,
    textSelectButtonLabel: languageStrings.textSelectButtonLabel,

    birthdayType: state.widgetSettings.birthdayType,
    postalCodeType: state.widgetSettings.postalCodeType,
    salutationType: state.widgetSettings.salutationType,
    isModifyResMode,
    isRequestBookingMode,
    venueLocale: state.venueInfo.municipality.locale,
    languages: selectEnabledLanguages(state),
    venueLanguages: state.languages.venueLanguages,
    selectedLanguage: state.languages.selectedLanguage,
    clientPreferredLanguage: state.languages.clientPreferredLanguage,
    reservationToBeModified,
    reservationRequest,
    hideCountryFlags: selectedVenue.hideCountryFlags,
    salutationOptions: state.venueInfo.salutationOptions,
  }
}

const mapDispatchToProps = {
  changePhoneNum,
  changeFlag,
  changeFormField,
  clientChangedPreferredLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutInformation))
