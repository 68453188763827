import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import _ from 'lodash'
import { fetchAdyenInitialData } from 'svr/component-lib/Widget/Payments/Adyen/services'
import { connect } from 'react-redux'

const IDEAL = 'ideal'
const SCHEME = 'scheme'
const BCMC = 'bcmc'
const PAYPAL = 'paypal'
const GIROPAY = 'giropay'
const PAYSAFECARD = 'paysafecard'
const SEPA_DIRECT_DEBIT = 'sepadirectdebit'

class AdyenFormRender extends Component {
  constructor(props) {
    super(props)
    this.adyenRootCard = React.createRef()

    this.state = {
      adyenCheckout: null,
      paymentType: SCHEME,
      hasIDeal: false,
      scheme: {
        state: { isValid: undefined },
      },
      ideal: {
        state: { isValid: undefined },
      },
      bcmc: {
        state: { isValid: undefined },
      },
      paypal: {
        state: {
          isValid: true,
          data: {
            paymentMethod: {
              type: PAYPAL,
            },
          },
        },
      },
      giropay: {
        state: {
          isValid: true,
          data: {
            paymentMethod: {
              type: GIROPAY,
            },
          },
        },
      },
      paysafecard: {
        state: {
          isValid: true,
          data: {
            paymentMethod: {
              type: PAYSAFECARD,
            },
          },
        },
      },
    }
  }

  hasPaymentMethod(method) {
    return _.some(this.props.adyenInitialData.payment_methods.message.paymentMethods, elem => elem.type === method)
  }

  componentDidMount() {
    const hasIDeal = this.hasPaymentMethod(IDEAL)
    const hasBcmc = !!this.props.adyenInitialData.is_bcmc_enabled
    const hasPaypal = this.hasPaymentMethod(PAYPAL)
    const paypalEnabled = !!this.props.adyenInitialData.is_paypal_enabled
    const hasGiropay = this.hasPaymentMethod(GIROPAY)
    const giropayEnabled = !!this.props.adyenInitialData.is_giropay_enabled

    this.setState({
      hasIDeal,
      hasBcmc,
      hasPaypal: hasPaypal && paypalEnabled,
      hasGiropay: hasGiropay && giropayEnabled,
      paymentType: SCHEME,
    })
    const configuration = {
      paymentMethodsResponse: this.props.adyenInitialData.payment_methods.message,
      clientKey: this.props.adyenInitialData.client_key,
      locale: this.props.adyenInitialData.locale,
      environment: this.props.adyenInitialData.environment,
      onChange: state => {
        this.handlerOnChange(state)
      },
      allowPaymentMethods: [SCHEME, IDEAL, PAYPAL, GIROPAY, PAYSAFECARD, BCMC, SEPA_DIRECT_DEBIT],
    }

    const dropInConfiguration = {
      onSelect: paymentTypeElement => {
        const paymentType = paymentTypeElement.props.type
        this.setState(prevState => {
          const newPaymentTypeState = { ...prevState[paymentType]?.state, paymentMethod: { type: paymentType } }
          return {
            ...prevState,
            paymentType,
            [paymentType]: {
              state: newPaymentTypeState,
            },
          }
        })
        this.props.onChange(this.state[paymentType].state)
      },
      showPayButton: false,
      redirectFromTopWhenInIframe: true,
    }

    AdyenCheckout(configuration).then(checkout => {
      checkout.create('dropin', dropInConfiguration).mount(this.adyenRootCard.current)
      this.setState({
        adyenCheckout: checkout,
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.dataFor3Dsecure) && !_.isEmpty(nextProps.dataFor3Dsecure)) {
      this.state.adyenCheckout
        .createFromAction(nextProps.dataFor3Dsecure, { redirectFromTopWhenInIframe: true })
        .mount(this.adyenRootCard.current)
    }
  }

  handlerOnChange = adyenState => {
    const paymentType = adyenState.data.paymentMethod.type === 'scheme' ? 'card' : adyenState.data.paymentMethod.type
    if (paymentType !== undefined) {
      this.setState(prevState => ({
        ...prevState,
        [paymentType]: adyenState,
      }))
    }
    this.props.onChange(adyenState)
  }

  render() {
    const { infoForm, mediaUrl } = this.props
    return (
      <>
        <div style={infoForm}>
          <div style={{ display: '' }} ref={this.adyenRootCard} />

          {this.state.paymentType === PAYPAL && (
            <div style={{ padding: '5px', textAlign: 'center' }}>
              <img
                style={{ width: '40%', display: 'inline-block', textAlign: 'center', padding: '10px' }}
                src={`${mediaUrl}images/widget/logo-adyen-paypal.png`}
              />
              <p>Payment details will be collected after clicking Submit.</p>
            </div>
          )}

          {this.state.paymentType === GIROPAY && (
            <div style={{ padding: '5px', textAlign: 'center' }}>
              <img
                style={{ width: '40%', display: 'inline-block', textAlign: 'center', padding: '10px' }}
                src={`${mediaUrl}images/widget/logo-adyen-giropay.png`}
              />
              <p>Payment details will be collected after clicking Submit.</p>
            </div>
          )}
        </div>
      </>
    )
  }
}

AdyenFormRender.propTypes = {
  dataFor3Dsecure: PropTypes.object,
  adyenInitialData: PropTypes.object,
  onChange: PropTypes.func,
  infoForm: PropTypes.object,
}

class AdyenFormFetch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adyenInitialData: null,
    }
  }

  componentDidMount() {
    fetchAdyenInitialData(this.props.venueId).then(r =>
      this.setState({
        adyenInitialData: r,
      })
    )
  }

  render() {
    if (!this.state.adyenInitialData?.client_key || this.state.adyenInitialData?.needClean) {
      return null
    }
    return (
      <>
        <AdyenFormRender
          key={this.props.venueId}
          adyenInitialData={this.state.adyenInitialData}
          dataFor3Dsecure={this.props.dataFor3Dsecure}
          infoForm={this.props.infoForm}
          onChange={this.props.onChange}
          mediaUrl={this.props.mediaUrl}
        />
      </>
    )
  }
}

AdyenFormFetch.propTypes = {
  venueId: PropTypes.string,
  dataFor3Dsecure: PropTypes.object,
  onChange: PropTypes.func,
  mediaUrl: PropTypes.string,
}

const mapStateToProps = state => ({
  mediaUrl: state.widgetSettings.mediaUrl,
})

export default connect(mapStateToProps)(AdyenFormFetch)
