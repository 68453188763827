import React from 'react'
import styled from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

const CaretIcon = styled(StyledVmsIconS)`
  order: 3;
  transform: rotate(${props => props.rotationAmt});
  font-weight: 100;
  font-size: ${props => (props.fontSize ? props.fontSize : '17px')};
  pointer-events: none;
  margin: 0 0 0 -5px;
  text-align: center;
  width: 30px;
  color: ${props => (props.customStyle ? props.customStyle.fontsColorPrimary : props.theme.navigationDark)};
`

class Caret extends React.PureComponent {
  getRotationFromDirection = direction => {
    const directionToRotationMap = {
      right: '0deg',
      down: '90deg',
      left: '180deg',
      up: '270deg',
    }
    return directionToRotationMap[direction]
  }

  render = () => {
    const { direction = 'up', customStyle, fontSize } = this.props
    const rotationAmt = this.getRotationFromDirection(direction)
    return (
      <CaretIcon role="button" tabIndex="0" rotationAmt={rotationAmt} fontSize={fontSize} customStyle={customStyle}>
        {VmsIcons.Chevron}
      </CaretIcon>
    )
  }
}

Caret.propTypes = {
  direction: React.PropTypes.string,
  fontSize: React.PropTypes.string,
  customStyle: React.PropTypes.object,
}

export default Caret
