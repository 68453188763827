/* eslint-disable react/prop-types */
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { selectLanguageStrings, selectCalculatedLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { selectTimeSlot } from '../../actions/navigation'
import { WidgetLine } from '../../uiKit/WidgetView'
import Dropdown from '../components/Dropdown'
import SideScrollRow from '../components/SideScrollRow'
import TimeSlot from '../components/TimeSlot'
import { selectVenueResults } from '../selectors'
import { calculateFeesForTimeSlot } from '../../utils/calculateFees'

const VenueResultsWrapper = styled.div`
  width: 100%;
`

const NoResultsWrapper = styled.div`
  text-align: center;
  background-color: ${props => props.theme.colorCellBackground};
  padding: 10px;
  line-height: 30px;
`

const VenueResultsLabel = styled.div`
  padding: 20px 4%;
  text-align: center;
  line-height: 24px;
  width: 100%;
  color: ${props => (props.noResults ? props.theme.fontsColorSecondary : props.theme.fontsColorPrimary)};
  background-color: ${props => props.theme.colorWidgetBackground};
`

const mapResultsToTimeSlot = (
  venueResult,
  availabilityLookup,
  partySize,
  selectTimeSlot,
  textPerPerson,
  textPerReservation,
  locale,
  upsellCategories,
  upsellInventories,
  isFeesInPriceDisplayed
) =>
  _.map(venueResult.times, (result, idx) => {
    if (!result) {
      return <TimeSlot key={`sr-timeslot-empty-${idx}`} timeSlotMoment={null} />
    }
    const timeSlotMoment = moment(result.timeIso)
    const dateKey = timeSlotMoment.format('Y-MM-DD')
    const timeKey = timeSlotMoment.format('h:mm A')
    const {
      requireCreditCard,
      cost,
      duration,
      chargeType,
      ccPartySizeMin,
      publicDescriptionTitle,
      publicTimeSlotDescription,
      publicLongFormDescription,
      publicPhoto,
      publicPhotoSizes,
      accessPersistentId,
    } = result
    const requirePayment = requireCreditCard && partySize > ccPartySizeMin
    const fees = calculateFeesForTimeSlot(isFeesInPriceDisplayed, result, upsellInventories, upsellCategories, partySize)

    return (
      <TimeSlot
        key={`sr-timeslot-${dateKey}-${timeKey}-${accessPersistentId}`}
        timeSlotMoment={timeSlotMoment}
        accessPersistentId={accessPersistentId}
        requirePayment={requirePayment}
        chargeType={chargeType}
        cost={cost}
        reservationFees={fees}
        duration={duration}
        publicDescriptionTitle={publicDescriptionTitle}
        publicTimeSlotDescription={publicTimeSlotDescription}
        publicLongFormDescription={publicLongFormDescription}
        publicPhoto={publicPhoto}
        publicPhotoSizes={publicPhotoSizes}
        onTimeSlotClick={selectTimeSlot}
        venueKey={venueResult.venueKey}
        textPerPerson={textPerPerson}
        textPerReservation={textPerReservation}
        locale={locale}
      />
    )
  })

const reduceVenueResults = (
  venueResults,
  venueEntities,
  availabilityLookup,
  partySize,
  selectTimeSlot,
  textPerPerson,
  textPerReservation,
  locale,
  selectedLanguage,
  upsellCategories,
  upsellInventories,
  isFeesInPriceDisplayed
) =>
  _.reduce(
    venueResults,
    (accum, venueResult, key) => {
      let newAccum = accum
      if (key !== 0) {
        newAccum = accum.concat(<WidgetLine key={`sr-line-${key}`} />)
      }
      const rowId = `sr-side-scroll-row-${venueResult.venueKey}`
      const venue = venueEntities[venueResult.venueKey]
      const crossSellVenueDescription = JSON.parse(venue.languageStringsJson ?? '{}')[selectedLanguage]
      const headerImg = venue.headerImg.large && `/.h/download/${venue.headerImg.large}`
      return newAccum.concat(
        <React.Fragment key={rowId}>
          <SideScrollRow
            label={venue.venueName}
            crossSellVenueShortDescription={crossSellVenueDescription?.resWidgetCrossSellVenueShortDescription ?? ''}
            crossSellVenueLongDescription={crossSellVenueDescription?.resWidgetCrossSellVenueLongDescription ?? ''}
            headerImg={headerImg}
          >
            {mapResultsToTimeSlot(
              venueResult,
              availabilityLookup,
              partySize,
              selectTimeSlot,
              textPerPerson,
              textPerReservation,
              locale,
              upsellCategories,
              upsellInventories,
              isFeesInPriceDisplayed
            )}
          </SideScrollRow>
        </React.Fragment>
      )
    },
    []
  )

const VenueResultsDisplay = ({
  venueResults,
  venueEntities,
  availabilityLookup,
  partySize,
  selectTimeSlot,
  mediaUrl,
  isFetchingMulti,
  isAllLocationsSearch,
  textSelectATime,
  textOtherLocations,
  textNoAdditionalOtherVenues,
  textNoAvailabilityForAccessRules,
  textPerPerson,
  textPerReservation,
  locale,
  open,
  selectedLanguage,
  upsellCategories,
  upsellInventories,
  isFeesInPriceDisplayed,
}) => {
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heightEvent',
        size: document.getElementById('dining-widget-app')?.getBoundingClientRect().height,
      },
      '*'
    )
  }, [])

  if (isAllLocationsSearch && _.isEmpty(venueResults)) {
    return (
      <VenueResultsWrapper>
        <VenueResultsLabel results>{textNoAvailabilityForAccessRules}</VenueResultsLabel>
      </VenueResultsWrapper>
    )
  } else if (isAllLocationsSearch) {
    return (
      <VenueResultsWrapper>
        {isAllLocationsSearch && <VenueResultsLabel>{textSelectATime}</VenueResultsLabel>}
        {reduceVenueResults(
          venueResults,
          venueEntities,
          availabilityLookup,
          partySize,
          selectTimeSlot,
          textPerPerson,
          textPerReservation,
          locale,
          selectedLanguage,
          upsellCategories,
          upsellInventories,
          isFeesInPriceDisplayed
        )}
      </VenueResultsWrapper>
    )
  }
  return (
    <Dropdown
      label={textOtherLocations}
      resultsIsEmpty={_.isEmpty(venueResults)}
      mediaUrl={mediaUrl}
      isFetching={isFetchingMulti}
      open={open}
    >
      <VenueResultsWrapper>
        {_.isEmpty(venueResults) && !isFetchingMulti ? (
          <NoResultsWrapper>{textNoAdditionalOtherVenues}</NoResultsWrapper>
        ) : (
          reduceVenueResults(
            venueResults,
            venueEntities,
            availabilityLookup,
            partySize,
            selectTimeSlot,
            textPerPerson,
            textPerReservation,
            locale,
            selectedLanguage,
            upsellCategories,
            upsellInventories,
            isFeesInPriceDisplayed
          )
        )}
      </VenueResultsWrapper>
    </Dropdown>
  )
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const placeholderLanguageStrings = selectCalculatedLanguageStrings(state)

  return {
    venueResults: selectVenueResults(state),
    venueEntities: state.venueEntities,
    availabilityLookup: state.availabilityLookup.toJS(),
    partySize: state.search.get('partySize'),
    isAllLocationsSearch: state.searchResults.get('isAllLocationsSearch'),
    mediaUrl: state.widgetSettings.mediaUrl,
    isFetchingMulti: state.searchResults.get('isFetchingMulti'),
    upsellCategories: state.upsells.entities.categories,
    upsellInventories: state.upsells.entities.inventories,
    isFeesInPriceDisplayed: state.widgetSettings.isFeesInPriceDisplayed,

    // text
    textNoAdditionalOtherVenues: languageStrings.textNoAdditionalOtherVenues,
    textNoAvailabilityForAccessRules: languageStrings.textNoAvailabilityForAccessRules,
    textOtherLocations: languageStrings.textOtherLocations,
    textPerPerson: languageStrings.textPerPerson,
    textPerReservation: languageStrings.textPerReservation,
    textSelectATime: placeholderLanguageStrings.textSelectATime,

    locale: state.venueInfo.locale,
    selectedLanguage: state.languages.selectedLanguage,
  }
}

const mapDispatchToProps = {
  selectTimeSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueResultsDisplay)
