import React from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  padding-right: ${props => props.theme.gutter.standard};
  font-size: ${props => props.theme.fontSize.body};
`

class VenueSupportedLanguageDropDown extends React.PureComponent {
  render() {
    const {
      languages,
      selectedLanguage,
      onChange,
      style,
      name = '',
      showCaret = true,
      noHeader = false,
      height = undefined,
      leftSideIcon = undefined,
      id = '',
      label = '',
      borderAreaStyle,
      customStyle,
      optionsContainerStyle,
      selectedItemDisplayStyle,
      optionBaseStyle,
      dropdownRelativeStyle,
      isLightTheme = true,
    } = this.props
    return languages.length ? (
      <Container>
        {label && <Label>{label}</Label>}
        <DropdownArrowsPicker
          key="VENUELANGUAGE"
          id={id}
          name={name}
          style={style}
          choices={languages}
          value={selectedLanguage}
          onChangeHandler={onChange}
          showCaret={showCaret}
          noHeader={noHeader}
          height={height}
          leftSideIcon={leftSideIcon}
          ariaDescriptor="Choose a language translation"
          customStyle={customStyle}
          borderAreaStyle={borderAreaStyle}
          optionsContainerStyle={optionsContainerStyle}
          selectedItemDisplayStyle={selectedItemDisplayStyle}
          optionBaseStyle={optionBaseStyle}
          isLightTheme={isLightTheme}
          dropdownRelativeStyle={dropdownRelativeStyle}
        />
      </Container>
    ) : null
  }
}

VenueSupportedLanguageDropDown.defaultProps = {
  selectedItemDisplayStyle: {},
  optionBaseStyle: {},
}

VenueSupportedLanguageDropDown.propTypes = {
  languages: React.PropTypes.array.isRequired,
  selectedLanguage: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  id: React.PropTypes.string,
  label: React.PropTypes.string,
}

export default VenueSupportedLanguageDropDown
