import Radium, { Style } from 'radium'
import React, { Component } from 'react'
import { getStyles, color } from './styles'

/* eslint-disable max-len */
const INPUT_LIMIT_TYPES = {
  none: /.+/g, // all characters
  // Need to support unicode chars (not just A-Za-z) and /p does not work in JS regex, so went with exclusive rather than inclusive
  // Source: https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
  name: /(^$)|(^([^!#$%&()*,./:;?@[\\\]_{|}¨ˇ“”€+<=>§°\s¤®™©]| )+$)/g,
  email: /.+/g,
  number: /[0-9 ]+/g, // digits and space
  birthday: /\d{0,2}(\/\d{0,2})?/, // birthday - mm/dd
}
/* eslint-enable max-len */

class TextAreaInput extends Component {
  constructor(props) {
    super(props)
    const formatPlaceholder = this.props.placeholder.toLowerCase().replace(' ', '-')
    this.inputId = this.props.inputId || `sr-${formatPlaceholder}`
    this.limitInput = this.limitInput.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  onInputChange(e) {
    const newVal = this.limitInput(e.target.value)
    if (newVal !== this.state.value) {
      this.props.onChange(newVal)
    }
  }

  limitInput(input) {
    const { limitType, charLimit } = this.props
    if (input === '') {
      return ''
    }
    const match = input.match(INPUT_LIMIT_TYPES[limitType])
    if (!match) {
      return ''
    } else if (charLimit) {
      return match[0].substring(0, charLimit)
    }
    return match[0]
  }

  validate() {
    return this.props.validator(this.props.value)
  }

  render() {
    const {
      value,
      isValid,
      placeholder,
      label,
      onFocusPlaceholder,
      placeholderColor,
      disabledColor,
      textColor,
      errorColor,
      style,
      disabled,
      readOnly,
      inputStyle,
    } = this.props
    const isFieldFocused = Radium.getState(this.state, this.inputId, ':focus') || value
    const styles = getStyles()

    return (
      <div style={[styles.inputWrapper, !isValid && { borderColor: errorColor }, style]}>
        <Style
          scopeSelector="*"
          rules={{
            '::-webkit-input-placeholder': {
              color: placeholderColor,
              fontFamily: 'inherit',
            },
            '::-moz-placeholder': {
              color: placeholderColor,
              fontFamily: 'inherit',
            },
            ':-ms-input-placeholder': {
              color: placeholderColor,
              fontFamily: 'inherit',
            },
            '.sr-invalid-input::-webkit-input-placeholder': {
              color: errorColor,
            },
            '.sr-invalid-input::-moz-placeholder': {
              color: errorColor,
            },
            '.sr-invalid-input:-ms-input-placeholder': {
              color: errorColor,
            },
          }}
        />
        <label
          style={[
            styles.label,
            { color: disabled ? disabledColor : isValid ? placeholderColor : errorColor },
            !label && !isFieldFocused && styles.labelHide,
          ]}
          htmlFor={this.inputId}
        >
          {label || placeholder}
        </label>
        <textarea
          id={this.inputId}
          key={this.inputId}
          type="text"
          value={this.props.value}
          className={(!isValid && 'sr-invalid-input') || undefined}
          onChange={this.onInputChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          placeholder={isFieldFocused ? onFocusPlaceholder : placeholder}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={this.props.autoComplete}
          data-test={this.props.dataTest}
          style={[
            styles.textAreaBox,
            (label || isFieldFocused) && styles.fillTextAreaBox,
            disabled && styles.defaultCursor,
            { color: isValid ? textColor : errorColor },
            { fontFamily: 'inherit' },
            inputStyle,
          ]}
        />
      </div>
    )
  }
}

TextAreaInput.propTypes = {
  value: React.PropTypes.string.isRequired,
  placeholder: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  onFocusPlaceholder: React.PropTypes.string,
  onChange: React.PropTypes.func,
  onFocus: React.PropTypes.func,
  onBlur: React.PropTypes.func,
  validator: React.PropTypes.func,
  isValid: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  dataTest: React.PropTypes.string,
  limitType: React.PropTypes.oneOf(['none', 'name', 'email', 'number', 'birthday']),
  charLimit: React.PropTypes.number,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  placeholderColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
  errorColor: React.PropTypes.string,
  autoComplete: React.PropTypes.string,
  readOnly: React.PropTypes.bool,
  inputStyle: React.PropTypes.objectOf(React.PropTypes.string),
}

TextAreaInput.defaultProps = {
  label: '',
  onFocusPlaceholder: '',
  isValid: true,
  disabled: false,
  readOnly: false,
  limitType: 'none',
  fontFamily: 'Roboto',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  validator: () => true,
  charLimit: null,
  style: {},
  inputStyle: {},
  placeholderColor: color.placeholder,
  disabledColor: color.placeholder,
  textColor: color.text,
  errorColor: color.error,
}

export default Radium(TextAreaInput)
