// /* @flow */
import * as React from 'react'

/* type ClickEvent = SyntheticMouseEvent<HTMLElement> */
/* type KeyboardEvent = SyntheticKeyboardEvent<HTMLElement> */
/* type Event = ClickEvent | KeyboardEvent */

/* type RenderProp = ({
  role: string,
  tabIndex: string,
  onClick: ClickEvent => void,
  onKeyDown: KeyboardEvent => void,
}) => React.Node */

/* type Props = {
  tabIndex: string,
  render: RenderProp,
  onClick: (Event => mixed) | boolean,
  children?: React.Node,
  customDiv?: Array<string | (() => mixed)>,
} */

class InteractiveEl extends React.Component /* <Props> */ {
  static KEY_CODES = [
    13, // spacebar
    32, // enter key
  ]

  static defaultProps = {
    onClick: () => {},
    tabIndex: '0',
  }

  getIsRelevantKey = (keyCode /* : number */) => InteractiveEl.KEY_CODES.includes(keyCode)

  role /* : string */ = 'button'

  eventHandler = (event /* : Event */) => {
    if (this.props.onClick instanceof Function) {
      event.preventDefault()
      // $FlowFixMe
      this.props.onClick(event)
    }
  }

  keyDownHandler = (event /* : KeyboardEvent */) => {
    if (this.getIsRelevantKey(event.keyCode)) {
      this.eventHandler(event)
    }
  }

  render() {
    return this.props.render({
      role: this.role,
      tabIndex: this.props.tabIndex,
      onClick: this.eventHandler,
      onKeyDown: this.keyDownHandler,
    })
  }
}

export default InteractiveEl
