import Radium from 'radium'
import React from 'react'
import Button from '../Buttons/Button'
import styles from './styles'

// requires fort awesome font package
const ItemStepper = ({
  value,
  label,
  incrementItem,
  labelColor,
  style,
  decrementItem,
  canDecrement,
  canIncrement,
  ariaIdentifier,
  onClick,
  id,
  dataTest,
  fontsColorPrimary,
}) => (
  <div style={[styles.itemWrapper, style]}>
    <div style={styles.stepperWrapper}>
      <Button
        innerElement={
          <i style={[styles.arrowIcon, !canDecrement && styles.arrowInactive]} className="fa fa-angle-left fa-fw" aria-hidden="true" />
        }
        disabled={!canDecrement}
        onClick={canDecrement && decrementItem}
        ariaLabel={ariaIdentifier ? `decrement ${ariaIdentifier}` : `decrement ${label}`}
        activeStyling={canDecrement}
        withDefaultOutline
        isLeft
        style={styles.arrowClickBox}
        testId="sr-decrement-arrow"
      />
      <button disabled={!label} style={styles.primaryButton} type="button" onClick={label && onClick} id={id} data-test={dataTest}>
        <div style={[styles.primaryButtonValue, { color: fontsColorPrimary }]}>{value}</div>
        <div style={[styles.primaryButtonLabel, { color: labelColor }]}>{label}</div>
      </button>
      <Button
        innerElement={
          <i style={[styles.arrowIcon, !canIncrement && styles.arrowInactive]} className="fa fa-angle-right fa-fw" aria-hidden="true" />
        }
        disabled={!canIncrement}
        onClick={canIncrement && incrementItem}
        ariaLabel={ariaIdentifier ? `increment ${ariaIdentifier}` : `increment ${label}`}
        activeStyling={canIncrement}
        withDefaultOutline
        isRight
        style={styles.arrowClickBox}
        testId="sr-increment-arrow"
      />
    </div>
  </div>
)

ItemStepper.propTypes = {
  value: React.PropTypes.node.isRequired,
  label: React.PropTypes.string,
  incrementItem: React.PropTypes.func.isRequired,
  decrementItem: React.PropTypes.func.isRequired,
  canDecrement: React.PropTypes.bool,
  canIncrement: React.PropTypes.bool,
  labelColor: React.PropTypes.string,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  ariaIdentifier: React.PropTypes.string,
  id: React.PropTypes.string,
  onClick: React.PropTypes.func,
  dataTest: React.PropTypes.string,
}

ItemStepper.defaultProps = {
  canDecrement: true,
  canIncrement: true,
  label: '',
  labelColor: 'rgba(51, 51, 51, 1.00)',
  style: {},
  ariaIdentifier: '',
}

export default Radium(ItemStepper)
