// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  minQuantitiesReached: boolean,
  total: { quantity: number, price: number },
  currencySymbol: string,
  selectUpsells: void => void,
} */

const UpsellFooter = (props /* : Props */) => (
  <FooterWrapper>
    <FooterQuantity>{`(${props.total.quantity}) ${props.textUpsellsSelectedLabel}`}</FooterQuantity>
    {props.total.price > 0 && (
      <FooterPrice data-test="sr-upgrade-price">{`${props.currencySymbol} ${props.total.price.toFixed(2)}`}</FooterPrice>
    )}
    <FooterButton
      isButtonEnabled={props.isFooterButtonEnabled}
      onClick={props.isFooterButtonEnabled && props.selectUpsells}
      data-test="sr-upgrade-next-button"
    >
      {props.textUpgradeNextLabel}
    </FooterButton>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  width: 100%;
  height: 75px;
  line-height: 55px;
  padding: 10px 20px;
  color: ${props => props.theme.fontsColorPrimary};
  background-color: ${props => props.theme.colorCellBackground};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FooterQuantity = styled.div`
  display: inline-block;
  height: 100%;
  width: 37%;
  text-align: left;
`

const FooterPrice = styled.div`
  display: inline-block;
  height: 100%;
  width: 37%;
  font-size: 18px;
  font-weight: 500;
  padding-right: 20px;
  text-align: right;
  color: ${props => props.theme.fontsColorPrimary};
`

const FooterButton = styled.button`
  display: inline-block;
  width: 24%;
  height: 42px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 2px;
  font-weight: 200;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  text-align: center;
  box-sizing: inherit;
  color: ${props => props.theme.fontsColorButton};
  background-color: ${props => props.theme.colorPrimary};
  cursor: ${props => (props.isButtonEnabled ? 'pointer' : 'default')};
  :focus {
    opacity: 0.8;
  }
  opacity: ${props => (props.isButtonEnabled ? '1.0' : '0.5')};
  :hover {
    opacity: ${props => (props.isButtonEnabled ? '0.8' : '0.5')};
  }
  :active {
    opacity: ${props => (props.isButtonEnabled ? '1.0' : '0.5')};
  }
`

export default UpsellFooter
