import priceFormatter from 'currency-formatter'
import Radium from 'radium'
import React from 'react'
import styled from 'styled-components'
import { WidgetButton } from 'svr/lib/Buttons/WidgetButton'
import styles from 'svr/lib/Modal/modals'
import { closeOnEscapeKeyDown } from 'svr/lib/Modal/modalUtils'
import { wysiwigFieldHasContents } from '../utils/conditions'
import media from '../utils/mediaTemplates'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const firstElementId = 'sr-dining-additional-selection-first'
const lastElementId = 'sr-dining-additional-selection-last'

const TimeSlotButtonMini = styled(WidgetButton)`
  position: relative;
  font-size: 14px;
  height: 36px;
  padding: 7px 0;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.57);
  color: ${props => props.theme.fontsColorButton};
  background-color: ${props => props.theme.colorPrimary};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  width: 24%;
  margin: 0 1%;
  float: right;
  ${media.small`
    width: 105px;
    margin: 0 3px;
  `};
`

const PublicPhotoImage = styled.div`
  width: 100%;
  height: 380px;
  background: transparent url(${props => props.url}) no-repeat center;
  background-size: cover;
`

const DiningAdditionalSelection = ({
  dismissModal,
  selectHandler,
  headerColor,
  headerTextColor,
  textColor,
  selectedAvailability,
  selectedTimeFormatted,
  upsellInventories,
  partySize,
  cost,
  currencyCode,
  textPerPerson,
  textPerReservation,
  selectButtonLabel = 'Select',
  fees = 0,
}) => {
  const {
    chargeType,
    duration,
    selectedAutomaticUpsells,
    publicDescriptionTitle,
    publicTimeSlotDescription,
    publicLongFormDescription,
    publicPhotoSizes,
    publicPhoto,
  } = selectedAvailability
  closeOnEscapeKeyDown(dismissModal)
  const slotSubtotal = (cost && chargeType && chargeType.endsWith('slot') ? (cost * duration) / 15 : cost) + fees
  const automaticUpsellDisplay = []
  Object.values(selectedAutomaticUpsells).forEach(selectedUpsell => {
    const quantityNum = selectedUpsell.quantityEqualType === 'SPECIFIC_NUMBER' ? selectedUpsell.quantityNum : partySize
    if (selectedUpsell.id in upsellInventories) {
      const upsellDisplay = [quantityNum, 'x', upsellInventories[selectedUpsell.id].name].join(' ')
      automaticUpsellDisplay.push(<div style={{ margin: '0 0 3px' }}>{upsellDisplay}</div>)
    }
  })
  return (
    <div style={styles.modalWidth} data-test="sr-time-slot-popup-modal">
      <div
        style={[
          styles.policyHeader,
          {
            backgroundColor: headerColor,
            color: headerTextColor,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        ]}
        data-test="sr-time-slot-popup-modal-header"
      >
        <span style={[styles.headerText, { width: '75%' }]}>{publicDescriptionTitle}</span>
        <button
          type="button"
          id={lastElementId}
          style={[styles.headerX, { backgroundColor: headerColor, color: headerTextColor }]}
          onClick={dismissModal}
          aria-label="Close modal"
        >
          ×
        </button>
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {publicPhotoSizes && publicPhotoSizes.large ? (
        <PublicPhotoImage url={`/.h/download/${publicPhotoSizes.large}`} />
      ) : publicPhoto ? ( // Viewport is only 655x380 so large (1000x1000) is sufficient, use raw for backwards compatibility
        <PublicPhotoImage url={publicPhoto} />
      ) : null}
      <div style={[styles.policyBody, { color: textColor, borderTop: '3px solid #CCC' }]}>
        <TimeSlotButtonMini id={firstElementId} onClick={selectHandler} data-test="sr-time-slot-popup-modal-select-button">
          <span>{selectButtonLabel}</span>
        </TimeSlotButtonMini>
        <div>{selectedTimeFormatted}</div>
        {cost ? (
          <div
            dangerouslySetInnerHTML={{
              __html: priceFormatter
                .format(slotSubtotal, { code: currencyCode })
                .concat(
                  chargeType && chargeType.startsWith('person') // handles 'person_slot' as well
                    ? `/${textPerPerson}`
                    : `/${textPerReservation}`
                )
                .concat(fees > 0 ? ` (Includes ${priceFormatter.format(fees, { code: currencyCode })} in fees)` : ''),
            }}
          />
        ) : null}
        <div
          dangerouslySetInnerHTML={{
            __html: publicTimeSlotDescription,
          }}
        />
      </div>
      {wysiwigFieldHasContents(publicLongFormDescription) && (
        <div
          style={[styles.policyBody, { color: textColor }, { borderTop: '3px solid #CCC' }]}
          dangerouslySetInnerHTML={{
            __html: publicLongFormDescription,
          }}
          data-test="sr-time-slot-popup-modal-bottom"
        />
      )}
      {automaticUpsellDisplay.length !== 0 && (
        <div style={[styles.policyBody, { color: textColor }, { borderTop: '3px solid #CCC' }]}>
          <div style={{ margin: '0 0 10px' }}>Included in the reservation:</div>
          {automaticUpsellDisplay}
        </div>
      )}
    </div>
  )
}

DiningAdditionalSelection.propTypes = {
  dismissModal: React.PropTypes.func.isRequired,
  headerColor: React.PropTypes.string,
  headerTextColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
  textPerPerson: React.PropTypes.string,
  textPerReservation: React.PropTypes.string,
  upsellInventories: React.PropTypes.object,
  selectButtonLabel: React.PropTypes.string,
}

DiningAdditionalSelection.defaultProps = {
  headerColor: 'black',
  headerTextColor: 'grey',
  textColor: 'black',
}

DiningAdditionalSelection.firstElementId = firstElementId
DiningAdditionalSelection.lastElementId = lastElementId

export default Radium(DiningAdditionalSelection)
