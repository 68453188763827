/* global Stripe, widgetInit */
import Cookies from 'js-cookie'
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import styled, { createGlobalStyle } from 'styled-components'
import AdyenForm from 'svr/component-lib/Widget/Payments/Adyen/AdyenForm'
import SaferpayForm from 'svr/component-lib/Widget/Payments/SaferPay/SaferpayForm'
import { getSaferpayToken } from 'svr/component-lib/Widget/Payments/SaferPay/services'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import CheckoutPaymentFreedompay from 'widget/dining/containers/CheckoutPaymentFreedompay'
import CheckoutPaymentStripe from './CheckoutPaymentStripe'
import { selectCalculatedStrings, selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { changeFormField } from 'widget/paylink/actions/forms'
import GMapsAddress from 'widget/universal/components/GMapsAddress'
import { selectUpsellPricingData } from '../../../modules/upsells/selectors'
import {
  selectPricingData,
  getGratuityFields,
  selectBasePrice,
  selectHasSubtotal,
  selectIsPaymentInfoRequired,
  selectIsUsingGiftCard,
  selectVenuePaymentType,
} from '../payments/paymentSelectors'

import {
  addPromoCode,
  changeCaptchaField,
  completeCaptchaField,
  mandatePolicies,
  removePromoCode,
  validateAllFields,
  verifyPromoCode,
} from '../actions/forms'
import { revertStage, toggleModalDisplay } from '../actions/navigation'
import {
  setAgreedToGdprDietaryOptIn,
  setFreedompayVisible,
  submitCheckoutData,
  submitGiftCardPaymentCheckoutData,
  submitPaymentCheckout,
  submitPaymentCheckoutDataCybersourceRedux,
  submitPaymentCheckoutDataCybersourceThreeDSRedux,
  submitPaymentCheckoutDataFreedomPay,
  submitPaymentCheckoutDataNetwork,
  submitPaymentCheckoutDataNetworkRedux,
  submitPaymentCheckoutDataNetworkReduxDirectPurchase,
  submitPaymentCheckoutDataStripe,
  hideLoading,
  tryCheckout,
  createStripeIntent,
} from '../actions/services'
import styles from '../assets/styles/checkout'
import searchStyles from '../assets/styles/search'
import Banner from '../components/Banner'
import { logReservationSummaryEvent } from '../utils/analyticsEvents'
import { CLIENT_PREFERRED_LANGUAGE_COOKIE, modalTypes, promoComponentTypes } from '../utils/constantTypes'
import { getBannerContents } from '../utils/selectors'
import CheckoutCybersourceThreeDsPayment from './CheckoutCybersourceThreeDsPayment'
import CheckoutInformation from './CheckoutInformation'
import CheckoutPayment from './CheckoutPayment'
import CheckoutSummary from './CheckoutSummary'
import { Redemption } from './Redemption'
import Shift4FormCheckout from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'

const is7RGdprPolicyRegex = /^https?:\/\/[\w\d._-]*sevenrooms[\w\d._-]*.com(:\d+)?\/gdpr-policy\//i

class Checkout extends Component {
  constructor(props) {
    super(props)
    this.inputFields = { information: {}, payment: {} }
    this.checkoutInfoId = 'sr-checkout-information'
    this.checkoutPaymentId = 'sr-checkout-payment'
    this.onSubmit = _.debounce(this.onSubmit.bind(this), 2000, {
      leading: true,
      trailing: false,
    })
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this)
    this.validateInputs = this.validateInputs.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
    this.setPromoCode = this.setPromoCode.bind(this)
    this.removePromoCode = this.removePromoCode.bind(this)
    this.onGMapsAddressUpdate = this.onGMapsAddressUpdate.bind(this)
    this.toggleAddPromoCodeModal = this.props.toggleModalDisplay.bind(this, modalTypes.PROMO_CODE_ADDED)
    this.toggleRemovePromoCodeModal = this.props.toggleModalDisplay.bind(this, modalTypes.PROMO_CODE_REMOVED)
    this.toggleSpecialAttentionMessage = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_ATTENTION_MESSAGE)

    const { paymentAccountId, connectedSetupIntents } = this.props.selectedVenue
    this.state = {}
    this.onShift4Success = null
    this.initializeWithStripeAccount = false
    if (this.props.paymentType === AccountTypes.STRIPE) {
      this.initializeWithStripeAccount = (this.props.hasSubtotal || connectedSetupIntents) && paymentAccountId
      const options = this.initializeWithStripeAccount ? { stripeAccount: paymentAccountId } : {}
      this.stripe = Stripe(widgetInit.stripeKey, options)
      if (this.props.isPaymentRequired) {
        this.props.createStripeIntent()
      }
    } else if (this.props.paymentType === AccountTypes.ADYEN) {
      this.state = {
        adyen: {
          state: { isValid: undefined },
        },
      }
    } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
      this.saferpayToken = null
      this.state = { saferpayIsValid: undefined }
    } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
      this.onShift4Success = (token, cardData) => this.props.submitPaymentCheckout(token, cardData)
    }
    this.state.submitClicked = false
    this.toggleSelectGratuityModal = this.props.toggleModalDisplay.bind(this, modalTypes.SELECT_GRATUITY)
  }

  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
    logReservationSummaryEvent()
  }

  onCaptchaChange(value) {
    this.props.changeCaptchaField(value)
    this.props.completeCaptchaField(value)
  }

  validateInputs() {
    this.setState({ submitClicked: true })
    const validateFields = fieldObj =>
      _.reduce(
        fieldObj,
        (fieldMap, input, field) => {
          let isValid = false
          if (input && 'validate' in input) {
            isValid = !input.validate()
          } else if (input && 'isValid' in input) {
            isValid = input.isValid() !== true
          }
          return _.assign(fieldMap, isValid && { [field]: true })
        },
        {}
      )

    let invalidInformationFields
    if (this.props.isModifyResMode) {
      invalidInformationFields = validateFields({
        phoneNumber: this.inputFields.information.phoneNumber,
        ...('birthday' in this.inputFields.information && {
          birthday: this.inputFields.information.birthday,
        }),
        ...('postalCode' in this.inputFields.information && {
          postalCode: this.inputFields.information.postalCode,
        }),
        ...('salutation' in this.inputFields.information && {
          salutation: this.inputFields.information.salutation,
        }),
      })
    } else {
      invalidInformationFields = validateFields(this.inputFields.information)
    }

    if (this.props.birthdayType === 'Optional' && !this.props.birthday && 'birthday' in invalidInformationFields) {
      delete invalidInformationFields.birthday
    }
    if (this.props.postalCodeType === 'Optional' && !this.props.postalCode && 'postalCode' in invalidInformationFields) {
      delete invalidInformationFields.postalCode
    }
    if (this.props.salutationType === 'Optional' && !this.props.salutationType && 'salutation' in invalidInformationFields) {
      delete invalidInformationFields.salutationType
    }
    const invalidPaymentFields = this.props.isPaymentRequired ? validateFields(this.inputFields.payment) : {}

    const gratuityError =
      this.props.gratuityFields.requireSelectGratuity && !this.props.selectedGratuityCharge ? { selectedGratuityCharge: true } : {}

    let agreedToBookingPolicyError = {}
    if (this.props.isPaymentRequired) {
      agreedToBookingPolicyError = this.props.agreedToBookingPolicy ? {} : { agreedToBookingPolicy: true }
    }

    let agreedToAboveAgeConsentError = {}
    if (this.props.displayAboveAgeConsentPolicy) {
      agreedToAboveAgeConsentError = this.props.agreedToAboveAgeConsentOn ? {} : { agreedToAboveAgeConsentOn: true }
    }

    let agreedCustomCheckoutPolicyError = {}
    if (this.props.isDisplayCustomCheckoutPolicy) {
      agreedCustomCheckoutPolicyError = this.props.agreedCustomCheckoutPolicy ? {} : { agreedCustomCheckoutPolicy: true }
    }

    let agreedToDietaryGdprOptinOnError = {}
    if (this.props.displayAgreedToDietaryOptIn) {
      const hasTags = Object.keys(this.props.dietaryRestrictions.toJS().selectedTags).length > 0
      agreedToDietaryGdprOptinOnError = this.props.agreedToDietaryGdprOptinOn || !hasTags ? {} : { agreedToDietaryGdprOptinOn: true }
    }

    if (
      agreedToDietaryGdprOptinOnError.length === 0 ||
      (is7RGdprPolicyRegex.test(this.props.textCustomGdprPolicyLink) && _.isEmpty(this.props.textCustomPrivacyPolicyLink))
    ) {
      this.props.setAgreedToGdprDietaryOptIn(true)
    }

    // this.props.recaptcha: recaptcha payload received after client completes the recaptcha
    // this.props.enableRecaptcha: if captcha is toggled on or off in the venue admin settings
    const validReCaptcha = this.props.recaptcha || !this.props.enableRecaptcha
    const reCaptchaError = validReCaptcha ? {} : { recaptcha: true }
    this.props.completeCaptchaField(this.props.recaptcha)

    const formErrors = _.assign(
      {},
      invalidInformationFields,
      invalidPaymentFields,
      agreedToBookingPolicyError,
      agreedCustomCheckoutPolicyError,
      agreedToAboveAgeConsentError,
      agreedToDietaryGdprOptinOnError,
      reCaptchaError,
      gratuityError
    )
    this.props.validateAllFields(formErrors)

    if (
      !_.isEmpty(invalidInformationFields) ||
      (this.props.isPaymentRequired &&
        (!_.isEmpty(invalidPaymentFields) ||
          (this.props.paymentType === AccountTypes.ADYEN && !this.state.adyen.state.isValid) ||
          (this.props.paymentType === AccountTypes.SAFERPAY && !this.state.saferpayIsValid) ||
          (this.isFreedomPayHpc() && !this.props.addressCountryCode && !this.props.isFreedompayRequestOnlyPostalCode) ||
          (this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX &&
            this.props.isCybersourceBillingAddressRequired &&
            !this.props.addressCountryCode)))
    ) {
      this.scrollToPayment()
    } else if (formErrors.selectedGratuityCharge) {
      document.querySelector(`#sr-gratuity-line`).focus()
      this.toggleSelectGratuityModal()
    } else if (
      formErrors.agreedToBookingPolicy ||
      formErrors.agreedToAboveAgeConsentOn ||
      formErrors.agreedCustomCheckoutPolicy ||
      formErrors.agreedToDietaryGdprOptinOn
    ) {
      this.props.mandatePolicies(formErrors)
    } else if (validReCaptcha) {
      return true
    }
    return false
  }

  onSubmit(event) {
    if (this.props.clientPreferredLanguage) {
      Cookies.set(CLIENT_PREFERRED_LANGUAGE_COOKIE, this.props.clientPreferredLanguage, { expires: 356 })
    }
    if (this.props.isPaymentRequired) {
      if (this.props.paymentType === AccountTypes.STRIPE) {
        this.props.submitPaymentCheckoutDataStripe(event, this.stripe)
      } else if (this.isFreedomPayHpc()) {
        this.props.setFreedompayVisible(true)
      } else if (this.props.paymentType === AccountTypes.FREEDOMPAY) {
        this.props.submitPaymentCheckoutDataFreedomPay()
      } else if (this.props.paymentType === AccountTypes.NETWORK) {
        this.props.submitPaymentCheckoutDataNetwork()
      } else if (this.props.paymentType === AccountTypes.CYBERSOURCE_REDUX) {
        this.props.submitPaymentCheckoutDataCybersourceRedux()
      } else if (this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX) {
        this.props.submitPaymentCheckoutDataCybersourceThreeDSRedux()
      } else if (this.props.paymentType === AccountTypes.NETWORK_REDUX) {
        this.props.submitPaymentCheckoutDataNetworkRedux()
      } else if (this.props.paymentType === AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE) {
        this.props.submitPaymentCheckoutDataNetworkReduxDirectPurchase()
      } else if (this.props.paymentType === AccountTypes.ADYEN) {
        this.props.submitPaymentCheckout(null, this.state.adyen.state.data)
      } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
        getSaferpayToken(this.saferpayToken).then(response => {
          this.saferpayToken = response.token
          this.props.submitPaymentCheckout(response.token)
        })
      } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
        this.props.paymentEngine.submit()
      } else {
        this.props.submitCheckoutData(null, null, true)
      }
    } else if (this.props.isUsingGiftCard) {
      this.props.submitGiftCardPaymentCheckoutData()
    } else {
      this.props.submitCheckoutData()
    }
  }

  onSubmitClickHandler(event) {
    const isDataValid = this.validateInputs()

    if (isDataValid) {
      this.onSubmit(event)
    }
  }

  handlerOnChangeAdyen = adyenState => {
    this.setState(prevState => ({ ...prevState, adyen: { state: adyenState } }))
  }

  handlerOnValidatedSaferPay = isValid => {
    this.setState({ saferpayIsValid: isValid })
  }

  onGMapsAddressUpdate(address, place) {
    const address1 = []
    let city
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.props.changeFormField('address1', null)
      this.props.changeFormField('city', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_3')) {
        city = addressComponent.long_name
      }
      if (!city && addressComponent.types.includes('administrative_area_level_2')) {
        city = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })

    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('city', city || '')
    this.props.changeFormField('locality', locality || '')
    this.props.changeFormField('administrativeArea', administrativeArea || '')
    this.props.changeFormField('countryCode', countryCode || '')
    this.props.changeFormField('postalCode', postalCode || '')
  }

  setPromoCode(promoCode) {
    if (this.props.hasSubtotal) {
      const hasUpsellPrice = this.props.upsellPrice && this.props.upsellPrice > 0
      const hasBasePrice = this.props.basePrice && this.props.basePrice > 0
      if (
        (promoCode.promoComponent === promoComponentTypes.UPGRADES && hasUpsellPrice) ||
        (promoCode.promoComponent === promoComponentTypes.BASE_PRICE && hasBasePrice) ||
        (promoCode.promoComponent === promoComponentTypes.ALL && (hasBasePrice || hasUpsellPrice))
      ) {
        this.props.addPromoCode(promoCode)
        this.props.verifyPromoCode()
        this.toggleAddPromoCodeModal()
      }
    }
  }

  scrollToPayment() {
    scroller.scrollTo(this.checkoutInfoId, {
      duration: 200,
      smooth: true,
      offset: -40,
    })
    document.querySelector(`#${this.checkoutInfoId} input`).focus()
  }

  removePromoCode(promoCode) {
    this.props.removePromoCode(promoCode)
    this.toggleRemovePromoCodeModal()
  }

  isFreedomPayHpc() {
    return this.props.paymentType === AccountTypes.FREEDOMPAY && this.props.isFreedompayHpcEnabled
  }

  renderPaymentBlock() {
    if (!this.props.isPaymentRequired) {
      return null
    }
    let component = null
    // it means we have not passed validation before we show payment block

    if (
      this.props.paymentType === AccountTypes.ADYEN ||
      this.props.paymentType === AccountTypes.SAFERPAY ||
      this.props.paymentType === AccountTypes.SHIFT_4
    ) {
      const isValid = this.props.paymentType === AccountTypes.ADYEN ? this.state.adyen.state.isValid : true
      component = (
        <PaymentDiv
          topElementId={this.checkoutPaymentId}
          style={styles.sectionWrapper}
          isValid={isValid}
          colorError={this.props.colorError}
          submitClicked={this.state.submitClicked}
        >
          <div style={styles.labelWrapper}>
            <span
              style={{
                ...styles.sectionLabel,
                color: this.props.fontsColorPrimary,
              }}
            >
              {this.props.textPayment}
            </span>
          </div>
          {this.props.paymentType === AccountTypes.ADYEN ? (
            <>
              <AdyenGlobalStyleDining />
              <AdyenForm
                venueId={this.props.venueId}
                infoForm={styles.infoForm}
                onChange={this.handlerOnChangeAdyen}
                dataFor3Dsecure={this.props.dataFor3Dsecure}
              />
            </>
          ) : null}
          {this.props.paymentType === AccountTypes.SAFERPAY ? (
            <>
              <SaferpayGlobalStyleDining />
              <SaferpayForm
                topElementId={this.checkoutPaymentId}
                venueId={this.props.venueId}
                onValidate={this.handlerOnValidatedSaferPay}
                dataFor3Dsecure={this.props.dataFor3Dsecure}
              />
            </>
          ) : null}
          {this.props.paymentType === AccountTypes.SHIFT_4 ? (
            <Shift4FormCheckout onSuccess={this.onShift4Success} isDining={this.props.isDining} />
          ) : null}
        </PaymentDiv>
      )
    } else if (this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && this.props.cybersourceThreeDsPaymentStep) {
      component = <CheckoutCybersourceThreeDsPayment />
    } else if (this.isFreedomPayHpc()) {
      let fpComponent = ''
      if (this.props.freedompay.visible) {
        fpComponent = <CheckoutPaymentFreedompay topElementId={this.checkoutPaymentId} />
      }

      if (this.props.isFreedompayRequestOnlyPostalCode) {
        component = fpComponent
      } else {
        component = (
          <>
            <div style={styles.sectionWrapper}>
              <div style={styles.infoForm}>
                <GMapsAddress onAddressUpdate={this.onGMapsAddressUpdate} submitClicked={this.state.submitClicked} />
              </div>
            </div>
            {fpComponent}
          </>
        )
      }
    } else if (this.props.paymentType === AccountTypes.STRIPE) {
      component = (
        <div style={styles.formWrapper} key={this.initializeWithStripeAccount}>
          <CheckoutPaymentStripe
            stripe={this.stripe || null}
            stripeIntentClientSecret={this.props.stripeIntentClientSecret}
            topElementId={this.checkoutPaymentId}
            paymentFields={this.inputFields.payment}
            selectedLanguage={this.props.selectedLanguage}
            submitClicked={this.state.submitClicked}
          />
        </div>
      )
    } else {
      component = (
        <div style={styles.formWrapper} key={this.initializeWithStripeAccount}>
          <CheckoutPayment
            topElementId={this.checkoutPaymentId}
            paymentFields={this.inputFields.payment}
            setPromoCode={this.setPromoCode}
            removePromoCode={this.removePromoCode}
            promoCodeInfo={this.props.promoCodeInfo}
            selectedLanguage={this.props.selectedLanguage}
            submitClicked={this.state.submitClicked}
            isCybersourceBillingAddressRequired={this.props.isCybersourceBillingAddressRequired}
          />
        </div>
      )
    }

    return component
  }

  render() {
    const {
      agreedToVenueSmsMarketingOptIn,
      agreedToReservationSmsOptIn,
      colorWidgetBackground,
      colorPrimary,
      colorActionBarBackground,
      fontsColorPrimary,
      fontsColorLinks,
      fontsColorActionBar,
      bannerInfo,
      isUsingGiftCard,
      revertStage,
      colorSummaryBar,
      fontsColorSummaryBar,
      fontsColorButton,
      colorLines,
      textSubmitButtonLabel,
      textHoldingTable,
      textHoldExpired,
      completeReCaptcha,
      recaptchaSiteKey,
      termsPolicyHolderName,
      termsOfServiceUrl,
      termsOfServiceText,
      textCustomPolicyHolderName,
      textCustomPrivacyPolicyLink,
      textCustomPrivacyPolicyLinkLabel,
      textCustomGdprPolicyLink,
      textCustomGdprPolicyLinkLabel,
      textAnd,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
      textPolicyDisclaimer,
      enableGiftCardRedemption,
      redemptionSystemsEnabled,
      policyUsTwilioSmsOptInSubfooter,
    } = this.props

    const buildTermsAnchorTag = (href, text) => (
      <a style={[styles.link, { color: fontsColorLinks }]} target="_blank" rel="noreferrer noopener" href={href} key={href + text}>
        {text}
      </a>
    )
    const groupTermsOfServiceAnchor = buildTermsAnchorTag(termsOfServiceUrl, termsOfServiceText)
    const gdprAnchor =
      !_.isEmpty(textCustomGdprPolicyLinkLabel) && buildTermsAnchorTag(textCustomGdprPolicyLink, textCustomGdprPolicyLinkLabel)

    const isSevenRoomsGdprPolicy = is7RGdprPolicyRegex.test(textCustomGdprPolicyLink)
    const hasCustomPrivacyPolicy = !_.isEmpty(textCustomPrivacyPolicyLink)

    const customPrivacyPolicyAnchor =
      hasCustomPrivacyPolicy && buildTermsAnchorTag(textCustomPrivacyPolicyLink, textCustomPrivacyPolicyLinkLabel)

    return (
      <div style={[styles.checkoutWrapper, { backgroundColor: colorWidgetBackground }]}>
        <Banner
          info={bannerInfo}
          onBackClick={revertStage}
          colorSummaryBar={colorSummaryBar}
          fontsColorSummaryBar={fontsColorSummaryBar}
          colorLines={colorLines}
          textHoldingTable={textHoldingTable}
          textHoldExpired={textHoldExpired}
        />
        {showSpecialAttentionMessage && (
          <div
            style={[
              styles.formWrapper,
              {
                backgroundColor: colorActionBarBackground,
                color: fontsColorActionBar,
                fontSize: '13px',
                borderBottomColor: colorLines,
                textAlign: 'center',
                padding: bannerInfo.expirationMoment ? '64px 0 0 0' : '40px 0 0 0',
              },
            ]}
          >
            <div style={{ padding: '16px 0' }}>
              <span>{specialAttentionMessageHeader}</span>
              <button
                type="button"
                style={[{ color: fontsColorActionBar }, searchStyles.infoIcon, searchStyles.buttonInfoIcon]}
                onClick={this.toggleSpecialAttentionMessage}
                className="fa fa-info-circle"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        <div
          style={[{ paddingTop: showSpecialAttentionMessage ? '0' : '68px' }, styles.formWrapper]}
          ref={comp => {
            this.initialFocusComponent = comp
          }}
        >
          <CheckoutInformation topElementId={this.checkoutInfoId} infoFields={this.inputFields.information} />
        </div>
        {!!isUsingGiftCard && _.includes(redemptionSystemsEnabled, 'VALUTEC') && enableGiftCardRedemption && (
          <div style={styles.formWrapper}>
            <Redemption />
          </div>
        )}
        {this.renderPaymentBlock()}
        <div style={styles.formWrapper}>
          <CheckoutSummary />
        </div>
        {this.props.enableRecaptcha ? (
          <div style={styles.summaryWrapper}>
            <ReCAPTCHA
              style={
                completeReCaptcha
                  ? {
                      ...styles.recaptcha,
                      borderColor: colorWidgetBackground,
                    }
                  : styles.invalidReCaptcha
              }
              // eslint-disable-next-line react/no-string-refs
              ref="recaptchaRef"
              sitekey={recaptchaSiteKey}
              onChange={this.onCaptchaChange}
            />
          </div>
        ) : null}
        <div style={[styles.footerText, { color: fontsColorPrimary }]}>
          {/* See WB-1546 for terms/policy formatting/language */}
          {`${textPolicyDisclaimer} ${termsPolicyHolderName} `}
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasCustomPrivacyPolicy && isSevenRoomsGdprPolicy
            ? [
                groupTermsOfServiceAnchor,
                gdprAnchor && ` ${textAnd} `,
                gdprAnchor,
                ` ${textAnd} ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
              ]
            : hasCustomPrivacyPolicy
            ? [
                groupTermsOfServiceAnchor,
                ` ${textAnd} ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
                gdprAnchor && ` ${textAnd} `,
                gdprAnchor,
              ]
            : [groupTermsOfServiceAnchor, gdprAnchor && ` ${textAnd} `, gdprAnchor]}
        </div>
        {(agreedToReservationSmsOptIn || agreedToVenueSmsMarketingOptIn) && (
          <div style={[styles.footerText, { color: fontsColorPrimary, marginBottom: '14px' }]}>
            <IntlProvider locale="en">
              <FormattedMessage
                id="policyUsTwilioSmsOptInSubfooter"
                // eslint-disable-next-line formatjs/enforce-default-message
                defaultMessage={policyUsTwilioSmsOptInSubfooter}
                values={{
                  i: chunks => <i>{chunks}</i>,
                }}
              />
            </IntlProvider>
          </div>
        )}
        <div style={styles.buttonWrapper}>
          <button
            type="submit"
            className="contact-button"
            style={[styles.submitButton, { backgroundColor: colorPrimary, color: fontsColorButton }]}
            onClick={this.onSubmitClickHandler}
            data-test="sr-submit-button"
          >
            {textSubmitButtonLabel}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const selectedVenue = state.venueEntities[state.searchResults.get('timeSlotVenue')]
  const languageStrings = selectLanguageStrings(state)
  const calculatedStrings = selectCalculatedStrings(state)
  const dietaryRestrictionsId = state.entities.tags.getIn(['clientTagGroups', 'dietaryPreference'])
  const tagGroups = state.entities.tags.get('tagGroups').toJS()
  const { policyUsTwilioSmsOptInSubfooter } = languageStrings

  return {
    dietaryRestrictions: state.entities.tags.getIn(['tagGroups', dietaryRestrictionsId]),
    bannerInfo: getBannerContents(state),
    partySize: state.search.get('partySize'),
    recaptcha: state.formFields.get('recaptcha'),
    paymentType: selectVenuePaymentType(state),
    currency: state.venueInfo.currencyCode,
    countryCode: state.venueInfo.countryCode,
    amount: selectPricingData(state).amountDueCents,
    freedompay: state.payment.freedompay,
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    agreedCustomCheckoutPolicy: state.formFields.get('agreedCustomCheckoutPolicy'),
    agreedToAboveAgeConsentOn: state.formFields.get('agreedToAboveAgeConsentOn'),
    agreedToDietaryGdprOptinOn: state.formFields.get('agreedToDietaryGdprOptinOn'),
    agreedToVenueSmsMarketingOptIn: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    agreedToReservationSmsOptIn: state.formFields.get('agreedToReservationSmsOptIn'),
    displayAboveAgeConsentPolicy: state.formFields.get('displayAboveAgeConsentPolicy'),
    isDisplayCustomCheckoutPolicy: state.formFields.get('isDisplayCustomCheckoutPolicy'),
    displayAgreedToDietaryOptIn: state.formFields.get('displayAgreedToDietaryOptIn'),
    addressCountryCode: state.formFields.get('countryCode'),
    isCybersourceBillingAddressRequired: selectedVenue.isCybersourceBillingAddressRequired,
    mediaUrl: state.widgetSettings.mediaUrl,
    selectedLanguage: state.languages.selectedLanguage,
    userStatus: state.user.get('status'),
    recaptchaSiteKey: state.widgetSettings.recaptchaSiteKey,
    enableRecaptcha: state.widgetSettings.recaptchaOn,
    completeReCaptcha: state.formFields.get('completeReCaptcha'),
    birthday: state.formFields.get('birthday'),
    birthdayType: state.widgetSettings.birthdayType,
    postalCodeType: state.widgetSettings.postalCodeType,
    isPaymentRequired: selectIsPaymentInfoRequired(state),
    salutationType: state.widgetSettings.salutationType,
    isUsingGiftCard: selectIsUsingGiftCard(state),
    venueId: state.venueInfo.id,
    hasSubtotal: selectHasSubtotal(state),
    promoCodeInfo: state.formFields.get('promoCodeInfo'),
    basePrice: selectBasePrice(state),
    upsellPrice: selectUpsellPricingData(state).upsellBaseAmount,
    selectedVenue,
    selectedGratuityCharge: state.formFields.get('selectedGratuityCharge'),
    gratuityFields: getGratuityFields(state),
    redemptionSystemsEnabled: selectedVenue && selectedVenue.redemptionSystemsEnabled,
    enableGiftCardRedemption: state.widgetSettings.enableGiftCardRedemption,
    isFreedompayHpcEnabled: state.venueInfo.isFreedompayHpcEnabled,
    isFreedompayRequestOnlyPostalCode: state.venueInfo.isFreedompayRequestOnlyPostalCode,
    stripeObject: state.commonPayment.stripe,
    // colors
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorLinks: state.widgetSettings.fontsColorLinks,
    fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
    fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
    colorLines: state.widgetSettings.colorLines,
    // text
    textSubmitButtonLabel: languageStrings.textSubmitButtonLabel,
    textHoldingTable: languageStrings.textHoldingTable,
    textHoldExpired: languageStrings.textHoldExpired,
    textPolicyDisclaimer: languageStrings.textPolicyDisclaimer,
    textAnd: languageStrings.textAnd,
    termsPolicyHolderName: state.widgetSettings.termsPolicyHolderName,
    termsOfServiceUrl: state.widgetSettings.termsOfServiceUrl,
    termsOfServiceText: languageStrings.textTermsOfServiceLinkLabel,
    privacyPolicyUrl: state.widgetSettings.privacyPolicyUrl,
    textCustomPolicyHolderName: calculatedStrings.textCustomPolicyHolderName,
    textCustomPrivacyPolicyLink: state.widgetSettings.textCustomPrivacyPolicyLink,
    textCustomPrivacyPolicyLinkLabel: languageStrings.textCustomPrivacyPolicyLinkLabel,
    textCustomGdprPolicyLink: state.widgetSettings.textCustomGdprPolicyLink,
    textCustomGdprPolicyLinkLabel: languageStrings.textCustomGdprPolicyLinkLabel,
    isModifyResMode: state.modifyReservation.get('isModifyResMode'),
    showSpecialAttentionMessage: state.widgetSettings.showSpecialAttentionMessage,
    specialAttentionMessageHeader: languageStrings.specialAttentionMessageHeader,
    clientPreferredLanguage: state.languages.clientPreferredLanguage,
    tagGroups,
    cybersourceThreeDsPaymentStep: state.payment.cybersourceThreeds.step,
    colorError: state.widgetSettings.colorError,
    textPayment: languageStrings.textPayment,
    dataFor3Dsecure: state.commonPayment.dataFor3Dsecure,
    paymentEngine: state.commonPayment.paymentEngine,
    policyUsTwilioSmsOptInSubfooter,
    isDining: true,
    locale: state.venueInfo.selectedLanguageCode,
    stripeIntentClientSecret: state.commonPayment.stripeIntentClientSecret,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  changeCaptchaField: recaptchaValue => {
    dispatch(changeCaptchaField(recaptchaValue))
  },
  completeCaptchaField: recaptchaValue => {
    dispatch(completeCaptchaField(recaptchaValue))
  },
  revertStage: (toStage, resetReCaptcha) => {
    dispatch(revertStage(toStage, resetReCaptcha))
  },
  submitCheckoutData: (prepped, processId, requirePaymentFields) => {
    dispatch(submitCheckoutData(prepped, processId, requirePaymentFields))
  },
  submitPaymentCheckout: (token, encryptedCardData) => {
    dispatch(submitPaymentCheckout(token, encryptedCardData))
  },
  submitPaymentCheckoutDataStripe: (event, stripe) => {
    dispatch(submitPaymentCheckoutDataStripe(event, stripe))
  },
  submitPaymentCheckoutDataFreedomPay: () => {
    dispatch(submitPaymentCheckoutDataFreedomPay())
  },
  submitPaymentCheckoutDataNetwork: () => {
    dispatch(submitPaymentCheckoutDataNetwork())
  },
  submitPaymentCheckoutDataNetworkRedux: () => {
    dispatch(submitPaymentCheckoutDataNetworkRedux())
  },
  submitPaymentCheckoutDataNetworkReduxDirectPurchase: () => {
    dispatch(submitPaymentCheckoutDataNetworkReduxDirectPurchase())
  },
  submitPaymentCheckoutDataCybersourceRedux: () => {
    dispatch(submitPaymentCheckoutDataCybersourceRedux())
  },
  submitPaymentCheckoutDataCybersourceThreeDSRedux: () => {
    dispatch(submitPaymentCheckoutDataCybersourceThreeDSRedux())
  },
  submitGiftCardPaymentCheckoutData: () => {
    dispatch(submitGiftCardPaymentCheckoutData())
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  validateAllFields: formErrors => {
    dispatch(validateAllFields(formErrors))
  },
  mandatePolicies: formErrors => {
    dispatch(mandatePolicies(formErrors))
  },
  addPromoCode: promoCode => {
    dispatch(addPromoCode(promoCode))
  },
  removePromoCode: promoCode => {
    dispatch(removePromoCode(promoCode))
  },
  verifyPromoCode: () => {
    dispatch(verifyPromoCode())
  },
  setFreedompayVisible: visible => {
    dispatch(setFreedompayVisible(visible))
  },
  setAgreedToGdprDietaryOptIn: agreedToGdprDietaryOptin => {
    dispatch(setAgreedToGdprDietaryOptIn(agreedToGdprDietaryOptin))
  },
  hideLoading: () => {
    dispatch(hideLoading())
  },
  tryCheckout: () => {
    dispatch(tryCheckout())
  },
  createStripeIntent: () => {
    dispatch(createStripeIntent())
  },
})

const mergeProps = (stateProps, dispatchProps) => {
  const revertStage =
    stateProps.userStatus === 'guest'
      ? dispatchProps.revertStage.bind(this, false, 2, true)
      : dispatchProps.revertStage.bind(this, false, 1, true)
  return _.assign(stateProps, dispatchProps, { revertStage })
}

const PaymentDiv = styled.div`
  border-radius: 8px;
  border-width: 2px;
  border-color: ${props => props.colorError};
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const AdyenGlobalStyleDining = createGlobalStyle`
  .adyen-checkout__label__text {
    padding: 10px 0px 10px 10px;
  }

  .adyen-checkout__input-wrapper {
    margin: 10px;
  }

  .adyen-checkout__field {
    margin-bottom: 0;
  }

  .adyen-checkout__issuer-list {
    padding: 1px;
  }
`

const SaferpayGlobalStyleDining = createGlobalStyle`
  #sr-checkout-payment {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 39%) 0px 0px 12px -2px;
  }

  #fields-holder-name, #fields-card-number, #fields-expiration, #fields-cvc {
    height: 40px;
    margin-bottom: 5px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Radium(Checkout))
