// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global Stripe */
import { Map } from 'immutable'
import _ from 'lodash'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { getLocaleDateFormat } from '../../../events/src/utils/date'
import {
  ADD_PROMO_CODE,
  APPLY_REDEMPTION,
  CANCEL_REDEMPTION_FORM,
  CHANGE_CAPTCHA_FIELD,
  CHANGE_COUNTRY_FLAG,
  CHANGE_FORM_FIELD,
  CHANGE_PHONE_NUM,
  CLEAR_FORM_VALIDATION,
  CLEAR_REDEMPTION_FORM,
  GET_WAITLIST_ENTRY_SUCCESS,
  GET_RESERVATION_REQUEST_SUCCESS,
  INVALIDATE_PROMO_CODE_ATTEMPT,
  LOAD_ACTUAL_SUCCESS,
  MANDATE_POLICIES,
  POST_CHECKOUT_FAILURE,
  REMOVE_PROMO_CODE,
  REVERT_STAGE,
  SELECT_TIME_SLOT,
  SET_USER_DATA_FB,
  SET_USER_DATA_GP,
  TOGGLE_FIELD,
  TRY_VERIFY_PROMO_CODE,
  VALIDATE_ALL_FIELDS,
  VALIDATE_CAPTCHA_FIELD,
  VERIFY_PROMO_CODE_FAILURE,
  VERIFY_PROMO_CODE_SUCCESS,
  VIEW_REDEMPTION_BALANCE,
  INITIALIZE_FORM_FIELDS,
  RESET_FORM_FIELDS,
} from '../actions/ActionTypes'
import * as AnalyticsEvents from '../utils/analyticsEvents'

const formFields = (state = Map(), action) => {
  switch (action.type) {
    case INITIALIZE_FORM_FIELDS:
      return state.merge(action.formFields)
    case RESET_FORM_FIELDS:
      return state.merge(action.formFields)
    case CLEAR_FORM_VALIDATION: {
      return state.merge({
        formErrors: state.get('formErrors').delete(action.field),
      })
    }
    case CHANGE_FORM_FIELD: {
      let { changeTo } = action
      if (action.field === 'birthday') {
        changeTo = changeTo.replace('/', '')
        if (Number.isNaN(changeTo)) {
          return state
        }
        if (changeTo.length > 2) {
          changeTo = `${changeTo.substr(0, 2)}/${changeTo.substr(2)}`
        }
      } else if (action.field === 'selectedGratuityCharge') {
        changeTo = changeTo.target.value
      }
      return state.merge({
        [action.field]: changeTo,
        formErrors: state.get('formErrors').delete(action.field),
      })
    }
    case CHANGE_PHONE_NUM:
      return state.merge({
        phoneNumber: action.changeTo,
        dialCode: action.dialCode,
        formErrors: state.get('formErrors').delete('phoneNumber'),
      })
    case CHANGE_COUNTRY_FLAG: {
      return state.merge({
        phoneNumberLocale: action.selectedCountry,
      })
    }
    case CHANGE_CAPTCHA_FIELD:
      return state.set('recaptcha', action.recaptchaValue)
    case VALIDATE_CAPTCHA_FIELD: {
      if (!action.recaptchaValue) {
        return state.set('completeReCaptcha', false)
      }
      return state.set('completeReCaptcha', true)
    }
    case REVERT_STAGE:
      return state.set('recaptcha', null)
    case VALIDATE_ALL_FIELDS: {
      return state.merge({
        formErrors: action.formErrors,
      })
    }
    case MANDATE_POLICIES: {
      const { formErrors } = action
      return state.merge({
        bookingPolicyStatus: formErrors.agreedToBookingPolicy ? 'invalid' : 'valid',
        customCheckoutPolicyStatus: formErrors.agreedCustomCheckoutPolicy ? 'invalid' : 'valid',
        agePolicyStatus: formErrors.agreedToAboveAgeConsentOn ? 'invalid' : 'valid',
        dietaryOptinStatus: formErrors.agreedToDietaryGdprOptinOn ? 'invalid' : 'valid',
      })
    }
    case POST_CHECKOUT_FAILURE: {
      const errors = [action.errorMessage]
      AnalyticsEvents.failedCheckout(errors)
      return state.merge({
        checkoutStatus: 'failure',
        error: errors,
      })
    }
    case TOGGLE_FIELD: {
      const fieldFlag = !state.get(action.field)
      const stateMergeValues = {
        [action.field]: fieldFlag,
        formErrors: state.get('formErrors').delete(action.field),
      }
      if (action.field === 'agreedToBookingPolicy') {
        _.extend(stateMergeValues, { bookingPolicyStatus: 'valid' })
      }
      if (action.field === 'agreedCustomCheckoutPolicy') {
        _.extend(stateMergeValues, { customCheckoutPolicyStatus: 'valid' })
      }
      if (action.field === 'agreedToAboveAgeConsentOn') {
        _.extend(stateMergeValues, { agePolicyStatus: 'valid' })
      }
      if (action.field === 'agreedToDietaryGdprOptinOn') {
        _.extend(stateMergeValues, { dietaryOptinStatus: 'valid' })
      }
      if (action.field === 'agreedToVenueGroupMarketingOptIn') {
        _.extend(stateMergeValues, {
          agreedToVenueSpecificMarketingOptIn: fieldFlag ? true : state.get('agreedToVenueSpecificMarketingOptIn'),
        })
      }
      if (action.field === 'agreedToVenueSmsMarketingOptIn') {
        _.extend(stateMergeValues, {
          agreedToVenueSmsMarketingOptIn: fieldFlag,
        })
      }
      if (action.field === 'agreedToVenueSpecificMarketingOptIn') {
        if (!fieldFlag && state.get('agreedToVenueGroupMarketingOptIn')) {
          _.extend(stateMergeValues, {
            agreedToVenueGroupMarketingOptIn: false,
          })
        }
      }
      if (action.field === 'agreedToReservationSmsOptIn') {
        _.extend(stateMergeValues, {
          agreedToReservationSmsOptIn: fieldFlag,
        })
      }
      if (action.field === 'agreedToTailoredCommunicationOptIn') {
        _.extend(stateMergeValues, {
          agreedToTailoredCommunicationOptIn: fieldFlag,
        })
      }
      return state.merge(stateMergeValues)
    }
    case SET_USER_DATA_FB:
      return state.mergeWith((prev, next) => prev || next, {
        email: action.response.email || '',
        firstName: action.response.first_name || '',
        lastName: action.response.last_name || '',
        birthday: action.response.birthday ? action.response.birthday.substr(0, 5) : '',
      })
    case SET_USER_DATA_GP:
      return state.mergeWith((prev, next) => prev || next, {
        email: action.response.email || '',
        firstName: action.response.firstName || '',
        lastName: action.response.lastName || '',
      })
    case TRY_VERIFY_PROMO_CODE:
      return state.set('isVerifyingPromoCode', true)
    case VERIFY_PROMO_CODE_SUCCESS:
      AnalyticsEvents.appliedPromoCode(action.data.id)
      return state.merge({
        isVerifyingPromoCode: false,
        validPromoCode: action.data.id,
      })
    case VERIFY_PROMO_CODE_FAILURE:
      return state.merge({
        isVerifyingPromoCode: false,
        validPromoCode: null,
        promoCode: '',
        autoAddedCreditCardPromoCode: false,
      })
    case ADD_PROMO_CODE:
      return state.merge({
        validPromoCode: null,
        promoCode: action.promoCode.code,
        promoCodeInfo: action.promoCode,
        autoAddedCreditCardPromoCode: true,
      })
    case REMOVE_PROMO_CODE:
      return state.merge({
        validPromoCode: null,
        promoCode: '',
        promoCodeInfo: null,
        lastApplicableCC: action.promoCode,
      })
    case INVALIDATE_PROMO_CODE_ATTEMPT:
      return state.merge({
        validPromoCode: null,
        promoCode: '',
        promoCodeInfo: null,
        lastApplicableCC: null,
        isVerifyingPromoCode: false,
      })
    case SELECT_TIME_SLOT:
      return state.merge({
        textVenueGroupMarketingOptIn: action.textVenueGroupMarketingOptIn,
        textVenueSpecificMarketingOptIn: action.textVenueSpecificMarketingOptIn,
        textReservationSmsOptIn: action.textReservationSmsOptIn,
        textTailoredCommunicationOptInLabel: action.textTailoredCommunicationOptInLabel,
        textTailoredCommunicationOptInHeader: action.textTailoredCommunicationOptInHeader,
        textTailoredCommunicationOptInBody: action.textTailoredCommunicationOptInBody,
        displayReservationSmsOptInPolicy: action.displayReservationSmsOptInPolicy,
        displayTailoredCommunicationOptInPolicy: action.displayTailoredCommunicationOptInPolicy,
        agreedToReservationSmsOptIn: action.agreedToReservationSmsOptIn,
        cardNum: '',
        cardMonthExp: '',
        cardYearExp: '',
        cardCvv: '',
        gratuityCharge: '',
        firstName: action.firstName,
        lastName: action.lastName,
        partySize: action.partySize,
        phoneNumber: action.phoneNumber,
        phoneNumberLocale: action.phoneNumberLocale,
        email: action.email,
        dialCode: action.dialCode,
      })
    case GET_RESERVATION_REQUEST_SUCCESS: {
      // Fail-safe even phone number locale is not good
      let locale = action.venueCountryCode
      if (action.reservationRequest.phoneNumberLocale !== 'INTL') {
        locale = action.reservationRequest.phoneNumberLocale.toLowerCase()
      }
      const { dialCode } = allCountries[iso2Lookup[locale]]
      let phoneNumber = action.reservationRequest.phoneNumber || ''
      phoneNumber = phoneNumber.replace(`+${dialCode}`, '').replace(/-/g, '')
      return state.merge({
        dialCode,
        phoneNumberLocale: locale,
        phoneNumber,
      })
    }
    case LOAD_ACTUAL_SUCCESS: {
      let birthday = action.actual.birthday !== null ? action.actual.birthday : ''

      // Our backend always returns birthday as mm/dd. If the locale requires dd/mm
      // update the format in state
      if (birthday && action.venueLocale && getLocaleDateFormat(action.venueLocale) === 'dd/mm') {
        birthday = `${birthday.split('/')[1]}/${birthday.split('/')[0]}`
      }

      // Fail-safe even phone number locale is not good
      let locale = action.venueCountryCode
      if (action.phoneNumberLocale !== 'INTL') {
        locale = action.phoneNumberLocale.toLowerCase()
      }
      const { dialCode } = allCountries[iso2Lookup[locale]]
      let phoneNumber = action.actual.phone_number || ''
      phoneNumber = phoneNumber.replace(`+${dialCode}`, '')
      return state.merge({
        dialCode,
        phoneNumberLocale: locale,
        phoneNumber: phoneNumber.replace(`+${dialCode}`, '').replace(/-/g, ''),
        ...(action.birthdayType !== 'Hidden'
          ? {
              birthday,
            }
          : null),
        ...(action.postalCodeType !== 'Hidden'
          ? {
              postalCode: action.actual.postal_code !== null ? action.actual.postal_code : '',
            }
          : null),
        ...(action.salutationType !== 'Hidden'
          ? {
              salutation: action.actual.salutation !== null ? action.actual.salutation : '',
            }
          : null),
      })
    }
    case GET_WAITLIST_ENTRY_SUCCESS: {
      const { waitlistEntry } = action
      return state.merge({
        firstName: waitlistEntry.first_name,
        lastName: waitlistEntry.last_name,
        phoneNumber: waitlistEntry.phone_number,
        email: waitlistEntry.email,
      })
    }

    case APPLY_REDEMPTION:
    case CLEAR_REDEMPTION_FORM:
    case CANCEL_REDEMPTION_FORM:
      return state.merge({
        redemptionCardId: '',
        redemptionCardPin: '',
        amountToRedeem: '',
      })
    case VIEW_REDEMPTION_BALANCE:
      return state.merge({
        amountToRedeem: action.amountToRedeem,
      })
    default:
      return state
  }
}

export default formFields
