// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  children: React.Node,
  shouldFocusFirstElement?: boolean,
  shouldScrollTop?: boolean,
  footer?: React.Node,
  customViewTable?: Array<string | (() => mixed)>,
  customViewRowGroup?: Array<string | (() => mixed)>,
  customViewSection?: Array<string | (() => mixed)>,
  customViewFill?: Array<string | (() => mixed)>,
} */

class WidgetView extends React.Component /* <Props> */ {
  static defaultProps = {
    customViewTable: [],
    customViewRowGroup: [],
    customViewSection: [],
    customViewFill: [],
    customSectionBorder: [],
  }

  componentDidMount() {
    if (this.props.shouldFocusFirstElement && this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
    if (this.props.shouldScrollTop) {
      if (document.body) {
        document.body.scrollTop = 0
      }
      if (document.documentElement) {
        document.documentElement.scrollTop = 0
      }
    }
  }

  initialFocusComponent /* : HTMLElement */

  render() {
    return (
      <ViewTable customViewTable={this.props.customViewTable}>
        <ViewRowGroup customViewRowGroup={this.props.customViewRowGroup}>
          {React.Children.map(this.props.children, (child, index) => (
            <ViewSection
              key={`sr-view-child-${index}`}
              tabIndex={this.props.shouldFocusFirstElement && index === 0 ? '0' : null}
              customViewSection={this.props.customViewSection}
              ref={comp => {
                // set focus to first view section
                if (index === 0 && this.props.shouldFocusFirstElement && comp instanceof HTMLElement) {
                  this.initialFocusComponent = comp
                }
              }}
            >
              {child}
            </ViewSection>
          ))
            .concat(
              <ViewFill key="sr-view-child-fill" customViewFill={this.props.customViewFill}>
                <div style={{ height: '100%' }} />
              </ViewFill>
            )
            .concat(this.props.footer)}
        </ViewRowGroup>
      </ViewTable>
    )
  }
}

const ViewTable = styled.div`
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  ${props => props.customViewTable};
`

const ViewRowGroup = styled.div`
  display: table-row-group;
  width: 100%;
  height: 100%;
  ${props => props.customViewRowGroup};
`

const ViewSection = styled.div`
  display: table-row;
  outline: none;
  height: 0px;
  ${props => props.customViewSection};
`

const ViewFill = styled.div`
  display: table-row;
  outline: none;
  height: 100%;
  ${props => props.customViewFill};
`

export default WidgetView
