import money from 'money-math'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'

// There's lot of direct show/hide
// here because the elements can't
// stop existing in react or Freedom Pay
// will break, and this can't remove
// dom or react will break. This method
// is a tense truce.
const interfaceToggle = show => {
  const style = show ? 'block' : 'none'
  document.getElementById('alternate-interface-cover').style.display = style
  document.getElementById('alternate-payment-interface').style.display = style

  if (!show) {
    document.getElementById('alternate-interface-mount').innerHTML = ''
    document.getElementById('alternate-interface-loader').style.display = 'block'
  }
}

const ProductLocalization = {
  orderWidget: {
    itemIdentifiers: {
      name: 'Cart',
      description: 'Web pickup or delivery order',
    },
    domPrep: () => {
      document.getElementById('standard-payment-interface').style.display = 'none'
      document.getElementById('close-button').onclick = () => {
        interfaceToggle(false)
      }
    },

    formStyles: `
      label { font-size: 15px; color: #878787; }
      input { color: #666666; border: 2px solid rgba(236,236,236,1); font-size: 18px; font-family: Inter, Arial, sans-serif; }
      input:hover { border-color: #666666; }
      input::placeholder { color: #cccccc; }
      button { border: none; background-color: rgba(33,63,99,1); padding: 0.75em; font-size: 18px; font-family: Inter, Arial, sans-serif; }
      button:hover { background-color: rgba(33,63,99,0.7); }
    `,

    appleStyles: `
      .fp-apple-pay-button { height:50px; width: 280px; display: block; }
    `,

    // See above note; this can be an empty
    // function in many places.
    localMount: () => {
      interfaceToggle(true)
    },
    localUnmount: () => {
      interfaceToggle(false)
    },
    mount: 'alternate-interface-mount',
    style: {
      mount: mount => {},
    },
  },
  vms: {},
}

const VERSION = 'v1.5'

export const FREEDOMPAY_SOFT_DECLINE_CODES = ['216', '217']

class FreedomPayGateway {
  constructor(venueInfo, isProd, product, info) {
    this.delayProcessing = true

    this.validationResults = []

    this.isProd = isProd
    this.venueInfo = venueInfo
    this.product = product
    this.localization = ProductLocalization[this.product]
    this.paymentMethod = null
    this.launchButtonRequired = true

    this.rejector = null
    this.resolver = null
    this.total = 0
    this.sessionKey = null

    this.channel = PAYMENT_CHANNELS.NEW_CREDIT_CARD

    // Not used in this class but referenced by business logic
    this.defaultChannel = PAYMENT_CHANNELS.NEW_CREDIT_CARD

    window.removeEventListener('message', window.boundListener)
    window.boundListener = this.handleEvent.bind(this)
    window.addEventListener('message', window.boundListener)

    this.loaderDots = document.getElementById('alternate-interface-loader')

    this.payButtonCallback = () => {
      console.log('callback not defined')
    }
    this.loadedCallback = () => {
      console.log('callback not defined')
    }
    this.info = info
  }

  // Note that mount is discarded in this case,
  // in favor of this.mount
  attachPrerequisites(mount, errHandler, save = false) {
    const domain = `https://hpc.${this.isProd ? '' : 'uat.'}freedompay.com`
    const cardinalLib = `${domain}/api/${VERSION}/cdn/hpc_min.js`
    const head = document.getElementsByTagName('head').item(0)
    const script = document.createElement('script')
    script.id = 'freedompay-script'

    this.loadedCallback()
    this.localization.domPrep()

    this.domain = domain

    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', cardinalLib)
    script.onerror = () => {
      errHandler()
    }

    this.mount = document.getElementById(this.localization.mount)
    this.localization.style.mount(this.mount)
    if (!document.getElementById('freedompay-script')) {
      head.appendChild(script)
    }
  }

  channelSelector(channel, supportInfo) {
    this.channel = channel
    const payLater = this.isPayLater(channel)
    const loadedForms = [PAYMENT_CHANNELS.NEW_CREDIT_CARD, PAYMENT_CHANNELS.GOOGLE_PAY, PAYMENT_CHANNELS.APPLE_PAY]
    const channelAvailable = loadedForms.includes(channel)
    document.getElementById('place-order-button').style.display = payLater ? 'block' : 'none'
    this.mount.style.display = channelAvailable ? 'block' : 'none'
    if (channelAvailable && supportInfo && supportInfo.submitOrder) {
      this.loaderDots.style.display = 'block'
      this.mount.style.display = 'none'

      supportInfo.submitOrder(channel)
    }
  }

  componentUpdate() {
    // This kind of dom manipulation
    // does not play nicely with react,
    // so we must tread lightly.
    const text = document.getElementById('checkout-button-text')
    if (!text) {
      return
    }
    text.innerHTML = 'Proceed to Payment'
  }

  mountForm(iframe, save = false) {
    this.mount.innerHTML = iframe
    this.mount.style.display = 'block'
    this.loaderDots.style.display = 'none'
  }

  mountScreen() {
    this.localization.localMount()
  }

  unmountForm() {
    this.localization.localUnmount()
    this.mount.style.display = 'none'
  }

  formLoaded(data) {
    const iframe = this.mount.childNodes[0]
    iframe.style.height = `${data.height}px`

    // If we override FreedomPay's defaults,
    // which we have to to avoid address
    // requirements, we have to build the
    // whole call, hence all the details.
    FreedomPay.Apm.ApplePay.initialize({
      total: { label: 'Order', type: 'final', amount: money.floatToAmount(this.total / 100) },
      currencyCode: this.venueInfo.currency,
      countryCode: this.venueInfo.countryCode.toUpperCase(),
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['amex', 'discover', 'jcb', 'masterCard', 'visa'],
    })
  }

  handleValidityChange(data) {
    this.validationResults = this.validationResults.filter(item => item.emittedBy !== data.emittedBy)

    if (data.isValid === false) {
      this.validationResults.push(data)
      // do something with the errors
    } else {
      // clear errors if necessary
    }
  }

  update(info) {
    if (info) {
      this.info = info
    }
    if (!this.paymentRequest) {
      return
    }
    this.paymentRequest.update({
      total: {
        label: 'Total',
        amount: this.info.total,
      },
    })
  }

  handleEvent(event) {
    const message = event.data
    console.log(message)
    switch (message.type) {
      case 1:
        this.rejector({ msg: message.error })
        break
      case 2:
        this.formLoaded(message.data)
        break
      case 3:
        this.handleSubmit(message.data)
        break
      case 4:
        this.handleValidityChange(message.data)
        break
    }
  }

  handleSubmit(data) {
    const proceedCallback = () => {
      const authUrl = `/api-yoa/payments/${this.venueInfo.urlKey}/authorize`
      const authData = new FormData()

      const attrs = data.attributes

      let maskedCard
      let cardIssuer

      switch (data.paymentType) {
        case 'Card':
          maskedCard = attrs.filter(attr => attr.Key === 'MaskedCardNumber')[0].Value
          cardIssuer = attrs.filter(attr => attr.Key === 'CardIssuer')[0].Value
          break
        case 'GooglePay':
          const { info } = attrs.filter(attr => attr.Key === 'Raw')[0].Value.paymentMethodData
          maskedCard = info.cardDetails
          cardIssuer = info.cardNetwork
          break
        case 'ApplePay':
          const networkNumber = attrs.filter(attr => attr.Key === 'maskedCardNumber')[0].Value.split(' ')
          maskedCard = networkNumber[1]
          cardIssuer = networkNumber[0]
          break
      }

      authData.append('paymentKey', data.paymentKeys[0])
      authData.append('sessionKey', this.sessionKey)
      authData.append('total', this.total)
      authData.append('tax', this.chargeData.chargeTaxAmount)
      authData.append('name', `${this.chargeData.firstName} ${this.chargeData.lastName}`)
      authData.append('transactionType', this.transactionType)

      fetch(authUrl, {
        body: authData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(response => {
          this.unmountForm()
          if (response.status !== 200) {
            this.rejector(response)
            return
          }
          this.resolver({
            id: response.data.authorization.transaction_id,
            invoiceId: response.data.authorization.invoice_id,
            maskedCard,
            brand: cardIssuer,
            posSyncId: response.data.authorization.pos_sync_id,
            purchaserCode: response.data.authorization.purchaser_code,
            nameOnCard: response.data.authorization.name_on_card,
            sessionKey: response.data.authorization.session_key,
          })
        })
    }
    if (this.checkSubmitStillValid) {
      const rejectCallback = () => {
        this.unmountForm()
        this.rejector({})
      }
      this.checkSubmitStillValid(proceedCallback, rejectCallback)
    } else {
      proceedCallback()
    }
  }

  isPayLater(channel = null) {
    channel = channel || this.channel
    return channel === PAYMENT_CHANNELS.PAY_LATER
  }

  authorizePayment(chargeData, transactionType, paymentMethod = null, checkSubmitStillValid = null) {
    this.total = chargeData.chargeAmount
    this.chargeData = chargeData
    this.transactionType = transactionType
    this.checkSubmitStillValid = checkSubmitStillValid
    const formData = new FormData()
    const url = `/api-yoa/payments/${this.venueInfo.urlKey}/begin_payment`

    if (!paymentMethod && !this.isPayLater()) {
      paymentMethod = this.channel
    }

    formData.append('total', this.total)
    formData.append('payment_method', paymentMethod)

    if (paymentMethod === PAYMENT_CHANNELS.APPLE_PAY) {
      formData.append('styles', this.localization.appleStyles)
    } else {
      formData.append('styles', this.localization.formStyles)
    }

    this.mountScreen()

    // This check prevents a UI bug
    // when reopening the modal.
    if (this.isPayLater(paymentMethod)) {
      this.loaderDots.style.display = 'none'
    }

    return new Promise((resolve, reject) => {
      this.resolver = resolve
      this.rejector = reject
      fetch(url, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(response => {
          if (response.status !== 200) {
            this.localization.localUnmount()
            this.rejector(response)
            return
          }

          // For same reason as above check
          if (!this.isPayLater()) {
            this.mountForm(response.data.begin_payment.iframe)
          }

          this.sessionKey = response.data.begin_payment.sessionKey
        })
    })
  }

  refund({ historyId, clientId, amount, reason }) {
    const url = `/api-yoa/payments/${this.venueInfo.urlKey}/refund`
    const formData = new FormData()

    formData.append('amount', amount)
    formData.append('reason', reason)
    formData.append('billing_history_id', historyId)
    formData.append('venue_group_client_id', clientId)

    return fetch(url, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'X-CSRFToken': window.Pmp.Client.Static.Csrf },
    })
      .then(response => response.json())
      .then(response => {
        if (response.status !== 200) {
          throw response
        }
        return response
      })
  }

  saveCard(cardData, transactionType) {}
}

export default FreedomPayGateway
