import React from 'react'
import styled from 'styled-components'
import { WidgetButton } from './WidgetButton'

const PromoButton = styled(WidgetButton)`
  display: inline-block;
  float: right;
  font-size: 14px;
  padding: 3px 7px;
  height: 25px;
  width: auto;
  max-width: 24.3%;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${props => (props.validPromoCode ? props.colorError : props.fontsColorCheckoutInactive)};
  border-color: ${props => (props.validPromoCode ? props.colorError : props.fontsColorCheckoutInactive)};
`

const SpinnerCell = styled.div`
  width: 24.3%;
  max-width: 70px;
  height: 45px;
  overflow: hidden;
  position: relative;
  float: right;
`

const SpinnerImage = styled.img.attrs(props => ({
  alt: 'loading-spinner',
  src: `${props.mediaUrl}images/icons/loading.gif`,
}))`
  height: 64px;
  position: relative;
  display: block;
  top: -9px;
  margin: 0 auto;
`

const PromoCodeButton = ({
  onApplyClick,
  onRemoveClick,
  validPromoCode,
  isVerifyingPromoCode,
  mediaUrl,
  applyButtonLabel = 'Apply',
  removeButtonLabel = 'Remove',
  fontsColorCheckoutInactive,
  colorError,
}) => {
  if (validPromoCode) {
    return (
      <PromoButton fontsColorCheckoutInactive={fontsColorCheckoutInactive} colorError={colorError} onClick={onRemoveClick} validPromoCode>
        {removeButtonLabel}
      </PromoButton>
    )
  } else if (isVerifyingPromoCode) {
    return (
      <SpinnerCell>
        <SpinnerImage mediaUrl={mediaUrl} />
      </SpinnerCell>
    )
  }
  return (
    <PromoButton fontsColorCheckoutInactive={fontsColorCheckoutInactive} onClick={onApplyClick} colorError={colorError}>
      {applyButtonLabel}
    </PromoButton>
  )
}

export default PromoCodeButton
