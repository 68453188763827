/*
  Common extensible Styled components
*/
import _ from 'lodash'
import React from 'react'
import styled, { keyframes } from 'styled-components'

export const makeOptionalAttr = (cssAttr, val) => (_.isUndefined(val) ? '' : `${cssAttr}: ${val};`)

export const ClearFix = styled.div`
  ${props => props.theme.clearFix};
`

export const Ellipsis = styled.div`
  ${props => props.theme.ellipsis};
`

/**
 * @deprecated Use from 'application/site/static/app/component-lib/Generic/Loading/Spinner.jsx'
 */
export const LoadingSpinner = styled.div`
  background-image: url(${props => props.theme.mediaUrl}images/new-spinner.gif);
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: center;
`

const pulsatingDotsAnimation = keyframes`
  30% {
    opacity: .1;
    transform: scale(.01);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const PulsatingDots = styled(props => (
  <div {...props}>
    <div />
    <div />
    <div />
  </div>
))`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  vertical-align: middle;
  font-size: 0;
  color: ${props => props.theme.white};
  > div {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 13px;
    height: 13px;
    margin: 5px;
    border-radius: 100%;
    animation: ${pulsatingDotsAnimation} 1s ease infinite;
  }
  > div:nth-child(1) {
    animation-delay: -200ms;
  }
  > div:nth-child(2) {
    animation-delay: -100ms;
  }
  > div:nth-child(3) {
    animation-delay: 0ms;
  }
`

export const PulsatingDotsRed = styled(PulsatingDots)`
  color: #f3356b;
  > div {
    width: 7px;
    height: 7px;
    margin: 3px;
  }
`

export const PulsatingDotsBlue = styled(PulsatingDots)`
  color: ${props => props.theme.primary};
  > div {
    background-color: ${props => props.theme.primary};
  }
`

export const ModalContainer = styled.div`
  background: #ffffff;
  background-color: #fdfeff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -245px;
  width: 490px;
  @media (max-width: 490px) {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
  z-index: 1001;
`

export const ModalShadow = styled.div`
  background: #000000;
  opacity: 0.5;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

export const ModalHeader = styled.div`
  background-color: #0a2239;
  border-radius: 6px 6px 0 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  height: 42px;
  line-height: 42px;
  position: relative;
  text-indent: 20px;
  text-transform: uppercase;
`

export const ModalClose = styled.div`
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 100;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
  ::after {
    content: '\u00d7';
  }
`

export const ActionButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 100%;
  :disabled {
    background-color: #dedede;
  }
`

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledComponentUtils = {
  ClearFix,
  Ellipsis,
  LoadingSpinner,
  PulsatingDots,
  ModalContainer,
  ModalShadow,
  ModalHeader,
  ModalClose,
  ActionButton,
}

export default StyledComponentUtils
