import {
  SET_FREEDOMPAY_INITIAL_DATA,
  SET_FREEDOMPAY_VISIBLE,
  SET_NETWORK_REDUX_RESPONSE,
  SET_PAYMENT_PENDING_RESPONSE,
} from '../actions/ActionTypes'
import {
  CYBERSOURCE_PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE,
  CYBERSOURCE_PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE,
  CYBERSOURCE_CLEAN_PAYMENT_INFORMATION,
  PayerAuthStep,
} from '../actions/cybersourceThreeDs'

const cybersourceThreedsInitialState = {
  payerAuthenticationSetupResult: null,
  payerAuthenticationEnrollmentResult: null,
  step: null,
}

const initialState = {
  cybersourceThreeds: cybersourceThreedsInitialState,
  freedompay: {
    initialData: null,
    visible: false,
  },
  networkReduxResponse: null,
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CYBERSOURCE_PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE:
      return {
        ...state,
        cybersourceThreeds: {
          ...state.cybersourceThreeds,
          payerAuthenticationSetupResult: action.data,
          step: PayerAuthStep.SETUP,
        },
      }
    case CYBERSOURCE_PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE:
      return {
        ...state,
        cybersourceThreeds: {
          ...state.cybersourceThreeds,
          payerAuthenticationEnrollmentResult: action.data,
          step: PayerAuthStep.ENROLLMENT,
        },
      }
    case CYBERSOURCE_CLEAN_PAYMENT_INFORMATION:
      return {
        ...state,
        cybersourceThreeds: cybersourceThreedsInitialState,
      }
    case SET_FREEDOMPAY_INITIAL_DATA:
      return {
        ...state,
        freedompay: {
          ...state.freedompay,
          initialData: action.data,
        },
      }
    case SET_FREEDOMPAY_VISIBLE:
      return {
        ...state,
        freedompay: {
          ...state.freedompay,
          visible: action.data,
        },
      }
    case SET_NETWORK_REDUX_RESPONSE:
      return {
        ...state,
        networkReduxResponse: action.networkReduxResponse,
      }
    case SET_PAYMENT_PENDING_RESPONSE:
      return {
        ...state,
        paymentPendingResponse: action.paymentPendingResponse,
      }
    default:
      return state
  }
}

export default paymentReducer
