import React from 'react'
import styled from 'styled-components'

const DetailsContainer = styled.div`
  color: ${props => props.color};
`

const DetailsInnerContainer = styled.div`
  border-bottom: 1px solid #d3d3d8;
  padding: ${props => (props.extraPadding ? '36' : '12')}px;
  text-align: ${props => (props.leftAlign ? 'left' : 'center')};
`

const Venue = styled.div`
  font-size: 30px;
`

const ExperienceTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const ExperienceDescription = styled.p`
  font-size: 16px;
`

const ExperiencePrice = styled.div`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`

const ExperienceHeader = ({ venue, experience, fontsColorPrimary }) => (
  <DetailsContainer color={fontsColorPrimary}>
    <DetailsInnerContainer>
      <Venue>{venue.name}</Venue>
    </DetailsInnerContainer>
    <DetailsInnerContainer leftAlign extraPadding>
      <ExperienceTitle>{experience.name}</ExperienceTitle>
      <ExperienceDescription>{experience.public_description_text}</ExperienceDescription>
      <ExperiencePrice>{experience.price_description}</ExperiencePrice>
    </DetailsInnerContainer>
  </DetailsContainer>
)

export default ExperienceHeader
