/* eslint-disable */
import _ from 'lodash'
import { roundMoney } from 'svr/common/Money'
import { CyberEncrypt } from 'svr/lib/Payments/CyberSource'
import { getSubtotal, getTotal, calcPercentageProduct, calcTotal } from './calcCharges'

export const convertToFormData = data => {
  const prunedData = _.pickBy(data, Boolean)
  return _.reduce(
    prunedData,
    (formData, val, key) => {
      formData.append(key, val)
      return formData
    },
    new FormData()
  )
}

export const prepUserData = state => ({
  first_name: state.formFields.get('firstName'),
  last_name: state.formFields.get('lastName'),
  email: state.formFields.get('email'),
  phone_number: state.formFields.get('phoneNumber'),
  country_code: state.formFields.get('phoneNumberLocale'),
})

export const prepResData = (selectedTimeSlot, partySize, state) => ({
  date: selectedTimeSlot.format('MM-DD-Y'),
  time: selectedTimeSlot.format('h:mm a'),
  party_size: partySize,
  venue_id: state.venueInfo.id,
  billing_history_id: state.billingInfo.billing_history_id,
})

export const prepChargeData = (state, cardToken = null, cardData = null) => {
  const chargeType = 'reservation'
  const {
    base_amount,
    service_charge_percentage,
    tax,
    encryption_id,
    upsell_categories,
    upsell_items,
    tax_amount,
    gratuity_percentage,
    gratuity_amount,
    select_gratuity,
    upsell_amount,
    service_charge_amount,
    reservation_base_price,
    reservation_tax_amount,
    reservation_tax,
    reservation_service_charge_amount,
    upsell_quantities,
    is_adhoc,
  } = state.billingInfo
  const gratuityPercentage = select_gratuity ? state.formFields.get('gratuityPercentage') : gratuity_percentage
  const cost = base_amount / 100
  const subTotal = getSubtotal(cost, 1, chargeType)
  let chargeServiceChargeAmount = service_charge_percentage ? calcPercentageProduct(subTotal, service_charge_percentage) : 0
  const preTaxTotal = roundMoney(parseFloat(subTotal) + parseFloat(chargeServiceChargeAmount))
  let chargeTaxAmount = tax ? calcPercentageProduct(preTaxTotal, tax) : 0
  let upsellAmount = null
  let upsellItems = null
  const containsUpsells = upsell_items !== null && ('automatic_upsells' in upsell_items || 'selected_upsells' in upsell_items)
  let chargeGratuityAmount = calcPercentageProduct(reservation_base_price ?? cost, gratuityPercentage)
  const reservationGratuityAmount = chargeGratuityAmount
  let total = calcTotal(subTotal, chargeServiceChargeAmount, chargeTaxAmount, chargeGratuityAmount)
  if (containsUpsells) {
    chargeGratuityAmount = select_gratuity
      ? calcPercentageProduct(reservation_base_price, gratuityPercentage) + gratuity_amount / 100
      : gratuity_amount / 100
    chargeTaxAmount = roundMoney(tax_amount / 100)
    Object.keys(upsell_items.automatic_upsells).forEach(automaticUpsellItemId => {
      const automaticUpsellItem = upsell_items.automatic_upsells[automaticUpsellItemId]
      const upsellQuantity = upsell_quantities.automatic_upsells[automaticUpsellItemId]
      if (upsell_categories[automaticUpsellItem.category_id].gratuity_charge_type === 'CLIENT_GRATUITY' && upsellQuantity > 0) {
        const upsellGratuity = calcPercentageProduct(automaticUpsellItem.promo_pricing[0][0], state.formFields.get('gratuityPercentage'))
        chargeGratuityAmount += upsellQuantity * upsellGratuity
        upsell_items.automatic_upsells[automaticUpsellItemId].gratuity = state.formFields.get('gratuityPercentage')
        upsell_items.automatic_upsells[automaticUpsellItemId].gratuity_charge_amount = upsellGratuity
      }
    })
    Object.keys(upsell_items.selected_upsells).forEach(selectedUpsellItemId => {
      const selectedUpsellItem = upsell_items.selected_upsells[selectedUpsellItemId]
      const upsellQuantity = upsell_quantities.selected_upsells[selectedUpsellItemId]
      if (upsell_categories[selectedUpsellItem.category_id].gratuity_charge_type === 'CLIENT_GRATUITY' && upsellQuantity > 0) {
        const upsellGratuity = calcPercentageProduct(selectedUpsellItem.price, state.formFields.get('gratuityPercentage'))
        upsell_items.selected_upsells[selectedUpsellItemId].gratuity = state.formFields.get('gratuityPercentage')
        upsell_items.selected_upsells[selectedUpsellItemId].gratuity_charge_amount = upsellGratuity
        chargeGratuityAmount += upsellQuantity * upsellGratuity
      }
    })
    upsellItems = JSON.stringify(upsell_items)
    upsellAmount = roundMoney(upsell_amount / 100)
    chargeServiceChargeAmount = service_charge_amount
    total = roundMoney(cost + chargeServiceChargeAmount + chargeTaxAmount + chargeGratuityAmount + upsellAmount)
  }
  const encryptor = new CyberEncrypt()
  const formFields = state.formFields.toJS()
  cardData = cardData
    ? JSON.stringify(cardData)
    : encryptor.encrypt([formFields.cardNum, formFields.cardMonthExp, formFields.cardYearExp, formFields.cardCvv].join('|'))
  return {
    card_token: cardToken,
    charge_base_amount: subTotal,
    charge_service_charge: service_charge_percentage,
    charge_service_charge_amount: chargeServiceChargeAmount,
    charge_tax: tax,
    charge_tax_amount: chargeTaxAmount,
    charge_gratuity: gratuityPercentage,
    charge_gratuity_amount: chargeGratuityAmount,
    charge_amount: total,
    upsell_items: upsellItems,
    charge_upsell_amount: upsell_amount,
    reservation_base_price,
    reservation_gratuity_amount: reservationGratuityAmount,
    reservation_tax_amount,
    reservation_tax,
    is_adhoc,
    reservation_service_charge_amount,
    encryption_id,
    card_data: cardData,
    zip: formFields.zipCode,
    card_first_name: formFields.cardFirstName,
    card_last_name: formFields.cardLastName,
    email: formFields.email,
    address1: formFields.address1,
    city: formFields.city,
    locality: formFields.locality,
    administrativeArea: formFields.administrativeArea,
    countryCode: formFields.countryCode,
    postalCode: formFields.postalCode,
    browser_screen_width: Number.isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth,
    browser_screen_height: Number.isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
  }
}

export const convertToStripeLocale = selectedLanguage => {
  let cleanedSelectedLanguage = selectedLanguage

  // Conversion for Stripe Locale
  if (cleanedSelectedLanguage == 'zh_HK') {
    cleanedSelectedLanguage = 'zh-HK'
  } else if (selectedLanguage == 'zh_CN') {
    cleanedSelectedLanguage = 'zh'
  }
  return cleanedSelectedLanguage
}
