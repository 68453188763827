import React from 'react'
import styled from 'styled-components'

const WidgetTable = styled.div`
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
`

const WidgetRowGroup = styled.div`
  display: table-row-group;
  width: 100%;
  height: 100%;
`

export const WidgetView = ({ children }) => (
  <WidgetTable>
    <WidgetRowGroup>{children}</WidgetRowGroup>
  </WidgetTable>
)

export const WidgetSection = styled.div`
  display: table-row;
  height: 0px;
`

export const WidgetSectionFill = styled.div`
  display: table-row;
  height: 100%;
`

export const WidgetViewContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const WidgetLine = styled.div`
  border: 1px solid ${props => props.theme.colorLines};
`
