/* eslint-disable no-return-assign */
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import Button from '../../../../lib/Buttons/Button'
import styles from '../../../../lib/Modal/modals'
import Tag from '../components/Tag'

const firstElementId = 'sr-tag-selector-first'
const lastElementId = 'sr-tag-selector-last'

class TagSelector extends Component {
  constructor(props) {
    super(props)
    const tagGroupsCopy = _.cloneDeep(this.props.tagGroups)
    this.nonEmptyTagGroups = _.reduce(
      tagGroupsCopy,
      (tagGroups, group) => (!_.isEmpty(group.tags) ? _.assign({}, tagGroups, { [group.id]: group }) : tagGroups),
      {}
    )
    this.tagGroupKeys = _.map(this.nonEmptyTagGroups, group => group.id)

    const selectedTagsByGroup = _.mapValues(this.nonEmptyTagGroups, group => group.selectedTags)
    this.state = {
      tab: this.tagGroupKeys[0],
      selectedTagsByGroup,
    }

    this.tabClickMap = _.map(this.tagGroupKeys, key => this.handleTabClick.bind(this, key))
    this.handleTagClick = this.handleTagClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
  }

  handleTabClick(key) {
    this.setState({ tab: key })
  }

  handleTagClick(tagName) {
    const { tab, selectedTagsByGroup } = this.state
    const selectedTags = _.assign({}, selectedTagsByGroup)

    if (selectedTagsByGroup[tab][tagName]) {
      delete selectedTags[tab][tagName]
    } else {
      selectedTags[tab][tagName] = tagName
    }
    this.setState({
      selectedTagsByGroup: selectedTags,
    })
  }

  handleSaveClick() {
    this.props.onSave(this.state.selectedTagsByGroup)
  }

  getTagDisplayText(tagName, tagNameDisplays) {
    return this.props.tagTranslations[tagName] || tagNameDisplays?.[tagName] || tagName
  }

  render() {
    const { groupNames, colorPrimary, colorSecondary, saveButtonLabel, cancelButtonLabel, onCancel } = this.props
    const { selectedTagsByGroup, tab } = this.state
    const tagsToMap = _.keys(this.nonEmptyTagGroups[tab].tags)
    const tagList = _.map(
      // tag have original names that are immutable and edited display names
      // we sort and show the display names
      // while using original names as value for what's selected
      _.sortBy(tagsToMap, tagName => this.getTagDisplayText(tagName, this.nonEmptyTagGroups[tab].tagNameDisplays).toLowerCase()),
      (tagName, idx) => (
        <Tag
          tagId={idx === tagsToMap.length - 1 ? lastElementId : null}
          key={tagName}
          tagName={tagName}
          label={this.getTagDisplayText(tagName, this.nonEmptyTagGroups[tab].tagNameDisplays)}
          onClick={this.handleTagClick}
          isSelected={!!selectedTagsByGroup[tab][tagName]}
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
        />
      )
    )

    let tabsSection = null
    if (this.tagGroupKeys.length > 1) {
      const tabsMap = _.map(this.tagGroupKeys, (key, idx) => (
        <button
          type="button"
          key={`sr-tags-${key}`}
          onClick={this.tabClickMap[idx]}
          style={[
            styles.tab,
            this.state.tab === key && {
              borderBottomColor: colorPrimary,
              fontWeight: 600,
            },
          ]}
          data-test={`sr-${groupNames[idx].toLowerCase() === 'your guests' ? 'your-guests' : 'yours'}-tab`}
        >
          {groupNames[idx]}
        </button>
      ))

      tabsSection = (
        <div>
          <div style={[styles.seperator, { borderBottomColor: colorPrimary }]} />
          <div style={styles.tabWrapper}>{tabsMap}</div>
        </div>
      )
    }

    return (
      <div style={[styles.modalWidth, styles.staticModalHeight, { color: colorPrimary }]}>
        <div style={{ backgroundColor: colorSecondary }}>
          <div style={styles.headerLine}>
            <Button
              buttonId={firstElementId}
              innerElement={cancelButtonLabel}
              onClick={onCancel}
              activeStyling
              style={_.assign({}, styles.smallFont, styles.floatLeft, {
                color: colorPrimary,
              })}
              testId="sr-tag-selector-cancel-button"
            />
            <Button
              innerElement={saveButtonLabel}
              onClick={this.handleSaveClick}
              activeStyling
              style={_.assign({}, styles.smallFont, styles.floatRight, {
                color: colorPrimary,
              })}
              testId="sr-tag-selector-save-button"
            />
          </div>
          {tabsSection}
        </div>
        <div style={styles.seperator} />
        <div style={[styles.seperator, { borderBottomColor: colorSecondary }]} />
        <div style={styles.tagListWrapper}>{tagList}</div>
      </div>
    )
  }
}

TagSelector.propTypes = {
  tagGroups: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
  onSave: React.PropTypes.func.isRequired,
  onCancel: React.PropTypes.func.isRequired,
  groupNames: React.PropTypes.arrayOf(React.PropTypes.string),
  colorPrimary: React.PropTypes.string.isRequired,
  colorSecondary: React.PropTypes.string.isRequired,
  tagTranslations: React.PropTypes.object,
}

TagSelector.defaultProps = {
  groupNames: [],
  colorPrimary: 'rgba(51, 51, 51, 1.00)',
  colorSecondary: 'rgba(227, 227, 227, 1.00)',
  tagTranslations: {},
}

TagSelector.firstElementId = firstElementId
TagSelector.lastElementId = lastElementId

export default Radium(TagSelector)
