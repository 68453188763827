import { Map, List, fromJS } from 'immutable'
import _ from 'lodash'
import {
  GET_VALID_DATES_SUCCESS,
  GET_INITIAL_AVAILABILITY_SUCCESS,
  GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  TRY_GET_ADDITIONAL_AVAILABILITY,
  GET_ADDITIONAL_AVAILABILITY_COMPLETE,
  SELECT_TIME_SLOT,
  SELECT_REQUEST_TIME,
  GET_EXPERIENCE_AVAILABILITY_SUCCESS,
  TRY_GET_INITIAL_AVAILABILITY,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
} from '../actions/ActionTypes'

const emptySearchState = Map({
  lookup: Map(),
  initialResults: List(),
  additionalResults: List(),
  noAvailability: Map(),
  orderedRequests: List(),
  additionalDatesCounter: 0,
  foundNearAvailability: false,
  isFetchingAvailability: false,
  exhaustedSearch: false,
})

const startingSearchState = emptySearchState.merge(
  Map({
    validDates: Map(),
    validExperienceDatesObject: Map(),
    firstAvailableExperienceDate: null,
  })
)

const availability = (state = startingSearchState, action) => {
  switch (action.type) {
    case GET_VALID_DATES_SUCCESS:
      return state.update('validDates', validDates => validDates.merge(action.data))
    case GET_EXPERIENCE_AVAILABILITY_SUCCESS:
      _.forEach(action.data.availability, (availDates, partySize) => {
        if (!state.hasIn(['validExperienceDatesObject', partySize])) {
          state = state.setIn(['validExperienceDatesObject', partySize], Map())
        }
        state = state.mergeIn(['validExperienceDatesObject', partySize], availDates)
      })
      return state.update('firstAvailableExperienceDate', firstAvailable => firstAvailable || action.data.firstAvailable || null)
    case GET_INITIAL_AVAILABILITY_SUCCESS: {
      let newState = state.update('lookup', lookup => lookup.merge(action.data.dateData))
      const initialResultList = _.head(action.data.orderedResults)
      if (!_.isEmpty(initialResultList)) {
        newState = newState.update('initialResults', results => results.concat(fromJS(initialResultList)))
      }
      if (!action.data.foundNearAvailability) {
        newState = newState.merge({
          additionalDatesCounter: 1,
          isFetchingAvailability: true,
        })
      }
      return newState.merge({
        foundNearAvailability: action.data.foundNearAvailability,
        latestResult: action.data.latestResult,
        orderedRequests: action.data.orderedRequests,
        selectedRequest: action.data.selectedRequest,
        noAvailability: action.data.noAvailability,
      })
    }
    case GET_ADDITIONAL_AVAILABILITY_SUCCESS: {
      let newState = state.update('lookup', lookup => lookup.merge(action.data.dateData))
      if (!_.isEmpty(action.data.orderedResults)) {
        newState = newState.update('additionalResults', results => results.concat(fromJS(action.data.orderedResults)))
      }
      newState = newState.set('latestResult', action.data.latestResult)
      return newState
    }
    case TRY_GET_INITIAL_AVAILABILITY:
    case TRY_GET_MULTI_VENUE_AVAILABILITY:
      return state.merge(emptySearchState).set('isFetchingAvailability', true)
    case TRY_GET_ADDITIONAL_AVAILABILITY: {
      const newState = state.update('additionalDatesCounter', counter => counter + 1)
      return newState.set('isFetchingAvailability', true)
    }
    case SELECT_TIME_SLOT:
      return state.merge({
        isFetchingAvailability: false,
        cancellationPolicy: action.cancellationPolicy,
        customCheckoutPolicy: action.customCheckoutPolicy,
      })
    case GET_ADDITIONAL_AVAILABILITY_COMPLETE:
      return state.merge({
        isFetchingAvailability: false,
        exhaustedSearch: true,
      })
    case SELECT_REQUEST_TIME:
      return state.set('selectedRequest', action.requestTime)
    default:
      return state
  }
}

export default availability
