const styles = {
  itemWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: '12px',
  },
  stepperWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '175px',
    height: '40px',
    lineHeight: '40px',
  },
  arrowClickBox: {
    position: 'absolute',
    width: '44px',
    height: '44px',
    padding: '0',
    top: '0',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundColor: 'transparent',
  },

  arrowIcon: {
    fontSize: '22px',
  },
  valueText: {
    position: 'absolute',
    fontSize: '20px',
    width: '100%',
    margin: '0 auto',
    left: '0',
  },
  primaryButton: {
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: 'none',
    height: '44px',
    width: '175px',
  },
  primaryButtonValue: {
    fontSize: '20px',
  },
  primaryButtonLabel: {
    fontSize: '15px',
  },
  shiftUp: {
    top: '-6px',
  },
  labelText: {
    position: 'absolute',
    bottom: '-13px',
    width: '100%',
    left: '0',
    fontSize: '15px',
  },
  arrowInactive: {
    opacity: '0.4',
  },
  hiddenAlert: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
}

export default styles
