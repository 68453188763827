/* eslint react/prop-types: 0  */
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ItemStepper from 'svr/lib/Items/ItemStepper'
import { selectLanguageDateFields, selectedOrDefaultLanguageCode } from 'widget/dining/selectors/languageSelectors'
import { selectQueryDate, incrementCalendarMonth, decrementCalendarMonth } from '../actions/navigation'
import CalendarBody from '../components/CalendarBody'

class Calendar extends Component {
  render() {
    const {
      today,
      fontsColorPrimary,
      colorCellBackground,
      colorHover,
      colorWidgetBackground,
      fontsColorInactive,
      dateMoment,
      bookingEnd,
      saleStartDate,
      saleEndDate,
      colorPrimary,
      selectQueryDate,
      availability,
      fontColorButton,
      incrementCalendarMonth,
      decrementCalendarMonth,
      calendarDateMoment,
      calendarMonthLabel,
      selectedLanguageLocale,
    } = this.props // eslint-disable-line no-shadow
    const canDecrement = calendarDateMoment > this.props.today
    const canIncrement = !this.props.bookingEnd || calendarDateMoment.clone().add(1, 'month') < this.props.bookingEnd

    return (
      <div style={{ backgroundColor: colorWidgetBackground }} data-test="sr-calendar-date-picker">
        <ItemStepper
          value={calendarMonthLabel}
          incrementItem={incrementCalendarMonth}
          decrementItem={decrementCalendarMonth}
          canDecrement={canDecrement}
          canIncrement={canIncrement}
          style={{ color: fontsColorPrimary }}
          ariaIdentifier="month"
          dataTest="sr-calendar-date-picker-button"
          fontsColorPrimary={fontsColorPrimary}
        />
        <CalendarBody
          calendarMoment={calendarDateMoment}
          selectedMoment={dateMoment}
          currentMoment={today}
          selectDate={selectQueryDate}
          availability={availability}
          bookingEnd={bookingEnd}
          saleStartDate={saleStartDate}
          saleEndDate={saleEndDate}
          colorHover={colorHover}
          colorCellBackground={colorCellBackground}
          fontsColorInactive={fontsColorInactive}
          fontsColorPrimary={fontsColorPrimary}
          colorPrimary={colorPrimary}
          fontColorButton={fontColorButton}
          selectedLanguageLocale={selectedLanguageLocale}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const isExperienceMode = state.experience.get('isExperienceMode')
  let availability
  if (isExperienceMode) {
    const searchPartySize = state.search.get('partySize')
    availability = state.availability.get('validExperienceDatesObject').get(String(searchPartySize))
    availability = availability ? availability.toJS() : {}
  } else {
    availability = state.availability.get('validDates').toJS()
  }
  return {
    today: state.search.get('venueToday'),
    calendarDateMoment: state.ui.get('calendarDateMoment'),
    dateMoment: state.search.get('dateMoment'),
    bookingEnd: state.search.get('bookingEnd'),
    saleStartDate: state.search.get('saleStartDate'),
    saleEndDate: state.search.get('saleEndDate'),
    venueTimeZone: state.venueInfo.timezone,
    availability,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    colorHover: state.widgetSettings.colorHover,
    fontsColorInactive: state.widgetSettings.fontsColorInactive,
    fontColorButton: state.widgetSettings.fontsColorButton,
    selectedLanguageLocale: selectedOrDefaultLanguageCode(state),
    calendarMonthLabel: selectLanguageDateFields(state).calendarMonthLabel,
  }
}

const mapDispatchToProps = dispatch => ({
  selectQueryDate: dateKey => {
    dispatch(selectQueryDate(dateKey))
  },
  incrementCalendarMonth: dateKey => {
    dispatch(incrementCalendarMonth(dateKey))
  },
  decrementCalendarMonth: dateKey => {
    dispatch(decrementCalendarMonth(dateKey))
  },
})

Calendar = connect(mapStateToProps, mapDispatchToProps)(Radium(Calendar))

export default Calendar
