import React from 'react'
import styled from 'styled-components'

const DetailsContainer = styled.div`
  text-align: center;
  border-bottom: 1px solid #d3d3d8;
  padding: 12px;
  color: ${props => props.color};
`

const Venue = styled.div`
  font-size: 32px;
`

const Experience = styled.div`
  font-size: 20px;
`

const ExperienceDetails = ({ venue, experience, fontsColorPrimary }) => (
  <DetailsContainer color={fontsColorPrimary}>
    <Venue>{venue.name}</Venue>
    <Experience>{experience.name}</Experience>
  </DetailsContainer>
)

export default ExperienceDetails
