import _ from 'lodash'
import moment from 'moment-timezone'
import { formatTimeOnly } from 'mgr/lib/utils/MomentUtils'
import { camelCaseObject } from '../../../../common/ObjectUtils'

export const transformAvailablityDataToLookup = availabilityData => {
  const shiftEntities = {}
  const noAvailability = {}
  const availabilityLookup = _.mapValues(availabilityData, dateMap =>
    _.mapValues(dateMap, (shiftArr, dateKey) => {
      const timeMap = {}
      _.each(shiftArr, shift => {
        const { times, ...shiftProps } = shift
        const cameled = camelCaseObject(shiftProps)
        if (_.isEmpty(times)) {
          noAvailability[dateKey] = cameled
        }
        shiftEntities[shift.shift_id] = cameled
        _.each(times, timeSlot => {
          if (timeSlot.access_persistent_id) {
            const timeMapProps = _.pick(timeSlot, [
              'time',
              'time_iso',
              'real_datetime_of_slot',
              'access_persistent_id',
              'shift_persistent_id',
              'cc_party_size_min',
              'charge_type',
              'public_description_title',
              'public_long_form_description',
              'public_photo',
              'public_photo_sizes',
              'public_time_slot_description',
              'cancellation_policy',
              'custom_checkout_policy',
              'cost',
              'cc_payment_rule',
              'duration',
              'confirmation_include_end_time',
              'gratuity',
              'policy',
              'require_credit_card',
              'tax_rate',
              'type',
              'selected_automatic_upsells',
              'apply_service_charge',
              'service_charge_type',
              'service_charge',
              'apply_gratuity_charge',
              'gratuity_type',
              'require_gratuity_charge',
              'default_gratuity',
              'default_service_charge',
            ])
            timeMapProps.shift_id = shift.shift_id
            timeMapProps.shift_persistent_id = shift.shift_persistent_id

            // set upsells for time slot
            timeMapProps.upsellCategories = timeSlot.is_using_shift_upsells ? shift.upsell_categories : timeSlot.upsell_categories

            const timeKey = moment(timeMapProps.time_iso, 'Y-MM-DD HH:mm:ss').format('h:mm A')
            timeMap[timeKey] = timeMap[timeKey] || {}
            timeMap[timeKey][timeSlot.access_persistent_id || 'request'] = camelCaseObject(timeMapProps)
          }
        })
      })
      return timeMap
    })
  )
  return {
    shiftEntities,
    noAvailability,
    availabilityLookup,
  }
}

// pre: this func takes a single date's availability (assumes sorted key),
//      and a moment object
// post: returns an object with three properties [before, equalTo, after], each
//       contain an array with date-time strings. the key represents the
//       date-time's relationship to `queryMoment`
export const splitResults = (availableOnDate, queryMoment, startOfDayTime) => {
  const queryMomentForCheck = queryMoment.clone()
  if (startOfDayTime) {
    const startOfDayTimeForCheck = formatTimeOnly(moment(startOfDayTime, 'h:mm A'))
    const queryMomentTime = formatTimeOnly(queryMoment)
    if (queryMomentTime.isBefore(startOfDayTimeForCheck)) {
      queryMomentForCheck.add(1, 'day')
    }
  }
  return _.reduce(
    _.sortBy(_.keys(availableOnDate), timeKey =>
      moment(_.values(availableOnDate[timeKey])[0].realDatetimeOfSlot, 'Y-MM-DD h:mm A').format()
    ),
    (accumResults, timeKey) => {
      const keyMoment = moment(timeKey, 'H:mm A').year(queryMoment.year()).month(queryMoment.month()).date(queryMoment.date())
      const realDatetimeMoment = moment(_.values(availableOnDate[timeKey])[0].realDatetimeOfSlot, 'Y-MM-DD h:mm A')
      if (realDatetimeMoment.isBefore(queryMomentForCheck)) {
        accumResults.before.push(keyMoment.format())
      } else if (realDatetimeMoment.isAfter(queryMomentForCheck)) {
        accumResults.after.push(keyMoment.format())
      } else {
        accumResults.equalTo.push(keyMoment.format())
      }
      return accumResults
    },
    { before: [], equalTo: [], after: [] }
  )
}

const getOrderedRequests = availabilityData =>
  _.reduce(
    availabilityData,
    (orderedRequests, shift) =>
      orderedRequests.concat(
        _.reduce(
          shift.times,
          (resultSet, requestSlot) => {
            if (requestSlot.type === 'request' && requestSlot.is_requestable) {
              return resultSet.concat(moment(requestSlot.time_iso).format())
            }
            return resultSet
          },
          []
        )
      ),
    []
  )

export const transformInitialDataToState = (availabilityData, queryMoment, venueKey, startOfDayTime, isExperienceMode) => {
  const dateKey = queryMoment.format('Y-MM-DD')
  const stateData = transformAvailablityDataToLookup(availabilityData)
  const initialResults = splitResults(stateData.availabilityLookup[venueKey][dateKey], queryMoment, startOfDayTime)
  const orderedRequests = getOrderedRequests(availabilityData[venueKey][dateKey])
  const searchDateCursor = queryMoment.clone().add(1, 'days')

  let selectedRequest = null
  let selectedEndTimeRequest = null
  if (!_.isEmpty(orderedRequests)) {
    if (isExperienceMode) {
      // eslint-disable-next-line prefer-destructuring
      selectedRequest = orderedRequests[0]
    } else {
      _.forEach(orderedRequests, requestTime => {
        if (moment(requestTime).isSame(queryMoment)) {
          selectedRequest = requestTime
        }
      })
    }
    selectedEndTimeRequest = orderedRequests[orderedRequests.length - 1]
  }

  return {
    ...stateData,
    initialResults,
    orderedRequests,
    searchDateCursor,
    selectedRequest,
    selectedEndTimeRequest,
  }
}

export const transformVenueDataToState = (availabilityData, queryMoment, venueKey, startOfDayTime) => {
  const dateKey = queryMoment.format('Y-MM-DD')
  const stateData = transformAvailablityDataToLookup(availabilityData)
  const venueResults = splitResults(stateData.availabilityLookup[venueKey][dateKey], queryMoment, startOfDayTime)
  venueResults.venueKey = venueKey
  return { ...stateData, venueResults }
}

export const transformAdditionalDataToState = (availabilityData, queryMoment, venueKey, bookingEnd, startOfDayTime) => {
  const stateData = transformAvailablityDataToLookup(availabilityData)
  const availabilityDates = _.keys(stateData.availabilityLookup[venueKey])
  const additionalDates = bookingEnd ? _.filter(availabilityDates, dateStr => dateStr < bookingEnd) : availabilityDates
  const additionalResults = _.map(additionalDates, date => {
    const dateMoment = moment(date, 'Y-MM-DD').hour(queryMoment.hour()).minute(queryMoment.minute())
    const dateKey = dateMoment.format('Y-MM-DD')
    const splitAdditionalResults = splitResults(stateData.availabilityLookup[venueKey][dateKey], dateMoment, startOfDayTime)
    splitAdditionalResults.dateMoment = dateMoment
    return splitAdditionalResults
  })
  const searchDateCursor = queryMoment.clone().add(3, 'days')
  return { ...stateData, additionalResults, searchDateCursor }
}
