import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { selectLanguageStrings, selectCalculatedLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import ItemStepper from '../../../../lib/Items/ItemStepper'
import {
  incrementItemDate,
  decrementItemDate,
  incrementItemPartySize,
  decrementItemPartySize,
  toggleSearchComponent,
  selectQueryPartySize,
  toggleModalDisplay,
  selectRequest,
} from '../actions/navigation'
import styles from '../assets/styles/search'
import ExperienceDetails from '../components/ExperienceDetails'
import ExperienceHeader from '../components/ExperienceHeader'
import MainResultsDisplay from '../SearchResults/containers/MainResultsDisplay'
import SubmitRequestButton from '../SearchResults/components/SubmitRequestButton'
import { modalTypes, searchTypes } from '../utils/constantTypes'
import Calendar from './Calendar'
import ItemPicker from './ItemPicker'
import { WidgetLine, WidgetSection, WidgetView } from 'widget/dining/uiKit/WidgetView'

const GlobalStyle = createGlobalStyle`
  html {
    min-height: 100% !important;
    height: auto !important;
  }
`

const ExperienceContainer = styled.div`
  background: ${props => props.background};
`

class Experiences extends React.PureComponent {
  constructor(props) {
    super(props)
    this.partySizeValues = _.range(this.props.minPartySize, this.props.maxPartySize + 1)
    this.togglePartySizeItem = this.props.toggleSearchComponent.bind(this, searchTypes.PARTY_SIZE)
    this.toggleSpecialAttentionMessage = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_ATTENTION_MESSAGE)
  }

  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
  }

  handlePartySizeRowClick = () => {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativePartySizeInput.focus()
    } else {
      this.togglePartySizeItem()
    }
  }

  handlePartySizeChange = e => this.props.selectQueryPartySize(parseInt(e.target.value, 10))

  render() {
    const {
      incrementItemPartySize,
      decrementItemPartySize,
      disableIncrement,
      disableDecrement,
      partySize,
      fontsColorPrimary,
      fontsColorSecondary,
      colorHover,
      fontsColorButton,
      fontsColorActionBar,
      textGuestLabel,
      colorCellBackground,
      colorActionBarBackground,
      colorLines,
      venue,
      colorPrimary,
      colorModalBackground,
      experience,
      showDescription,
      isMobile,
      isAndroid,
      displaySearchType,
      textSelectPartySizeDropdown,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
    } = this.props

    const stepperStyles = [{ color: fontsColorPrimary }, { backgroundColor: colorCellBackground }]

    const itemPickerColorProps = {
      fontsColorPrimary,
      colorCellBackground,
      colorHover,
      colorHighlightRow: colorPrimary,
      colorHighlightRowText: fontsColorButton,
    }

    return (
      <ExperienceContainer background={colorModalBackground}>
        <GlobalStyle />
        {experience &&
          (showDescription ? (
            <ExperienceHeader venue={venue} experience={experience} fontsColorPrimary={fontsColorPrimary} />
          ) : (
            <ExperienceDetails venue={venue} experience={experience} fontsColorPrimary={fontsColorPrimary} />
          ))}
        {showSpecialAttentionMessage && (
          <div
            style={{
              ...styles.stepRow,
              backgroundColor: colorActionBarBackground,
              color: fontsColorActionBar,
              fontSize: '13px',
              borderBottomColor: colorLines,
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span>{specialAttentionMessageHeader}</span>
              <button
                style={{
                  color: fontsColorActionBar,
                  ...styles.infoIcon,
                  ...styles.buttonInfoIcon,
                }}
                onClick={this.toggleSpecialAttentionMessage}
                className="fa fa-info-circle"
                aria-hidden="true"
                aria-label="special attention message"
                type="button"
              />
            </div>
          </div>
        )}
        <div
          style={styles.stepRow}
          ref={comp => {
            this.initialFocusComponent = comp
          }}
        >
          <ItemStepper
            value={partySize}
            label={textGuestLabel}
            incrementItem={incrementItemPartySize}
            decrementItem={decrementItemPartySize}
            canDecrement={!disableDecrement.partySize}
            canIncrement={!disableIncrement.partySize}
            labelColor={fontsColorSecondary}
            fontsColorPrimary={fontsColorPrimary}
            style={stepperStyles}
            onClick={this.partySizeValues.length > 1 && this.handlePartySizeRowClick}
            dataTest="sr-guest-count-dropdown"
          />
          {isMobile && !isAndroid && (
            <select
              style={styles.hiddenInput}
              onChange={this.handlePartySizeChange}
              value={partySize}
              ref={input => {
                this.nativePartySizeInput = input
              }}
            >
              {_.map(this.partySizeValues, idx => (
                <option key={`party-size-${idx}`} value={idx}>
                  {idx}
                </option>
              ))}
            </select>
          )}
          {(!isMobile || isAndroid) && displaySearchType === searchTypes.PARTY_SIZE && (
            <ItemPicker
              headerText={textSelectPartySizeDropdown}
              selectedValue={partySize}
              valueSet={this.partySizeValues}
              onItemClick={this.props.selectQueryPartySize}
              onOutsideClick={this.togglePartySizeItem}
              {...itemPickerColorProps}
            />
          )}
        </div>
        <Calendar />
        <MainResultsDisplay />

        {!this.props.isAllLocationsSearch &&
          this.props.internalArBookingEnabled &&
          this.props.requestsEnabled &&
          this.props.ableToMakeReservationRequest &&
          this.props.hasSelectedRequest && (
            <WidgetView>
              <WidgetSection>
                <SubmitRequestButton
                  textCantFind={this.props.textCantFind}
                  textSubmitRequest={this.props.textSubmitRequest}
                  onClick={this.props.selectRequest}
                />
                <WidgetLine />
              </WidgetSection>
            </WidgetView>
          )}
      </ExperienceContainer>
    )
  }
}

const mapStateToProps = state => {
  const timeMoment = state.search.get('timeMoment')
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  return {
    timeMoment,
    isMobile: state.app.isMobile,
    isAndroid: state.app.isAndroid,
    partySize: state.search.get('partySize'),
    displaySearchType: state.ui.get('displaySearchType'),
    disableDecrement: state.search.get('disableDecrement').toJS(),
    disableIncrement: state.search.get('disableIncrement').toJS(),
    minPartySize: state.search.get('minPartySize'),
    maxPartySize: state.search.get('maxPartySize'),
    textDate: languageStrings.textDate,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textTime: languageStrings.textTime,
    textSelectPartySizeDropdown: languageStrings.textSelectPartySizeDropdown,

    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
    fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
    colorHover: state.widgetSettings.colorHover,
    colorLines: state.widgetSettings.colorLines,
    venue: state.venueInfo,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorModalBackground: state.widgetSettings.colorModalBackground,
    experience: state.experience.toJS().experience,
    showDescription: state.app.requestParams.showDesc,
    showSpecialAttentionMessage: state.widgetSettings.showSpecialAttentionMessage,
    specialAttentionMessageHeader: languageStrings.specialAttentionMessageHeader,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    isAllLocationsSearch: state.searchResults.get('isAllLocationsSearch'),
    hasSelectedRequest: !_.isNil(state.searchResults.get('selectedRequest')),
    requestsEnabled: state.widgetSettings.enableRequests,
    textCantFind: calcLanguageStrings.textCantFind,
    textSubmitRequest: languageStrings.textSubmitRequest,
    internalArBookingEnabled: state.venueInfo.internalArBookingEnabled,
    ableToMakeReservationRequest: state.featureFlags.ableToMakeReservationRequest || false,
  }
}

const mapDispatchToProps = {
  incrementItemDate,
  decrementItemDate,
  incrementItemPartySize,
  decrementItemPartySize,
  selectQueryPartySize,
  toggleSearchComponent,
  toggleModalDisplay,
  selectRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Experiences)
