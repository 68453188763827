import _ from 'lodash'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LongWeekDayFormat } from 'svr/widget/scripts/utils/constants'
import { selectLanguageStrings, selectCalculatedLanguageStrings, selectLanguageDateFields } from 'widget/dining/selectors/languageSelectors'
import { selectTimeSlot } from '../../actions/navigation'
import { WidgetLine } from '../../uiKit/WidgetView'
import { ALL_LOCATIONS, calculateSelectedUpsells } from '../../utils/convertData'
import Dropdown from '../components/Dropdown'
import SideScrollRow from '../components/SideScrollRow'
import TimeSlot from '../components/TimeSlot'
import { selectAdditionalResults } from '../selectors'
import { calculateFeesForTimeSlot } from '../../utils/calculateFees'

const AdditionalResultsWrapper = styled.div`
  width: 100%;
`

const NoResultsWrapper = styled.div`
  text-align: center;
  background-color: ${props => props.theme.colorCellBackground};
  color: ${props => props.theme.fontsColorPrimary};
  padding: 10px;
  line-height: 30px;
`

const mapResultsToTimeSlot = (
  result,
  availabilityLookup,
  partySize,
  selectTimeSlot,
  venueKey,
  currencyCode,
  textPerPerson,
  textPerReservation,
  locale,
  upsellInventories,
  upsellCategories = [],
  isFeesInPriceDisplayed = false
) =>
  _.map(result.times, (result, idx) => {
    if (!result) {
      return <TimeSlot key={`sr-timeslot-empty-${idx}`} timeSlotMoment={null} />
    }
    const timeSlotMoment = moment(result.timeIso)
    const dateKey = timeSlotMoment.format('Y-MM-DD')
    const timeKey = timeSlotMoment.format('h:mm A')
    const {
      requireCreditCard,
      cost = 0,
      duration,
      chargeType,
      ccPartySizeMin,
      publicDescriptionTitle,
      publicTimeSlotDescription,
      publicLongFormDescription,
      publicPhoto,
      publicPhotoSizes,
      accessPersistentId,
      selectedAutomaticUpsells,
    } = result
    const requirePayment = requireCreditCard && partySize > ccPartySizeMin
    const totalCost =
      cost +
      (selectedAutomaticUpsells && selectedAutomaticUpsells.length > 0
        ? calculateSelectedUpsells(selectedAutomaticUpsells, chargeType, partySize, upsellInventories, duration)
        : 0)
    const fees = calculateFeesForTimeSlot(isFeesInPriceDisplayed, result, upsellInventories, upsellCategories, partySize)
    return (
      <TimeSlot
        key={`sr-timeslot-${dateKey}-${timeKey}-${accessPersistentId}`}
        timeSlotMoment={timeSlotMoment}
        accessPersistentId={accessPersistentId}
        requirePayment={requirePayment}
        chargeType={chargeType}
        cost={totalCost}
        reservationFees={fees}
        duration={duration}
        publicDescriptionTitle={publicDescriptionTitle}
        publicTimeSlotDescription={publicTimeSlotDescription}
        publicLongFormDescription={publicLongFormDescription}
        publicPhoto={publicPhoto}
        publicPhotoSizes={publicPhotoSizes}
        onTimeSlotClick={selectTimeSlot}
        venueKey={venueKey}
        currencyCode={currencyCode}
        textPerPerson={textPerPerson}
        textPerReservation={textPerReservation}
        locale={locale}
      />
    )
  })

const reduceAdditionalResults = (
  additionalResults,
  availabilityLookup,
  partySize,
  selectTimeSlot,
  venueKey,
  currencyCode,
  textPerPerson,
  textPerReservation,
  locale,
  selectedLanguage,
  shouldTranslateDates,
  upsellInventories,
  upsellCategories = [],
  isFeesInPriceDisplayed = false
) =>
  _.reduce(
    additionalResults,
    (accum, dateResult, key) => {
      let newAccum = accum
      if (key !== 0) {
        newAccum = accum.concat(<WidgetLine key={`sr-line-${key}`} />)
      }
      const dateMoment = shouldTranslateDates ? dateResult.dateMoment.clone().locale(selectedLanguage) : dateResult.dateMoment
      const dateLabel = dateMoment.format(LongWeekDayFormat[locale] || LongWeekDayFormat.default)
      const rowId = `sr-side-scroll-row-${key}`
      return newAccum.concat(
        <SideScrollRow label={dateLabel} key={rowId} data-test="sr-other-dates-label">
          {mapResultsToTimeSlot(
            dateResult,
            availabilityLookup,
            partySize,
            selectTimeSlot,
            venueKey,
            currencyCode,
            textPerPerson,
            textPerReservation,
            locale,
            upsellInventories,
            upsellCategories,
            isFeesInPriceDisplayed
          )}
        </SideScrollRow>
      )
    },
    []
  )

class AdditionalResultsDisplay extends Component {
  constructor(props) {
    super(props)
    this.appElement = document.getElementById('dining-widget-app')
  }

  componentDidUpdate() {
    window.parent.postMessage(
      {
        type: 'heightEvent',
        size: this.appElement.getBoundingClientRect().height,
      },
      '*'
    )
  }

  render() {
    const {
      additionalResults,
      availabilityLookup,
      partySize,
      mediaUrl,
      isFetchingAdditional,
      selectTimeSlot,
      venueKey,
      currencyCode,
      textOtherDates,
      textNoAdditional,
      textPerPerson,
      textPerReservation,
      locale,
      open,
      selectedLanguage,
      shouldTranslateDates,
      upsellInventories,
      upsellCategories,
      isFeesInPriceDisplayed,
    } = this.props

    return (
      <Dropdown
        label={textOtherDates}
        resultsIsEmpty={_.isEmpty(additionalResults)}
        mediaUrl={mediaUrl}
        isFetching={isFetchingAdditional}
        open={open}
      >
        <AdditionalResultsWrapper>
          {_.isEmpty(additionalResults) && !isFetchingAdditional ? (
            <NoResultsWrapper>{textNoAdditional}</NoResultsWrapper>
          ) : (
            reduceAdditionalResults(
              additionalResults,
              availabilityLookup,
              partySize,
              selectTimeSlot,
              venueKey,
              currencyCode,
              textPerPerson,
              textPerReservation,
              locale,
              selectedLanguage,
              shouldTranslateDates,
              upsellInventories,
              upsellCategories,
              isFeesInPriceDisplayed
            )
          )}
        </AdditionalResultsWrapper>
      </Dropdown>
    )
  }
}

const mapStateToProps = state => {
  const selectedVenue = state.search.get('selectedVenue')
  const venueKey = selectedVenue === ALL_LOCATIONS ? state.venueInfo.urlKey : selectedVenue
  const { currencyCode } = state.venueInfo
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  return {
    additionalResults: selectAdditionalResults(state),
    mediaUrl: state.widgetSettings.mediaUrl,
    availabilityLookup: state.availabilityLookup.get(venueKey).toJS(),
    partySize: state.search.get('partySize'),
    isFetchingAdditional: state.searchResults.get('isFetchingAdditional'),
    venueKey,
    currencyCode,

    // text
    textOtherDates: calcLanguageStrings.textOtherDates,
    textNoAdditional: calcLanguageStrings.textNoAdditional,
    textPerPerson: languageStrings.textPerPerson,
    textPerReservation: languageStrings.textPerReservation,
    selectedLanguage: state.languages.selectedLanguage,
    shouldTranslateDates: selectLanguageDateFields(state).shouldTranslateDates,
    isFeesInPriceDisplayed: state.widgetSettings.isFeesInPriceDisplayed,
    upsellCategories: state.upsells.entities.categories,
    locale: state.venueInfo.locale,
    upsellInventories: state.upsells.entities.inventories,
  }
}

const mapDispatchToProps = {
  selectTimeSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalResultsDisplay)
