/* eslint react/prop-types: 0  */
import _ from 'lodash'
import moment from 'moment-timezone'
import Radium, { Style } from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { inIframe } from 'svr/common/A11y'
import { VmsIcons } from 'svr/common/VmsIcons'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'
import Button from 'svr/lib/Buttons/Button'
import { selectLanguageStrings, selectCalculatedLanguageStrings, selectLanguageDateFields } from 'widget/dining/selectors/languageSelectors'
import { getDurationDisplay } from '../../../../common/TimeUtil'
import ItemStepper from '../../../../lib/Items/ItemStepper'
import { venueLanguageChanged } from '../actions/language'
import {
  incrementItemDate,
  decrementItemDate,
  incrementItemPartySize,
  decrementItemPartySize,
  incrementItemDuration,
  decrementItemDuration,
  incrementItemTime,
  decrementItemTime,
  toggleSearchComponent,
  selectQueryPartySize,
  selectQueryDuration,
  selectQueryTime,
  selectVenue,
  toggleModalDisplay,
  setPageTitle,
} from '../actions/navigation'
import styles from '../assets/styles/search'
import VenueDropdown from '../components/VenueDropdown'
import { modalTypes, searchTypes } from '../utils/constantTypes'
import { ALL_LOCATIONS } from '../utils/convertData'
import Calendar from './Calendar'
import Footer from './Footer'
import ItemPicker from './ItemPicker'

class Search extends Component {
  constructor(props) {
    super(props)
    this.toggleCalendarItem = this.props.toggleSearchComponent.bind(this, searchTypes.DATE)
    this.togglePartySizeItem = this.props.toggleSearchComponent.bind(this, searchTypes.PARTY_SIZE)
    this.toggleDurationItem = this.props.toggleSearchComponent.bind(this, searchTypes.DURATION)
    this.toggleTimeItem = this.props.toggleSearchComponent.bind(this, searchTypes.TIME)
    this.toggleSpecialAttentionMessage = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_ATTENTION_MESSAGE)
    this.handlePartySizeRowClick = this.handlePartySizeRowClick.bind(this)
    this.handleDurationRowClick = this.handleDurationRowClick.bind(this)
    this.handleTimeRowClick = this.handleTimeRowClick.bind(this)
    this.handlePartySizeChange = this.handlePartySizeChange.bind(this)
    this.handleDurationChange = this.handleDurationChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handlePageTitleChange = this.handlePageTitleChange.bind(this)
    this.handleSelectVenue = this.handleSelectVenue.bind(this)
    this.initialFocusComponent = 'sr-search-initial-comp'

    this.partySizeValues = _.range(this.props.minPartySize, this.props.maxPartySize + 1)
    this.durationValues = _.range(this.props.minDuration, this.props.maxDuration + 1, this.props.durationInterval)
    this.setDurationDisplays()
    this.appElement = document.getElementById('dining-widget-app')
    this.isPoweredBy = this.props.widgetTitle !== 'Powered by SEVENROOMS' && this.props.widgetTitle !== 'reservations by SEVENROOMS'
  }

  componentDidMount() {
    this.handlePageTitleChange()
    if (!inIframe()) {
      const focusComponent = document.getElementById(this.initialFocusComponent)
      if (focusComponent) {
        focusComponent.focus()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props
    document.title = pageTitle

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
      {
        type: 'heightEvent',
        size: this.appElement.getBoundingClientRect().height,
      },
      '*'
    )

    if (prevProps.selectedLanguage !== this.props.selectedLanguage) {
      this.setDurationDisplays()
    }
  }

  setDurationDisplays() {
    this.durationDisplays = this.durationValues.map(v =>
      getDurationDisplay(v, false, false, false, this.props.textHourLabel, this.props.textMinuteLabel)
    )
  }

  getTimeValues(venueStartOfDayTime) {
    const { customTimeSlots } = this.props
    const timeIter = moment(`1970-01-01 ${venueStartOfDayTime}`)

    if (customTimeSlots) {
      return customTimeSlots
    }
    return _.map(Array(96), () => {
      const timeKey = timeIter.format(this.props.timeDisplayFormat)
      timeIter.add(15, 'minutes')
      return timeKey
    })
  }

  handlePartySizeRowClick() {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativePartySizeInput.focus()
    } else {
      this.togglePartySizeItem()
    }
  }

  handleDurationRowClick() {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativeDurationInput.focus()
    } else {
      this.toggleDurationItem()
    }
  }

  handleTimeRowClick() {
    if (this.props.isMobile && !this.props.isAndroid) {
      this.nativeTimeInput.focus()
    } else {
      this.toggleTimeItem()
    }
  }

  handlePartySizeChange(e) {
    this.props.selectQueryPartySize(parseInt(e.target.value, 10))
  }

  handleDurationChange(e) {
    const displayValue = Object.prototype.hasOwnProperty.call(e, 'target') ? e.target.value : e
    const value = this.durationValues[this.durationDisplays.indexOf(displayValue)]
    this.props.selectQueryDuration(parseInt(value, 10))
  }

  handleTimeChange(e) {
    const value = Object.prototype.hasOwnProperty.call(e, 'target') ? e.target.value : e
    const timeMoment = moment(value, this.props.timeDisplayFormat)
    this.props.selectQueryTime(timeMoment)
  }

  handlePageTitleChange() {
    const { setPageTitle, pageTitle, venueName } = this.props
    if (!pageTitle) {
      setPageTitle(`${venueName} Reservations`)
    }
  }

  handleSelectVenue(venue, venueFullName) {
    const { selectVenue, setPageTitle } = this.props
    if (venueFullName) {
      setPageTitle(`${venueFullName} Reservations`)
    }

    selectVenue(venue)
  }

  render() {
    /* eslint-disable no-shadow */
    const {
      imgSrc,
      incrementItemDate,
      decrementItemDate,
      incrementItemPartySize,
      decrementItemPartySize,
      incrementItemDuration,
      decrementItemDuration,
      incrementItemTime,
      decrementItemTime,
      dateItemStr,
      timeItemStr,
      partySize,
      enableDurationPicker,
      duration,
      disableDecrement,
      displaySearchType,
      timeMoment,
      isMobile,
      isAndroid,
      disableIncrement,
      venueMap,
      venueSortOrders,
      venueStartOfDayTime,
      selectedVenue,
      widgetTitle,
      venueName,
      timeDisplayFormat,
      lockTime,
      colorPrimary,
      colorHover,
      colorLines,
      colorCellBackground,
      colorBackground,
      colorActionBarBackground,
      colorWidgetBackground,
      fontsColorPrimary,
      fontsColorSecondary,
      fontsColorButton,
      fontsColorActionBar,
      fontsColorLinks,
      isMultiVenue,
      textDate,
      textGuestLabel,
      textTime,
      textDuration,
      textSelectTimeDropdown,
      textSelectPartySizeDropdown,
      textSelectDurationDropdown,
      hijack,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
      selectedLanguage,
      venueLanguageChanged,
      enabledLanguages,
      widgetTemplateTheme,
    } = this.props
    /* eslint-enable no-shadow */
    const itemPickerColorProps = {
      fontsColorPrimary,
      colorCellBackground,
      colorHover,
      colorHighlightRow: colorPrimary,
      colorHighlightRowText: fontsColorButton,
    }
    const durationDisplay = getDurationDisplay(duration, false, false, false, this.props.textHourLabel, this.props.textMinuteLabel)
    const showLanguageList = !_.isNil(enabledLanguages) && !_.isNil(selectedLanguage) && enabledLanguages.length > 1
    const isIOS = isMobile && !isAndroid

    return (
      <div style={[styles.viewWrapper, { backgroundColor: colorWidgetBackground }]}>
        <div style={styles.viewGroup}>
          {showLanguageList && (
            <div
              style={[
                styles.viewRow,
                {
                  borderBottomColor: 'transparent',
                  backgroundColor: colorCellBackground,
                  color: fontsColorPrimary,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  padding: '5px 0px 4px 0px',
                },
              ]}
            >
              <VenueSupportedLanguageDropDown
                id="res-widget-supported-languages-picker"
                languages={enabledLanguages}
                selectedLanguage={selectedLanguage}
                onChange={venueLanguageChanged}
                style={{ textAlign: 'left' }}
                customStyle={{
                  colorLines,
                  colorBackground,
                  fontsColorPrimary,
                  colorWidgetBackground,
                  colorPrimary,
                  widgetTemplateTheme,
                  colorCellBackground,
                }}
                height={31}
                showCaret
                noHeader
                leftSideIcon={VmsIcons.Translate}
              />
            </div>
          )}
          {isMultiVenue && (
            <div style={[styles.viewRow, { borderBottomColor: 'transparent' }]}>
              <VenueDropdown
                venueMap={venueMap}
                venueSortOrders={venueSortOrders}
                selectedVenue={selectedVenue}
                onVenueClick={this.handleSelectVenue}
                dropdownFontColor={fontsColorActionBar}
                listFontColor={fontsColorPrimary}
                backgroundColor={colorActionBarBackground}
                modalBackground={colorCellBackground}
                lineColor={colorLines}
                hoverColor={colorHover}
                data-test="sr-location-dropdown"
              />
            </div>
          )}
          {imgSrc && (
            <div style={[styles.viewRow, { borderBottomColor: colorLines }]}>
              <div style={styles.imageWrapper}>
                <div style={styles.imageContainer}>
                  <img aria-hidden="true" alt="" style={styles.imageContent} src={imgSrc} />
                </div>
              </div>
            </div>
          )}
          {widgetTitle && this.isPoweredBy && !hijack.hijackReservationWidget && (
            <div style={[styles.viewRow, { borderBottomColor: 'transparent' }]}>
              <div
                aria-label={widgetTitle}
                style={[
                  styles.widgetTitle,
                  {
                    backgroundColor: colorCellBackground,
                    color: fontsColorPrimary,
                  },
                ]}
              >
                {widgetTitle}
              </div>
            </div>
          )}
        </div>
        {hijack.hijackReservationWidget ? (
          <div
            style={[
              styles.hijacked,
              {
                backgroundColor: colorCellBackground,
                color: fontsColorPrimary,
              },
            ]}
          >
            <Style scopeSelector="a" rules={{ color: fontsColorLinks }} />
            <div
              dangerouslySetInnerHTML={{
                __html: hijack.alternateWidgetContent,
              }}
            />
            {hijack.deliveryServiceUrl ? (
              <div>
                <Button
                  withDefaultOutline
                  innerElement={hijack.deliveryButtonText}
                  onClick={() => {
                    window.parent.location = hijack.deliveryServiceUrl
                  }}
                  style={{
                    backgroundColor: colorPrimary,
                    color: fontsColorButton,
                    fontSize: '16px',
                    letterSpacing: '0.3px',
                    marginTop: '30px',
                    padding: '15px 50px',
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div style={styles.viewGroup}>
            {showSpecialAttentionMessage && (
              <div
                style={[
                  styles.viewRow,
                  {
                    backgroundColor: colorActionBarBackground,
                    color: fontsColorActionBar,
                    fontSize: '13px',
                    borderBottomColor: colorLines,
                    textAlign: 'center',
                  },
                ]}
                data-test="sr-special-attention-header"
              >
                <button
                  name="special-attention-message"
                  onClick={this.toggleSpecialAttentionMessage}
                  style={[
                    styles.buttonInfoIcon,
                    {
                      backgroundColor: 'transparent',
                      color: fontsColorActionBar,
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '16px 8px',
                      width: '100%',
                      alignItems: 'center',
                    },
                  ]}
                  data-test="sr-special-attention-header-icon-button"
                  type="button"
                >
                  {specialAttentionMessageHeader}
                  <i
                    style={[styles.infoIcon, { paddingLeft: '8px' }]}
                    title="See full special attention message."
                    className="fa fa-info-circle"
                  />
                </button>
              </div>
            )}
            <div style={[styles.viewRow, { borderBottomColor: colorLines }]} id={this.initialFocusComponent} data-test="sr-calendar-date">
              {displaySearchType === searchTypes.DATE ? (
                <Calendar />
              ) : (
                <ItemStepper
                  value={dateItemStr}
                  onItemClick={this.props.selectQueryPartySize}
                  label={textDate}
                  incrementItem={incrementItemDate}
                  decrementItem={decrementItemDate}
                  canDecrement={!disableDecrement.dateMoment}
                  canIncrement={!disableIncrement.dateMoment}
                  labelColor={fontsColorSecondary}
                  style={[{ color: fontsColorPrimary }, { backgroundColor: colorCellBackground }]}
                  onClick={this.toggleCalendarItem}
                  dataTest="sr-calendar-date-button"
                  fontsColorPrimary={fontsColorPrimary}
                />
              )}
            </div>
            <div style={[styles.viewRow, { borderBottomColor: colorLines }]}>
              <div style={styles.stepRow} data-test="sr-guest-count">
                <ItemStepper
                  value={partySize}
                  label={textGuestLabel}
                  incrementItem={incrementItemPartySize}
                  decrementItem={decrementItemPartySize}
                  canDecrement={!disableDecrement.partySize}
                  canIncrement={!disableIncrement.partySize}
                  labelColor={fontsColorSecondary}
                  style={[{ color: fontsColorPrimary }, { backgroundColor: colorCellBackground }]}
                  onClick={this.partySizeValues.length > 1 && this.handlePartySizeRowClick}
                  dataTest="sr-guest-count-button"
                  fontsColorPrimary={fontsColorPrimary}
                />
                {isIOS && (
                  <select
                    style={styles.hiddenInput}
                    onChange={this.handlePartySizeChange}
                    value={partySize}
                    ref={input => {
                      this.nativePartySizeInput = input
                    }}
                  >
                    {_.map(this.partySizeValues, idx => (
                      <option key={`party-size-${idx}`} value={idx}>
                        {idx}
                      </option>
                    ))}
                  </select>
                )}
                {(!isMobile || isAndroid) && displaySearchType === searchTypes.PARTY_SIZE && (
                  <ItemPicker
                    headerText={textSelectPartySizeDropdown}
                    selectedValue={partySize}
                    valueSet={this.partySizeValues}
                    onItemClick={this.props.selectQueryPartySize}
                    onOutsideClick={this.togglePartySizeItem}
                    imgSrc={imgSrc}
                    {...itemPickerColorProps}
                    dataTest="sr-guest-count-dropdown"
                    fontsColorPrimary={fontsColorPrimary}
                  />
                )}
              </div>
            </div>
            {enableDurationPicker && (
              <div style={[styles.viewRow, { borderBottomColor: colorLines }]}>
                <div style={styles.stepRow} data-test="sr-duration">
                  <ItemStepper
                    value={durationDisplay}
                    label={textDuration}
                    incrementItem={incrementItemDuration}
                    decrementItem={decrementItemDuration}
                    canDecrement={!disableDecrement.duration}
                    canIncrement={!disableIncrement.duration}
                    labelColor={fontsColorSecondary}
                    style={[{ color: fontsColorPrimary }, { backgroundColor: colorCellBackground }]}
                    onClick={this.durationValues.length > 1 && this.handleDurationRowClick}
                    dataTest="sr-duration-picker"
                    fontsColorPrimary={fontsColorPrimary}
                  />
                  {isIOS && (
                    <select
                      style={styles.hiddenInput}
                      onChange={this.handleDurationChange}
                      value={duration}
                      ref={input => {
                        this.nativeDurationInput = input
                      }}
                    >
                      {_.map(this.durationDisplays, val => (
                        <option key={`duration-${val}`} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  )}
                  {(!isMobile || isAndroid) && displaySearchType === searchTypes.DURATION && (
                    <ItemPicker
                      headerText={textSelectDurationDropdown}
                      selectedValue={durationDisplay}
                      valueSet={this.durationDisplays}
                      onItemClick={this.handleDurationChange}
                      onOutsideClick={this.toggleDurationItem}
                      imgSrc={imgSrc}
                      {...itemPickerColorProps}
                      data-test="sr-duration-picker-dropdown"
                    />
                  )}
                </div>
              </div>
            )}
            <div style={[styles.viewRow, { borderBottomColor: colorLines }]}>
              <div style={styles.stepRow} data-test="sr-time-picker">
                <ItemStepper
                  value={timeItemStr}
                  label={textTime}
                  incrementItem={incrementItemTime}
                  decrementItem={decrementItemTime}
                  canDecrement={!disableDecrement.timeMoment}
                  canIncrement={!disableIncrement.timeMoment}
                  labelColor={fontsColorSecondary}
                  style={[{ color: fontsColorPrimary }, { backgroundColor: colorCellBackground }]}
                  dataTest="sr-time-button"
                  fontsColorPrimary={fontsColorPrimary}
                  onClick={this.handleTimeRowClick}
                />
                {isIOS && !lockTime && (
                  <select
                    style={styles.hiddenInput}
                    onChange={this.handleTimeChange}
                    value={timeMoment.format(timeDisplayFormat)}
                    ref={input => {
                      this.nativeTimeInput = input
                    }}
                  >
                    {_.map(this.getTimeValues(venueStartOfDayTime), (val, idx) => (
                      <option key={`sr-time-interval-${idx + 1}`} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                )}
                {(!isMobile || isAndroid) && displaySearchType === searchTypes.TIME && !lockTime && (
                  <ItemPicker
                    headerText={textSelectTimeDropdown}
                    selectedValue={timeMoment.format(timeDisplayFormat)}
                    valueSet={this.getTimeValues(venueStartOfDayTime)}
                    onItemClick={this.handleTimeChange}
                    onOutsideClick={this.toggleTimeItem}
                    imgSrc={imgSrc}
                    {...itemPickerColorProps}
                    data-test="sr-time-picker-dropdown"
                  />
                )}
              </div>
            </div>
            <div style={[styles.viewRow, { borderBottomColor: colorLines }]}>
              <Footer />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  let imgSrc = null
  if (state.widgetSettings.headerImg) {
    imgSrc = `/.h/download/${state.widgetSettings.headerImg}`
  }
  const timeMoment = state.search.get('timeMoment')
  const timeDisplayFormat = state.venueInfo.locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
  const timeSearchDisplayFormat = state.venueInfo.locale === 'en_GB' ? 'HH:mm' : 'h:mm A'
  const venueMapAll = state.search.get('venueMap').toJS()
  const enabledMultiVenues = Object.entries(venueMapAll).filter(
    ([key]) => key === 'alllocations' || state.venueEntities[key].isMultiVenueReservationEnabled
  )
  const venueMap = {}
  for (const venue of enabledMultiVenues) {
    const [venueKey, venueName] = venue
    venueMap[venueKey] = venueName
  }

  const venueSortOrders = _.mapValues(state.venueEntities, venueEntity => venueEntity.sortOrder)

  // The COVID protocols
  const currentVenue = state.venueEntities[state.search.get('selectedVenue')] || state.venueInfo
  const hijack = {
    hijackReservationWidget: currentVenue.hijackReservationWidget,
    alternateWidgetContent: currentVenue.alternateWidgetContent,
    deliveryButtonText: currentVenue.deliveryButtonText,
    deliveryServiceUrl: currentVenue.deliveryServiceUrl,
  }

  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  const languageDateFields = selectLanguageDateFields(state)

  venueMap[ALL_LOCATIONS] = languageStrings.textLocationPickerAllLabel

  return {
    imgSrc,
    timeMoment,
    isMobile: state.app.isMobile,
    isAndroid: state.app.isAndroid,
    timeItemStr: timeMoment.format(timeSearchDisplayFormat),
    dateItemStr: languageDateFields.datePickerLabel,
    partySize: state.search.get('partySize'),
    duration: state.search.get('duration'),
    disableDecrement: state.search.get('disableDecrement').toJS(),
    disableIncrement: state.search.get('disableIncrement').toJS(),
    minPartySize: state.search.get('minPartySize'),
    maxPartySize: state.search.get('maxPartySize'),
    enableDurationPicker: state.search.get('enableDurationPicker'),
    minDuration: state.search.get('minDuration'),
    maxDuration: state.search.get('maxDuration'),
    durationInterval: state.search.get('durationInterval'),
    selectedVenue: state.search.get('selectedVenue'),
    displaySearchType: state.ui.get('displaySearchType'),
    venueMap,
    venueSortOrders,
    venueStartOfDayTime: state.venueInfo.startOfDayTime,
    isMultiVenue: _.keys(venueMap).length > 2,
    lockTime: state.search.get('lockTime'),
    venueName: state.venueInfo.name,
    timeDisplayFormat,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorLines: state.widgetSettings.colorLines,
    colorButton: state.widgetSettings.colorButton,
    colorHover: state.widgetSettings.colorHover,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    colorBackground: state.widgetSettings.colorBackground,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
    fontsColorLinks: state.widgetSettings.fontsColorLinks,
    // text
    textDate: languageStrings.textDate,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textTime: languageStrings.textTime,
    textDuration: languageStrings.textDuration,
    textSelectTimeDropdown: languageStrings.textSelectTimeDropdown.toUpperCase(),
    textSelectDurationDropdown: languageStrings.textSelectDurationDropdown,
    textSelectPartySizeDropdown: languageStrings.textSelectPartySizeDropdown.toUpperCase(),
    textHourLabel: languageStrings.textHourLabel,
    textMinuteLabel: languageStrings.textMinuteLabel,
    widgetTitle: languageStrings.textWidgetTitle,
    showSpecialAttentionMessage: state.widgetSettings.showSpecialAttentionMessage,
    specialAttentionMessageHeader: languageStrings.specialAttentionMessageHeader,
    customTimeSlots: state.search.get('customTimeSlots'),
    hijack,
    enabledLanguages: state.languages.venueLanguages,
    selectedLanguage: state.languages.selectedLanguage,
    pageTitle: state.search.get('pageTitle'),
    widgetTemplateTheme: state.widgetSettings.widgetTemplateTheme,
  }
}

const mapDispatchToProps = dispatch => ({
  incrementItemDate: () => {
    dispatch(incrementItemDate())
  },
  decrementItemDate: () => {
    dispatch(decrementItemDate())
  },
  incrementItemPartySize: () => {
    dispatch(incrementItemPartySize())
  },
  decrementItemPartySize: () => {
    dispatch(decrementItemPartySize())
  },
  incrementItemDuration: () => {
    dispatch(incrementItemDuration())
  },
  decrementItemDuration: () => {
    dispatch(decrementItemDuration())
  },
  incrementItemTime: () => {
    dispatch(incrementItemTime())
  },
  decrementItemTime: () => {
    dispatch(decrementItemTime())
  },
  toggleSearchComponent: item => {
    dispatch(toggleSearchComponent(item))
  },
  selectQueryPartySize: partySize => {
    dispatch(selectQueryPartySize(partySize))
  },
  selectQueryDuration: duration => {
    dispatch(selectQueryDuration(duration))
  },
  selectQueryTime: timeMoment => {
    dispatch(selectQueryTime(timeMoment))
  },
  selectVenue: venue => {
    dispatch(selectVenue(venue))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  venueLanguageChanged: languageCode => {
    dispatch(venueLanguageChanged(languageCode))
  },
  setPageTitle: title => {
    dispatch(setPageTitle(title))
  },
})

Search = connect(mapStateToProps, mapDispatchToProps)(Radium(Search))

export default Search
