// /* @flow */
import * as React from 'react'
import { withTheme } from 'styled-components'

/* type Props = { theme: { [string]: string } } */

const UpsellStyleTag = (props /* : Props */) => (
  <style
    dangerouslySetInnerHTML={{
      __html: `a {
        color: ${props.theme.fontsColorLinks};
        text-decoration: none;
      }`,
    }}
  />
)

export default withTheme(UpsellStyleTag)
