import { CHANGE_VENUE_LANGUAGE, CLIENT_CHANGE_PREFERRED_LANGUAGE } from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { SELECT_VENUE, GET_REMAINING_LANGUAGE_STRINGS_SUCCESS, REVERT_STAGE, INITIALIZE_LANGUAGE } from '../actions/ActionTypes'
import { viewTypes } from '../utils/constantTypes'

const initialState = {
  venueLanguages: [],
  languageStrings: {},
  selectedLanguage: '',
  selectedLanguageStrings: {},
  clientPreferredLanguage: '',
  venueLanguageStringMap: {},
  baseVenueUrlKey: '',
}

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_LANGUAGE:
      return {
        ...state,
        ...action.language,
      }
    case CHANGE_VENUE_LANGUAGE: {
      const selectedLanguage = action.enabledLanguageCodes.includes(action.languageCode) ? action.languageCode : action.defaultLanguageCode
      return {
        ...state,
        selectedLanguage: action.languageCode,
        selectedLanguageStrings: state.languageStrings[selectedLanguage],
      }
    }
    case SELECT_VENUE: {
      const urlKey = action.venue in state.venueLanguageStringMap ? action.venue : state.baseVenueUrlKey
      const languageStrings = state.venueLanguageStringMap[urlKey]
      const selectedLanguage = action.enabledLanguageCodes.includes(state.selectedLanguage)
        ? state.selectedLanguage
        : action.defaultLanguageCode
      return {
        ...state,
        languageStrings,
        selectedLanguageStrings: languageStrings[selectedLanguage],
      }
    }
    case CLIENT_CHANGE_PREFERRED_LANGUAGE:
      return {
        ...state,
        clientPreferredLanguage: action.languageCode,
      }
    case GET_REMAINING_LANGUAGE_STRINGS_SUCCESS: {
      const selectedLanguage = action.enabledLanguageCodes.includes(state.selectedLanguage)
        ? state.selectedLanguage
        : action.defaultLanguageCode
      const urlKey = action.selectedVenue in state.venueLanguageStringMap ? action.selectedVenue : state.baseVenueUrlKey
      const languageStrings = state.venueLanguageStringMap[urlKey]
      const updatedLanguageStrings = {
        ...languageStrings,
        [selectedLanguage]: {
          ...languageStrings[selectedLanguage],
          ...action.remainingLanguageStrings,
        },
      }
      const updatedTagLanguageStrings = {
        [selectedLanguage]: {
          ...action.tagLanguageStrings,
        },
      }
      return {
        ...state,
        languageStrings: updatedLanguageStrings,
        selectedLanguageStrings: updatedLanguageStrings[selectedLanguage],
        tagLanguageStrings: updatedTagLanguageStrings,
      }
    }
    case REVERT_STAGE: {
      if (action.nextView !== viewTypes.SEARCH_RESULTS) {
        return state
      }
      const selectedLanguage = action.enabledLanguageCodes.includes(state.selectedLanguage)
        ? state.selectedLanguage
        : action.defaultLanguageCode
      const urlKey = action.selectedVenue in state.venueLanguageStringMap ? action.selectedVenue : state.baseVenueUrlKey
      const languageStrings = state.venueLanguageStringMap[urlKey]
      return {
        ...state,
        languageStrings,
        selectedLanguageStrings: languageStrings[selectedLanguage],
      }
    }
    default:
      return state
  }
}

export default languageReducer
