/* eslint-disable no-param-reassign */
import _ from 'lodash'
import Radium from 'radium'
import PhoneNumber from 'svr/component-lib/Widget/PhoneNumber'
import { validateEmail, validateNotEmpty, validateTimeRange } from '../../../../common/Validators'
import TextInput from '../../../../lib/TextInputs/TextInput'
import styles from '../assets/styles/checkout'
import { ConsentToNotificationsCheckbox } from '../components/ConsentToNotificationsCheckbox'
import { searchTypes } from '../utils/constantTypes'
import ItemPicker from './ItemPicker'

export const RequestPriorityAlertForm = ({
  partySize,
  queryDate,
  startTime,
  endTime,
  firstName,
  lastName,
  email,
  isMobile,
  displaySearchType,
  phoneNumber,
  phoneNumberLocale,
  dialCode,
  changePhoneNum,
  changeFlag,
  formErrors,
  colorCheckoutCellBackground,
  colorLines,
  colorPrimary,
  fontsColorPrimary,
  fontsColorCheckoutInactive,
  fontsColorCheckoutActive,
  fontFamily,
  colorCellBackground,
  colorHover,
  fontsColorButton,
  textFirstName,
  textLastName,
  textEmailAddress,
  textPhoneNumber,
  textSelectTimeDropdown,
  textGuestLabel,
  isAndroid,

  onFirstNameChange,
  onLastNameChange,
  onTimeRowClick,
  onToggleTimeSelect,
  requestTimes,
  onTimeChange,
  textInputProps,
  onEmailChange,
  inputFields,

  resWidgetPriorityAlertsDetailsHeader,
  startOfDayTime,
  resWidgetPriorityAlertsReservationTimeBetween,
  onEndTimeRowClick,
  onEndTimeChange,
  onToggleEndTimeSelect,
  resWidgetPriorityAlertsYourDetails,
  consentToNotifications,
  onConsentToNotifications,
  policySmsOptIn,
  priorityAlertsSmsEnabled,
}) => (
  <>
    <div style={styles.sectionWrapper}>
      <div style={styles.labelWrapper}>
        <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{resWidgetPriorityAlertsDetailsHeader}</span>
      </div>
      <div style={styles.infoForm}>
        <div
          style={[
            styles.topBorderRadius,
            styles.requestLine,
            { color: fontsColorCheckoutActive },
            { backgroundColor: colorCheckoutCellBackground },
          ]}
        >
          <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-calendar fa-fw" aria-hidden="true" />
          <span style={[styles.summaryText, styles.requestFieldItem]}>{queryDate}</span>
        </div>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div style={[styles.requestLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
          <i style={[styles.lineIcon, styles.requestFieldItem, styles.shiftLeft]} className="fa fa-users fa-fw" aria-hidden="true" />
          <span style={[styles.summaryText, styles.requestFieldItem]}>
            {partySize} {textGuestLabel}
          </span>
        </div>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div
          style={[
            styles.formLine,
            styles.bottomBorderRadius,
            styles.infoForm,
            formErrors.startTime || formErrors.endTime ? styles.invalidTime : null,
          ]}
        >
          <div
            key="sr-request-start-time"
            onKeyUp={requestTimes.length > 1 && onTimeRowClick}
            onClick={requestTimes.length > 1 && onTimeRowClick}
            style={[
              styles.requestLine,
              styles.formBox,
              styles.startTimeSelect,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
              requestTimes.length > 1 && styles.activeLine,
            ]}
          >
            <i
              style={[
                styles.lineIcon,
                styles.requestFieldItem,
                styles.shiftLeft,
                formErrors.startTime || formErrors.endTime ? styles.invalidTimeIcon : null,
              ]}
              className="fa fa-time-8 fa-fw"
              aria-hidden="true"
            />
            <TextInput
              validator={() => validateTimeRange(startTime, endTime, startOfDayTime)}
              isValid={!formErrors.startTime}
              placeholder={resWidgetPriorityAlertsReservationTimeBetween}
              value={startTime}
              style={styles.requestInputItem}
              ref={input => {
                inputFields.startTime = input
              }}
              {...textInputProps}
            />
            {(!isMobile || isAndroid) && displaySearchType === searchTypes.REQUEST_TIME && requestTimes.length > 1 && (
              <ItemPicker
                headerText={textSelectTimeDropdown}
                selectedValue={startTime}
                valueSet={requestTimes}
                onItemClick={onTimeChange}
                onOutsideClick={onToggleTimeSelect}
                style={styles.requestItemPicker}
                fontsColorPrimary={fontsColorPrimary}
                colorCellBackground={colorCellBackground}
                colorHover={colorHover}
                colorHighlightRow={colorPrimary}
                colorHighlightRowText={fontsColorButton}
              />
            )}
          </div>
          <div
            key="sr-request-end-time"
            onKeyUp={requestTimes.length > 1 && onEndTimeRowClick}
            onClick={requestTimes.length > 1 && onEndTimeRowClick}
            style={[
              styles.requestLine,
              styles.formBox,
              styles.endTimeSelect,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
              requestTimes.length > 1 && styles.activeLine,
            ]}
          >
            <TextInput
              testId="sr-data-test-request-end-time"
              validator={() => validateTimeRange(startTime, endTime, startOfDayTime)}
              isValid={!formErrors.endTime}
              value={endTime}
              placeholder=""
              style={styles.requestInputItem}
              ref={input => {
                inputFields.endTime = input
              }}
              {...textInputProps}
            />
            {(!isMobile || isAndroid) && displaySearchType === searchTypes.REQUEST_END_TIME && requestTimes.length > 1 && (
              <ItemPicker
                headerText={textSelectTimeDropdown}
                selectedValue={endTime}
                valueSet={requestTimes}
                onItemClick={onEndTimeChange}
                onOutsideClick={onToggleEndTimeSelect}
                style={styles.requestItemPicker}
                fontsColorPrimary={fontsColorPrimary}
                colorCellBackground={colorCellBackground}
                colorHover={colorHover}
                colorHighlightRow={colorPrimary}
                colorHighlightRowText={fontsColorButton}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div style={styles.sectionWrapper}>
      <div style={styles.labelWrapper}>
        <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{resWidgetPriorityAlertsYourDetails}</span>
      </div>
      <div style={styles.infoForm}>
        <div style={[styles.formLine, styles.topBorderRadius, { backgroundColor: colorCheckoutCellBackground }]}>
          <div style={styles.formBox}>
            <TextInput
              placeholder={`${textFirstName} *`}
              limitType="name"
              value={firstName}
              validator={validateNotEmpty}
              isValid={!formErrors.firstName}
              onChange={onFirstNameChange}
              style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
              ariaLabel="First Name"
              ref={input => {
                inputFields.firstName = input
              }}
              {...textInputProps}
            />
          </div>
          <div style={[styles.formBox, styles.padLeft]}>
            <TextInput
              placeholder={`${textLastName} *`}
              limitType="name"
              value={lastName}
              validator={validateNotEmpty}
              isValid={!formErrors.lastName}
              onChange={onLastNameChange}
              style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
              ariaLabel="Last Name"
              ref={input => {
                inputFields.lastName = input
              }}
              {...textInputProps}
            />
          </div>
        </div>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div style={[styles.formLine, { backgroundColor: colorCheckoutCellBackground }]}>
          <TextInput
            placeholder={`${textEmailAddress} *`}
            limitType="email"
            value={email}
            validator={validateEmail}
            isValid={!formErrors.email}
            onChange={onEmailChange}
            style={styles.singleFieldInput}
            ariaLabel="Email"
            ref={input => {
              inputFields.email = input
            }}
            {...textInputProps}
          />
        </div>
        <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
        <div
          style={[
            styles.bottomBorderRadius,
            styles.formLine,
            formErrors.phoneNumber ? styles.invalidPhoneNumber : null,
            { backgroundColor: colorCheckoutCellBackground },
          ]}
        >
          <PhoneNumber
            onPhoneNumberChange={changePhoneNum}
            onFlagChange={changeFlag}
            customPhoneNumberWrapper={[styles.singleFieldInput]}
            dialCode={dialCode}
            fontsColorCheckoutActive={fontsColorCheckoutActive}
            fontsColorCheckoutInactive={fontsColorCheckoutInactive}
            fontFamily={fontFamily}
            isValid={!formErrors.phoneNumber}
            phoneNumber={phoneNumber}
            selectedCountryCode={phoneNumberLocale}
            placeholderText={textPhoneNumber}
            autoComplete="random-bits-of-words-to-break-autocomplete"
            ariaLabel="Phone Number"
            ref={input => {
              inputFields.phoneNumber = input
            }}
            {...textInputProps}
          />
        </div>
      </div>
      <br />
    </div>
    {priorityAlertsSmsEnabled && (
      <div style={[styles.sectionWrapper]}>
        <ConsentToNotificationsCheckbox
          name="consent-to-notifications-checkbox"
          validator={() => true}
          value={consentToNotifications}
          onChange={onConsentToNotifications}
          isInvalid={!!formErrors.consentToNotifications}
          ref={input => {
            inputFields.consentToNotifications = input
          }}
          label={policySmsOptIn}
        />
      </div>
    )}
  </>
)

export default Radium(RequestPriorityAlertForm)
