import Radium from 'radium'
import React from 'react'
import styles from './modals'

const PromoCodePopover = ({
  promoCodeHeaderIcon,
  promoCodeLogo,
  promoCodeLogoDisplay,
  promoCodeDetails,
  dismissModal,
  headerColor,
  headerTextColor,
  textColor,
}) => (
  <div style={styles.promoCodeModalContainer}>
    <div style={[styles.promoCodeHeader, { backgroundColor: headerColor, color: headerTextColor }]}>
      <span style={styles.promoCodeHeaderText} className={promoCodeHeaderIcon} />
      <span style={styles.promoHeaderX} onClick={dismissModal}>
        ×
      </span>
    </div>
    <div style={{ display: promoCodeLogoDisplay }}>
      <img style={styles.promoCodeLogo} src={promoCodeLogo} />
    </div>
    <div style={[styles.promoCodeBody, { color: textColor }]} dangerouslySetInnerHTML={{ __html: promoCodeDetails }} />
  </div>
)

PromoCodePopover.propTypes = {
  promoCodeHeaderIcon: React.PropTypes.string.isRequired,
  promoCodeLogo: React.PropTypes.string.isRequired,
  promoCodeLogoDisplay: React.PropTypes.string.isRequired,
  promoCodeDetails: React.PropTypes.string.isRequired,
  dismissModal: React.PropTypes.func.isRequired,
  headerColor: React.PropTypes.string,
  headerTextColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
}

export default Radium(PromoCodePopover)
