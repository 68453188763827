import { Map, List } from 'immutable'
import _ from 'lodash'
import { camelCaseObject } from '../../../../common/ObjectUtils'
import {
  TRY_GET_INITIAL_AVAILABILITY,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
  GET_INITIAL_AVAILABILITY_SUCCESS,
  GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
  GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  GET_ADDITIONAL_AVAILABILITY_COMPLETE,
  SELECT_TIME_SLOT,
  SELECT_REQUEST_TIME,
  SELECT_REQUEST_END_TIME,
  GET_PROMO_CODES_SUCCESS,
  INCREMENT_ITEM_PARTY_SIZE,
  DECREMENT_ITEM_PARTY_SIZE,
  INCREMENT_ITEM_DURATION,
  DECREMENT_ITEM_DURATION,
  SELECT_TIME_SLOT_VENUE,
} from '../actions/ActionTypes'

const initialState = Map({
  applicablePromoCodes: Map(),
  initialResults: Map(),
  venueResults: List(),
  orderedRequests: List(),
  additionalResults: List(),
  isAllLocationsSearch: false,
  searchDateCursor: null,
  isFetchingMulti: false,
  isFetchingAdditional: false,
  timeSlotVenue: null,
  selectedRequest: null,
  selectedEndTimeRequest: null,
})
const searchResults = (state = initialState, action = {}) => {
  switch (action.type) {
    case SELECT_TIME_SLOT:
      return state.set('timeSlotVenue', action.venueKey)
    case GET_ADDITIONAL_AVAILABILITY_SUCCESS: {
      const newState = state.update('additionalResults', additionalResults => additionalResults.concat(action.data.additionalResults))
      return newState.set('searchDateCursor', action.data.searchDateCursor)
    }
    case TRY_GET_INITIAL_AVAILABILITY: {
      if (action.isModifyResUpgradesMode) {
        return state.set('isAllLocationsSearch', false)
      }
      return initialState.set('isAllLocationsSearch', false)
    }
    case TRY_GET_MULTI_VENUE_AVAILABILITY: {
      if (action.isModifyResUpgradesMode) {
        return state.set('isAllLocationsSearch', true)
      }
      return initialState.set('isAllLocationsSearch', true)
    }
    case GET_INITIAL_AVAILABILITY_SUCCESS: {
      let newState = state
      if (!state.get('isAllLocationsSearch')) {
        newState = newState.set('orderedRequests', List(action.data.orderedRequests))
        newState = newState.set('searchDateCursor', action.data.searchDateCursor)
      }
      newState = newState.set('initialResults', Map(action.data.initialResults))
      newState = newState.set('selectedRequest', action.data.selectedRequest)
      newState = newState.set('selectedEndTimeRequest', action.data.selectedEndTimeRequest)
      return newState.merge({
        isFetchingMulti: true,
        isFetchingAdditional: true,
      })
    }
    case GET_MULTI_VENUE_AVAILABILITY_SUCCESS: {
      let nextState = state
      _.each(action.data, result => {
        nextState = nextState.update('venueResults', resultsList => resultsList.push(result.venueResults))
      })
      return nextState.merge({
        isFetchingMulti: false,
        isFetchingAdditional: true,
      })
    }
    case GET_ADDITIONAL_AVAILABILITY_COMPLETE:
      return state.set('isFetchingAdditional', false)
    case SELECT_REQUEST_TIME:
      return state.set('selectedRequest', action.requestTime)
    case SELECT_REQUEST_END_TIME:
      return state.set('selectedEndTimeRequest', action.requestEndTime)
    case GET_PROMO_CODES_SUCCESS:
      return state.set('applicablePromoCodes', camelCaseObject(action.promoCodes))
    case INCREMENT_ITEM_PARTY_SIZE:
    case DECREMENT_ITEM_PARTY_SIZE:
    case INCREMENT_ITEM_DURATION:
    case DECREMENT_ITEM_DURATION:
      return action.clearAvailability ? state.merge(initialState) : state
    case SELECT_TIME_SLOT_VENUE: {
      return state.set('timeSlotVenue', action.selectedVenue)
    }
    default:
      return state
  }
}

export default searchResults
