import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid ${props => props.theme.color.grey};
`
const ErrorBody = styled.div`
  height: 68px;
  padding: ${props => props.theme.gutter.triple};
  background: ${props => props.theme.color.black};
  color: ${props => props.theme.color.white};
  font-size: 14px;
  flex: 1;
  text-align: center;
`

export const ErrorNotFound = () => (
  <ErrorContainer>
    <ErrorBody>
      <p>Route not found.</p>
    </ErrorBody>
  </ErrorContainer>
)

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorBody>
            <p>Unexpected error encountered while rendering this page.</p>
          </ErrorBody>
        </ErrorContainer>
      )
    }

    return this.props.children
  }
}
