// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  name: string,
  minQuantity: number,
  maxQuantity: number,
  showCategoryFlag: boolean,
} */

const CategoryHeader = (props /* : Props */) => {
  if (props.minQuantity) {
    return (
      <HeaderWrapper isRequired data-test="sr-upgrade-section-header">
        <CategoryLabelSection isRequired>{props.name}</CategoryLabelSection>
        <CategoryRequiredSection>
          <CategoryRequiredText>{props.textUpgradeRequiredLabel}</CategoryRequiredText>
          <CategoryRequiredText italic>
            {props.minQuantity === props.maxQuantity
              ? `Must select ${props.minQuantity}`
              : `${props.textUpgradeMustAtLeastLabel} ${props.minQuantity}`}
          </CategoryRequiredText>
        </CategoryRequiredSection>
      </HeaderWrapper>
    )
  } else if (props.showCategoryFlag) {
    return (
      <HeaderWrapper data-test="sr-upgrade-section-header">
        <CategoryLabelSection>{props.name}</CategoryLabelSection>
      </HeaderWrapper>
    )
  }
  return <HeaderWrapper isBlank />
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${props => (props.isRequired ? '60px' : '30px')};
  font-size: 14px;
  padding: 0 20px;
  color: ${props => props.theme.fontsColorButton};
  background-color: ${props => (props.isBlank ? props.theme.colorWidgetBackground : props.theme.colorPrimary)};
`

const CategoryLabelSection = styled.div`
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: ${props => (props.isRequired ? '60px' : '30px')};
`

const CategoryRequiredSection = styled.div`
  float: right;
  padding: 10px 0;
  line-height: 20px;
  text-align: right;
  height: 60px;
  margin-left: 20px;
  min-width: max-content;
`

const CategoryRequiredText = styled.div`
  height: 20px;
  width: 100%;
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
`

export default CategoryHeader
