import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { StyledVmsIconXXS, StyledVmsIconXS, StyledVmsIconS, StyledVmsIconM, StyledVmsIconL, VmsIcons } from 'svr/common/VmsIcons'

const InnerIcon = styled.div`
  color: white;
  font-size: 0.7em;
  position: absolute;
`

export const BrandMap = {
  Google: { icon: VmsIcons.Google, color: '#EA4335' },
  Yelp: { icon: VmsIcons.Yelp, color: '#EA4335' },
  OpenTable: { icon: VmsIcons.OpenTable, color: '#C94549' },
  TripAdvisor: { icon: VmsIcons.TripAdvisor, color: '#00B087' },
  Facebook: { icon: VmsIcons.Facebook, color: '#4267B2' },
  SevenRooms: { icon: VmsIcons.LogoSymbol, color: '#000000' },
  Grubhub: { icon: VmsIcons.Grubhub, color: '#BA0000' },
  TheFork: { icon: VmsIcons.TheFork, color: '#4ce33a' },
  Resy: { icon: VmsIcons.Resy, color: '#EA4335' },
}

export const BrandPNGMap = {
  bookatable: 'bookatable.png',
  openrice: 'openrice.png',
}

const getReviewSitePNG = (mediaURL, pngFileName) => `${window.location.origin}${mediaURL}images/${pngFileName}`
export const getBrandPNGSrcMap = mediaURL => _.mapValues(BrandPNGMap, value => getReviewSitePNG(mediaURL, value))
export const getReviewSitePNGWithoutMediaURL = reviewSite => getBrandPNGSrcMap(Pmp.Settings.MEDIA_URL)[reviewSite]

export const BRAND_MAPPER = {
  opentable: 'OpenTable',
  opentableusa: 'OpenTable',
  google: 'Google',
  facebook: 'Facebook',
  tripadvisor: 'TripAdvisor',
  yelp: 'Yelp',
  sevenrooms: 'SevenRooms',
  bookatable: 'Bookatable',
  openrice: 'OpenRice',
  grubhub: 'Grubhub',
  thefork: 'TheFork',
  resy: 'Resy',
}

export const BRAND_MAPPER_NAMES = {
  grubhub: 'Grubhub/Seamless',
}

// Pass in a BrandMap
const BrandIconComponentFactory = IconClass => {
  const CenterIconClass = styled(IconClass)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `

  return ({ company, pngSrc }) => {
    if (pngSrc) {
      const PNGIconClass = styled.div`
        background-image: url('${pngSrc}');
        background-size: cover;
        width: 1em;
        height: 1em;
      `
      return (
        <CenterIconClass>
          <PNGIconClass />
        </CenterIconClass>
      )
    }
    return (
      <CenterIconClass>
        <div style={{ color: company.color }}>{VmsIcons.Oval}</div>
        <InnerIcon>{company.icon}</InnerIcon>
      </CenterIconClass>
    )
  }
}

export const BrandVmsIconXXS = BrandIconComponentFactory(StyledVmsIconXXS)
export const BrandVmsIconXS = BrandIconComponentFactory(StyledVmsIconXS)
export const BrandVmsIconS = BrandIconComponentFactory(StyledVmsIconS)
export const BrandVmsIconM = BrandIconComponentFactory(StyledVmsIconM)
export const BrandVmsIconL = BrandIconComponentFactory(StyledVmsIconL)
