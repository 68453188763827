import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import commonPaymentReducer from 'svr/component-lib/Widget/Payments/reducer'
import * as upsells from 'widget/modules/upsells'
import { fetchValidDates, fetchExperienceValidDates } from '../actions/epics'
import {
  fetchInitialAvailability,
  fetchMultiVenueAvailability,
  fetchAdditionalAvailability,
  findWhenAvailabilityComplete,
  releaseReservationHoldOnRevertStage,
} from '../App/epics/availability'
import availabilityLookup from '../App/reducers/availabilityLookup'
import searchResults from '../SearchResults/reducer'
import availability from './availabilityReducer'
import confirmation from './confirmationReducer'
import experienceReducer from './experienceReducer'
import formFields from './formFieldsReducer'
import { landingPage } from './landingPageReducer'
import languageReducer from './languageReducer'
import modifyReservation from './modifyReservationReducer'
import paymentReducer from './paymentReducer'
import preloadedState from './preloadedStateRecucer'
import promoCode from './promoCodeReducer'
import { redemptionReducer } from './redemptionReducer'
import reservationHold from './reservationHoldReducer'
import reservationRequestReducer from './reservationRequestReducer'
import search from './searchReducer'
import shift from './shiftReducer'
import tags from './tagsReducer'
import ui from './uiReducer'
import user from './userReducer'
import { venueInfo, venueEntities } from './venueInfoReducer'
import waitlist from './waitlistReducer'

export const rootEpic = combineEpics(
  fetchValidDates,
  fetchExperienceValidDates,
  fetchInitialAvailability,
  fetchMultiVenueAvailability,
  fetchAdditionalAvailability,
  findWhenAvailabilityComplete,
  releaseReservationHoldOnRevertStage
)

export const rootReducer = combineReducers({
  // Constant state
  app: (state = {}) => state,
  availabilityLookup,
  clientInfo: (state = {}) => state,
  venueInfo,
  venueEntities,
  widgetSettings: (state = {}) => state,
  theme: (state = {}) => state,
  featureFlags: (state = {}) => state,

  // Mutable state
  user,
  formFields,
  ui,
  preloadedState,
  search,
  availability,
  landingPage,
  searchResults,
  reservationHold,
  modifyReservation,
  redemption: redemptionReducer,
  experience: experienceReducer,
  reservationRequest: reservationRequestReducer,
  // Imported modules
  [upsells.constants.MODULE_NAME]: upsells.reducer,
  // Entities stores state object models
  entities: combineReducers({
    tags,
    shift,
    promoCode,
  }),
  waitlist,
  languages: languageReducer,
  payment: paymentReducer, // should be deleted later
  commonPayment: commonPaymentReducer,
  confirmation,
})
