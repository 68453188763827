const getAvailabilityUrl = origin => `${origin}/api-yoa/availability/widget/range`

export const getAvailabilityUrlWithParams = (
  origin,
  venueKey,
  partySize,
  queryMoment,
  timeHalo,
  numDays,
  isModifyResMode,
  actualId,
  experienceId,
  pickedDuration, // For duration picker widgets only
  selectedLanguage
) => {
  const path = getAvailabilityUrl(origin)
  const timeSlot = experienceId ? '12:00' : queryMoment.format('HH:mm')
  const startDate = queryMoment.format('MM-DD-Y')
  const { channel, clientId } = widgetInit
  const haloSizeInterval = timeHalo / 15
  const actualIdParam = isModifyResMode ? `&actual_id=${actualId}` : ''
  const experienceIdParam = experienceId ? `&experience_id=${experienceId}` : ''
  const selectedLanguageParam = selectedLanguage ? `&selected_lang_code=${selectedLanguage}` : ''
  let queryParams =
    `?venue=${venueKey}&time_slot=${timeSlot}&party_size=${partySize}` +
    `&halo_size_interval=${haloSizeInterval}&start_date=${startDate}` +
    `&num_days=${numDays}&channel=${channel}` +
    `${actualIdParam}${experienceIdParam}${selectedLanguageParam}`

  if (clientId) {
    queryParams += `&client_id=${clientId}`
  }
  if (pickedDuration) {
    queryParams += `&picked_duration=${pickedDuration}`
  }
  return `${path}${queryParams}`
}
