// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  children: Array<React.Node>,
  customListWrapper?: Array<string | (() => mixed)>,
  customBorder?: Array<string | (() => mixed)>,
} */

const WidgetList = (props /* : Props */) => (
  <ListWrapper customListWrapper={props.customListWrapper}>
    {_.reduce(
      props.children,
      (componentArray, child, index) => {
        const concatArgs = index === 0 ? [child] : [<Border customBorder={props.customBorder} key={`sr-widget-border-${index}`} />, child]
        return _.concat.call(this, componentArray, ...concatArgs)
      },
      []
    )}
  </ListWrapper>
)

WidgetList.defaultProps = {
  customListWrapper: [],
  customBorder: [],
}

const ListWrapper = styled.div`
  ${props => props.customListWrapper};
`

const Border = styled.div`
  border: 1px solid black;
  ${props => props.customBorder};
`

export default WidgetList
