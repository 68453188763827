import { INITIALIZE, REVERT_STAGE, ADVANCE_STAGE, TOGGLE_FIELD, CHANGE_FIELD, DISPLAY_MODAL, DISMISS_MODAL, TRY_AGAIN } from './ActionTypes'

export const initialize = () => ({ type: INITIALIZE })

export const revertStage = clearAvailability => ({
  type: REVERT_STAGE,
  clearAvailability,
})

export const advanceStage = () => ({ type: ADVANCE_STAGE })

export const toggleField = field => ({ type: TOGGLE_FIELD, field })

export const changeField = (field, changeTo) => ({
  type: CHANGE_FIELD,
  field,
  changeTo,
})

export const toggleModalDisplay = modal => ({ type: DISPLAY_MODAL, modal })

export const dismissModal = () => ({ type: DISMISS_MODAL })

export const tryAgain = () => ({ type: TRY_AGAIN })
