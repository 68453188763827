// /* @flow */
import { combineReducers } from 'redux'
import entities from './reducers/entities'
import inventoryEdits from './reducers/inventoryEdits'
import main from './reducers/main'

export default combineReducers({
  main,
  entities,
  inventoryEdits,
})
