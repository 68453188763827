import _ from 'lodash'

export const camelCaseObject = obj => {
  if (_.isArray(obj)) {
    return obj.map(val => camelCaseObject(val))
  }
  if (!_.isObject(obj)) {
    return obj
  }
  const toCamelCase = (val, key) => _.camelCase(key)
  const mappedObj = _.mapKeys(obj, toCamelCase)
  return _.reduce(
    mappedObj,
    (accum, val, key) => {
      if (_.isObject(val)) {
        return _.assign(mappedObj, { [key]: camelCaseObject(val) })
      }
      return accum
    },
    mappedObj
  )
}

export const snakeCaseObject = item => {
  if (_.isPlainObject(item)) {
    const mappedKeys = _.mapKeys(item, (val, key) => _.snakeCase(key))
    return _.mapValues(mappedKeys, snakeCaseObject)
  }
  return item
}
