import Radium from 'radium'
import React, { Component } from 'react'
import Button from '../../../../lib/Buttons/Button'
import styles from '../../../../lib/Modal/modals'

const INPUT_LIMIT = 300
const firstElementId = 'sr-note-input-first'
const lastElementId = 'sr-note-input-last'

class NoteInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: this.props.noteText,
    }
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleSuccesClick = this.handleSuccesClick.bind(this)
  }

  componentDidMount() {
    this.textArea.focus()
  }

  handleSuccesClick() {
    this.props.onSuccess(this.state.text)
    this.props.onCancel()
  }

  handleTextChange(e) {
    this.setState({ text: e.target.value.substring(0, INPUT_LIMIT) })
  }

  render() {
    const {
      headerColor,
      headerTextColor,
      textColor,
      placeholder = 'Leave a note...',
      saveButtonLabel = 'Save',
      cancelButtonLabel = 'Cancel',
    } = this.props
    return (
      <div style={styles.modalWidth} data-test="sr-guest-notes-modal">
        <div style={[styles.headerLine, { backgroundColor: headerColor }]}>
          <Button
            buttonId={firstElementId}
            innerElement={cancelButtonLabel}
            onClick={this.props.onCancel}
            activeStyling
            style={{
              ...styles.smallFont,
              ...styles.floatLeft,
              color: headerTextColor,
            }}
            testId="sr-guest-notes-cancel-button"
          />
          <Button
            innerElement={saveButtonLabel}
            onClick={this.handleSuccesClick}
            activeStyling
            style={{
              ...styles.smallFont,
              ...styles.floatRight,
              color: headerTextColor,
            }}
            testId="sr-guest-notes-save-button"
          />
        </div>
        <div style={[styles.seperator, { color: headerTextColor }]} />
        <div style={styles.textAreaWrapper}>
          <textarea
            id={lastElementId}
            style={[styles.textArea, { color: textColor }]}
            value={this.state.text}
            onChange={this.handleTextChange}
            placeholder={placeholder}
            ref={input => {
              this.textArea = input
            }}
          />
        </div>
      </div>
    )
  }
}

NoteInput.propTypes = {
  noteText: React.PropTypes.string.isRequired,
  onSuccess: React.PropTypes.func.isRequired,
  onCancel: React.PropTypes.func.isRequired,
  headerColor: React.PropTypes.string,
  headerTextColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
}

NoteInput.defaultProps = {
  headerColor: 'rgba(255, 255, 255, 1.00)',
  headerTextColor: 'rgba(50, 70, 84, 1.00)',
  textColor: 'rgba(50, 70, 84, 1.00)',
}

NoteInput.firstElementId = firstElementId
NoteInput.lastElementId = lastElementId

export default Radium(NoteInput)
