const styles = {
  inline: {
    display: 'inline-block',
  },
  floatLeft: {
    float: 'left',
    clear: 'left',
  },
  floatRight: {
    float: 'right',
    clear: 'right',
  },
  tinyFont: {
    fontSize: '11px',
    fontStyle: 'italic',
    marginBottom: '2px',
  },
  doublePadLeft: {
    marginLeft: '4px',
  },
  checkoutWrapper: {
    width: '100%',
    height: '100%',
  },
  formWrapper: {
    width: '100%',
    paddingBottom: '13px',
    outline: 'none',
  },
  sectionWrapper: {
    width: '89.33333%',
    margin: '0 auto',
    paddingTop: '45px',
  },
  labelWrapper: {
    textAlign: 'center',
    width: 'auto',
    lineHeight: '19px',
    margin: '0px 30px 10px',
    paddingTop: '1px',
  },
  alertMeHeader: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'block',
    fontSize: '14px',
    textAlign: 'center',
    width: 'auto',
  },
  sectionLabel: {
    fontSize: '16px',
    fontWeight: 500,
  },
  infoForm: {
    borderRadius: '8px',
    boxShadow: '0px 0px 12px -2px rgba(0,0,0,0.39)',
  },
  formLine: {
    width: '100%',
    height: '46px',
    display: 'table',
  },
  formBox: {
    height: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '50%',
  },
  smallFormThreeBox: {
    width: '33%',
  },
  smallFormFourBox: {
    width: '25%',
  },
  firstNameInput: {
    padding: '0px 2% 0px 8.35392%',
    overflow: 'auto',
  },
  lastNameInput: {
    padding: '0px 2% 0px 8.35392%',
    overflow: 'auto',
  },
  birthdayInput: {
    padding: '0 3.69880% 0 4.17910%',
    borderRadius: '0 0 8px 8px',
    overflow: 'auto',
  },
  cardInput: {
    padding: '2% 4.5% 2% 4.5%',
    height: '46px',
  },
  stripePaymentElement: {
    padding: '2.5% 2% 2.5% 2%',
    borderRadius: '8px 8px 8px 8px',
  },
  singleFieldInput: {
    padding: '0 3.69880% 0 4.17910%',
  },
  startTimeSelect: {
    borderRadius: '0 0 0 8px',
    whiteSpace: 'nowrap',
  },
  endTimeSelect: {
    borderRadius: '0 0 8px 0',
    paddingBottom: '4px',
  },
  topBorderRadius: {
    borderRadius: '8px 8px 0 0',
  },
  bottomBorderRadius: {
    borderRadius: '0 0 8px 8px',
  },
  selectorBorderRadius: {
    borderRadius: '8px 8px 8px 8px',
  },
  noBorderRadius: {
    borderRadius: '0',
  },
  formLineSeperator: {
    borderStyle: 'solid',
    borderWidth: '1px',
    margin: '0',
    width: '100%',
  },
  crossedOutText: {
    color: 'rgb(154, 154, 154)',
    textDecoration: 'line-through',
    fontStyle: 'italic',
  },
  thirdBox: {
    paddingLeft: '12.532%',
    overflow: 'auto',
  },
  addButton: {
    display: 'inline-block',
    float: 'right',
    fontSize: '14px',
    padding: '3px 7px',
    height: '25px',
    marginTop: '-2px',
    cursor: 'pointer',
  },
  summaryWrapper: {
    width: '89.33333%',
    margin: '0 auto',
    paddingTop: '20px',
  },
  summaryLine: {
    width: '100%',
    padding: '11px 4.17910% 11px 3.28358%',
    overflow: 'auto',
  },
  buttonLine: {
    border: '0',
    fontFamily: 'Avenir, Roboto, sans-serif',
    fontWeight: '400',
  },
  lineIcon: {
    fontSize: '23px',
    display: 'inline-block',
    float: 'left',
  },
  summaryText: {
    display: 'inline-block',
    fontSize: '14px',
    float: 'left',
    lineHeight: '23px',
    paddingLeft: '10px',
    maxWidth: '85%',
  },
  adjustedText: {
    maxWidth: '65%',
  },
  priceBreakdown: {
    width: '100%',
    padding: '12px 4.17910% 16px 4.47761%',
    overflow: 'auto',
  },
  breakdown: {
    height: '21px',
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  upsellbreakdown: {
    display: 'flex',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  lastBreakdown: {
    height: '29px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.5',
    paddingTop: '8px',
  },
  policySection: {
    width: '100%',
    padding: '16px 4.17910% 5px 4.47761%',
    '@media (max-width: 320px)': {
      padding: '16px 2.1791% 5px 2.1791%',
    },
  },
  policy: {
    width: '100%',
    marginBottom: '14px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  policyLine: {
    display: 'inline-block',
    fontSize: '13px',
    cursor: 'pointer',
    maxWidth: '75%',
  },
  infoIcon: {
    fontSize: '23px',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  buttonInfoIcon: {
    backgroundColor: 'inherit',
    border: '0',
    padding: '0',
  },
  recaptcha: {
    transform: 'scale(0.90)',
    WebkitTransform: 'scale(0.90)',
    transformOrigin: '0 0',
    WebkitTransformOrigin: '0 0',
    borderStyle: 'solid',
    height: '82px',
    width: '308px',
  },
  invalidReCaptcha: {
    transform: 'scale(0.90)',
    WebkitTransform: 'scale(0.90)',
    transformOrigin: '0 0',
    WebkitTransformOrigin: '0 0',
    borderStyle: 'solid',
    borderColor: 'red',
    height: '82px',
    width: '308px',
  },
  footerText: {
    width: '100%',
    padding: '7px 5.35828% 10px',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '1.8',
    textAlign: 'left',
  },
  invalidPhoneNumber: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgb(243, 55, 107)',
    borderCollapse: 'separate',
  },
  invalidTime: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgb(243, 55, 107)',
    borderRadius: '0 0 8px 8px',
    borderCollapse: 'separate',
  },
  invalidTimeIcon: {
    color: 'rgb(243, 55, 107)',
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
  },
  buttonWrapper: {
    paddingBottom: '80px',
  },
  submitButton: {
    width: '92.26666%',
    height: '50px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '0 4%',
    padding: '15px 0',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '1px',
    color: 'white',
    cursor: 'pointer',
    borderWidth: '0px',
    fontFamily: 'Roboto',
    ':focus': {
      opacity: '0.8',
    },
  },
  selectedTagdisplay: {
    width: '78%',
    marginLeft: '39px',
    float: 'left',
    clear: 'left',
  },
  miniTag: {
    fontSize: '11px',
    height: '17px',
    lineHeight: '15px',
    padding: '0 4px',
    margin: '0px 5px 5px 0px',
    display: 'inline-block',
    border: '1px solid transparent',
    borderRadius: '8.5px',
    color: 'white',
  },
  shiftUp: {
    marginTop: '-2px',
  },
  activeLine: {
    cursor: 'pointer',
    ':active': {
      backgroundColor: 'rgba(199, 226, 241, 1)',
    },
  },
  switch: {
    float: 'right',
  },
  noteDisplay: {
    width: '100%',
    float: 'left',
    fontSize: '13px',
    fontStyle: 'italic',
    padding: '6px 39px',
    wordWrap: 'break-word',
  },
  requestFieldItem: {
    height: '100%',
    lineHeight: '46px',
  },
  shiftLeft: {
    marginLeft: '-2px',
  },
  requestButtonMargin: {
    marginTop: '40px',
  },
  requestLine: {
    padding: '0px 4.1791%',
    position: 'relative',
    width: '100%',
    // overflow: 'auto',
    height: '46px',
  },
  requestInputItem: {
    zIndex: '1',
    height: 'auto',
    width: 'auto',
    position: 'relative',
    borderSize: '0px',
    borderStyle: 'none',
    paddingLeft: '10px',
    pointerEvents: 'none',
    display: 'inline-block',
  },
  requestNotePadding: {
    padding: '0px 39px 12px',
  },
  requestItemPicker: {},
  hiddenInput: {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    zIndex: '-99999',
    opacity: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50px',
    fontSize: '20px',
  },
  promoCodeLine: {
    width: '100%',
    padding: '0 4.17910% 0 3.28358%',
    overflow: 'auto',
  },
  promoCodeField: {
    paddingLeft: '38px',
    height: '45px',
    display: 'inline-block',
    maxWidth: '73%',
  },
  promoCodeButton: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  promoDiscount: {
    maxWidth: '78%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  checkIcon: {
    width: '18px',
    height: '18px',
    fontSize: '18px',
    display: 'block',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    opacity: '0.7',
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'inherit',
    marginRight: '3.26784%',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    padding: '0',
  },
  readOnlyInputText: {
    fontStyle: 'italic',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  phoneNumberInputContainer: {
    display: 'block',
    verticalAlign: 'middle',
  },
  languageBox: {
    padding: '5px 3.6988% 5px 2.1791%',
    marginTop: '2px',
    bordeRradius: '5px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  languageDropDownLabel: {
    fontSize: '10px',
    color: '#9a9a9a',
    marginBottom: '5px',
    paddingLeft: '10px',
  },
  tableCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  consentToNotifications: {
    color: 'white',
  },
  stripeIframe: {
    fontFamily: 'Avenir, Roboto, sans-serif',
    fontSizeBase: '14px',
    fontSize: '15px',
    lineHeight: '46px',
  },
  calendarStar: {
    width: '29px',
    marginLeft: '-2px',
  },
  offerName: {
    display: 'flex',
  },
}

export default styles
