import Radium from 'radium'
import React from 'react'
import styles from './styles'

const firstElementId = 'sr-policy-popover-first'
const lastElementId = firstElementId

const ErrorDisplay = ({ errorMessage, actionText, dismissModal, errorColor, okMessage, textPaylinkPageUnableToProcess }) => (
  <div style={[styles.body, { maxWidth: '400px' }]}>
    <span style={[styles.text, { color: errorColor, padding: '40px 0px' }]}>{errorMessage || textPaylinkPageUnableToProcess}</span>
    <button id={firstElementId} style={[styles.okButton, { backgroundColor: errorColor }]} onClick={dismissModal}>
      {okMessage || actionText}
    </button>
  </div>
)

ErrorDisplay.propTypes = {
  errorMessage: React.PropTypes.string.isRequired,
  actionText: React.PropTypes.string.isRequired,
  dismissModal: React.PropTypes.func.isRequired,
  errorColor: React.PropTypes.string,
}

ErrorDisplay.defaultProps = {
  errorColor: 'red',
  actionText: 'Ok',
}

ErrorDisplay.firstElementId = firstElementId
ErrorDisplay.lastElementId = lastElementId

export default Radium(ErrorDisplay)
