import Radium from 'radium'
import React, { Component } from 'react'
import styles from '../../../../lib/Modal/modals'

class Tag extends Component {
  onClick = () => {
    this.props.onClick(this.props.tagName)
  }

  render() {
    const { tagId, label, isSelected, colorPrimary, colorSecondary } = this.props
    return (
      <button
        onClick={this.onClick}
        id={tagId}
        style={[
          styles.tag,
          {
            backgroundColor: colorSecondary,
            borderColor: colorSecondary,
            color: colorPrimary,
          },
        ]}
      >
        <span>{label}</span>
        <div style={[styles.circle, isSelected ? { backgroundColor: colorPrimary } : { backgroundColor: 'transparent' }]}>
          <i
            style={isSelected ? { color: colorSecondary } : { color: 'transparent' }}
            className="fa fa-check-alt fa-fw"
            aria-hidden="true"
          />
        </div>
      </button>
    )
  }
}

Tag.propTypes = {
  tagName: React.PropTypes.string.isRequired,
  label: React.PropTypes.string.isRequired,
  onClick: React.PropTypes.func.isRequired,
  isSelected: React.PropTypes.bool,
  colorPrimary: React.PropTypes.string,
  colorSecondary: React.PropTypes.string,
}

Tag.defaultProps = {
  isSelected: false,
  colorPrimary: 'rgba(185, 243, 243, 1.00)',
  colorSecondary: 'rgba(50, 70, 84, 1.00)',
}

export default Radium(Tag)
