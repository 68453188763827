import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { SegmentedBar } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/ChannelSpecificData'
import { toggleModalDisplay } from 'widget/dining/actions/navigation'
import { tryGetWaitlistEtas, tryGetWaitlistEntry, tryCancelWaitlistEntry, rejoinWaitlist } from 'widget/dining/actions/services'
import { VenueBanner } from 'widget/dining/components/Banner'
import { selectCalculatedStrings, selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import styles from '../assets/styles/checkout'
import searchStyles from '../assets/styles/search'
import { modalTypes } from '../utils/constantTypes'
import { parseBoolean } from 'widget/dining/utils/preloadedState'

const WaitingBar = styled(SegmentedBar)`
  @media (max-width: 400px) {
    min-width: auto;
  }
`

const WaitingRoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.colorWidgetBackground};
`

const WaitingIconContainer = styled.div`
  text-align: center;
`

const SocialIconContainer = styled.a`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.fontsColorPrimary};
  border-radius: 100%;
  color: ${props => props.fontsColorPrimary};
  margin: 0 10px;
`

const QueueContainer = styled.div`
  position: relative;
  margin: 0 0 24px 0;
  padding-top: 24px;
`

const QueueTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
  display: flex;
  text-align: center;
  padding: 0 20px;
`

const QueueCaption = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  padding-top: 10px;
`

const TitleContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`

const ActionButton = styled.button`
  padding: 8px 12px;
  background-color: ${props => props.colorPrimary};
  color: ${props => props.fontsColorButton};
  border: 1px solid #7f634f;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.3s;
  margin: 25px 0 30px;

  &:hover {
    opacity: 0.7;
  }
`

const WaitingRoomSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  color: ${props => props.fontsColorPrimary};
`

const WaitingRoomSummaryView = styled.div`
  border-radius: 10px;
  width: 80%;
  background-color: ${props => props.colorCheckoutCellBackground};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 16px;
  justify-content: center;
  align-items: center;

  &:first-child {
    border-right: 1px solid #f3f3f3;
  }
`

export const InfoTitle = styled.div`
  color: #b6b6b6;
  font-size: 12px;
  margin-bottom: 16px;
`

export const InfoData = styled.div`
  font-size: 22px;
  font-weight: 500;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f3f3f3;
`

const DetailContainer = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`

export const Icon = styled.i`
  font-size: 23px !important;

  ${props => props.color && `color: ${props.color};`}
  ${props => props.bold && `font-weight: bold !important;`}
  ${props => props.fontSize && `font-size: ${props.fontSize} !important;`}
`
const DetailText = styled.div`
  flex: 1;
  margin-left: 16px;
`

const LinkButton = styled.button`
  font-family: ${props => props.fontFamily};
  font-size: 16px;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
`

const Detail = ({ detail, iconColor }) => (
  <LinkButton
    onClick={() => (detail.targetTop ? (window.top.location.href = detail.actionLink) : (window.location.href = detail.actionLink))}
    style={{ textDecoration: 'none', color: iconColor }}
  >
    <DetailContainer>
      <Icon fontSize="23px" className={detail.iconClass} />
      <DetailText>{detail.text}</DetailText>
      <Icon bold fontSize="35px" color={iconColor} className="fa fa-angle-right fa-fw" />
    </DetailContainer>
  </LinkButton>
)

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
`

const StatusContainer = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`

const WAITLIST_STATUS = {
  WAITING: 'Waiting',
  JOINED_ONLINE: 'Joined Online',
  AWAITING_CONFIRMATION: 'Awaiting Confirmation',
  TABLE_READY: 'Table Ready',
  CONFIRMED: 'Confirmed',
  CANCELED: 'Canceled',
  SEATED: 'Seated',
  REMOVED: 'Removed',
  NO_SHOW: 'No Show',
}

const QueueContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`

class WaitingRoom extends React.PureComponent {
  constructor(props) {
    super(props)

    this.toggleSpecialAttentionMessage = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_ATTENTION_MESSAGE)
  }

  componentDidMount() {
    const { waitlist, tryGetWaitlistEntry } = this.props

    if (waitlist.id) {
      tryGetWaitlistEntry(waitlist.id)
    }
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
  }

  getWaitlistStatusText(status, waitlistProps) {
    let statusText
    switch (status) {
      case WAITLIST_STATUS.AWAITING_CONFIRMATION:
        statusText = waitlistProps.textWaitlistAwaitingConfirmation
        break
      case WAITLIST_STATUS.TABLE_READY:
        statusText = waitlistProps.textWaitlistTableReady
        break
      case WAITLIST_STATUS.SEATED:
        statusText = waitlistProps.textWaitlistSeated
        break
      case WAITLIST_STATUS.CANCELED:
        statusText = waitlistProps.textWaitlistCanceled
        break
      case WAITLIST_STATUS.REMOVED:
        statusText = waitlistProps.textWaitlistRemoved
        break
      case WAITLIST_STATUS.NO_SHOW:
        statusText = waitlistProps.textWaitlistNoShow
        break
      default:
        statusText = waitlistProps.textDefaultStatus
    }
    return statusText
  }

  render() {
    const details = []
    if (this.props.showReservationLink) {
      details.push({
        iconClass: 'fa fa-book-line fa-fw',
        text: this.props.textMakeReservation,
        targetTop: false,
        actionLink: this.props.resWidgetLink,
      })
    }
    if (this.props.showMenu) {
      details.push({
        iconClass: 'fa fa-forkknife-line fa-fw',
        text: this.props.textViewMenu,
        targetTop: false,
        actionLink: this.props.menuLink,
      })
    }
    if (this.props.showMap) {
      details.push({
        iconClass: 'fa fa-map-marker fa-fw',
        text: this.props.textViewMap,
        targetTop: true,
        actionLink: this.props.gmapsLink,
      })
    }
    if (this.props.contactUs !== 'HIDE') {
      details.push({
        iconClass: 'fa fa-phone-line fa-fw',
        text: this.props.textContactUs,
        targetTop: true,
        actionLink: this.props.contactUs === 'CALL' ? `tel:${this.props.phoneNumber}` : `mailto:${this.props.email}`,
      })
    }
    const { venue, waitlist, isVenueOpen, isWaitlistOpen } = this.props
    const waitlist_status = waitlist.status

    return (
      <WaitingRoomContainer colorWidgetBackground={this.props.colorWidgetBackground}>
        {this.props.showVenueBanner && (
          <VenueBanner fontsColorSummaryBar={this.props.fontsColorSummaryBar} colorSummaryBar={this.props.colorSummaryBar}>
            {venue.name}
          </VenueBanner>
        )}
        {this.props.showWaitingRoomSpecialAttentionMessage && (
          <div
            style={{
              ...searchStyles.viewRow,
              backgroundColor: this.props.colorActionBarBackground,
              color: this.props.fontsColorActionBar,
              fontSize: '13px',
              borderBottomColor: this.props.colorLines,
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span>{this.props.waitlistWidgetSpecialAttentionLabel}</span>
              <button
                type="button"
                style={{ color: this.props.fontsColorActionBar, ...searchStyles.infoIcon, ...searchStyles.buttonInfoIcon }}
                onClick={this.toggleSpecialAttentionMessage}
                className="fa fa-info-circle"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        <WaitingRoomSummaryContainer fontsColorPrimary={this.props.fontsColorPrimary}>
          <WaitingRoomSummaryView style={styles.infoForm} colorCheckoutCellBackground={this.props.colorCheckoutCellBackground}>
            {!isVenueOpen && !waitlist.id ? (
              <TitleContainer style={{ marginBottom: '25px' }}>
                <QueueTitle ref={comp => (this.initialFocusComponent = comp)}>{this.props.textVenueClosed}</QueueTitle>
                <QueueCaption>{this.props.textVenueClosedCaption}</QueueCaption>
              </TitleContainer>
            ) : !isWaitlistOpen && !waitlist.id ? (
              <StatusContainer>
                <QueueTitle ref={comp => (this.initialFocusComponent = comp)}>{this.props.textWaitlistUnavailable}</QueueTitle>
              </StatusContainer>
            ) : waitlist_status === WAITLIST_STATUS.WAITING || waitlist_status === WAITLIST_STATUS.JOINED_ONLINE ? (
              <div>
                <QueueContainer>
                  <QueueContent>
                    <WaitingIconContainer>
                      <Icon
                        className="fa fa-check"
                        fontSize="40px"
                        style={{
                          padding: '5px',
                          border: '3px solid #00C08E',
                          borderRadius: '100%',
                          color: '#00C08E',
                        }}
                      />
                    </WaitingIconContainer>
                    <TitleContainer>
                      <QueueTitle ref={comp => (this.initialFocusComponent = comp)}>
                        {this.props.hideQuoteTimes ? this.props.textHideQuoteTimeArrivalMessage : this.props.arriveByFormatted}
                      </QueueTitle>
                      <QueueCaption>
                        {this.props.textForYourPartyOf} {waitlist.party_size} {this.props.textFor} {venue.name}
                      </QueueCaption>
                    </TitleContainer>
                    <ActionButton
                      onClick={this.props.tryCancelWaitlistEntry}
                      fontsColorButton={this.props.fontsColorButton}
                      colorPrimary={this.props.colorPrimary}
                    >
                      {this.props.textLeaveWaitlist}
                    </ActionButton>
                  </QueueContent>
                </QueueContainer>
              </div>
            ) : (
              <QueueContainer>
                <QueueContent>
                  <QueueTitle ref={comp => (this.initialFocusComponent = comp)}>
                    {this.getWaitlistStatusText(waitlist_status, this.props)}
                  </QueueTitle>
                  {(waitlist_status === WAITLIST_STATUS.CANCELED ||
                    waitlist_status === WAITLIST_STATUS.REMOVED ||
                    waitlist_status === WAITLIST_STATUS.NO_SHOW) && (
                    <ActionButton
                      onClick={this.props.rejoinWaitlist}
                      fontsColorButton={this.props.fontsColorButton}
                      colorPrimary={this.props.colorPrimary}
                    >
                      {this.props.textButton}
                    </ActionButton>
                  )}
                </QueueContent>
              </QueueContainer>
            )}
            {(details.length > 0 || this.props.facebookLink || this.props.twitterLink || this.props.instagramLink) && (
              <div>
                <DetailsContainer>
                  {details.map((detail, index) => (
                    <Detail key={index} detail={detail} iconColor={this.props.fontsColorPrimary} />
                  ))}
                </DetailsContainer>
                <SocialIconsContainer>
                  {this.props.facebookLink && (
                    <SocialIconContainer target="_top" href={this.props.facebookLink} fontsColorPrimary={this.props.fontsColorPrimary}>
                      <Icon className="fa fa-facebook" fontSize="20px" style={{ padding: '10px 0 0 13px' }} />
                    </SocialIconContainer>
                  )}
                  {this.props.twitterLink && (
                    <SocialIconContainer target="_top" href={this.props.twitterLink} fontsColorPrimary={this.props.fontsColorPrimary}>
                      <Icon className="fa fa-twitter" fontSize="20px" style={{ padding: '10px 0 0 10px' }} />
                    </SocialIconContainer>
                  )}
                  {this.props.instagramLink && (
                    <SocialIconContainer target="_top" href={this.props.instagramLink} fontsColorPrimary={this.props.fontsColorPrimary}>
                      <Icon className="fa fa-social-instagram" fontSize="20px" style={{ padding: '10px 0 0 12px' }} />
                    </SocialIconContainer>
                  )}
                </SocialIconsContainer>
              </div>
            )}
          </WaitingRoomSummaryView>
        </WaitingRoomSummaryContainer>
      </WaitingRoomContainer>
    )
  }
}

const mapStateToProps = state => {
  const waitlist = state.waitlist.toJS()
  const waitTimeBuffer = parseInt(state.widgetSettings.waitTimeBuffer, 10)
  const minimumWaitShown = parseInt(state.widgetSettings.minimumWaitShown, 10)
  const currentVenueTimeLocalized = state.search.get('venueToday')
  const currentVenueTime = moment(currentVenueTimeLocalized.format('LLLL'))
  const { showArrivalTimeType } = state.widgetSettings
  const timeFormat = state.venueInfo.locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
  const arriveByTime = moment(waitlist.start_wait_time).add(waitlist.quoted_wait_time, 'minutes')
  const languageStrings = selectLanguageStrings(state)
  const calculatedStrings = selectCalculatedStrings(state)
  const { selectedLanguage } = state.languages
  let arriveByFormatted
  if (showArrivalTimeType === 'TIME') {
    const arriveByEndRange = moment(waitlist.start_wait_time).add(waitlist.quoted_wait_time + waitTimeBuffer, 'minutes')
    arriveByFormatted = `${languageStrings.waitlistTextArriveBy} ${arriveByTime.format(timeFormat)}`
    if (waitTimeBuffer) {
      arriveByFormatted += ` - ${arriveByEndRange.format(timeFormat)}`
    }
  } else {
    const remainingWaitTimeMins = arriveByTime.diff(currentVenueTime, 'minutes')
    if (remainingWaitTimeMins > minimumWaitShown) {
      arriveByFormatted = `${languageStrings.waitlistTextArriveByMinutes} ${remainingWaitTimeMins}`
      if (waitTimeBuffer) {
        arriveByFormatted += ` - ${remainingWaitTimeMins + waitTimeBuffer}`
      }
    } else {
      arriveByFormatted = `${languageStrings.waitlistTextArriveByMinutes} ${state.widgetSettings.minimumWaitShown}`
      if (waitTimeBuffer) {
        arriveByFormatted += ` - ${parseInt(state.widgetSettings.minimumWaitShown, 10) + waitTimeBuffer}`
      }
    }
    arriveByFormatted += ` ${languageStrings.waitlistWidgetMinsText || 'mins'}`
  }
  return {
    venue: state.venueInfo,
    waitlist,
    isVenueOpen: waitlist.isVenueOpen,
    isWaitlistOpen: waitlist.isWaitlistOpen,
    arriveByFormatted,
    showVenueBanner: state.widgetSettings.showVenueBanner,
    fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
    fontFamily: state.widgetSettings.font,
    colorLines: state.widgetSettings.colorLines,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    textWaitlistCanceled: languageStrings.waitlistWidgetWaitlistCanceledText,
    textWaitlistRemoved: languageStrings.waitlistWidgetWaitlistRemovedText,
    textWaitlistNoShow: languageStrings.waitlistWidgetWaitlistNoShowText,
    textWaitlistAwaitingConfirmation: languageStrings.waitlistWidgetWaitlistAwaitingConfText,
    textWaitlistTableReady: languageStrings.waitlistWidgetWaitlistTableReadyText,
    textWaitlistSeated: calculatedStrings.waitlistWidgetWaitlistSeatedText,
    textButton: languageStrings.waitlistTextButton,
    textLeaveWaitlist: languageStrings.waitlistTextLeaveWaitlist,
    textMakeReservation: languageStrings.waitlistMakeReservationLabel,
    textViewMap: languageStrings.waitlistTextViewMap,
    textViewMenu: languageStrings.waitlistTextViewMenu,
    textContactUs: languageStrings.waitlistWidgetContactUsLabel,
    textVenueClosed: languageStrings.waitlistTextVenueClosed,
    textVenueClosedCaption: languageStrings.waitlistWidgetVenueClosedText,
    textWaitlistUnavailable: languageStrings.waitlistTextWaitlistUnavailable,
    textDefaultStatus: languageStrings.waitlistWidgetDefaultStatusText,
    textHideQuoteTimeArrivalMessage: languageStrings.waitlistTextHideQuoteTimeArrivalMessage,
    textForYourPartyOf: languageStrings.waitlistTextForYourPartyofLabel,
    textFor: languageStrings.waitlistForLabel,
    contactUs: state.widgetSettings.contactUs,
    phoneNumber: state.venueInfo.phoneNumber,
    email: state.venueInfo.email,
    menuLink: state.venueInfo.menuLink,
    gmapsLink: state.venueInfo.gmapsLink,
    facebookLink: state.venueInfo.facebookLink,
    twitterLink: state.venueInfo.twitterLink,
    instagramLink: state.venueInfo.instagramLink,
    showReservationLink: state.widgetSettings.showReservationLink,
    showMap: state.widgetSettings.showMap,
    showMenu: state.widgetSettings.showMenu,
    resWidgetLink: state.venueInfo.reservationWidgetLink || `/reservations/${state.venueInfo.urlKey}`,
    showWaitingRoomSpecialAttentionMessage: parseBoolean(state.widgetSettings.showWaitingRoomSpecialAttentionMessage),
    waitlistWidgetSpecialAttentionLabel: languageStrings.waitlistWidgetSpecialAttentionLabel,
    hideQuoteTimes: state.widgetSettings.hideQuoteTimes,
  }
}

const mapDispatchToProps = {
  tryGetWaitlistEtas,
  tryGetWaitlistEntry,
  tryCancelWaitlistEntry,
  rejoinWaitlist,
  toggleModalDisplay,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoom)
