export const masterCard = {
  display: 'inline-block',
  width: '40px',
  marginTop: '5px',
}

export const visaCard = {
  display: 'inline-block',
  width: '60px',
  marginTop: '5px',
}

export const discoverCard = {
  display: 'inline-block',
  width: '52px',
  marginTop: '5px',
}

export const amexCard = {
  display: 'inline-block',
  width: '60px',
  height: '20px',
  marginTop: '5px',
}

export const dinersClubCard = {
  display: 'inline-block',
  width: '90px',
  marginTop: '5px',
}

export const jcbCard = {
  display: 'inline-block',
  width: '33px',
  marginTop: '5px',
}

export const unknownCard = {
  display: 'none',
}

export function getCardStyle(cardType) {
  if (cardType === 'MasterCard') {
    return masterCard
  }
  if (cardType === 'Visa') {
    return visaCard
  }
  if (cardType === 'American Express') {
    return amexCard
  }
  if (cardType === 'Discover') {
    return discoverCard
  }
  if (cardType === 'Diners Club') {
    return dinersClubCard
  }
  if (cardType === 'JCB') {
    return jcbCard
  }
  return unknownCard
}
