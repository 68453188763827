// /* @flow */
import * as actionTypes from '../actionTypes'
/* import type { Action } from '../flowTypes' */

/* type MainState = {
  +upsellCategories: Array<string>,
  +selectedInventory?: string,
  +partySize?: number,
  +duration?: number,
} */

const initialState /* : MainState */ = {
  upsellCategories: [],
  selectedInventory: undefined,
  partySize: undefined,
  duration: undefined,
}

export default (state /* : MainState */ = initialState, action /* : Action */) /* : MainState */ => {
  switch (action.type) {
    case actionTypes.SELECT_TIME_SLOT:
      return {
        ...state,
        upsellCategories: action.upsellCategories,
        partySize: action.partySize,
        duration: action.duration,
        selectedInventory: undefined,
      }
    case actionTypes.CLICK_INVENTORY:
      return { ...state, selectedInventory: action.inventoryId }
    case actionTypes.INITIALIZE_UPGRADES_STATE: {
      return {
        ...state,
        partySize: action.actual.total_guests,
        duration: action.actual.duration,
        upsellCategories: action.shiftOrBookingAccessOptionalUpgradeCategoryIds,
      }
    }
    default:
      return state
  }
}
