import React from 'react'
import styled from 'styled-components'
import { WidgetButton } from '../../../../../lib/Buttons/WidgetButton'

const RequestButtonContainer = styled.div`
  position: relative;
  padding: 10px 15px 15px;
  background-color: ${props => props.theme.colorCellBackground};
`
const RequestButtonMessage = styled.div`
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  width: 100%;
  color: ${props => props.theme.fontsColorPrimary};
`

const RequestButton = styled(WidgetButton)`
  background-color: rgb(133, 21, 36);
  color: rgb(255, 255, 255);
  height: 42px;
  width: 50%;
  margin-left: 25%;
  font-size: 16px;
  letter-spacing: 0.3px;
  background-color: ${props => props.theme.colorPrimary};
  color: ${props => props.theme.fontsColorButton};
`

const SubmitRequestButton = ({ onClick, textCantFind, textSubmitRequest }) => (
  <RequestButtonContainer>
    <RequestButtonMessage>{textCantFind}</RequestButtonMessage>
    <RequestButton onClick={onClick} data-test="sr-request-button">
      {textSubmitRequest}
    </RequestButton>
  </RequestButtonContainer>
)

export default SubmitRequestButton
