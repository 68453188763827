import _ from 'lodash'
import React from 'react'

export const keyDownHandlerWrapper = (clickHandler, event) => {
  if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
    event.preventDefault()
    event.stopPropagation()
    clickHandler(event)
  }
}

export const accessibleComponentWrapper = (WrappedComponent, removeOutline = true) =>
  class extends React.PureComponent {
    state = { isFocused: false }

    onFocus = () => this.setState({ isFocused: true })

    onBlur = () => this.setState({ isFocused: false })

    onKeyDownHandler = event => keyDownHandlerWrapper(this.props.onClick, event)

    render() {
      const newStyle = _.assign(
        removeOutline ? { outlineWidth: 0 } : {},
        this.props.style,
        this.state.isFocused ? { opacity: this.props.focusOpacity || 0.75 } : {}
      )
      const newProps = _.omit(this.props, 'style', 'focusOpacity')
      return (
        <WrappedComponent onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDownHandler} style={newStyle} {...newProps} />
      )
    }
  }

const DivButtonComponent = props => <div role={props.role || 'button'} tabIndex={props.tabIndex || '0'} {...props} />

export const AccessibleDivComponent = accessibleComponentWrapper(DivButtonComponent)

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
