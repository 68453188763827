import { roundMoney } from 'svr/common/Money'

export const calcCharges = (chargeType, partySize, cost, taxRate, gratuity) => {
  const chargeBaseAmount = chargeType === 'person' ? cost * partySize : cost
  const chargeGratuityAmount = gratuity ? chargeBaseAmount * (gratuity / 100) : 0
  const chargeTaxAmount = taxRate ? chargeBaseAmount * (taxRate / 100) : 0
  const chargeAmount = (0 || chargeBaseAmount) + (0 || chargeGratuityAmount) + (0 || chargeTaxAmount)
  return {
    charge_amount: chargeAmount,
    charge_base_amount: chargeBaseAmount,
    charge_gratuity_amount: chargeGratuityAmount,
    charge_tax_amount: chargeTaxAmount,
  }
}

export const calcRoundedProduct = (x, y) => {
  const floatX = parseFloat(x)
  const floatY = parseFloat(y)
  const total = floatX * floatY
  return roundMoney(total)
}

export const calcPercentageProduct = (x, y) => calcRoundedProduct(x, parseFloat(y / 100))

export const getSubtotal = (cost, partySize, chargeType) => (chargeType === 'reservation' ? roundMoney(cost) : roundMoney(cost * partySize))

export const getTotal = (subTotal, serviceChargePercentage, taxRate, gratuityPercentage) => {
  const serviceCharge = serviceChargePercentage ? calcPercentageProduct(subTotal, serviceChargePercentage) : 0
  const preTaxTotal = roundMoney(parseFloat(subTotal) + parseFloat(serviceCharge))
  const tax = taxRate ? calcPercentageProduct(preTaxTotal, taxRate) : 0
  const tip = gratuityPercentage ? calcPercentageProduct(subTotal, gratuityPercentage) : 0
  return roundMoney(parseFloat(subTotal) + parseFloat(tax) + parseFloat(tip))
}

export const calcTotal = (subTotal, serviceChargeAmount, tax, tip) =>
  roundMoney(parseFloat(subTotal) + parseFloat(serviceChargeAmount) + parseFloat(tax) + parseFloat(tip))
