export const fetchSaferpayInitialData = venueId => {
  const url = `/api-yoa/saferpay/${venueId}/get_initial_data`
  return fetch(url, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(response => response.data)
}

export const getSaferpayToken = token => {
  if (token) {
    return Promise.resolve({ token })
  }
  return new Promise(resolve => {
    SaferpayFields.submit({
      onSuccess: evt => {
        resolve({ token: evt.token })
      },
      onError(evt) {
        console.log(evt)
        resolve({ token: null })
      },
    })
  })
}
