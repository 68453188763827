import _ from 'lodash'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { validateNotEmpty } from 'svr/common/Validators'
import Button from 'svr/lib/Buttons/Button'
import TextInput from 'svr/lib/TextInputs/TextInput'

const styles = {
  cardNumberInput: {
    padding: '0 3.69880% 0 4.17910%',
    borderRadius: '8px 0 0 0',
    overflow: 'auto',
  },
  addButton: {
    display: 'inline-block',
    float: 'right',
    fontSize: '14px',
    padding: '3px 7px',
    height: '25px',
    marginTop: '-2px',
    cursor: 'pointer',
  },
}

const FormWrapper = styled.div`
  width: 89.33333%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.39);
`

const FormLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  overflow: auto;
  color: ${props => props.fontsColorCheckoutActive};
  background-color: ${props => props.colorCheckoutCellBackground};
  border-radius: ${props => (props.isFirstLine ? '8px 8px 0 0' : '0')};
`

const FieldSeparator = styled.hr`
  border-style: solid;
  border-width: 1px;
  margin: 0;
  width: 100%;
  border-color: ${props => props.colorLines};
`

const FormBox = styled.div`
  height: 100%;
  flex-grow: 1;
  flex-basis: 40%;
  display: flex;
  align-items: center;
`

const LineActions = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  padding-right: 4.1791%;
  flex-grow: 0;
  flex-shrink: 0;
  width: 130px;
`

const AmountAvailable = styled.div`
  margin-left: 4px;
  padding: 3.6988%;
  color: ${props => props.fontsColorCheckoutActive};
  font-size: 13px;
`

const BasicButton = styled.button`
  border: none;
  cursor: pointer;
  color: ${props => props.fontsColorCheckoutInactive};
  margin-left: 10px;
  background: transparent;
`

export const RedemptionForm = ({
  isViewBalanceVisible,
  redemptionCardBalance,
  redemptionCardId,
  redemptionCardPin,
  amountToRedeem,
  formErrors,
  fontFamily,
  fontsColorCheckoutInactive,
  fontsColorCheckoutActive,
  colorError,
  colorCheckoutCellBackground,
  colorLines,
  onFormFieldChange,
  onApply,
  onViewBalance,
  onClear,
  onCancel,
  currencySymbol,
  isFormDisabled,
}) => {
  const isRequiredCardInfoProvided = !!(redemptionCardId && redemptionCardPin)
  const isViewBalanceButtonVisible = !isViewBalanceVisible && isRequiredCardInfoProvided
  const isCancelButtonVisible = !isViewBalanceVisible && !isRequiredCardInfoProvided

  const textInputProps = {
    fontFamily,
    placeholderColor: fontsColorCheckoutInactive,
    textColor: fontsColorCheckoutActive,
    errorColor: colorError,
  }

  const buttonStyles = {
    ...styles.addButton,
    color: fontsColorCheckoutActive,
    borderColor: fontsColorCheckoutActive,
    textAlign: 'center',
    lineHeight: '1em',
  }

  const onSubmitButtonClick = useCallback(() => {
    const resultAmountToRedeem = _.toNumber(amountToRedeem)

    onApply({
      redemptionCardId,
      redemptionCardPin,
      redemptionCardBalance,
      amountToRedeem: _.isNumber(resultAmountToRedeem) ? resultAmountToRedeem : 0,
    })
  }, [onApply, redemptionCardId, redemptionCardPin, amountToRedeem])

  const renderActions = () => {
    if (isViewBalanceButtonVisible) {
      return (
        <Button
          withDefaultOutline
          innerElement="View Balance"
          onClick={() => {
            onViewBalance(redemptionCardId, redemptionCardPin)
          }}
          disabled={isFormDisabled}
          activeStyling
          style={buttonStyles}
        />
      )
    } else if (isCancelButtonVisible) {
      return (
        <BasicButton onClick={onCancel} fontsColorCheckoutInactive={fontsColorCheckoutInactive}>
          Cancel
        </BasicButton>
      )
    }

    return (
      <BasicButton onClick={onClear} fontsColorCheckoutInactive={fontsColorCheckoutInactive}>
        Clear
      </BasicButton>
    )
  }

  return (
    <FormWrapper>
      <FormLine colorCheckoutCellBackground={colorCheckoutCellBackground} isFirstLine>
        <FormBox>
          <TextInput
            placeholder="Card Number *"
            ariaRequired
            value={redemptionCardId}
            isValid={!formErrors.redemptionCardId}
            onChange={value => {
              onFormFieldChange('redemptionCardId', value)
            }}
            disabled={isViewBalanceVisible || isFormDisabled}
            style={styles.cardNumberInput}
            ariaLabel="Card Number"
            inputId="sr-gift-card-number-*"
            {...textInputProps}
          />
        </FormBox>

        <FormBox>
          <TextInput
            placeholder="PIN *"
            limitType="number"
            ariaRequired
            value={redemptionCardPin}
            validator={validateNotEmpty}
            isValid={!formErrors.redemptionCardPin}
            onChange={value => {
              onFormFieldChange('redemptionCardPin', value)
            }}
            style={styles.thirdBox}
            ariaLabel="PIN"
            disabled={isViewBalanceVisible || isFormDisabled}
            {...textInputProps}
          />
        </FormBox>

        <LineActions>{renderActions()}</LineActions>
      </FormLine>

      {isViewBalanceVisible && (
        <div>
          <FieldSeparator colorLines={colorLines} />

          <FormLine colorCheckoutCellBackground={colorCheckoutCellBackground}>
            <FormBox>
              <AmountAvailable fontsColorCheckoutActive={fontsColorCheckoutActive}>
                Amount available: {currencySymbol}
                {redemptionCardBalance}
              </AmountAvailable>
            </FormBox>

            <FormBox>
              <TextInput
                placeholder="Amount to redeem *"
                type="number"
                ariaRequired
                validator={validateNotEmpty}
                value={amountToRedeem}
                isValid={!formErrors.amountToRedeem}
                onChange={value => {
                  onFormFieldChange('amountToRedeem', value)
                }}
                style={styles.thirdBox}
                ariaLabel="Amount to redeem"
                disabled={isFormDisabled}
                {...textInputProps}
              />
            </FormBox>

            <LineActions>
              <Button withDefaultOutline innerElement="Apply" onClick={onSubmitButtonClick} activeStyling style={buttonStyles} />
            </LineActions>
          </FormLine>
        </div>
      )}
    </FormWrapper>
  )
}
