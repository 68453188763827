import moment from 'moment-timezone'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Url from 'url-parse'
import {
  selectLanguageStrings,
  selectCalculatedLanguageStrings,
  selectLanguageDateFields,
  selectedOrDefaultLanguageCode,
} from 'widget/dining/selectors/languageSelectors'
import { LongDayFormat, timeFormat } from '../../../scripts/utils/constants'
import { tryRedirect } from '../actions/services'
import styles from '../assets/styles/confirmation'
import { TripAdvisorConversionPixel } from '../components/TrackingPixels'
import { FormatService } from '@sevenrooms/core/locales'

class Confirmation extends Component {
  // eslint-disable-line react/prefer-stateless-function

  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
    try {
      // eslint-disable-next-line no-eval
      window.eval(widgetInit.customSuccess)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  getClientNameWithSalutation = () => `${this.props.salutation ?? ''} ${this.props.firstName ?? ''} ${this.props.lastName ?? ''}`

  render() {
    const {
      facebookLink,
      twitterLink,
      snapchatLink,
      instagramLink,
      colorWidgetBackground,
      colorPrimary,
      fontsColorPrimary,
      fontsColorButton,
      name,
      isBookingConfirmationEnabled,
      selectedTimeSlot,
      selectedRequestTimeSlot,
      tripadvisorRefId,
      textSuccessHeader,
      textSuccessBody,
      textGuestLabel,
      textRequestReceived,
      textPriorityAlertsSuccessHeader,
      textPriorityAlertsSuccessBody,
      textYouWillBeContacted,
      textConfirmationSentTo,
      textModifySuccessHeader,
      textModifySuccessBody,
      textModifySuccessConfirmation,
      isModifyResMode,
      locale,
      email,
      firstName,
      lastName,
      partySize,
      tryRedirect,
      priorityAlertsEnabled,
      priorityAlertsPartySizeMax,
      priorityAlertsPartySizeMin,
      requestStartTime,
      requestEndTime,
      bookingConfirmationDestination,
      experienceId,
      experienceName,
    } = this.props

    this.returnUrl = null
    if (widgetInit.returnUrl) {
      const parsedUrl = new Url(decodeURIComponent(widgetInit.returnUrl), true)

      const resInfo = {
        email,
        firstName,
        lastName,
        partySize,
        selectedTimeSlot,
      }

      parsedUrl.query = { ...parsedUrl.query, ...resInfo }
      this.returnUrl = parsedUrl.toString()
    }

    if (this.returnUrl) {
      const testMalicious = this.returnUrl.split(':')[0].trim()
      if (['data', 'javascript'].includes(testMalicious)) {
        this.returnUrl = null
      }
    }

    if (widgetInit.redirect === 'direct' && this.returnUrl) {
      tryRedirect()
      document.location = this.returnUrl
      return <div style={[styles.successWraper, { backgroundColor: colorWidgetBackground }]} />
    }

    let contactText
    if (!selectedTimeSlot) {
      contactText = textYouWillBeContacted
    } else if (!isBookingConfirmationEnabled) {
      contactText = ''
    } else if (isModifyResMode) {
      contactText = textModifySuccessConfirmation
    } else {
      contactText = textConfirmationSentTo
    }
    contactText = contactText
      .replace('<email>', email)
      .replace('EMAIL', email)
      .replace('<booking_conf_destination>', bookingConfirmationDestination)
    const timeDisplayFormat = locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
    const dateFormat = LongDayFormat[locale] || LongDayFormat.default
    const isPriorityAlertsFlow =
      priorityAlertsEnabled &&
      partySize <= priorityAlertsPartySizeMax &&
      partySize >= priorityAlertsPartySizeMin &&
      requestStartTime &&
      requestEndTime &&
      !selectedTimeSlot &&
      !experienceId

    let dateText
    if (isPriorityAlertsFlow) {
      dateText = `${selectedRequestTimeSlot.format(dateFormat)} · ${requestStartTime} - ${requestEndTime}`
    } else if (selectedTimeSlot) {
      dateText = `${selectedTimeSlot.format(dateFormat)} · ${selectedTimeSlot.format(timeDisplayFormat)}`
    } else {
      dateText = `${selectedRequestTimeSlot.format(dateFormat)}·${selectedRequestTimeSlot.format(timeDisplayFormat)}`
    }
    return (
      <div style={[styles.successWraper, { backgroundColor: colorWidgetBackground }]}>
        <div style={[styles.successHeader, { backgroundColor: colorPrimary }]}>
          {selectedTimeSlot ? (
            <div>
              <span style={styles.smallConfirmText} ref={comp => (this.initialFocusComponent = comp)}>
                {isModifyResMode ? textModifySuccessHeader : textSuccessHeader}
              </span>
              <span style={styles.largeConfirmText}>{isModifyResMode ? textModifySuccessBody : textSuccessBody}</span>
            </div>
          ) : (
            <div>
              <span style={styles.smallConfirmText} ref={comp => (this.initialFocusComponent = comp)} />
              <span style={styles.largeConfirmText}>{isPriorityAlertsFlow ? textPriorityAlertsSuccessHeader : textRequestReceived}</span>
            </div>
          )}
        </div>
        <div style={styles.successBody}>
          <div style={[styles.bodyText, { color: fontsColorPrimary }]}>
            <span style={styles.confirmation}>{isPriorityAlertsFlow ? textPriorityAlertsSuccessBody : contactText}</span>
          </div>
          <div style={[styles.summaryDetails, { color: fontsColorPrimary }]}>
            <span style={styles.textLine}>{this.getClientNameWithSalutation()}</span>
            <span style={styles.textLine}>{name}</span>
            <span style={styles.textLine}>{dateText}</span>
            <span style={styles.confirmation}>{`${partySize} ${textGuestLabel}`}</span>
            {experienceName && <span style={styles.textLine}>{experienceName}</span>}
          </div>

          {widgetInit.redirect === 'button' && this.returnUrl ? (
            <div style={[styles.bodyText, { color: fontsColorPrimary }]}>
              <div style={styles.buttonWrapper}>
                <button
                  type="button"
                  style={[styles.returnToVendorButton, { backgroundColor: colorPrimary, color: fontsColorButton }]}
                  onClick={() => {
                    document.location = this.returnUrl
                  }}
                >
                  {widgetInit.returnButtonText ? widgetInit.returnButtonText : 'Return to Vendor'}
                </button>
              </div>
            </div>
          ) : null}

          <div style={[styles.followUs, { color: fontsColorPrimary }]}>
            {facebookLink || instagramLink || twitterLink || instagramLink ? <span style={styles.followText}>follow us on</span> : null}
            <div style={[styles.iconBox, { color: fontsColorPrimary }]}>
              {facebookLink ? (
                <a target="_blank" rel="noopener noreferrer" href={facebookLink}>
                  <div style={[styles.socialIcon, { color: colorPrimary, borderColor: colorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-facebook" />
                  </div>
                </a>
              ) : null}
              {instagramLink ? (
                <a target="_blank" rel="noopener noreferrer" href={instagramLink}>
                  <div style={[styles.socialIcon, { color: colorPrimary, borderColor: colorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-social-instagram" />
                  </div>
                </a>
              ) : null}
              {twitterLink ? (
                <a target="_blank" rel="noopener noreferrer" href={twitterLink}>
                  <div style={[styles.socialIcon, { color: colorPrimary, borderColor: colorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-twitter" />
                  </div>
                </a>
              ) : null}
              {snapchatLink ? (
                <a target="_blank" rel="noopener noreferrer" href={snapchatLink}>
                  <div style={[styles.socialIcon, { color: colorPrimary, borderColor: colorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-social-snapchat" />
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
        {tripadvisorRefId ? <TripAdvisorConversionPixel refId={tripadvisorRefId} /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const venueKey =
    state.ui.get('isRequest') || widgetInit.successBypass ? state.search.get('selectedVenue') : state.searchResults.get('timeSlotVenue')
  const languageCode = selectedOrDefaultLanguageCode(state)
  const successLoad = widgetInit.actualInfo
    ? {
        email: widgetInit.actualInfo.email,
        firstName: widgetInit.actualInfo.firstName,
        lastName: widgetInit.actualInfo.lastName,
        partySize: widgetInit.actualInfo.partySize,
        selectedTimeSlot: moment(widgetInit.actualInfo.selectedTimeSlot, 'YYYY-MM-DD HH:mm').locale(languageCode),
      }
    : {}

  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const languageStrings = selectLanguageStrings(state)
  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  const languageDateFields = selectLanguageDateFields(state)
  const { locale } = state.venueInfo
  const timeDisplayFormat = timeFormat[locale] || timeFormat.default

  const actual = isModifyResMode ? state.modifyReservation.get('actual') : null
  const phoneNumber = isModifyResMode ? actual.get('phone_number') : state.formFields.get('phoneNumber')
  const phoneNumberLocale = isModifyResMode ? actual.get('phone_number_locale') : state.formFields.get('phoneNumberLocale')
  const email = isModifyResMode ? actual.get('email_address_raw') : state.formFields.get('email')
  const bookingConfirmationDestination =
    state.confirmation.lastConfirmationMethod === 'SMS' ? FormatService.formatPhoneNumber(phoneNumber, phoneNumberLocale) : email
  const isBookingConfirmationEnabled = state.venueInfo.sendConfirmationsResWidgetBookings

  return {
    email,
    bookingConfirmationDestination,
    isBookingConfirmationEnabled,
    firstName: state.formFields.get('firstName'),
    lastName: state.formFields.get('lastName'),
    salutation: state.formFields.get('salutation'),
    partySize: state.search.get('partySize'),
    selectedTimeSlot: languageDateFields.selectedTimeSlot,
    selectedRequestTimeSlot: moment(state.searchResults.get('selectedRequest')),
    // venue info
    name: state.venueEntities[venueKey].venueName,
    tripadvisorRefId: state.widgetSettings.tripadvisorRefId,
    facebookLink: state.venueInfo.facebookLink,
    twitterLink: state.venueInfo.twitterLink,
    snapchatLink: state.venueInfo.snapchatLink,
    instagramLink: state.venueInfo.instagramLink,
    priorityAlertsEnabled: state.venueInfo.priorityAlertsEnabled,
    priorityAlertsPartySizeMin: state.venueInfo.priorityAlertsPartySizeMin,
    priorityAlertsPartySizeMax: state.venueInfo.priorityAlertsPartySizeMax,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    // text
    textSuccessHeader: calcLanguageStrings.textSuccessHeader,
    textSuccessBody: calcLanguageStrings.textSuccessBody,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textRequestReceived: languageStrings.textRequestReceived,
    textPriorityAlertsSuccessHeader: languageStrings.textPriorityAlertsSuccessHeader,
    textPriorityAlertsSuccessBody: languageStrings.textPriorityAlertsSuccessBody,
    requestStartTime: state.searchResults.get('selectedRequest')
      ? moment(state.searchResults.get('selectedRequest')).format(timeDisplayFormat)
      : null,
    requestEndTime: state.searchResults.get('selectedEndTimeRequest')
      ? moment(state.searchResults.get('selectedEndTimeRequest')).format(timeDisplayFormat)
      : null,
    textConfirmationSentTo: calcLanguageStrings.textConfirmationSentTo,
    textYouWillBeContacted: languageStrings.textYouWillBeContacted,
    textModifySuccessHeader: languageStrings.textModifySuccessHeader,
    textModifySuccessBody: languageStrings.textModifySuccessBody,
    textModifySuccessConfirmation: languageStrings.textModifySuccessConfirmation,
    isModifyResMode,
    locale: state.venueInfo.locale,
    ...successLoad,
    experienceId: state.experience.get('id'),
    experienceName: state.experience.get('isExperienceMode') ? state.experience.get('experience').get('name') : null,
  }
}

const mapDispatchToProps = dispatch => ({
  tryRedirect: () => {
    dispatch(tryRedirect())
  },
})

Confirmation = connect(mapStateToProps, mapDispatchToProps)(Radium(Confirmation))

export default Confirmation
