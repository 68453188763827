// /* @flow */
export const MODULE_NAME = 'upsells'

export const maxQuantityTypes = {
  UNLIMITED: 'UNLIMITED',
  NUMBER_OF_GUESTS: 'NUMBER_OF_GUESTS',
  SPECIFIC_NUMBER: 'SPECIFIC_NUMBER',
}
/* export type MaxQuantityTypes = 'UNLIMITED' | 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER' */

export const minQuantityTypes = {
  NONE_REQUIRED: 'NONE_REQUIRED',
  NUMBER_OF_GUESTS: 'NUMBER_OF_GUESTS',
  SPECIFIC_NUMBER: 'SPECIFIC_NUMBER',
}
/* export type MinQuantityTypes = 'NONE_REQUIRED' | 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER' */

export const gratuityChargeType = {
  DEFAULT_GRATUITY: 'DEFAULT_GRATUITY',
  SPECIFIC_GRATUITY: 'SPECIFIC_GRATUITY',
}
/* export type GratuityChargeType = 'DEFAULT_GRATUITY' | 'SPECIFIC_GRATUITY' */
