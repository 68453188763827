import { LOAD_LANDING_PAGE_SUCCESS } from '../actions/ActionTypes'

const landingPageState = {
  data: {
    buttons: [],
  },
}

export const landingPage = (state = landingPageState, action) => {
  switch (action.type) {
    case LOAD_LANDING_PAGE_SUCCESS:
      return { data: action.landingPageSettings }
    default:
      return state
  }
}
