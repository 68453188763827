/* globals widgetInit */
import { hexToRgb } from 'svr/common/Color'

const { color_widget_background, color_widget_background_opacity } = widgetInit.settings

const rgbaWidgetBackground = hexToRgb(color_widget_background, color_widget_background_opacity)

export const loadingBackground = {
  height: '99.5vh',
  width: '100%',
  backgroundColor: rgbaWidgetBackground,
}

export const loadingSpinner = {
  height: '70px',
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
}
