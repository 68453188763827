export const color = {
  background: 'transparent',
  border: 'transparent',
  placeholder: 'rgba(51, 51, 51, 1)',
  text: 'rgba(37, 37, 37, 1)',
  error: 'rgba(208, 26, 33, 1)',
  activeButton: 'rgba(199, 226, 241, 1)',
  dialCode: 'rgba(153,153,153, 1)',
  line: 'rgba(204,204,204,1)',
}

export function getStyles(textColor = color.text) {
  return {
    defaultCursor: {
      cursor: 'default',
    },
    inputWrapper: {
      height: '100%',
      width: '100%',
      position: 'relative',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: color.border,
    },
    label: {
      width: '85%',
      position: 'absolute',
      padding: '2px 0 0 0',
      fontSize: '10px',
    },
    labelHide: {
      display: 'none',
    },
    inputBox: {
      fontSize: '14px',
      height: '100%',
      minHeight: '40px',
      width: '100%',
      minWidth: '60px',
      padding: '0',
      border: '0',
      outline: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: color.background,
      ':focus': {},
    },
    textAreaBox: {
      fontSize: '14px',
      height: '70%',
      minHeight: '30px',
      width: '100%',
      minWidth: '60px',
      padding: '0',
      margin: '15px 0 0 0',
      border: '0',
      outline: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: color.background,
      ':focus': {},
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    dropdownButton: {
      width: '79px',
      height: '24px',
      borderRadius: '3px',
      outline: 'none',
      padding: '3px',
      backgroundColor: color.background,
      borderWidth: '1px',
      borderStyle: 'solid',
      marginRight: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.388235) 0px 0px 2px -2px',
      cursor: 'pointer',
      ':active': {
        backgroundColor: color.activeButton,
      },
    },
    flexBox: {
      flex: '1',
    },
    flag: {
      width: '16px',
      height: '11px',
      margin: '3px 5px 4px 0',
      float: 'left',
      display: 'inline-block',
    },
    arrow: {
      float: 'left',
      marginTop: '8px',
      width: '0px',
      height: '0px',
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      borderTopColor: textColor,
      borderBottomColor: textColor,
    },
    upArrow: {
      borderBottomWidth: '4px',
      borderBottomStyle: 'solid',
    },
    downArrow: {
      borderTopWidth: '4px',
      borderTopStyle: 'solid',
    },
    dropdown: {
      width: '250px',
      maxHeight: '200px',
      position: 'absolute',
      top: '24px',
      left: '18px',
      padding: '0',
      boxShadow: '1px 1px 4px rgba(0,0,0,.2)',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '3px',
      overflowY: 'scroll',
      zIndex: '5',
      fontSize: '12px',
      listStyle: 'none',
      color: 'rgba(39, 39, 39, 1.00)',
    },
    countryLine: {
      padding: '5px 10px',
      cursor: 'pointer',
    },
    inlineFlag: {
      display: 'inline-block',
      marginRight: '6px',
      marginTop: '2px',
    },
    countryName: {
      marginRight: '7px',
    },
    dialCode: {
      color: color.dialCode,
    },
    listSeperator: {
      paddingBottom: '5px',
      marginBottom: '5px',
      borderBottom: `1px solid ${color.line}`,
    },
    dialCodeText: {
      width: '40px',
      float: 'left',
      marginRight: '4px',
      fontSize: '14px',
      textAlign: 'center',
    },
    fillBox: {
      padding: '11px 0 0 0',
    },
    fillTextAreaBox: {
      padding: '0',
    },
  }
}
