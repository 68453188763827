import _ from 'lodash'
import { promoCodeErrorTypes } from '../utils/constantTypes'
import { convertToFormData } from '../utils/convertData'
import {
  ADD_PROMO_CODE,
  CHANGE_CAPTCHA_FIELD,
  CHANGE_COUNTRY_FLAG,
  CHANGE_FORM_FIELD,
  CHANGE_PHONE_NUM,
  CLEAR_FORM_VALIDATION,
  CLEAR_REDEMPTIONS_ON_PROMO_CODE,
  INVALIDATE_PROMO_CODE_ATTEMPT,
  MANDATE_POLICIES,
  PASS_STRIPE_CARD_ELEMENT,
  REMOVE_PROMO_CODE,
  SAVE_TAG_GROUP,
  TRY_VERIFY_PROMO_CODE,
  VALIDATE_ALL_FIELDS,
  VALIDATE_CAPTCHA_FIELD,
  VERIFY_PROMO_CODE_FAILURE,
  VERIFY_PROMO_CODE_SUCCESS,
} from './ActionTypes'

export const passCardElement = element => ({
  type: PASS_STRIPE_CARD_ELEMENT,
  element,
})

export const changePhoneNum = (changeTo, dialCode, formattedNumber) => ({
  type: CHANGE_PHONE_NUM,
  changeTo,
  dialCode,
  formattedNumber,
})

export const changeFlag = (changeTo, selectedCountry) => ({
  type: CHANGE_COUNTRY_FLAG,
  changeTo,
  selectedCountry,
})

export const validateAllFields = formErrors => ({
  type: VALIDATE_ALL_FIELDS,
  formErrors,
})

export const mandatePolicies = formErrors => ({
  type: MANDATE_POLICIES,
  formErrors,
})

export const changeFormField = (field, changeTo) => ({
  type: CHANGE_FORM_FIELD,
  field,
  changeTo,
})

export const clearFormValidation = field => ({
  type: CLEAR_FORM_VALIDATION,
  field,
})

export const changeCaptchaField = recaptchaValue => ({
  type: CHANGE_CAPTCHA_FIELD,
  recaptchaValue,
})

export const completeCaptchaField = recaptchaValue => ({
  type: VALIDATE_CAPTCHA_FIELD,
  recaptchaValue,
})

export const saveTagGroup = (tagGroup, groupId) => ({
  type: SAVE_TAG_GROUP,
  tagGroup,
  groupId,
})

export const addPromoCode = promoCode => ({
  type: ADD_PROMO_CODE,
  promoCode,
})

export const removePromoCode = promoCode => ({
  type: REMOVE_PROMO_CODE,
  promoCode,
})

export const tryVerifyPromoCode = () => ({ type: TRY_VERIFY_PROMO_CODE })

export const verifyPromoCodeSuccess = data => ({
  type: VERIFY_PROMO_CODE_SUCCESS,
  data,
})

export const clearRedemptionsOnPromoCode = () => ({
  type: CLEAR_REDEMPTIONS_ON_PROMO_CODE,
})

export const verifyPromoCodeFailure = error => {
  let errorMessage
  switch (error) {
    case promoCodeErrorTypes.DOES_NOT_EXIST:
      errorMessage = 'Invalid promo code.'
      break
    case promoCodeErrorTypes.EXHAUSTED:
      errorMessage = 'That promo code has already been used.'
      break
    default:
      errorMessage = 'Invalid entry.'
      break
  }
  return { type: VERIFY_PROMO_CODE_FAILURE, errorMessage }
}

export const invalidatePromoCodeAttempt = () => ({
  type: INVALIDATE_PROMO_CODE_ATTEMPT,
})

export const verifyPromoCode = () => (dispatch, getState) => {
  const state = getState()
  const venueKey = state.venueInfo.venueUrlKey
  const promoCode = state.formFields.get('promoCode')
  const { baseUrl } = state.widgetSettings
  const selectedTimeSlot = state.search.get('selectedTimeSlot')
  const resDate = selectedTimeSlot.format('Y-MM-DD')
  const resTime = selectedTimeSlot.format('HH:mm')
  const { redemptions } = state.redemption
  const postUrl = `${baseUrl}/booking/widget/${venueKey}/validate_promo`

  dispatch(tryVerifyPromoCode())

  fetch(postUrl, {
    body: convertToFormData({
      promo_code: promoCode,
      res_date: resDate,
      res_time: resTime,
      platform_apply_type: 'RESERVATION_WIDGET',
    }),
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (!response.errors) {
        dispatch(verifyPromoCodeSuccess(response))

        if (_.size(redemptions)) {
          dispatch(clearRedemptionsOnPromoCode())
        }
      } else if (state.formFields.get('promoCodeInfo')) {
        dispatch(invalidatePromoCodeAttempt())
      } else {
        dispatch(verifyPromoCodeFailure(response.errors[0]))
      }
    })
    .catch(() => {
      dispatch(verifyPromoCodeFailure('Unable to process promo code. Please try again'))
    })
}
