// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import WidgetView from 'svr/component-lib/Widget/Layouts/WidgetView'
import { clickInventory, changeInventoryQuantity, clickInventoryDescription } from '../actions'
import UpsellCategory from '../components/UpsellCategory'
import UpsellFooter from '../components/UpsellFooter'
import UpsellStyleTag from '../components/UpsellStyleTag'
import * as selectors from '../selectors'
/* import type {
  ClickInventoryAction,
  ChangeInventoryQuantityAction,
  ClickInventoryDescriptionAction,
} from '../flowTypes' */
/* import type { UpsellInventoryEntity } from '../reducers/entities' */
/* import type { InventoryEditState } from '../reducers/inventoryEdits' */

/* type Props = {
  partySize: number,
  sortedCategoryEntities: Array<mixed>,
  inventoryEntities: { [string]: UpsellInventoryEntity },
  inventoryEdits: InventoryEditState,
  selectedInventory: string,
  isMobile: boolean,
  total: { quantity: number, price: number },
  currencySymbol: string,
  minQuantitiesReached: boolean,
  clickInventory: string => ClickInventoryAction,
  clickInventoryDescription: (
    string,
    boolean
  ) => ClickInventoryDescriptionAction,
  changeInventoryQuantity: (string, number) => ChangeInventoryQuantityAction,
  selectUpsells: void => void,
  textUpgrade: string,
} */

class UpsellsMainView extends React.Component /* <Props> */ {
  componentWillUpdate(nextProps) {
    if (this.props.total !== nextProps.total) {
      this.selectUpsells = this.props.selectUpsells.bind(this, this.props.total)
    }
  }

  selectUpsells = this.props.selectUpsells.bind(this, this.props.total)

  render() {
    const isFooterButtonEnabled = this.props.isModifyResUpgradesMode ? this.props.total.quantity > 0 : this.props.minQuantitiesReached
    return (
      <div>
        <UpsellStyleTag />
        <WidgetView
          shouldScrollTop
          shouldFocusFirstElement
          footer={
            <div key="sr-view-footer">
              <UpsellFooter
                total={this.props.total}
                currencySymbol={this.props.currencySymbol}
                selectUpsells={this.selectUpsells}
                textUpsellsSelectedLabel={this.props.textUpsellsSelectedLabel}
                textUpgradeNextLabel={this.props.textUpgradeNextLabel}
                isFooterButtonEnabled={isFooterButtonEnabled}
              />
              <SectionBorder />
            </div>
          }
          customViewFill={css`
            background-color: ${props => props.theme.colorWidgetBackground};
          `}
        >
          <ViewHeader>{this.props.textUpgrade || 'Upgrade your reservation'}</ViewHeader>
          <SectionBorder />
          {_.map(this.props.sortedCategoryEntities, (entity, index) => (
            <UpsellCategory
              key={`sr-upsells-category-${index}`}
              index={index}
              partySize={this.props.partySize}
              categoryEntity={entity}
              inventoryEntities={this.props.inventoryEntities}
              inventoryEdits={this.props.inventoryEdits}
              selectedInventory={this.props.selectedInventory}
              isMobile={this.props.isMobile}
              currencySymbol={this.props.currencySymbol}
              clickInventory={this.props.clickInventory}
              clickInventoryDescription={this.props.clickInventoryDescription}
              changeInventoryQuantity={this.props.changeInventoryQuantity}
              textUpgradeRequiredLabel={this.props.textUpgradeRequiredLabel}
              textUpgradeMustAtLeastLabel={this.props.textUpgradeMustAtLeastLabel}
              textUpgradeEachLabel={this.props.textUpgradeEachLabel}
              isFeesInPriceDisplayed={this.props.isFeesInPriceDisplayed}
              defaultServiceCharge={this.props.defaultServiceCharge}
              defaultGratuity={this.props.defaultGratuity}
            />
          ))}
          <SectionBorder />
        </WidgetView>
      </div>
    )
  }
}

const ViewHeader = styled.div`
  padding: 20px 4%;
  text-align: center;
  line-height: 24px;
  width: 100%;
  color: ${props => props.theme.fontsColorPrimary};
  background-color: ${props => props.theme.colorWidgetBackground};
`

const SectionBorder = styled.div`
  border: 1px solid ${props => props.theme.colorLines};
`

const mapStateToProps = state => ({
  inventoryEntities: state.upsells.entities.inventories,
  inventoryEdits: state.upsells.inventoryEdits,
  selectedInventory: state.upsells.main.selectedInventory,
  currencySymbol: state.venueInfo.currencySymbol,
  sortedCategoryEntities: selectors.selectCategoryEntities(state),
  total: selectors.selectUpsellTotal(state),
  minQuantitiesReached: selectors.selectAreAllMinQuantitiesReached(state),
  isMobile: state.app.isMobile,
  isModifyResUpgradesMode: state.modifyReservation.get('isModifyResUpgradesMode'),
  isFeesInPriceDisplayed: state.widgetSettings.isFeesInPriceDisplayed,
  defaultServiceCharge: selectors.getDefaultServiceCharge(state),
  defaultGratuity: selectors.getDefaultGratuity(state),
})

const mapDispatchToProps = {
  clickInventory,
  changeInventoryQuantity,
  clickInventoryDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsellsMainView)
