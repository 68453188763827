import _ from 'lodash'
import { ALL_LOCATIONS } from 'widget/dining/utils/convertData'

export const getVenueInternalName = (venue, state) => {
  let venueInternalName
  if (_.isEqual(venue, ALL_LOCATIONS)) {
    venueInternalName = ALL_LOCATIONS
  } else {
    venueInternalName = state.venueEntities[venue].internalName
  }

  return venueInternalName
}

export const getSelectedVenueFullName = (selectedVenue, state) => state.venueMap[selectedVenue]
