// /* @flow */
import * as React from 'react'
import styled from 'styled-components'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import FlagButton from './FlagButton'
import NumberInput from './NumberInput'

/* type Props = {
  onPhoneNumberChange: (string, string, string) => mixed,
  onFlagChange: (string, string) => mixed,
  dialCode: string,
  fontsColorCheckoutActive: string,
  fontsColorCheckoutInactive: string,
  fontFamily: string,
  isValid?: boolean,
  phoneNumber: string,
  selectedCountryCode: string,
  placeholderText: string,
  autoComplete: string,
  customStyles: Object,
  labelText: string,
  hideFlags: boolean,
} */

/* type State = {
  charLimit: number,
  isDropDownOpen: boolean,
  isFocused: boolean,
  preferredCountries: Array<string>,
  value: string,
} */

class PhoneNumber extends React.Component /* <Props, State> */ {
  constructor(props) {
    super(props)
    this.state = {
      charLimit: 20,
      isDropDownOpen: false,
      isFocused: false,
      preferredCountries: ['us', 'gb', 'au', 'ca'],
      value: '',
    }
  }

  changeFlagHandler = (newCountryCode /* : string */) => {
    if (newCountryCode !== this.props.selectedCountryCode) {
      // format phone number to events phoneNumber format
      // ***remove when events phoneNumber input changes***
      const selectedDialCode = allCountries[iso2Lookup[newCountryCode]].dialCode
      const phoneNumber = selectedDialCode + this.props.phoneNumber.replace(/[^0-9]/g, '')
      let numberFormat = allCountries[iso2Lookup[newCountryCode]].format
      if (!numberFormat) {
        numberFormat = '+'
      }
      for (let i = 0, len = phoneNumber.length; i < len; i += 1) {
        if (numberFormat.indexOf('.') !== -1) {
          numberFormat = numberFormat.replace('.', phoneNumber[i])
        } else {
          numberFormat += phoneNumber[i]
        }
      }
      const numberFormattedReversed = numberFormat.split('').reverse().join('')
      const indexOfLastDigit = numberFormat.length - numberFormattedReversed.search(/\d/)
      const newPhoneNumber = numberFormat.slice(0, indexOfLastDigit)

      this.setState({
        isDropDownOpen: !this.state.isDropDownOpen,
        value: newPhoneNumber,
        isFocused: true,
      })
      this.props.onFlagChange(newPhoneNumber, newCountryCode)
    } else {
      this.setState({
        isDropDownOpen: !this.state.isDropDownOpen,
      })
    }
  }

  showFlagMenuHandler = () => {
    this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
  }

  changeNumberHandler = (value /* : string */, phoneNumber /* : string */) => {
    let newValue = this.state.value
    let newPhoneNumber = this.props.phoneNumber
    const formattedCountries = ['us', 'ca']
    const { dialCode } = allCountries[iso2Lookup[this.props.selectedCountryCode]]
    const selectedNumberFormat = allCountries[iso2Lookup[this.props.selectedCountryCode]].format
    if (
      formattedCountries.indexOf(this.props.selectedCountryCode) === -1 ||
      (formattedCountries.indexOf(this.props.selectedCountryCode) !== -1 && value.length <= selectedNumberFormat.length)
    ) {
      newValue = value
      // Handle delete of dialCode i.e. +44 to +4
      if (value.length === `+${dialCode}`.length - 1) {
        newValue = `+${dialCode}`
      }
      newPhoneNumber = phoneNumber
    }

    this.setState({ value: newValue })
    this.props.onPhoneNumberChange(newPhoneNumber, dialCode, newValue)
  }

  getDialCode = (countryCode /* : string */) => allCountries[iso2Lookup[countryCode]].dialCode

  getNumberFormat = (countryCode /* : string */) => allCountries[iso2Lookup[countryCode]].format

  numberInputFocusHandler = () => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: !this.state.isFocused })
    }
  }

  blurHandler = () => {
    if (this.state.isFocused) {
      this.setState({ isFocused: !this.state.isFocused })
    }
  }

  formattedNumber = () => {
    // Reset phone number if value is empty
    const selectedDialCode = allCountries[iso2Lookup[this.props.selectedCountryCode]].dialCode
    const selectedNumberFormat = allCountries[iso2Lookup[this.props.selectedCountryCode]].format
    let completePhoneNumber = ''
    if (this.props.isModifyResMode && this.props.phoneNumber.indexOf('+') === 0) {
      // Phone number without the "+"
      completePhoneNumber = this.props.phoneNumber.slice(1)
    } else {
      completePhoneNumber = selectedDialCode + this.props.phoneNumber
    }
    let numberFormat = selectedNumberFormat
    if (!numberFormat) {
      numberFormat = '+'
    }

    // Replace '.' with phonenumber
    // Limit to format on only for us and ca
    const formattedCountries = ['us', 'ca']
    for (let i = 0, len = completePhoneNumber.length; i < len; i += 1) {
      if (numberFormat.indexOf('.') !== -1) {
        // Limit phone number to the format of it if it's us or ca
        numberFormat = numberFormat.replace('.', completePhoneNumber[i])
      } else if (formattedCountries.indexOf(this.props.selectedCountryCode) === -1) {
        numberFormat += completePhoneNumber[i]
      }
    }
    // Reverse formatted string to find first digit to splice up to
    const numberFormattedReversed = numberFormat.split('').reverse().join('')
    const indexOfLastDigit = numberFormat.length - numberFormattedReversed.search(/\d/)
    return numberFormat.slice(0, indexOfLastDigit)
  }

  validate = () => {
    const formattedCountries = ['us', 'ca']
    if (formattedCountries.indexOf(this.props.selectedCountryCode) !== -1) {
      const selectedNumberFormat = allCountries[iso2Lookup[this.props.selectedCountryCode]].format
      return this.formattedNumber().length === selectedNumberFormat.length
    }
    return this.validator()
  }

  validator = () => this.props.phoneNumber.length >= 6

  render() {
    return (
      <PhoneNumberWrapper customStyles={this.props.customStyles} readOnly={this.props.readOnly}>
        {!this.props.readOnly && (
          <FlagButton
            onDropDownItemClick={this.changeFlagHandler}
            onDropDownListClick={this.showFlagMenuHandler}
            isDropDownOpen={this.state.isDropDownOpen}
            preferredCountries={this.state.preferredCountries}
            selectedCountryCode={this.props.selectedCountryCode}
            hideFlags={this.props.hideFlags}
          />
        )}

        <NumberInput
          ariaRequired={this.props.ariaRequired}
          errorColor={this.props.errorColor}
          onBlur={this.blurHandler}
          onChange={this.changeNumberHandler}
          onFocus={this.numberInputFocusHandler}
          charLimit={this.state.charLimit}
          fontFamily={this.props.fontFamily}
          isFocused={this.state.isFocused}
          isValid={this.props.isValid}
          readOnly={this.props.readOnly}
          autoComplete={this.props.autoComplete}
          selectedDialCode={this.getDialCode(this.props.selectedCountryCode)}
          selectedNumberFormat={this.getNumberFormat(this.props.selectedCountryCode)}
          value={this.formattedNumber()}
          placeholderText={this.props.placeholderText}
          customInputStyles={this.props.customInputStyles}
          ariaLabel={this.props.ariaLabel}
          labelText={this.props.labelText}
          fontsColorCheckoutActive={this.props.textColor}
          fontsColorCheckoutInactive={this.props.placeholderColor}
          testId={this.props.testId}
        />
      </PhoneNumberWrapper>
    )
  }
}

const PhoneNumberWrapper = styled.div`
  display: block;
  padding: ${props => (props.readOnly ? '20px 0 5px 0' : '5px 3.6988% 5px 4.1791%')};
  min-height: 48px;
  ${props => props.customStyles};
`

PhoneNumber.defaultProps = {
  hideFlags: false,
}

export default PhoneNumber
