export const calculateFees = (isFeesInPriceDisplayed, price, serviceChargePercentage, gratuityPercentage, chargeType, duration) => {
  let fees = 0
  if (!isFeesInPriceDisplayed || !price) {
    return 0
  }
  if (serviceChargePercentage) {
    fees += (serviceChargePercentage * price) / 100
  }
  if (gratuityPercentage) {
    fees += (gratuityPercentage * price) / 100
  }
  if (duration && chargeType.endsWith('slot')) {
    fees *= duration / 15
  }
  return fees
}

export const calculateCategoryFees = (isFeesInPriceDisplayed, price, category, defaultServiceCharge, defaultGratuity) => {
  const serviceCharge = getServiceChargePercentage(
    category.isChargingServiceCharge,
    category.serviceChargeType,
    category.serviceChargePercentage,
    defaultServiceCharge
  )
  const mandatoryGratuity = getGratuityPercentage(
    category.isChargingGratuity,
    category.gratuityChargeType,
    category.gratuityPercentage,
    defaultGratuity
  )

  return calculateFees(isFeesInPriceDisplayed, price, serviceCharge, mandatoryGratuity)
}

export const calculateFeesForTimeSlot = (isFeesInPriceDisplayed, availability, upsellInventories, upsellCategories, partySize) => {
  const {
    cost,
    chargeType,
    duration,
    applyServiceCharge,
    serviceChargeType,
    serviceCharge,
    defaultServiceCharge,
    applyGratuityCharge,
    gratuityType,
    gratuity,
    defaultGratuity,
    selectedAutomaticUpsells,
  } = availability

  const serviceChargePercentage = getServiceChargePercentage(applyServiceCharge, serviceChargeType, serviceCharge, defaultServiceCharge)
  const gratuityPercentage = getGratuityPercentage(applyGratuityCharge, gratuityType, gratuity, defaultGratuity)

  let fees = calculateFees(isFeesInPriceDisplayed, cost, serviceChargePercentage, gratuityPercentage, chargeType, duration)
  if (isFeesInPriceDisplayed && selectedAutomaticUpsells) {
    selectedAutomaticUpsells.forEach(automaticUpsell => {
      if (upsellInventories[automaticUpsell.id] && upsellCategories[upsellInventories[automaticUpsell.id].categoryId]) {
        const category = upsellCategories[upsellInventories[automaticUpsell.id].categoryId]
        const quantityNum = automaticUpsell.quantityEqualType === 'SPECIFIC_NUMBER' ? automaticUpsell.quantityNum : partySize
        const upsellInventoryCost = upsellInventories[automaticUpsell.id].price * quantityNum
        let upsellFee = calculateCategoryFees(isFeesInPriceDisplayed, upsellInventoryCost, category, defaultServiceCharge, defaultGratuity)
        if (chargeType && chargeType.startsWith('person')) {
          upsellFee /= partySize
        }
        fees += upsellFee
      }
    })
  }
  return fees
}

const getServiceChargePercentage = (shouldApplyServiceCharge, serviceChargeType, serviceChargePercentage, defaultServiceCharge) => {
  let serviceCharge = 0

  if (shouldApplyServiceCharge) {
    if (serviceChargeType === 'DEFAULT_SERVICE_CHARGE' && defaultServiceCharge !== undefined) {
      serviceCharge = defaultServiceCharge
    } else {
      serviceCharge = serviceChargePercentage
    }
  }

  return serviceCharge
}

const getGratuityPercentage = (shouldApplyGratuity, gratuityType, gratuityPercentage, defaultGratuity) => {
  let mandatoryGratuity = 0

  if (shouldApplyGratuity) {
    if (gratuityType === 'DEFAULT_GRATUITY' && defaultGratuity) {
      mandatoryGratuity = defaultGratuity
    } else if (gratuityType === 'SPECIFIC_GRATUITY' && gratuityPercentage) {
      mandatoryGratuity = gratuityPercentage
    }
  }

  return mandatoryGratuity
}
