/* globals widgetInit */
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import ViewManager from './ViewManager'

import { initialize, initializeExperienceMode, initializeRequestBookingMode } from '../actions/navigation'
import { postReleaseReservationHold, tryGetActual } from '../actions/services'
import { embeddedWidgetLoaded } from 'widget/dining/utils/analyticsEvents'

class App extends Component {
  componentDidMount() {
    const { isModifyResMode, token, isExperienceMode, isRequestBookingMode } = this.props

    if (isModifyResMode && token) {
      this.props.tryGetActual()
    }

    if (isExperienceMode) {
      this.props.initializeExperienceMode()
    } else if (isRequestBookingMode) {
      this.props.initializeRequestBookingMode()
    } else {
      this.props.initialize()
    }

    this.onUnload = this.onUnload.bind(this)
    window.addEventListener('beforeunload', this.onUnload)

    document.onkeydown = event => {
      if (event.keyCode === 27 /* Escape */) {
        window.parent.postMessage({ type: 'dismiss' }, '*')
      }
    }

    if (window.frameElement) {
      embeddedWidgetLoaded()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  onUnload() {
    this.props.postReleaseReservationHold()
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <ViewManager />
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  venueId: _.get(state, 'venueInfo.id'),
  isModifyResMode: state.modifyReservation.get('isModifyResMode'),
  isExperienceMode: state.experience.get('isExperienceMode'),
  isRequestBookingMode: state.reservationRequest.get('isRequestBookingMode'),
  token: state.modifyReservation.get('token'),
})

const mapDispatchToProps = {
  initialize,
  initializeExperienceMode,
  initializeRequestBookingMode,
  postReleaseReservationHold,
  tryGetActual,
}

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
