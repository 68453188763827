import PropTypes from 'prop-types'
import { Component } from 'react'
import Checkbox from 'mgr/lib/forms/Checkbox'

export class ConsentToNotificationsCheckbox extends Component {
  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
  }

  validate() {
    return this.props.validator(this.props.value)
  }

  render() {
    const { value, onChange, isInvalid, label, id, name } = this.props

    return <Checkbox id={id} name={name} value={value} onChange={() => onChange(!value)} on={value} isInvalid={isInvalid} label={label} />
  }
}

ConsentToNotificationsCheckbox.propTypes = {
  id: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}
