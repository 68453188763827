const styles = {
  modalWidth: {
    width: '76vw',
    minWidth: '250px',
    maxWidth: '655px',
    maxHeight: 'calc(100vh - 100px)',
  },
  staticModalHeight: {
    height: '92vh',
  },
  headerLine: {
    padding: '5px',
    fontSize: '14px',
    overflow: 'auto',
  },
  smallFont: {
    fontSize: '14px',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  seperator: {
    width: '100%',
    height: '0px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  tabWrapper: {
    display: 'table',
    width: '100%',
  },
  tab: {
    display: 'table-cell',
    width: '50%',
    fontSize: '16px',
    textAlign: 'center',
    paddingTop: '16px',
    borderBottomWidth: '4px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    cursor: 'pointer',
  },
  tagListWrapper: {
    fontSize: '15px',
    padding: '15px 10px',
  },
  tag: {
    fontSize: '15px',
    padding: '4px 4px 4px 8px',
    borderRadius: '15px',
    display: 'inline-block',
    marginRight: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  circle: {
    display: 'inline-block',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: '10px',
    textAlign: 'center',
    height: '20px',
    width: '20px',
    fontSize: '15px',
    lineHeight: '13px',
    paddingTop: '1px',
    userSelect: 'none',
  },
  textAreaWrapper: {
    padding: '15px 10px',
    height: '114px',
  },
  textArea: {
    outline: 'none',
    border: 'none',
    resize: 'none',
    height: '100%',
    width: '100%',
    fontSize: '14px',
    backgroundColor: 'transparent',
  },
  policyHeader: {
    width: '100%',
    height: '50px',
    padding: '10px 5px 13px 25px',
    borderRadius: '7px 7px 0 0',
  },
  headerText: {
    display: 'block',
    float: 'left',
    width: '80%',
    height: '30px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '5px',
  },
  headerX: {
    width: '40px',
    height: '34px',
    float: 'right',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '22px',
    fontSize: '28px',
    marginRight: '5px',
    borderWidth: '0px',
  },
  policyBody: {
    padding: '19px 25px',
    overflow: 'auto',
    fontSize: '14px',
  },
  promoCodeLogo: {
    width: '70px',
    paddingTop: '35px',
  },
  promoCodeHeader: {
    width: '100%',
    height: '80px',
    padding: '15px 0 13px 25px',
    borderRadius: '7px 7px 0 0',
  },
  promoCodeModalContainer: {
    minWidth: '296px',
    maxWidth: '296px',
    textAlign: 'center',
  },
  promoCodeHeaderText: {
    display: 'block',
    float: 'left',
    width: '250px',
    height: '50px',
    fontSize: '48px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'absolute',
  },
  promoCodeBody: {
    padding: '19px 25px',
    overflow: 'auto',
    fontSize: '14px',
  },
  promoHeaderX: {
    width: '18%',
    height: '22px',
    float: 'right',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '22px',
    fontSize: '28px',
    position: 'relative',
    zIndex: '1',
  },
}

export default styles
