// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  onBlur: string => mixed,
  onChange: string => mixed,
  onFocus: void => mixed,
  isFocused: boolean,
  isValid: boolean,
  value: string,
  ariaLabel: string,
  charLimit: number,
  customInput: Array<string | (() => mixed)>,
  customInputWrapper: Array<string | (() => mixed)>,
} */

class TextInput extends React.Component /* <Props> */ {
  static defaultProps = {
    customInput: [],
    customInputWrapper: [],
    isValid: true,
  }

  handleInputChange = (event /* : SyntheticEvent<HTMLInputElement> */) => {
    const inputVal = event.currentTarget.value
    if (inputVal !== this.props.value && inputVal.length <= this.props.charLimit) {
      this.props.onChange(inputVal)
    }
  }

  handleNumberInputFocus = () => {
    this.props.onFocus()
  }

  render() {
    return (
      <InputWrapper
        customInputWrapper={this.props.customInputWrapper}
        onFocus={this.handleNumberInputFocus}
        onBlur={this.props.onBlur}
        isFocused={this.props.isFocused}
      >
        <Input
          aria-label={this.props.ariaLabel || 'phone number'}
          type="tel"
          value={this.props.value}
          aria-required={this.props.ariaRequired === true ? true : ''}
          isValid={this.props.isValid}
          onChange={this.handleInputChange}
          customInput={this.props.customInput}
          autoComplete={this.props.autoComplete}
          aria-invalid={!this.props.isValid}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          style={{
            color: this.props.isValid ? this.props.textColor : this.props.errorColor,
          }}
          data-test={this.props.testId}
        />
      </InputWrapper>
    )
  }
}

const InputWrapper = styled.div`
  width: calc(100% - 45px);
  box-sizing: border-box;
  padding-left: 5px;
  overflow: hidden;
  font-size: 14px;
  ${props => props.customInputWrapper};
`

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: transparent;
  font-family: Roboto;
  box-sizing: inherit;
  :focus {
    outline: 0;
  }
  ${props => props.customInput};
`

export default TextInput
