import { Map } from 'immutable'
import { LOAD_WIDGET_DATA_ERROR, LOAD_WIDGET_DATA_SUCCESS, LOAD_WIDGET_DATA_START } from '../actions/ActionTypes'

const preloadedState = (state = Map(), action) => {
  switch (action.type) {
    case LOAD_WIDGET_DATA_START:
      return state.merge({
        errorMessage: null,
        isFetchingWidgetData: true,
      })
    case LOAD_WIDGET_DATA_SUCCESS:
      return state.merge({
        errorMessage: null,
        isFetchingWidgetData: false,
      })
    case LOAD_WIDGET_DATA_ERROR:
      return state.merge({
        errorMessage: 'Failed to load. Please refresh the page to try again.',
        isFetchingWidgetData: true,
      })
    default:
      return state
  }
}

export default preloadedState
