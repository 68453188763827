import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddressInput from 'svr/component-lib/Generic/TextInputs/AddressInput'
import styles from 'widget/dining/assets/styles/checkout'

class GMapsAddress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isValid: false,
      address: '',
    }

    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.isValidAddress = this.isValidAddress.bind(this)
    this.render = this.render.bind(this)
  }

  isValidAddress() {
    if (this.props.submitClicked) {
      return this.state.isValid
    }
    return true
  }

  handleAddressChange(addrStr, addrObj) {
    this.setState({
      isValid: addrStr?.length > 0,
      address: addrStr,
    })

    this.props.onAddressUpdate(addrStr, addrObj)
  }

  render() {
    return (
      <>
        <div style={{ ...styles.formLine, ...styles.bottomBorderRadius, backgroundColor: this.props?.colorCheckoutCellBackground }}>
          <AddressInput
            googlePlacesApiKey={widgetInit.googlePlacesApiKey}
            placeholder="Enter your billing address"
            showLabel
            borderColor="#e7e7e7"
            value={this.state.address}
            isValid={this.isValidAddress()}
            onChange={this.handleAddressChange}
            testId="sr-input-address"
            inputStyles={{ marginLeft: '7px' }}
            placeholderColor="#252525"
            errorColor="#d01a21"
            hideBorder={this.isValidAddress()}
            labelStyle={{
              color: '#323232',
              marginTop: '4px',
              position: 'absolute',
              fontSize: '11px',
              width: '200px',
              zIndex: '1',
              marginLeft: '17px',
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  const { widgetSettings } = state

  return {
    colorCheckoutCellBackground: widgetSettings.colorCheckoutCellBackground,
  }
}

const mapDispatchToProps = dispatch => ({}) // ?

GMapsAddress.propTypes = {
  submitClicked: React.PropTypes.bool,
  onAddressUpdate: React.PropTypes.func,
  colorCheckoutCellBackground: React.PropTypes.string,
}

GMapsAddress = connect(mapStateToProps, mapDispatchToProps)(Radium(GMapsAddress))

export default GMapsAddress
