/* eslint-disable react/prefer-stateless-function */
/* eslint no-alert: 0, no-shadow: 0, react/prop-types: 0,
  jsx-a11y/no-static-element-interactions: 0 */
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { containers as upsellsContainers } from 'widget/modules/upsells'
import { revertStage, selectUpsells } from '../actions/navigation'
import Banner from '../components/Banner'
import { WidgetViewContainer } from '../uiKit/WidgetView'
import { getBannerContents, selectTimeSlotEntity } from '../utils/selectors'

const DiningUpsellsContainer = styled(WidgetViewContainer)`
  padding-top: 62px;
  background-color: ${props => props.theme.colorWidgetBackground};
`

const UpsellsMainView = upsellsContainers.MainView

class DiningUpsells extends React.Component {
  render() {
    const {
      bannerInfo,
      revertStage,
      widgetColorSettings,
      partySize,
      selectUpsells,
      textUpgrade,
      textUpgradeRequiredLabel,
      textUpgradeMustAtLeastLabel,
      textUpsellsSelectedLabel,
      textUpgradeNextLabel,
      textUpgradeEachLabel,
      isModifyResUpgradesMode,
      currentView,
      defaultServiceCharge,
      defaultGratuity,
    } = this.props
    return (
      <DiningUpsellsContainer>
        <Banner
          info={bannerInfo}
          onBackClick={revertStage}
          colorSummaryBar={widgetColorSettings.colorSummaryBar}
          fontsColorSummaryBar={widgetColorSettings.fontsColorSummaryBar}
          colorLines={widgetColorSettings.colorLines}
          textHoldingTable={widgetColorSettings.textHoldingTable}
          textHoldExpired={widgetColorSettings.textHoldExpired}
          isModifyResUpgradesMode={isModifyResUpgradesMode}
          currentView={currentView}
        />
        <UpsellsMainView
          partySize={partySize}
          selectUpsells={selectUpsells}
          resetModuleState
          textUpgrade={textUpgrade}
          textUpgradeRequiredLabel={textUpgradeRequiredLabel.toUpperCase()}
          textUpgradeMustAtLeastLabel={textUpgradeMustAtLeastLabel}
          textUpsellsSelectedLabel={textUpsellsSelectedLabel}
          textUpgradeNextLabel={textUpgradeNextLabel}
          textUpgradeEachLabel={textUpgradeEachLabel}
        />
      </DiningUpsellsContainer>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const isModifyResUpgradesMode = state.modifyReservation.get('isModifyResUpgradesMode')
  return {
    bannerInfo: getBannerContents(state),
    partySize: state.search.get('partySize'),
    widgetColorSettings: {
      colorSummaryBar: state.widgetSettings.colorSummaryBar,
      fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
      colorLines: state.widgetSettings.colorLines,
      textHoldingTable: languageStrings.textHoldingTable,
      textHoldExpired: languageStrings.textHoldExpired,
    },
    textUpgrade: languageStrings.textUpgrade,
    textUpgradeRequiredLabel: languageStrings.textUpgradeRequiredLabel,
    textUpgradeMustAtLeastLabel: languageStrings.textUpgradeMustAtLeastLabel,
    textUpsellsSelectedLabel: languageStrings.textUpsellsSelectedLabel,
    textUpgradeNextLabel: languageStrings.textUpgradeNextLabel,
    textUpgradeEachLabel: languageStrings.textUpgradeEachLabel,
    isModifyResUpgradesMode,
    currentView: state.ui.get('viewSequence').get(state.ui.get('stage')),
  }
}

const mapDispatchToProps = dispatch => ({
  revertStage: () => {
    dispatch(revertStage(false, 1))
  },
  selectUpsells: upsellTotals => {
    dispatch(selectUpsells(upsellTotals))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DiningUpsells)
