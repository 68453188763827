import { TransactionTypes, PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import { GatewayError } from 'svr/lib/Payments/Util'

export const IntentSources = {
  [TransactionTypes.INTERNAL]: 'SU5URVJOQUw=',
  [TransactionTypes.WEB_PAYLINK_CHARGE]: 'UEFZTElOSw==',
  [TransactionTypes.WEB_EVENT_CHARGE]: 'RVZFTlRT',
  [TransactionTypes.WEB_DINING_CHARGE]: 'RElOSU5H',
  [TransactionTypes.WEB_ORDER_CHARGE]: 'R2D2C3POBB8',
}

const STRIPE_PUBLIC_DEV_KEY = 'pk_test_wIPMArVIe1cLcAJiGnBJoamu'
const STRIPE_PUBLIC_PROD_KEY = 'pk_live_vnGByiaF532dJktI7IxNLzee'

const ProductLocalization = {
  orderWidget: {
    domPrep: mount => {
      const mountElement = document.getElementById(mount)
      mountElement.style.border = '2px solid rgba(236,236,236,1)'
      mountElement.style.borderRadius = '5px'
      mountElement.style.padding = '0 0 0 10px'
    },
    style: {
      base: {
        color: '#666',
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        lineHeight: '46px',
        '::placeholder': { color: '#999' },
      },
    },
  },
}

class StripeGateway {
  constructor(venueInfo, isProd, product, info) {
    this.delayProcessing = false
    this.bypassChannelScan = true

    this.defaultChannel = PAYMENT_CHANNELS.NEW_CREDIT_CARD

    this.venueInfo = venueInfo
    this.stripeKey = isProd ? STRIPE_PUBLIC_PROD_KEY : STRIPE_PUBLIC_DEV_KEY
    this.product = product
    this.payButtonCallback = () => {
      // eslint-disable-next-line no-console
      console.log('callback not defined')
    }
    this.loadedCallback = () => {
      // eslint-disable-next-line no-console
      console.log('callback not defined')
    }
    this.info = info
  }

  getIntentSource(transactionType) {
    return IntentSources[transactionType]
  }

  attachPrerequisites(mount, errHandler, save = false) {
    const stripeLib = 'https://js.stripe.com/v3/'
    const head = document.getElementsByTagName('head').item(0)
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', stripeLib)
    script.onload = () => {
      if (mount) {
        this.mountForm(mount, save)
      }
    }
    script.onerror = () => {
      errHandler()
    }

    head.appendChild(script)
  }

  mountForm(mount, save = false) {
    const localization = ProductLocalization[this.product]
    localization.domPrep(mount)

    const options = save ? {} : { stripeAccount: this.venueInfo.accountId }
    this.stripe = Stripe(this.stripeKey, options)
    this.elements = this.stripe.elements()

    this.card = this.elements.create('card', { style: localization.style })
    document.getElementById(mount).innerHTML = ''
    this.card.mount(`#${mount}`)

    this.mountPaymentRequest('platform-pay-button')
  }

  update(info) {
    if (info) {
      this.info = info
    }
    if (!this.paymentRequest) {
      return
    }
    this.paymentRequest.update({
      total: {
        label: 'Total',
        amount: this.info.total,
      },
    })
  }

  mountPaymentRequest(mount) {
    this.paymentRequest = this.stripe.paymentRequest({
      country: this.venueInfo.countryCode.toUpperCase(),
      currency: this.venueInfo.currency.toLowerCase(),
      total: {
        label: 'Total',
        amount: this.info.total || 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    })

    this.paymentRequest.canMakePayment().then(result => {
      const options = []
      if (result && document.getElementById(mount)) {
        prButton.mount(`#${mount}`)

        // So this is happening. Stripe returns
        // null if there's no available pay button.
        // If apple pay is available, it returns
        // {applePay: true} and if google pay is
        // available it returns {applePay: false}.
        // ¯\_(ツ)_/¯
        if (result.applePay) {
          options.push(PAYMENT_CHANNELS.APPLE_PAY)
        } else {
          options.push(PAYMENT_CHANNELS.GOOGLE_PAY)
        }

        // Due to load lag this needs a little kick
        // to show the right total if you refresh on
        // the checkout page.
        this.update()
      }
      this.loadedCallback(options)
    })

    this.paymentRequest.on('paymentmethod', ev => {
      this.payButtonCallback({ payment_method: ev.paymentMethod.id })
      ev.complete('success')
    })
  }

  authorizePayment(chargeData, transactionType, paymentMethod = null, checkSubmitStillValid = null) {
    if (!paymentMethod) {
      paymentMethod = this.card
    }
    const total = chargeData.chargeAmount
    const formData = new FormData()
    const url = `/api-yoa/payments/${this.venueInfo.urlKey}/begin_payment`

    const additionalData = {
      billing_details: {
        name: `${chargeData.firstName} ${chargeData.lastName}`,
      },
    }

    formData.append('total', total)
    formData.append('intent_source', this.getIntentSource(transactionType))

    return fetch(url, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(response => {
        if (response.status != 200) {
          throw response
        }
        return this.stripe.handleCardPayment(response.data.begin_payment.client_secret, paymentMethod, {
          payment_method_data: additionalData,
        })
      })
      .then(response => {
        if (response.error) {
          throw new GatewayError(response.error.message)
        }
        return {
          id: response.paymentIntent.id,
        }
      })
  }

  saveCard(cardData, transactionType) {
    const url = `/api-yoa/payments/${this.venueInfo.urlKey}/begin_save`
    const cardElement = this.card
    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(response => {
        if (response.status !== 200) {
          throw response
        }
        return this.stripe.handleCardSetup(response.data.begin_save.client_secret, cardElement, {})
      })
      .then(response => {
        if (response.error) {
          throw new GatewayError(response.error.message)
        }
        return {
          id: response.setupIntent.payment_method,
        }
      })
  }

  refund({ historyId, clientId, amount, reason }) {
    const url = `/api-yoa/payments/${this.venueInfo.urlKey}/refund`
    const formData = new FormData()

    formData.append('amount', amount)
    formData.append('reason', reason)
    formData.append('billing_history_id', historyId)
    formData.append('venue_group_client_id', clientId)

    return fetch(url, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'X-CSRFToken': window.Pmp.Client.Static.Csrf },
    })
      .then(response => response.json())
      .then(response => {
        if (response.status != 200) {
          throw response
        }
        return response
      })
  }
}

export default StripeGateway
