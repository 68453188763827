// /* @flow */
import * as React from 'react'
import styled from 'styled-components'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import FlagListItem, { FlagListHeader } from './FlagListItem'

/* type Props = {
  onDropDownItemClick: string => mixed,
  onDropDownListClick: void => mixed,
  countryCode: string,
  dialCode: string,
  isDropDown: boolean,
  preferredCountries: Array<string>,
  selectedCountryCode: string,
  hideFlags: boolean,
} */

class FlagDropDownList extends React.Component /* <Props> */ {
  generateFlagListItems = (preferredCountries /* : Array<string> */) => {
    const countriesList = []
    if (this.props.hideFlags) {
      const header = <FlagListHeader headerText="COUNTRY/REGION CODE" key="sr-flaglistitem-header" />
      countriesList.push(header)
    }

    // Generate preferred countries
    for (let i = 0; i < preferredCountries.length; i += 1) {
      countriesList.push(this.createFlagItem(iso2Lookup[preferredCountries[i]]))
    }
    // Generate rest of the countries
    for (let i = 0; i < allCountries.length; i += 1) {
      if (preferredCountries.indexOf(allCountries[i].iso2) === -1) {
        countriesList.push(this.createFlagItem(i))
      }
    }
    return countriesList
  }

  createFlagItem = (country /* : number */) => (
    <FlagListItem
      key={`sr-flaglistitem-${allCountries[country].iso2}`}
      onDropDownItemClick={this.props.onDropDownItemClick}
      onDropDownListClick={this.props.onDropDownListClick}
      countryCode={allCountries[country].iso2}
      countryName={allCountries[country].name}
      dialCode={allCountries[country].dialCode}
      isDropDown={this.props.isDropDown}
      selectedCountryCode={this.props.selectedCountryCode}
      hideFlag={this.props.hideFlags}
    />
  )

  render() {
    return <FlagList>{this.generateFlagListItems(this.props.preferredCountries)}</FlagList>
  }
}

FlagDropDownList.defaultProps = {
  isDropDown: true,
}

const FlagList = styled.ul`
  padding: 0px;
  margin-top: 10px;
  width: 250px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
`

export default FlagDropDownList
