export class RSAEncrypt {
  constructor(public_key) {
    const components = this.parse_pub_key(public_key)
    this.encryptor = new RsaOaep(components[0], components[1])
  }

  parse_pub_key(public_key) {
    const pem_re = /-----BEGIN (?:[\w\s]+)-----\s*([0-9A-Za-z+/=\s]+)-----END/
    let pem_result = public_key
    if (pem_result.indexOf('-----BEGIN') > -1) {
      pem_result = pem_re.exec(public_key)[1].replace(/\s/g, '')
    }
    const prep = window.atob(pem_result)
    const pubkey_tree = ASN1.decode(prep)

    const n_raw = pubkey_tree.sub[1].sub[0].sub[0].rawContent()
    const e_raw = pubkey_tree.sub[1].sub[0].sub[1].rawContent()

    let e = 0
    for (let i = 0; i < e_raw.length; i++) {
      e = (e << 8) | e_raw.charCodeAt(i)
    }
    return [n_raw, e]
  }

  freedomPayCard(cardNumber, expMonth, expYear, ccv) {
    expYear = expYear.substr(-2)
    expMonth = expMonth.length === 1 ? `0${expMonth}` : expMonth
    const str = `M${cardNumber}=${expYear}${expMonth}:${ccv}`
    return this.encryptor.encrypt(str)
  }
}
