import { Map } from 'immutable'
import { camelCaseObject } from '../../../../common/ObjectUtils'
import { VERIFY_PROMO_CODE_SUCCESS } from '../actions/ActionTypes'

const promoCode = (state = Map(), action) => {
  switch (action.type) {
    case VERIFY_PROMO_CODE_SUCCESS:
      return state.set(action.data.id, camelCaseObject(action.data))
    default:
      return state
  }
}

export default promoCode
