import { Map } from 'immutable'
import _ from 'lodash'
import {
  GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  GET_INITIAL_AVAILABILITY_SUCCESS,
  GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
  LOAD_ACTUAL_SUCCESS,
} from '../actions/ActionTypes'

const time = (state = Map(), action) => {
  switch (action.type) {
    case GET_INITIAL_AVAILABILITY_SUCCESS:
    case GET_ADDITIONAL_AVAILABILITY_SUCCESS:
      return state.merge(action.data.shiftEntities)
    case GET_MULTI_VENUE_AVAILABILITY_SUCCESS: {
      let nextState = state
      _.each(action.data, result => {
        nextState = nextState.merge(result.shiftEntities)
      })
      return nextState
    }
    case LOAD_ACTUAL_SUCCESS:
      return state.merge({
        shiftIntervalMinutes: action.shiftIntervalMinutes,
      })
    default:
      return state
  }
}

export default time
