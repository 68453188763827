import Immutable from 'immutable'
import { SET_LAST_CONFIRMATION_METHOD } from '../actions/ActionTypes'

interface ConfirmationAction {
  type: string
  lastConfirmationMethod: string
}

const confirmation = (state = Immutable.Map(), action: ConfirmationAction) => {
  switch (action.type) {
    case SET_LAST_CONFIRMATION_METHOD: {
      const { lastConfirmationMethod } = action
      return { ...state, lastConfirmationMethod }
    }
    default:
      return state
  }
}

export default confirmation
