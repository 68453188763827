import moment from 'moment-timezone'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from '../../../../lib/Buttons/Button'
import { viewTypes } from '../utils/constantTypes'

const styles = {
  bannerWrapper: {
    position: 'fixed',
    top: '0',
    right: '0',
    left: '0',
    height: '40px',
    margin: '0 auto',
    maxWidth: '700px',
    minWidth: '300px',
    width: '100%',
    zIndex: '20',
    padding: '7px',
    fontSize: '12px',
    textAlign: 'center',
    color: 'white',
    lineHeight: '26px',
    backgroundColor: 'rgba(50, 70, 84, 1.00)',
  },
  subBannerWrapper: {
    top: '40px',
    height: '24px',
    lineHeight: '20px',
    padding: '1px 0 0',
    borderTop: '2px solid',
  },
  bannerClickBox: {
    height: '26px',
    float: 'left',
    width: '26px',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '22px',
    padding: '0',
    position: 'relative',
  },
  backArrow: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  clockIcon: {
    fontSize: '12px',
    display: 'inline-block',
    marginRight: '4px',
  },
  holdTime: {
    fontWeight: '800',
  },
  holdWarning: {
    backgroundColor: '#fcc',
    color: '#000',
  },
  holdExpired: {
    backgroundColor: '#fcc',
    color: '#f00',
  },
}

const TopBanner = ({
  info,
  onBackClick,
  colorSummaryBar,
  fontsColorSummaryBar,
  colorLines,
  textHoldingTable,
  textHoldExpired,
  showBackButton,
  styleBannerWrapper,
  styleHoldSubBanner,
  errorMessageForHoldReservationFailure,
  isExperienceMode = false,
}) => {
  const { dateStr, partySizeStr, timeStr, expirationMoment } = info
  const infoStr = !isExperienceMode
    ? [dateStr, partySizeStr, timeStr].join('\u00A0\u00A0·\u00A0\u00A0')
    : [dateStr, partySizeStr].join('\u00A0\u00A0·\u00A0\u00A0')
  return (
    <div>
      <div style={[styles.bannerWrapper, { color: fontsColorSummaryBar, backgroundColor: colorSummaryBar }, styleBannerWrapper || {}]}>
        {showBackButton && (
          <Button
            innerElement={
              <i style={[styles.backArrow, { color: fontsColorSummaryBar }]} className="fa fa-angle-left fa-fw" aria-hidden="true" />
            }
            onClick={onBackClick}
            activeStyling
            style={styles.bannerClickBox}
            ariaLabel="Back"
          />
        )}
        {infoStr}
      </div>
      {(expirationMoment || errorMessageForHoldReservationFailure) && (
        <HoldSubBanner
          {...{
            expirationMoment: expirationMoment || moment(),
            fontsColorSummaryBar,
            colorSummaryBar,
            colorLines,
            textHoldingTable,
            textHoldExpired,
            errorMessageForHoldReservationFailure,
          }}
          styleHoldSubBanner={styleHoldSubBanner || {}}
        />
      )}
    </div>
  )
}

TopBanner.propTypes = {
  info: React.PropTypes.object.isRequired,
  onBackClick: React.PropTypes.func.isRequired,
  colorSummaryBar: React.PropTypes.string.isRequired,
  fontsColorSummaryBar: React.PropTypes.string.isRequired,
  colorLines: React.PropTypes.string.isRequired,
  textHoldingTable: React.PropTypes.string.isRequired,
  textHoldExpired: React.PropTypes.string.isRequired,
  showBackButton: React.PropTypes.bool,
  errorMessageForHoldReservationFailure: React.PropTypes.string,
  isExperienceMode: React.PropTypes.bool,
}

TopBanner.defaultProps = {
  colorSummaryBar: 'rgba(185, 243, 243, 1.00)',
  fontsColorSummaryBar: 'white',
  colorLines: 'white',
  textHoldingTable: '',
  textHoldExpired: '',
  errorMessageForHoldReservationFailure: null,
}

class HoldSubBanner extends Component {
  tick() {
    if (this.props.expirationMoment) {
      this.forceUpdate()
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick.bind(this), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {
      expirationMoment,
      colorSummaryBar,
      fontsColorSummaryBar,
      colorLines,
      textHoldingTable,
      textHoldExpired,
      errorMessageForHoldReservationFailure,
    } = this.props
    const expirationDiffMs = expirationMoment.diff(moment())
    let expirationBody
    let timeStyling
    if (errorMessageForHoldReservationFailure) {
      timeStyling = styles.holdWarning
      expirationBody = (
        <div>
          <i style={styles.clockIcon} className="fa fa-timer-line fa-fw" aria-hidden="true" />
          {`${errorMessageForHoldReservationFailure}`}
        </div>
      )
    } else if (expirationDiffMs >= 1000) {
      const expirationMinSec = moment.utc(expirationDiffMs).format('m:ss')
      timeStyling = expirationDiffMs < 60000 ? styles.holdWarning : {}
      expirationBody = (
        <div>
          <i style={styles.clockIcon} className="fa fa-timer-line fa-fw" aria-hidden="true" />
          {`${textHoldingTable} `}
          <span style={[styles.holdTime]}>{expirationMinSec}</span>
          {' minutes'}
        </div>
      )
    } else {
      timeStyling = styles.holdExpired
      expirationBody = textHoldExpired
    }
    return (
      <div
        style={[
          styles.bannerWrapper,
          styles.subBannerWrapper,
          {
            color: fontsColorSummaryBar,
            backgroundColor: colorSummaryBar,
            borderColor: colorLines,
          },
          timeStyling,
          this.props.styleHoldSubBanner,
        ]}
      >
        {expirationBody}
      </div>
    )
  }
}

export const VenueBanner = styled.div`
  display: flex;
  background: ${props => props.colorSummaryBar};
  color: ${props => props.fontsColorSummaryBar};
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
`

HoldSubBanner = Radium(HoldSubBanner)

HoldSubBanner.propTypes = {
  expirationMoment: React.PropTypes.object.isRequired,
  colorSummaryBar: React.PropTypes.string.isRequired,
  fontsColorSummaryBar: React.PropTypes.string.isRequired,
  colorLines: React.PropTypes.string.isRequired,
  textHoldingTable: React.PropTypes.string.isRequired,
  textHoldExpired: React.PropTypes.string.isRequired,
}

HoldSubBanner.defaultProps = {
  colorSummaryBar: 'rgba(185, 243, 243, 1.00)',
  fontsColorSummaryBar: 'white',
  colorLines: 'white',
}

const mapStateToProps = state => {
  const currentView = state.ui?.get('viewSequence').get(state.ui?.get('stage'))
  const isModifyResUpgradesMode = state.modifyReservation?.get('isModifyResUpgradesMode')
  const showBackButton = !isModifyResUpgradesMode || currentView !== viewTypes.UPSELLS
  return {
    showBackButton,
  }
}

const Banner = connect(mapStateToProps, null)(Radium(TopBanner))

export default Banner
