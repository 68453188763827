import _ from 'lodash'
import { createSelector } from 'reselect'
import {
  currencyAdd,
  currencyMult,
  currencySubtr,
  currencyPercentage,
  currencyAddSeq,
  amountToCents,
  roundToNearestHundredth,
} from '../../../../lib/Payments/Util'
import { selectedUpsellInventoriesSortedPrices, selectCategoryEntities } from '../../../modules/upsells/selectors'
import { promoCodeTypes, upsellGratuityTypes, creditCardPaymentRuleTypes, promoComponentTypes } from '../utils/constantTypes'
import { selectTimeSlotEntity, selectPromoCodeEntity } from '../utils/selectors'

const _throwTypeError = (field, value) => {
  throw new TypeError(`Invalid value for field ${field}: ${String(value)}`)
}

const getTimeSlotVenueEntity = state => state.venueEntities[state.searchResults.get('timeSlotVenue')]
const isModifyResUpgradesMode = state => state.modifyReservation.get('isModifyResUpgradesMode')

const subtotalForChargeType = (cost, chargeType, partySize, duration) => {
  if (!cost) {
    return 0
  }
  let subtotal = cost
  if (chargeType) {
    if (chargeType.startsWith('person')) {
      subtotal *= partySize
    }
    if (chargeType.endsWith('slot')) {
      subtotal *= duration / 15
    }
  }
  return subtotal
}

// Select base price
const getPartySize = state => state.search.get('partySize')
const getBasePrice = state => state.search.get('cost')
export const _deriveBasePrice = (
  cost /* :number */,
  timeEntity /* :{ chargeType: string } */,
  partySize /* :number */,
  duration /* :number */
) => {
  const { chargeType, requireCreditCard, ccPartySizeMin, selectedAutomaticUpsells } = timeEntity
  const hasAutomaticUpsells = !_.isEmpty(selectedAutomaticUpsells)
  if ((requireCreditCard && partySize > ccPartySizeMin) || hasAutomaticUpsells) {
    return subtotalForChargeType(cost, chargeType, partySize, duration)
  }
  return 0
}

export const selectDuration = createSelector([selectTimeSlotEntity], timeSlotEntity => timeSlotEntity.duration)
export const selectBasePrice = createSelector([getBasePrice, selectTimeSlotEntity, getPartySize, selectDuration], _deriveBasePrice)

export const _deriveBaseData = (timeSlotEntity, partySize, duration, isModifyResUpgradesMode) => {
  const { chargeType, cost, requireCreditCard, ccPartySizeMin, selectedAutomaticUpsells } = timeSlotEntity
  const automaticUpsellInventories = []
  Object.values(selectedAutomaticUpsells ?? {}).forEach(automaticUpsell => {
    const upsellQuantity = automaticUpsell.quantityEqualType === 'PARTY_SIZE' ? partySize : automaticUpsell.quantityNum
    const selectedAutomaticUpsell = { ...automaticUpsell, price: automaticUpsell.price / upsellQuantity }
    for (let i = 0; i < upsellQuantity; i += 1) {
      automaticUpsellInventories.push(selectedAutomaticUpsell)
    }
  })
  const automaticUpsellSortedPrices = automaticUpsellInventories ? _.sortBy(automaticUpsellInventories, 'price') : []
  const baseData = {
    reservationBasePrice: 0,
    automaticUpsells: automaticUpsellSortedPrices,
  }
  if (requireCreditCard && partySize > ccPartySizeMin) {
    baseData.reservationBasePrice = subtotalForChargeType(cost, chargeType, partySize, duration)
  }

  if (isModifyResUpgradesMode) {
    baseData.reservationBasePrice = 0
  }

  return baseData
}

const getIsModifyResUpgradesMode = state => state.modifyReservation.get('isModifyResUpgradesMode')

export const selectedBaseData = createSelector(
  [selectTimeSlotEntity, getPartySize, selectDuration, getIsModifyResUpgradesMode],
  _deriveBaseData
)

export const selectPromoCodeData = createSelector([selectPromoCodeEntity], promoCodeEntity => ({
  promoType: promoCodeEntity ? promoCodeEntity.promoType : undefined,
  // promoValue: represented by either dollar or percentage value
  promoValue: promoCodeEntity ? promoCodeEntity.promoValue / 100 : undefined,
  // promoValueCap: represented by dollar value
  promoValueCap: promoCodeEntity ? promoCodeEntity.promoValueCap / 100 : undefined,
  promoComponent: promoCodeEntity ? promoCodeEntity.promoComponent : undefined,
}))

const getSelectedGratuityCharge = state => currencyPercentage(state.formFields.get('selectedGratuityCharge'))

const getRedemptions = state => state.redemption && state.redemption.redemptions

// Select all necessary pricing data !important
const selectTimeSlotFields = createSelector([selectTimeSlotEntity], timeSlotEntity => ({
  timeSlotTax: currencyPercentage(timeSlotEntity.taxRate || 0),
  timeSlotGratuity: currencyPercentage(timeSlotEntity.gratuity || 0),
  timeSlotServiceCharge: currencyPercentage(timeSlotEntity.serviceCharge || 0),
  timeSlotApplyGratuityCharge: timeSlotEntity.applyGratuityCharge,
  timeSlotGratuityType: timeSlotEntity.gratuityType,
  timeSlotRequireGratuityCharge: timeSlotEntity.requireGratuityCharge,
  timeSlotEntity,
}))

const selectVenueTaxGratuity = createSelector([getTimeSlotVenueEntity], venueEntity => ({
  venueTax: currencyPercentage(venueEntity.defaultTax),
  venueGratuity: currencyPercentage(venueEntity.defaultGratuity),
}))

export const selectVenuePaymentType = createSelector([getTimeSlotVenueEntity], venueEntity => venueEntity.paymentSystem)

/* eslint-disable no-nested-ternary */
export const sortUpsellsByPriceTaxGratuityAscending = upsells =>
  upsells.sort((a, b) =>
    a.price > b.price
      ? 1
      : a.price < b.price
      ? -1
      : a.tax > b.tax
      ? 1
      : a.tax < b.tax
      ? -1
      : a.gratuity > b.gratuity
      ? 1
      : a.gratuity < b.gratuity
      ? -1
      : 0
  )
/* eslint-enable no-nested-ternary */

const getIncludedUpsellsWithPricing = (
  timeSlotEntity,
  upsellCategories,
  upsellInventories,
  partySize,
  venueTaxGratuity,
  venueTaxGroups
) => {
  const { selectedAutomaticUpsells } = timeSlotEntity
  const selectedUpsellsWithPrice = []

  if (selectedAutomaticUpsells) {
    Object.values(selectedAutomaticUpsells).forEach(selectedUpsell => {
      if (selectedUpsell.id in upsellInventories) {
        const quantityNum = selectedUpsell.quantityEqualType === 'SPECIFIC_NUMBER' ? selectedUpsell.quantityNum : partySize
        const upsellCategory = upsellCategories[upsellInventories[selectedUpsell.id].categoryId]
        const upsellPrice = currencyMult(upsellInventories[selectedUpsell.id].price, quantityNum)

        const upsellTaxRate = _getTaxRate(venueTaxGroups, upsellCategory.taxGroupId)

        /* eslint-disable no-param-reassign */
        selectedUpsell.taxGroupId = upsellCategory.taxGroupId
        selectedUpsell.price = roundToNearestHundredth(upsellPrice)
        selectedUpsell.tax = upsellCategory.isChargingTax ? upsellTaxRate : 0
        selectedUpsell.isChargingGratuity = upsellCategory.isChargingGratuity
        selectedUpsell.requireGratuityCharge = upsellCategory.requireGratuityCharge
        selectedUpsell.gratuityPercentage = upsellCategory.gratuityPercentage
        selectedUpsell.gratuityChargeType = upsellCategory.gratuityChargeType
        selectedUpsell.isChargingServiceCharge = upsellCategory.isChargingServiceCharge
        selectedUpsell.serviceChargeType = upsellCategory.serviceChargeType
        selectedUpsell.serviceChargePercentage = upsellCategory.serviceChargePercentage
        selectedUpsellsWithPrice.push(selectedUpsell)
        /* eslint-enable no-param-reassign */
      }
    })
  }
  const sortedSelectedUpsellsWithPrice = sortUpsellsByPriceTaxGratuityAscending(selectedUpsellsWithPrice)
  return {
    selectedUpsells: sortedSelectedUpsellsWithPrice,
  }
}

const getUpsellCategories = state => state.upsells.entities.categories
const getUpsellInventories = state => state.upsells.entities.inventories
const getInventoryEdits = state => state.upsells.inventoryEdits

const getVenueTaxGroups = state => {
  const venueUrlKey = state.searchResults.toJS().timeSlotVenue
  const venueTaxGroups = []
  const { taxGroups } = state.venueEntities[venueUrlKey]
  // eslint-disable-next-line guard-for-in
  for (const key in taxGroups) {
    const taxGroup = { ...taxGroups[key] }
    taxGroup.taxRate /= 100
    venueTaxGroups.push(taxGroup)
  }
  return venueTaxGroups
}

const _getTaxRate = (taxGroups, taxGroupId) => {
  const taxGroup = taxGroups.find(obj => obj.id === taxGroupId)
  return taxGroup ? taxGroup.taxRate : 0
}

const selectIncludedUpsells = createSelector(
  [selectTimeSlotEntity, getUpsellCategories, getUpsellInventories, getPartySize, selectVenueTaxGratuity, getVenueTaxGroups],
  getIncludedUpsellsWithPricing
)

export const getGratuityFields = createSelector(
  [selectTimeSlotEntity, selectIncludedUpsells, selectCategoryEntities, getInventoryEdits],
  (timeSlotEntity, automaticIncludedUpsells, upsellCategories, inventoryEdits) => {
    const gratuityFields = {
      displayGratuityLine: false,
      displaySelectGratuityLine: false,
      requireSelectGratuity: false,
    }
    // reservation base gratuity fields
    if (timeSlotEntity.applyGratuityCharge) {
      if (timeSlotEntity.gratuityType === 'CLIENT_GRATUITY') {
        gratuityFields.displaySelectGratuityLine = true
        gratuityFields.requireSelectGratuity = timeSlotEntity.requireGratuityCharge
      } else if (timeSlotEntity.gratuityType === 'DEFAULT_GRATUITY') {
        gratuityFields.displayGratuityLine = !!timeSlotEntity.defaultGratuity
      } else {
        gratuityFields.displayGratuityLine = !!timeSlotEntity.gratuity
      }
    }
    if (gratuityFields.displayGratuityLine && gratuityFields.displaySelectGratuityLine && gratuityFields.requireSelectGratuity) {
      return gratuityFields
    }

    // automatic upsells gratuity fields
    Object.values(automaticIncludedUpsells.selectedUpsells).forEach(automaticUpsell => {
      if (gratuityFields.displayGratuityLine && gratuityFields.displaySelectGratuityLine && gratuityFields.requireSelectGratuity) {
        return
      }
      if (automaticUpsell.isChargingGratuity) {
        if (automaticUpsell.gratuityChargeType === 'CLIENT_GRATUITY') {
          gratuityFields.displaySelectGratuityLine = true
          gratuityFields.requireSelectGratuity = gratuityFields.requireSelectGratuity || automaticUpsell.requireGratuityCharge
        } else if (automaticUpsell.gratuityChargeType === 'DEFAULT_GRATUITY') {
          gratuityFields.displayGratuityLine = !!(gratuityFields.displayGratuityLine || timeSlotEntity.defaultGratuity)
        } else {
          gratuityFields.displayGratuityLine = !!(gratuityFields.displayGratuityLine || automaticUpsell.gratuityPercentage)
        }
      }
    })
    // selected upsells gratuity fields
    upsellCategories.forEach(upsellCategory => {
      upsellCategory.inventories.forEach(upsellInventoryId => {
        if (inventoryEdits[upsellInventoryId].quantity) {
          if (gratuityFields.displayGratuityLine && gratuityFields.displaySelectGratuityLine && gratuityFields.requireSelectGratuity) {
            return
          }
          if (upsellCategory.isChargingGratuity) {
            if (upsellCategory.gratuityChargeType === 'CLIENT_GRATUITY') {
              gratuityFields.displaySelectGratuityLine = true
              gratuityFields.requireSelectGratuity = gratuityFields.requireSelectGratuity || upsellCategory.requireGratuityCharge
            } else if (upsellCategory.gratuityChargeType === 'DEFAULT_GRATUITY') {
              gratuityFields.displayGratuityLine = !!(gratuityFields.displayGratuityLine || timeSlotEntity.defaultGratuity)
            } else {
              gratuityFields.displayGratuityLine = !!(gratuityFields.displayGratuityLine || upsellCategory.gratuityPercentage)
            }
          }
        }
      })
    })
    return gratuityFields
  }
)

const _applyDollarDiscount = (baseAmount, dollarDiscount) => {
  if (baseAmount >= dollarDiscount) {
    return [dollarDiscount, 0]
  }
  return [baseAmount, dollarDiscount - baseAmount]
}

const _applyPercentDiscount = (baseAmount, percentDiscount) => currencyMult(baseAmount, percentDiscount)

const _applyDiscount = (baseAmount, promoType, dollarDiscount, percentDiscount) => {
  let discounted = 0
  let remainder = 0
  let discountInfo
  if (promoType === promoCodeTypes.DOLLAR_DISCOUNT) {
    discountInfo = _applyDollarDiscount(baseAmount, dollarDiscount)
    discounted += discountInfo[0]
    remainder += discountInfo[1]
  } else if (promoType === promoCodeTypes.PERCENT_DISCOUNT) {
    discounted += _applyPercentDiscount(baseAmount, percentDiscount)
  }
  return { discounted, remainder }
}

/* Build pricing line items (manipulates remainingDollarDiscount and
 * return value has running Discount, Tax, and Gratuity
 */
const _processUpsells = (
  upsellPricingOrder,
  upsellPricingMap,
  isPromoCodeApplied,
  effectivePromoCodeType,
  remainingDollarDiscount,
  percentDiscount,
  venueGratuity,
  venueTaxGroups
) =>
  _.reduce(
    upsellPricingOrder,
    (resultData, upsellId) => {
      const { cost, gratuityChargeType, gratuityPercentage, isChargingGratuity, isChargingTax, name, taxGroupId } =
        upsellPricingMap[upsellId]
      let { runningDiscount, runningGratPaid, runningTaxPaid, upsellRemainingDollarDiscount } = resultData
      const gratuityDecimal = gratuityPercentage / 100
      const lineItem = {
        name,
        baseCost: 0,
        discount: 0,
        gratuity: 0,
        tax: 0,
        final: 0,
      }
      if (!cost) {
        return resultData
      }
      lineItem.baseCost = cost
      lineItem.discount = 0
      if (isPromoCodeApplied) {
        const { discounted, remainder } = _applyDiscount(
          lineItem.baseCost,
          effectivePromoCodeType,
          upsellRemainingDollarDiscount,
          percentDiscount
        )
        lineItem.discount = discounted
        upsellRemainingDollarDiscount = remainder
      }

      const runningCost = lineItem.baseCost - lineItem.discount
      if (isChargingGratuity) {
        if (gratuityChargeType === upsellGratuityTypes.SPECIFIC_GRATUITY) {
          lineItem.gratuity = currencyMult(runningCost, gratuityDecimal)
        } else if (gratuityChargeType === upsellGratuityTypes.DEFAULT_GRATUITY) {
          lineItem.gratuity = currencyMult(runningCost, venueGratuity)
        } else {
          _throwTypeError('gratuityChargeType', gratuityChargeType)
        }
      }
      const upsellTaxRate = _getTaxRate(venueTaxGroups, taxGroupId)
      if (isChargingTax) {
        lineItem.tax = currencyMult(runningCost, upsellTaxRate)
      }

      lineItem.final = _.sum([lineItem.baseCost, lineItem.gratuity, lineItem.tax])
      lineItem.final = currencySubtr(lineItem.final, lineItem.discount)

      runningDiscount = currencyAdd(runningDiscount, lineItem.discount)
      runningGratPaid = currencyAdd(runningGratPaid, lineItem.gratuity)
      runningTaxPaid = currencyAdd(runningTaxPaid, lineItem.tax)
      return {
        runningDiscount,
        runningTaxPaid,
        runningGratPaid,
        upsellRemainingDollarDiscount,
      }
    },
    {
      runningDiscount: 0,
      runningTaxPaid: 0,
      runningGratPaid: 0,
      upsellRemainingDollarDiscount: remainingDollarDiscount,
    }
  )

export const _processIncludedUpsells = (
  selectedUpsells,
  applyPromoToBase,
  effectivePromoCodeType,
  remainingDollarDiscount,
  percentDiscount
) => {
  let discountedUpsellsAmt = 0
  let includedUpsellBasePrice = 0
  let includedUpsellTax = 0
  let includedUpsellGratuity = 0
  let remainingAmt = remainingDollarDiscount || 0
  selectedUpsells.forEach(selectedUpsell => {
    let discountedAmt = 0
    if (applyPromoToBase) {
      const { discounted, remainder } = _applyDiscount(selectedUpsell.price, effectivePromoCodeType, remainingAmt, percentDiscount)
      discountedAmt = discounted
      remainingAmt = remainder
    }
    const selectedUpsellFinalPrice = selectedUpsell.price - discountedAmt
    includedUpsellBasePrice += selectedUpsell.price
    includedUpsellTax += currencyMult(selectedUpsellFinalPrice, selectedUpsell.tax)
    includedUpsellGratuity += currencyMult(selectedUpsellFinalPrice, selectedUpsell.gratuity)

    discountedUpsellsAmt += discountedAmt
  })
  return {
    baseAmount: roundToNearestHundredth(includedUpsellBasePrice),
    tax: roundToNearestHundredth(includedUpsellTax),
    gratuity: roundToNearestHundredth(includedUpsellGratuity),
    discountOnBase: roundToNearestHundredth(discountedUpsellsAmt),
    remainingDollarDiscount: remainingAmt,
  }
}

const _calculatePromoDiscount = (basePrice, remainingPromoValue, applyPromo, promoType, remainingPromoValueCap) => {
  /* eslint-disable no-param-reassign */
  let promoDiscount = 0
  if (applyPromo && remainingPromoValue) {
    if (promoType === 'DOLLAR_DISCOUNT') {
      if (remainingPromoValue >= basePrice) {
        remainingPromoValue -= basePrice
        promoDiscount = basePrice
        basePrice = 0
      } else {
        basePrice -= remainingPromoValue
        promoDiscount = remainingPromoValue
        remainingPromoValue = 0
      }
    } else if (promoType === 'PERCENT_DISCOUNT') {
      promoDiscount = basePrice * remainingPromoValue
      basePrice -= promoDiscount
    } else if (promoType === 'PERCENT_DISCOUNT_WITH_CAP') {
      promoDiscount = basePrice * remainingPromoValue
      if (remainingPromoValueCap >= promoDiscount) {
        basePrice -= promoDiscount
        remainingPromoValueCap -= promoDiscount
      } else {
        basePrice -= remainingPromoValueCap
        promoDiscount = remainingPromoValueCap
        remainingPromoValueCap = 0
      }
    }
  }
  return [basePrice, remainingPromoValue, promoDiscount, remainingPromoValueCap]
  /* eslint-enable no-param-reassign */
}

/*
  This selector will apply the algorithm for computing pricing on widget checkout
  output values: { baseAmount, upsellBaseAmount, promoCodeDiscount, paidInTax,
                   paidInGratuity, finalAmount }
*/
export const _derivePricingData = (
  promoCodeData,
  timeSlotFields,
  selectedUpsellData,
  venueTaxGroups,
  partySize,
  selectedBaseData,
  selectedUpsellInventoriesSortedPrices,
  timeSlotVenueEntity,
  selectedGratuityCharge,
  redemptions,
  isModifyResUpgradesMode
) => {
  // pricingData is the returned object, these properties are modified throughout func
  const pricingData = {
    baseAmount: 0,
    upsellBaseAmount: 0,
    promoCodeDiscount: 0,
    paidInServiceCharge: 0,
    paidInTax: 0,
    paidInGratuity: 0,
    paidInSelectedGratuity: 0,
    finalAmount: 0,
    amountDueCents: 0,
    amountDue: 0,
  }
  const { timeSlotTax, timeSlotServiceCharge, timeSlotGratuity, timeSlotApplyGratuityCharge, timeSlotGratuityType } = timeSlotFields
  const venueDefaultServiceCharge = currencyPercentage(timeSlotVenueEntity.defaultServiceCharge || 0)
  const venueDefaultGratuity = currencyPercentage(timeSlotVenueEntity.defaultGratuity || 0)
  const isPromoCodeApplied = !!promoCodeData.promoType
  const { promoType, promoValue, promoValueCap, promoComponent } = promoCodeData
  let remainingPromoValue = promoValue
  let remainingPromoValueCap = promoValueCap
  let totalPromoDiscount = 0
  const shouldApplyPromoToUpsells = promoComponent === promoComponentTypes.ALL || promoComponent === promoComponentTypes.UPGRADES
  const shouldApplyPromoToBasePrice = promoComponent === promoComponentTypes.ALL || promoComponent === promoComponentTypes.BASE_PRICE
  const applyPromoToUpsells = shouldApplyPromoToUpsells && isPromoCodeApplied
  const applyPromoToBase = shouldApplyPromoToBasePrice && isPromoCodeApplied

  // Ensure promoValue is defined
  if (isPromoCodeApplied && !promoValue) {
    _throwTypeError('promoValue', promoValue)
  }

  let discountedBasePrice = 0
  let reservationBasePrice = 0
  let automaticUpsells = []
  if (!isModifyResUpgradesMode) {
    reservationBasePrice = selectedBaseData.reservationBasePrice
    automaticUpsells = selectedBaseData.automaticUpsells
  }
  // Calculating reservation base price + promo discounts + tips + gratuity
  let updatedReservationBasePrice = reservationBasePrice
  const [basePriceUpdate, remainingPromoValueUpdate, totalDiscountUpdate, remainingPromoValueCapUpdate] = _calculatePromoDiscount(
    updatedReservationBasePrice,
    remainingPromoValue,
    applyPromoToBase,
    promoType,
    remainingPromoValueCap
  )

  updatedReservationBasePrice = basePriceUpdate
  remainingPromoValue = remainingPromoValueUpdate
  totalPromoDiscount += totalDiscountUpdate
  remainingPromoValueCap = remainingPromoValueCapUpdate
  const reservationBaseServiceCharge = updatedReservationBasePrice * timeSlotServiceCharge
  pricingData.paidInServiceCharge += reservationBaseServiceCharge
  pricingData.paidInTax += (updatedReservationBasePrice + reservationBaseServiceCharge) * timeSlotTax
  if (timeSlotApplyGratuityCharge) {
    if (timeSlotGratuityType === 'CLIENT_GRATUITY') {
      pricingData.paidInSelectedGratuity += updatedReservationBasePrice * selectedGratuityCharge
    } else if (timeSlotGratuityType === 'DEFAULT_GRATUITY') {
      pricingData.paidInGratuity += updatedReservationBasePrice * venueDefaultGratuity
    } else {
      pricingData.paidInGratuity += updatedReservationBasePrice * timeSlotGratuity
    }
  }
  pricingData.baseAmount += reservationBasePrice
  pricingData.baseAmount = roundToNearestHundredth(pricingData.baseAmount)
  discountedBasePrice += updatedReservationBasePrice

  // Calculating selected upsells prices + promo discounts + tips + gratuity
  const areUpsellsSelected = !_.isEmpty(selectedUpsellInventoriesSortedPrices)
  let discountedSelectedUpsellPrice = 0
  if (areUpsellsSelected) {
    selectedUpsellInventoriesSortedPrices.forEach(upsellInventory => {
      const selectedUpsellCategoryTaxRate = _getTaxRate(venueTaxGroups, upsellInventory.categoryObj.taxGroupId)
      for (let i = 0; i < upsellInventory.quantity; i += 1) {
        let updatedSelectedUpsellPrice = upsellInventory.price
        const promoFields = _calculatePromoDiscount(
          updatedSelectedUpsellPrice,
          remainingPromoValue,
          applyPromoToUpsells,
          promoType,
          remainingPromoValueCap
        )

        const [selectedUpsellsUpdate, remainingPromoValueUpdate, totalDiscountUpdate, remainingPromoValueCapUpdate] = promoFields
        updatedSelectedUpsellPrice = selectedUpsellsUpdate
        remainingPromoValue = remainingPromoValueUpdate
        totalPromoDiscount += totalDiscountUpdate
        remainingPromoValueCap = remainingPromoValueCapUpdate

        let selectedUpsellServiceCharge = 0
        if (upsellInventory.categoryObj.isChargingServiceCharge) {
          if (upsellInventory.categoryObj.serviceChargeType === 'DEFAULT_SERVICE_CHARGE') {
            selectedUpsellServiceCharge = updatedSelectedUpsellPrice * venueDefaultServiceCharge
            pricingData.paidInServiceCharge += selectedUpsellServiceCharge
          } else {
            const selectedUpsellCategoryServiceChargeValue = currencyPercentage(upsellInventory.categoryObj.serviceChargePercentage || 0)
            selectedUpsellServiceCharge = updatedSelectedUpsellPrice * selectedUpsellCategoryServiceChargeValue
            pricingData.paidInServiceCharge += selectedUpsellServiceCharge
          }
        }
        pricingData.paidInTax += (updatedSelectedUpsellPrice + selectedUpsellServiceCharge) * selectedUpsellCategoryTaxRate
        if (upsellInventory.categoryObj.isChargingGratuity) {
          if (upsellInventory.categoryObj.gratuityChargeType === 'CLIENT_GRATUITY') {
            pricingData.paidInSelectedGratuity += updatedSelectedUpsellPrice * selectedGratuityCharge
          } else if (upsellInventory.categoryObj.gratuityChargeType === 'DEFAULT_GRATUITY') {
            pricingData.paidInGratuity += updatedSelectedUpsellPrice * venueDefaultGratuity
          } else {
            const selectedUpsellGratuityValue = currencyPercentage(upsellInventory.categoryObj.gratuityPercentage || 0)
            pricingData.paidInGratuity += updatedSelectedUpsellPrice * selectedUpsellGratuityValue
          }
        }
        pricingData.upsellBaseAmount += upsellInventory.price
        discountedSelectedUpsellPrice += updatedSelectedUpsellPrice
      }
    })
  }
  pricingData.upsellBaseAmount = roundToNearestHundredth(pricingData.upsellBaseAmount)

  // Calculating automatic base price + promo discounts + tips + gratuity
  if (!_.isEmpty(automaticUpsells)) {
    automaticUpsells.forEach(automaticUpsell => {
      let updatedAutomaticUpsellPrice = automaticUpsell.price
      const promoFields = _calculatePromoDiscount(
        updatedAutomaticUpsellPrice,
        remainingPromoValue,
        applyPromoToBase,
        promoType,
        remainingPromoValueCap
      )
      const [upsellPriceUpdate, remainingPromoValueUpdate, totalDiscountUpdate, remainingPromoValueCapUpdate] = promoFields
      updatedAutomaticUpsellPrice = upsellPriceUpdate
      remainingPromoValue = remainingPromoValueUpdate
      totalPromoDiscount += totalDiscountUpdate
      remainingPromoValueCap = remainingPromoValueCapUpdate

      let automaticUpsellServiceCharge = 0
      if (automaticUpsell.isChargingServiceCharge) {
        if (automaticUpsell.serviceChargeType === 'DEFAULT_SERVICE_CHARGE') {
          automaticUpsellServiceCharge = updatedAutomaticUpsellPrice * venueDefaultServiceCharge
          pricingData.paidInServiceCharge += automaticUpsellServiceCharge
        } else {
          const automaticUpsellServiceChargeValue = currencyPercentage(automaticUpsell.serviceChargePercentage || 0)
          automaticUpsellServiceCharge = updatedAutomaticUpsellPrice * automaticUpsellServiceChargeValue
          pricingData.paidInServiceCharge += automaticUpsellServiceCharge
        }
      }
      const automaticUpsellCategoryTaxRate = _getTaxRate(venueTaxGroups, automaticUpsell.taxGroupId)
      pricingData.paidInTax += (updatedAutomaticUpsellPrice + automaticUpsellServiceCharge) * automaticUpsellCategoryTaxRate
      if (automaticUpsell.isChargingGratuity) {
        if (automaticUpsell.gratuityChargeType === 'CLIENT_GRATUITY') {
          pricingData.paidInSelectedGratuity += updatedAutomaticUpsellPrice * selectedGratuityCharge
        } else if (automaticUpsell.gratuityChargeType === 'DEFAULT_GRATUITY') {
          pricingData.paidInGratuity += updatedAutomaticUpsellPrice * venueDefaultGratuity
        } else {
          const automaticUpsellGratuityValue = currencyPercentage(automaticUpsell.gratuityPercentage || 0)
          pricingData.paidInGratuity += updatedAutomaticUpsellPrice * automaticUpsellGratuityValue
        }
      }
      pricingData.baseAmount += automaticUpsell.price
      discountedBasePrice += updatedAutomaticUpsellPrice
    })
  }

  // If there is nothing to charge return default empty state
  if (!pricingData.baseAmount && !areUpsellsSelected) {
    return pricingData
  }

  // Calculate final amount
  pricingData.promoCodeDiscount = roundToNearestHundredth(totalPromoDiscount)
  const totalAmount = currencyAddSeq([
    discountedBasePrice,
    discountedSelectedUpsellPrice,
    pricingData.paidInServiceCharge,
    pricingData.paidInTax,
    pricingData.paidInGratuity,
    pricingData.paidInSelectedGratuity,
  ])
  pricingData.paidInServiceCharge = roundToNearestHundredth(pricingData.paidInServiceCharge)
  pricingData.paidInTax = roundToNearestHundredth(pricingData.paidInTax)
  pricingData.paidInGratuity = roundToNearestHundredth(pricingData.paidInGratuity)
  pricingData.paidInSelectedGratuity = roundToNearestHundredth(pricingData.paidInSelectedGratuity)
  pricingData.finalAmount = roundToNearestHundredth(totalAmount)
  pricingData.amountDue = roundToNearestHundredth(totalAmount)

  const redemptionAmount = _.reduce(
    redemptions,
    (acc, redemption) => {
      if (redemption && _.isNumber(redemption.amountToRedeem)) {
        return redemption.amountToRedeem + acc
      }

      return acc
    },
    0
  )

  pricingData.amountDue = roundToNearestHundredth(totalAmount - redemptionAmount)
  pricingData.amountDueCents = amountToCents(pricingData.amountDue)

  return pricingData
}

export const selectPricingData = createSelector(
  [
    selectPromoCodeData,
    selectTimeSlotFields,
    selectIncludedUpsells,
    getVenueTaxGroups,
    getPartySize,
    selectedBaseData,
    selectedUpsellInventoriesSortedPrices,
    getTimeSlotVenueEntity,
    getSelectedGratuityCharge,
    getRedemptions,
    isModifyResUpgradesMode,
  ],
  _derivePricingData
)

export const selectHasSubtotal = createSelector(
  [selectPricingData],
  pricingData => !!(pricingData.baseAmount + pricingData.upsellBaseAmount)
)

export const selectHasOutstandingBalance = createSelector([selectPricingData], pricingData => !!pricingData.amountDue)

const hasCreditCardOnFile = state => state.modifyReservation.get('hasCreditCardOnFile')

// Selector for isPaymentRequired flag
// false: no cc required, true: cc required
export const _deriveIsPaymentInfoRequired = (hasOutstandingBalance, partySize, timeSlotEntity, hasCreditCardOnFile) => {
  // if there is a balance on the res, ask for cc
  if (hasOutstandingBalance) {
    return true
  }

  const { requireCreditCard, ccPaymentRule, ccPartySizeMin } = timeSlotEntity

  // if there is no balance, but PaymentRule is SAVE_FOR_LATER, ask for cc if
  // min party size is met
  if (
    requireCreditCard &&
    !hasCreditCardOnFile &&
    ccPaymentRule === creditCardPaymentRuleTypes.SAVE_FOR_LATER &&
    partySize > ccPartySizeMin
  ) {
    return true
  }

  return false
}

export const selectIsPaymentInfoRequired = createSelector(
  [selectHasOutstandingBalance, getPartySize, selectTimeSlotEntity, hasCreditCardOnFile],
  _deriveIsPaymentInfoRequired
)

export const selectIsUsingGiftCard = createSelector([selectPricingData], pricingData => !!pricingData.finalAmount)
