import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeEnabledLanguagesParams = state => ({
  languages: state.languages.venueLanguages,
})

const computeEnabledLanguagesValues = ({ languages = [] }) => languages.filter(language => language.is_enabled)

export const selectEnabledLanguages = createShallowEqualSelector(computeEnabledLanguagesParams, computeEnabledLanguagesValues)

const computeDefaultLanguage = state => state.languages.defaultLanguage

const computeLanguageStringSettingsLanguages = (enabledLanguages, defaultLanguage) =>
  enabledLanguages.filter(language => language.value !== defaultLanguage.value)

export const selectLanguageStringSettingsLanguages = createShallowEqualSelector(
  [selectEnabledLanguages, computeDefaultLanguage],
  computeLanguageStringSettingsLanguages
)

export const selectClientPreferredLanguage = state => {
  const defaultLanguage = state.languages.defaultLanguage ? state.languages.defaultLanguage.value : ''
  return state.bookClientState.selectedClient && state.bookClientState.selectedClient.preferred_language_code
    ? state.bookClientState.selectedClient.preferred_language_code
    : defaultLanguage
}
