export const wysiwigFieldHasContents = value => {
  const vdiv = document.createElement('div')
  vdiv.innerHTML = value
  const parsed = vdiv.textContent || vdiv.innerText || ''
  return !!parsed.trim()
}

export const doShowDiningAdditionalSelectionModal = (
  publicLongFormDescription,
  publicDescriptionTitle,
  publicPhoto,
  selectedAutomaticUpsells
) =>
  !!(
    wysiwigFieldHasContents(publicLongFormDescription) ||
    (publicDescriptionTitle || '').trim() ||
    (publicPhoto || '').trim() ||
    Object.keys(selectedAutomaticUpsells || {}).length > 0
  )
