import { Map } from 'immutable'
import { GET_RESERVATION_REQUEST_SUCCESS, GET_RESERVATION_REQUEST_FAIL } from 'widget/dining/actions/ActionTypes'
import { defaultRequestState } from 'widget/dining/utils/preloadedState'

const reservationRequestReducer = (
  state = Map({
    isRequestBookingMode: false,
    id: null,
    reservationRequest: defaultRequestState(),
  }),
  action
) => {
  switch (action.type) {
    case GET_RESERVATION_REQUEST_SUCCESS:
      return state.merge({
        reservationRequest: action.reservationRequest,
      })
    case GET_RESERVATION_REQUEST_FAIL:
      return state.merge({
        isRequestBookingMode: false,
      })
    default:
      return state
  }
}

export default reservationRequestReducer
