import { Map } from 'immutable'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  GET_WAITLIST_ETAS_START,
  GET_WAITLIST_ETAS_SUCCESS,
  GET_WAITLIST_ETAS_FAIL,
  INCREMENT_WAITLIST_PARTY_SIZE,
  DECREMENT_WAITLIST_PARTY_SIZE,
  GET_WAITLIST_ENTRY_SUCCESS,
  GET_WAITLIST_ENTRY_FAIL,
  SUBMIT_WAITLIST_SUCCESS,
  CANCEL_WAITLIST_ENTRY_SUCCESS,
  REJOIN_WAITLIST,
} from 'widget/dining/actions/ActionTypes'

const waitlistReducer = (
  state = Map({
    isWaitlistMode: true,
  }),
  action
) => {
  switch (action.type) {
    case GET_WAITLIST_ETAS_START: {
      return state.merge({
        isLoading: true,
      })
    }
    case GET_WAITLIST_ETAS_SUCCESS: {
      const { isVenueOpen } = action
      const { isWaitlistOpen } = action
      let waitlistEtas
      let chosenAccessRuleId
      const accessIdsToShiftIds = action.accessIdsToShiftIds || {}
      if (isVenueOpen && isWaitlistOpen) {
        chosenAccessRuleId = Object.keys(action.waitlistEtasByAccessRules)[0]
        waitlistEtas = action.waitlistEtasByAccessRules[chosenAccessRuleId]
      }
      const partySize = waitlistEtas ? waitlistEtas[0].size : 0
      const quotedWaitTime = waitlistEtas ? waitlistEtas[0].estimated_wait_time : -1
      const canBook = waitlistEtas ? waitlistEtas[0].estimated_wait_time !== -1 : false
      return state.merge({
        isVenueOpen,
        isWaitlistOpen,
        shiftPersistentId: accessIdsToShiftIds[chosenAccessRuleId],
        chosenAccessRuleId,
        validPartySizes: waitlistEtas,
        party_size: partySize,
        quoted_wait_time: quotedWaitTime,
        canBook,
        isLoading: false,
      })
    }
    case GET_WAITLIST_ENTRY_FAIL: {
      return state.merge({
        isVenueOpen: true,
        isWaitlistOpen: false,
        id: null,
      })
    }
    case GET_WAITLIST_ETAS_FAIL: {
      return state.merge({
        isLoading: false,
        canBook: false,
      })
    }
    case INCREMENT_WAITLIST_PARTY_SIZE: {
      const { maxGuests } = action
      const validPartySizes = state.get('validPartySizes').toJS()
      const currentPartySize = state.get('party_size')
      const nextPartySize = currentPartySize + 1
      if (nextPartySize > maxGuests) {
        return state
      }
      const nextPartySizeIndex = _.findIndex(validPartySizes, partySize => nextPartySize === partySize.size)
      if (nextPartySizeIndex === -1) {
        return state.merge({
          party_size: nextPartySize,
          quoted_wait_time: -1,
          canBook: false,
        })
      }
      const newPartySize = validPartySizes[nextPartySizeIndex]
      return state.merge({
        party_size: newPartySize.size,
        quoted_wait_time: newPartySize.estimated_wait_time,
        canBook: newPartySize.estimated_wait_time !== -1,
      })
    }
    case DECREMENT_WAITLIST_PARTY_SIZE: {
      const { minGuests } = action
      const validPartySizes = state.get('validPartySizes').toJS()
      const currentPartySize = state.get('party_size')
      const nextPartySize = currentPartySize - 1
      if (nextPartySize < minGuests) {
        return state
      }
      const nextPartySizeIndex = _.findIndex(validPartySizes, partySize => nextPartySize === partySize.size)
      if (nextPartySizeIndex === -1) {
        return state.merge({
          party_size: nextPartySize,
          quoted_wait_time: -1,
          canBook: false,
        })
      }
      const newPartySize = validPartySizes[nextPartySizeIndex]
      return state.merge({
        party_size: newPartySize.size,
        quoted_wait_time: newPartySize.estimated_wait_time,
        canBook: newPartySize.estimated_wait_time !== -1,
      })
    }
    case SUBMIT_WAITLIST_SUCCESS:
    case GET_WAITLIST_ENTRY_SUCCESS:
    case CANCEL_WAITLIST_ENTRY_SUCCESS: {
      const { waitlistEntry } = action

      return state.merge({
        sort_order: waitlistEntry.sort_order,
        quoted_wait_time: waitlistEntry.quoted_wait_time,
        party_size: parseInt(waitlistEntry.party_size, 10),
        start_wait_time: waitlistEntry.start_wait_time,
        wait_duration: waitlistEntry.wait_duration,
        id: waitlistEntry.id,
        status: waitlistEntry.status,
        parties_ahead: waitlistEntry.parties_ahead,
      })
    }
    case REJOIN_WAITLIST:
      return state.merge({
        sort_order: null,
        quoted_wait_time: null,
        party_size: null,
        start_wait_time: null,
        wait_duration: null,
        id: null,
        status: null,
        parties_ahead: null,
      })
    default:
      return state
  }
}

export default waitlistReducer
