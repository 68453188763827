import React, { Component } from 'react'
import styled from 'styled-components'

class Button extends Component {
  constructor(props) {
    super(props)

    this.clickHandler = e => {
      e.stopPropagation()
      if (this.props.onClick) {
        this.props.onClick(e)
      }
    }
  }

  keyPressHandler(event) {
    event.stopPropagation()
  }

  render() {
    const { buttonId, innerElement, style, activeStyling, isLeft, isRight, disabled, ariaLabel, withDefaultOutline, testId } = this.props
    return (
      <StyledButton
        id={buttonId}
        onClick={this.clickHandler}
        onKeyDown={this.keyPressHandler}
        isActive={activeStyling}
        isLeft={isLeft}
        withDefaultOutline={withDefaultOutline}
        isRight={isRight}
        customSyle={style}
        disabled={disabled}
        aria-label={ariaLabel}
        data-test={testId}
      >
        {innerElement}
      </StyledButton>
    )
  }
}

Button.propTypes = {
  innerElement: React.PropTypes.node.isRequired,
  onClick: React.PropTypes.oneOfType([React.PropTypes.func, React.PropTypes.bool]).isRequired,
  ariaLabel: React.PropTypes.string,
  style: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
  activeStyling: React.PropTypes.bool,
  testId: React.PropTypes.string,
}

Button.defaultProps = {
  onClick: () => {},
  ariaLabel: undefined,
  style: {},
  activeStyling: false,
}

const StyledButton = styled.button`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  font-family: inherit;
  background-color: transparent;
  color: inherit;
  :focus-visible {
    opacity: 0.8;
    outline: 5px auto Highlight;
    outline: -webkit-focus-ring-color auto 1px;
  }
  ${props => !props.withDefaultOutline && 'outline: none;'}
  ${props => props.isLeft && 'left: -40px;'}
  ${props => props.isRight && 'right: -40px;'}
  ${props => props.customSyle}
  ${props => props.isActive && '{ :active { background-color: rgba(199, 226, 241, 1); } }'}
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => props.disabled && 0.5};
  pointer-events: ${props => props.disabled && 'none'};
 }
`

export default Button
