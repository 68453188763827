import Immutable from 'immutable'
import { CHANGE_VENUE_LANGUAGE } from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { SAVE_TAG_GROUP, INITIALIZE_TAGS } from '../actions/ActionTypes'

const tags = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case INITIALIZE_TAGS:
      return state.merge(action.tags)
    case SAVE_TAG_GROUP:
      return state.setIn(['tagGroups', action.groupId, 'selectedTags'], Immutable.Map(action.tagGroup))
    case CHANGE_VENUE_LANGUAGE:
      return state.updateIn(['tagGroups'], tagGroups => tagGroups.map(tagGroup => tagGroup.setIn(['selectedTags'], {})))
    default:
      return state
  }
}

export default tags
