import _ from 'lodash'
import Radium from 'radium'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { changeFormField } from '../actions/forms'
import {
  viewRedemptionBalance,
  applyRedemption,
  clearRedemptionForm,
  showRedemptionForm,
  removeRedemption,
  cancelRedemptionForm,
} from '../actions/services'
import { RedemptionForm } from '../components/RedemptionForm'
import { RedemptionItem } from '../components/RedemptionItem'

const GiftCardButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0;
`

const GiftCardButton = styled.button`
  font-size: 13px;
  margin-top: 10px;
  color: ${props => props.fontsColorPrimary};
  background-color: transparent;
  border: 0;
  text-decoration: ${props => (props.disabled ? 'none' : 'underline')};
  outline: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const RedemptionError = styled.div`
  margin-top: 10px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  color: ${props => props.colorError};
`

const RedemptionComponent = props => {
  const {
    redemptions,
    isRedemptionFormVisible,
    showRedemptionForm,
    applyRedemption,
    clearRedemptionForm,
    viewRedemptionBalance,
    changeFormField,
    removeRedemption,
    cancelRedemptionForm,
    redemptionError,
    colorError,
  } = props

  return (
    <div>
      <GiftCardButtonWrapper>
        <GiftCardButton
          {...props}
          type="button"
          disabled={isRedemptionFormVisible || _.size(redemptions) >= 1}
          onClick={showRedemptionForm}
        >
          Redeem a Gift Card
        </GiftCardButton>
      </GiftCardButtonWrapper>
      {_.map(redemptions, (redemption, index) => {
        if (redemption) {
          return (
            <RedemptionItem
              {...props}
              key={`${redemption.redemptionCardId}-${index}`}
              redemption={redemption}
              onRemove={() => {
                removeRedemption(index)
              }}
            />
          )
        }

        return undefined
      })}
      {isRedemptionFormVisible && (
        <RedemptionForm
          {...props}
          onFormFieldChange={changeFormField}
          onClear={clearRedemptionForm}
          onCancel={cancelRedemptionForm}
          onViewBalance={viewRedemptionBalance}
          onApply={applyRedemption}
        />
      )}
      {!_.isNil(redemptionError) && <RedemptionError colorError={colorError}>{redemptionError}</RedemptionError>}
    </div>
  )
}

const mapStateToProps = state => ({
  redemptionError: state.redemption.redemptionError,
  isFormDisabled: state.redemption.isFormDisabled,
  redemptions: state.redemption.redemptions,
  isRedemptionFormVisible: state.redemption.isRedemptionFormVisible,
  isViewBalanceVisible: state.redemption.isViewBalanceVisible,
  redemptionCardBalance: state.redemption.redemptionCardBalance,
  redemptionCardId: state.formFields.get('redemptionCardId'),
  redemptionCardPin: state.formFields.get('redemptionCardPin'),
  amountToRedeem: state.formFields.get('amountToRedeem'),
  formErrors: state.formFields.get('formErrors').toJS(),
  fontFamily: state.widgetSettings.font,
  fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
  fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
  colorError: state.widgetSettings.colorError,
  currencySymbol: state.venueInfo.currencySymbol,
  // colors
  colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
  colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
  colorPrimary: state.widgetSettings.colorPrimary,
  colorSummaryBar: state.widgetSettings.colorSummaryBar,
  colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
  fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
  fontsColorButton: state.widgetSettings.fontsColorButton,
  fontsColorLinks: state.widgetSettings.fontsColorLinks,
  fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
  fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
  colorLines: state.widgetSettings.colorLines,
})

const mapDispatchToProps = {
  changeFormField,
  viewRedemptionBalance,
  applyRedemption,
  clearRedemptionForm,
  showRedemptionForm,
  removeRedemption,
  cancelRedemptionForm,
}

export const Redemption = connect(mapStateToProps, mapDispatchToProps)(Radium(RedemptionComponent))
