import { Map } from 'immutable'
import { GET_EXPERIENCE_SUCCESS } from 'widget/dining/actions/ActionTypes'

const experienceReducer = (
  state = Map({
    isExperienceMode: false,
    id: null,
    experience: {
      name: '',
    },
  }),
  action
) => {
  switch (action.type) {
    case GET_EXPERIENCE_SUCCESS:
      return state.merge({
        experience: action.experience.experience,
      })
    default:
      return state
  }
}

export default experienceReducer
