// /* @flow */
import moment from 'moment-timezone'
import { DayRanges } from './Constants'

const LONG_FORMATS = ['llll', 'LLLL', 'lll', 'LLL', 'll', 'LL', 'LT', 'LTS', 'l', 'L']

export function formatWithoutYear(date, format) {
  // Tries to strip year from localized formats
  const localeData = moment.localeData()

  let updated = format
  for (const longFormat of LONG_FORMATS) {
    if (updated.includes(longFormat)) {
      updated = updated.replace(longFormat, localeData.longDateFormat(longFormat))
    }
  }
  // Strips year and leading/trailing punctuation from the long format, like 'mm/dd/yyyy' becomes 'mm/dd'
  updated = updated.replace(/[\s,./-]*YYYY[\s,./-]*/, '')

  return date.format(updated)
}

export const timeParts = (dateTime /* : moment.Moment */) => ({
  h: dateTime.hours(),
  m: dateTime.minutes(),
})

// Canonical moment normalized to the same date for the passed in time
export const formatTimeOnly = (dateTime /* : moment.Moment */) =>
  moment({
    Y: 2000,
    M: 1,
    D: 1,
    ...timeParts(dateTime),
  })

export const dateParts = (dateTime /* : moment.Moment */) => ({
  Y: dateTime.year(),
  M: dateTime.month(),
  D: dateTime.date(),
})
export const formatDateOnly = (dateTime /* : moment.Moment */) => moment(dateParts(dateTime))

export const parseDate = dateString => dateString && formatDateOnly(moment(dateString, 'YYYY-MM-DD'))

export const secondsSinceMidnight = (dateTime /* : moment.Moment */) => dateTime.diff(dateTime.clone().startOf('day'))

export const dayRangeDates = range => {
  const yesterday = moment().subtract(1, 'days')
  switch (range) {
    case DayRanges.YESTERDAY:
      return {
        start: yesterday,
        end: yesterday,
      }
    case DayRanges.L3DAY:
      return {
        start: moment().subtract(3, 'days'),
        end: moment(),
      }
    case DayRanges.L7DAY:
      return {
        start: moment().subtract(7, 'days'),
        end: moment(),
      }
    case DayRanges.L30DAY:
      return {
        start: moment().subtract(30, 'days'),
        end: moment(),
      }
    case DayRanges.THISMONTH:
      return {
        start: moment().startOf('month'),
        end: moment(),
      }
    case DayRanges.LASTMONTH:
      const lastmonth = moment().subtract(1, 'month')
      return {
        start: lastmonth.clone().startOf('month'),
        end: lastmonth.clone().endOf('month'),
      }
    case DayRanges.THISYEAR:
      return {
        start: moment().startOf('year'),
        end: moment(),
      }
    case DayRanges.LASTYEAR:
      const lastyear = moment().subtract(1, 'year')
      return {
        start: lastyear.clone().startOf('year'),
        end: lastyear.clone().endOf('year'),
      }
    default:
      return {
        start: yesterday,
        end: yesterday,
      }
  }
}

export const formattedDayRangeDates = range => {
  const { start, end } = dayRangeDates(range)
  return {
    start_date: start.format('MM/DD/YYYY'),
    end_date: end.format('MM/DD/YYYY HH:MM'),
  }
}

/**
 * If date time is after midnight, adds 1 day. Intended for use of time iteration over a canonical venue date
 * (e.g. all times are from 1970-01-01T06:00 to 1970-01-02T06:00
 */
export const adjustForStartOfDay = (timeMoment, startOfDayHour, isEndTime = false) => {
  if (timeMoment.hour() < startOfDayHour || (isEndTime && timeMoment.minute() === 0 && timeMoment.hour() === startOfDayHour)) {
    return timeMoment.clone().add({ days: 1 })
  }
  return timeMoment
}

/**
 * If date time is before midnight, treat as the previous logical venue date.  Returns a moment with date parts only.
 */
export const venueDateForDatetime = (dateTimeMoment, startOfDayHour) => {
  const adjustedDateTime = dateTimeMoment.hour() < startOfDayHour ? dateTimeMoment.clone().subtract({ days: 1 }) : dateTimeMoment
  return formatDateOnly(adjustedDateTime)
}

export const getPythonWeekday = date => (date.day() === 0 ? 6 : date.day() - 1)

export const getAdjustedWeekday = date => (date.day() === 0 ? 7 : date.day())
