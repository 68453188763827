import Radium from 'radium'
import React from 'react'
import styles from 'svr/lib/Modal/modals'
import { closeOnEscapeKeyDown } from 'svr/lib/Modal/modalUtils'

const firstElementId = 'sr-policy-popover-first'
const lastElementId = firstElementId

const PolicyPopover = ({ policyHeader, policyDetails, dismissModal, headerColor, headerTextColor, textColor }) => {
  closeOnEscapeKeyDown(dismissModal)
  return (
    <div
      style={styles.modalWidth}
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialog-modal-header"
      aria-describedby="dialog-modal-content"
    >
      <div
        style={[
          styles.policyHeader,
          {
            backgroundColor: headerColor,
            color: headerTextColor,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        ]}
      >
        <span id="dialog-modal-header" style={styles.headerText}>
          {policyHeader}
        </span>
        <button
          type="button"
          id={firstElementId}
          onClick={dismissModal}
          style={[styles.headerX, { backgroundColor: headerColor, color: headerTextColor }]}
          aria-label="Close modal"
        >
          ×
        </button>
      </div>
      <div
        id="dialog-modal-content"
        style={[styles.policyBody, { color: textColor }]}
        dangerouslySetInnerHTML={{ __html: policyDetails }}
      />
    </div>
  )
}

PolicyPopover.propTypes = {
  policyHeader: React.PropTypes.string.isRequired,
  policyDetails: React.PropTypes.string.isRequired,
  dismissModal: React.PropTypes.func.isRequired,
  headerColor: React.PropTypes.string,
  headerTextColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
}

PolicyPopover.defaultProps = {
  headerColor: 'black',
  headerTextColor: 'grey',
  textColor: 'black',
}

PolicyPopover.firstElementId = firstElementId
PolicyPopover.lastElementId = lastElementId

export default Radium(PolicyPopover)
