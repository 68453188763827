// /* @flow */
import _ from 'lodash'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import * as actionTypes from '../actionTypes'
import { minQuantityTypes } from '../constants'
import { getMemoizedDOMData, resetMemoization } from '../utils/getDOMData'
/* import type { Action } from '../flowTypes' */
/* import type {
  MaxQuantityTypes,
  MinQuantityTypes,
  GratuityChargeType,
} from '../constants' */

/* export type UpsellCategoryEntity = {
  +id: string,
  +maxQuantityType: MaxQuantityTypes, // set to category
  +maxQuantityInt: number,
  +minQuantityType: MinQuantityTypes,
  +minQuantityInt: number,
  +name: string,
  +showCategoryFlag: boolean,
  +isChargingTax: boolean,
  +isChargingGratuity: boolean,
  +gratuityChargeType: GratuityChargeType,
  +gratuityPercentage: number,
  +inventories: Array<string>,
} */

/* export type UpsellInventoryEntity = {
  +id: string,
  +categoryId: string,
  +description: string,
  +previewImageUrlKey: string,
  +name: string,
  +price: number,
} */

/* type UpsellState = {
  +categories: { [string]: UpsellCategoryEntity },
  +inventories: { [string]: UpsellInventoryEntity },
} */

export default (state /* : UpsellState */ = getInitialUpgradeState(), action /* : Action */) /* : UpsellState */ => {
  switch (action.type) {
    case actionTypes.RE_INITIALIZE_UPGRADES:
      return getInitialUpgradeState(true)
    default:
      return state
  }
}

function getInitialUpgradeState(resetMemo = false) /* : UpsellState */ {
  if (resetMemo) {
    resetMemoization()
  }
  const upsellsData = getMemoizedDOMData()
  const categories = _.reduce(
    upsellsData.categories,
    (result, entity) => {
      const categoryProps = _.omit(entity, ['deleted'])
      return {
        ...result,
        [entity.id]: {
          ...camelCaseObject(categoryProps),
          isRequired: entity.min_quantity_type !== minQuantityTypes.NONE_REQUIRED,
          inventories: [],
        },
      }
    },
    {}
  )

  const inventories = _.reduce(
    upsellsData.inventory,
    (result, entity) => {
      const inventoryProps = _.omit(entity, ['deleted', 'venue_id'])
      return {
        ...result,
        [entity.id]: camelCaseObject(inventoryProps),
      }
    },
    {}
  )

  // within category entities, build inventory array (sorted by reverse sortOrder)
  _.each(inventories, (inventoryEntity, inventoryId) => {
    const categoryEntity = categories[inventoryEntity.categoryId]
    if (categoryEntity) {
      if (!categoryEntity.inventories) {
        categoryEntity.inventories = []
      }
      categoryEntity.inventories.push(inventoryId)
    }
  })
  _.each(categories, categoryEntity => {
    categoryEntity.inventories = _.sortBy(
      categoryEntity.inventories,
      // negative sortOrder is used here as a reverse
      inventoryId => -inventories[inventoryId].sortOrder
    )
  })
  return { categories, inventories }
}
