// /* @flow */
import _ from 'lodash'
import * as actionTypes from '../actionTypes'
import { getMemoizedDOMData, resetMemoization } from '../utils/getDOMData'
/* import type { Action } from '../flowTypes' */

/* export type InventoryEditState = {
  +[string]: { quantity: number, expandReadMore: boolean },
} */

export default (state /* : InventoryEditState */ = getInitialState(), action /* : Action */) /* : InventoryEditState */ => {
  switch (action.type) {
    case actionTypes.RE_INITIALIZE_UPGRADES:
      return getInitialState(true)
    case actionTypes.SELECT_TIME_SLOT:
      return getInitialState()
    case actionTypes.CHANGE_INVENTORY_QUANTITY:
      return {
        ...state,
        [action.inventoryId]: {
          ...state[action.inventoryId],
          quantity: action.quantity,
        },
      }
    case actionTypes.CLICK_INVENTORY_DESCRIPTION:
      return {
        ...state,
        [action.inventoryId]: {
          ...state[action.inventoryId],
          expandReadMore: !action.expandReadMore,
        },
      }
    default:
      return state
  }
}

function getInitialState(resetMemo = false) /* : InventoryEditState */ {
  if (resetMemo) {
    resetMemoization()
  }
  const upsellsData = getMemoizedDOMData()

  const inventoryEdits = _.reduce(
    upsellsData.inventory,
    (result, entity) => ({
      ...result,
      [entity.id]: { quantity: 0, expandReadMore: false },
    }),
    {}
  )
  return inventoryEdits
}
