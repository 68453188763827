import _ from 'lodash'
import moment from 'moment'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'

/*
  Utilities for times
  Assumes moment() has the primary data structure for dates & times
*/

const _EPOCH = '1970-01-01'
const _SORT_ORDER_INTERVAL = 15 // minutes
export const MIN_SORT_ORDER = 0
export const MAX_SORT_ORDER = 95 // 24 * 60 / 15 - 1

export const isPastTime = (order, timezone, startOfDayHour, date) => {
  const currentTime = getVenueLocalTime(timezone)
  const currentOrder = timeToOrder(startOfDayHour, currentTime)
  const currentDayAtMidnight = getVenueToday(timezone, startOfDayHour)
  const selectedDateAtMidnight = date.clone().startOf('day')
  const isPastDate = selectedDateAtMidnight.isBefore(currentDayAtMidnight, 'day')
  const isCurrentDate = selectedDateAtMidnight.isSame(currentDayAtMidnight, 'day')
  return isPastDate || (isCurrentDate && order < currentOrder)
}

export const getVenueToday = (timezone, startOfDayHour) => {
  const localTime = getVenueLocalTime(timezone)
  const adjustedLocalTime = localTime.subtract({ hours: startOfDayHour })
  return adjustedLocalTime.startOf('day')
}

export const getVenueLocalTime = (timezone, dateTime) => moment(moment.utc(dateTime).tz(timezone).format('LLLL'))

export const getVenueLocalTimeOrder = (timezone, startOfDayHour) => {
  const localTime = getVenueLocalTime(timezone)
  return timeToOrder(startOfDayHour, localTime)
}

export const dateToLocalTimezoneStartOfDay = (date, timezone) =>
  // When setting tz on moment, it applies the timezone offset
  // so have to reset the date after the conversion to get the
  // midnight date local to timezone
  moment(date).tz(timezone).year(date.year()).month(date.month()).date(date.date()).startOf('day')

export const dateToPythonWeekday = date => {
  const dowJs = date.day()
  let dowPy = dowJs - 1
  dowPy = dowPy < 0 ? 6 : dowPy
  return dowPy
}

export const zeroIndexMonth = month => {
  const zeroedMonth = month - 1
  return zeroedMonth < 0 ? 11 : zeroedMonth
}

export const createStartOfDayTime = startOfDayHour => {
  const padded = pad2(startOfDayHour)
  return moment(`${_EPOCH} ${padded}:00:00`)
}

export const createLocalTime = timeString => moment(`${_EPOCH} ${timeString}`)

export const orderToTime = (startOfDayHour, order) => createStartOfDayTime(startOfDayHour).add(order * _SORT_ORDER_INTERVAL, 'minutes')

export const timeToOrder = (startOfDayHour, momentTime) => {
  const epochMomentTime = createLocalTime(momentTime.format('LT'))
  const startOfDayMomentTime = createStartOfDayTime(startOfDayHour)
  if (epochMomentTime < startOfDayMomentTime) {
    epochMomentTime.add(1, 'd')
  }
  const duration = moment.duration(epochMomentTime.diff(startOfDayMomentTime))
  const durationHours = parseInt(duration.asHours())
  const durationMins = duration.asMinutes() % 60
  return durationHours * 4 + Math.floor(durationMins / _SORT_ORDER_INTERVAL)
}

export const timeListToOrder = (startOfDayHour, timeList) => {
  const orderList = []
  for (const time of timeList) {
    orderList.push(timeToOrder(startOfDayHour, createLocalTime(time)))
  }
  return orderList
}
/** Creates an array of moments between startMoment and endMoment (inclusive) with the given interval in minutes.
 * The last moment returned will be equal to endMoment, even if the interval does not divide evenly.
 *
 * @precondition: intervalInMinutes > 0
 *
 * @param intervalInMinutes: number
 * @param startMoment: moment.Moment
 * @param endMoment: moment.Moment
 * @returns moment.Moment[]
 */
const createMomentsWithIntervalInMinutes = (intervalInMinutes, startMoment, endMoment) => {
  if (!startMoment || !startMoment.isValid() || !endMoment || !endMoment.isValid()) {
    throw new Error('Start time and end time are required')
  }
  if (intervalInMinutes <= 0) {
    throw new Error('Interval must be positive')
  }

  const accumulator = []
  let nextMoment = startMoment.clone()
  if (startMoment > endMoment) {
    while (nextMoment.isAfter(endMoment)) {
      accumulator.push(nextMoment.clone())
      nextMoment = nextMoment.subtract(intervalInMinutes, 'minutes')
    }
  } else if (endMoment > startMoment) {
    while (nextMoment.isBefore(endMoment)) {
      accumulator.push(nextMoment.clone())
      nextMoment = nextMoment.add(intervalInMinutes, 'minutes')
    }
  } else {
    return [startMoment]
  }
  accumulator.push(endMoment.clone())
  return accumulator
}

const ONE_DAY_IN_MINUTES = 24 * 60
/** Creates a non-repeating array of times between startTime and endTime (inclusive) with the given interval.
 * Only times are considered. (Maximum span 24 hours)
 *
 * @param intervalInMinutes: number
 * @param startTime: moment.Moment = 0:00:00
 * @param endTime: moment.Moment = startTime + 24hrs
 * @returns moment.Moment[]
 */
export const timesByMinuteDuration = (intervalInMinutes, startTime, endTime) => {
  if (!intervalInMinutes) {
    return []
  }

  let initialMoment = moment()
  const isAscending = intervalInMinutes > 0
  if (startTime && startTime.isValid()) {
    initialMoment = initialMoment.hours(startTime.hours()).minutes(startTime.minutes())
  } else {
    initialMoment = initialMoment.hours(0).minutes(0)
  }
  initialMoment = initialMoment.seconds(0)
  const validEndTimeProvided = endTime && endTime.isValid()

  let endMoment = initialMoment.clone()
  if (validEndTimeProvided) {
    endMoment = endMoment.hours(endTime.hours()).minutes(endTime.minutes())
  }
  if (isAscending && (endMoment.isSameOrBefore(initialMoment) || !validEndTimeProvided)) {
    endMoment = endMoment.add(ONE_DAY_IN_MINUTES, 'minutes')
  } else if (!isAscending && (endMoment.isSameOrAfter(initialMoment) || !validEndTimeProvided)) {
    endMoment = endMoment.subtract(ONE_DAY_IN_MINUTES, 'minutes')
  }
  const momentArray = createMomentsWithIntervalInMinutes(Math.abs(intervalInMinutes), initialMoment, endMoment)

  if (endMoment.hours() === initialMoment.hours() && endMoment.minutes() === initialMoment.minutes() && momentArray.length > 1) {
    momentArray.pop()
  }

  return momentArray
}

/** Creates a non-repeating array of times (formatted with optional displayFormat) between startTime and endTime with the given interval.
 * Only times are considered. (Maximum span 24 hours)
 *
 * @param intervalInMinutes: number
 * @param startTime: moment.Moment = 0:00:00
 * @param endTime: moment.Moment = startTime + 24hrs
 * @param displayFormat: string (moment format string) = 'h:mmA'
 * @returns string[]
 */
export const formattedTimesByMinuteDuration = (intervalInMinutes, startTime, endTime, displayFormat) => {
  const format = displayFormat || 'h:mmA'
  return timesByMinuteDuration(intervalInMinutes, startTime, endTime).map(timeMoment => timeMoment.format(format))
}

export const isVenueToday = (timezone, startOfDayHour, date) => {
  const today = getVenueToday(timezone, startOfDayHour)
  return today.isSame(date, 'day')
}

export const pad2 = number => (number < 10 ? '0' : '') + number

export const getDurationDisplay = (
  duration,
  shortFormat = true,
  dropMinutesUnit = true,
  dropMinutesValue = false,
  hourLabel = 'hr',
  minuteLabel = 'min',
  hourShortLabel = 'h',
  minuteShortLabel = 'm'
) => {
  if (!_.isNumber(duration)) {
    return ''
  }
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  const timeComponents = []
  if (hours > 0) {
    const space = shortFormat ? '' : ' '
    const hourSuffix = shortFormat ? hourShortLabel : hourLabel
    const hoursComponent = hours + space + hourSuffix
    timeComponents.push(hoursComponent)
  }
  const skipMinutes = hours > 0 && dropMinutesValue
  if (minutes > 0 && !skipMinutes) {
    const dropUnit = dropMinutesUnit && hours !== 0
    const space = dropUnit || shortFormat ? '' : ' '
    const unitSuffix = shortFormat ? minuteShortLabel : minuteLabel
    const minuteSuffix = dropUnit ? '' : unitSuffix
    const zeroPadded = hours > 0 && minutes < 10 && shortFormat && dropMinutesUnit ? '0' : ''
    const minutesComponent = [zeroPadded, minutes, space, minuteSuffix].join('')
    timeComponents.push(minutesComponent)
  }
  return timeComponents.join(shortFormat && dropMinutesUnit ? '' : ' ')
}

export const getStartTimeEndTimeDisplay = (startTime, duration, showEndTime) => {
  const endTime = startTime.clone().add(duration, 'minutes')
  return startTime.format('LT') + (showEndTime ? ` - ${endTime.format('LT')}` : '')
}

export const timeconfigToDatetime = (startOfDayHour, resTime, cfgNum, cfgType, cfgHourTimeStr, date, timezone) => {
  const result = dateToLocalTimezoneStartOfDay(date, timezone)
  if (resTime.hour < startOfDayHour) {
    result.add({ days: 1 })
  }
  if (cfgType === 'HOURS') {
    result.hour(resTime.hour())
    result.minute(resTime.minute())
    result.subtract(cfgNum, 'hours')
  } else {
    const cfgTime = _.isNil(cfgHourTimeStr) ? resTime : moment(`${_EPOCH} ${cfgHourTimeStr}`)
    result.hour(cfgTime.hour())
    result.minute(cfgTime.minute())
    if (cfgType === 'DAYS') {
      result.subtract(cfgNum, 'days')
    } else if (cfgType === 'WEEKS') {
      result.subtract(cfgNum, 'weeks')
    } else if (cfgType === 'MONTHS') {
      result.subtract(cfgNum, 'months')
    }
  }
  return result
}

export const TIME_FORMATS = {
  military: 'HH:mm',
  regular: 'h:mmA',
}

svrExport('TimeUtils', 'timeToOrder', timeToOrder)
svrExport('TimeUtils', 'createLocalTime', createLocalTime)
