import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { tryLoadLandingPage } from 'widget/dining/actions/landing'
import { toggleModalDisplay } from 'widget/dining/actions/navigation'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { modalTypes } from '../utils/constantTypes'

class LandingPage extends React.Component {
  componentDidMount() {
    this.props.tryLoadLandingPage()
  }

  render() {
    const primaryButtons = []
    const secondaryButtons = []
    const { specialAttentionMessageHeader } = this.props

    this.props.data.buttons.forEach(b => (b.primary ? primaryButtons.push(b) : secondaryButtons.push(b)))
    return (
      <div>
        {this.props.data.show_venue_banner && (
          <VenueName {...this.props.styles} data-test-id="lp-venue-name">
            {this.props.venue.name}
          </VenueName>
        )}
        {this.props.data.show_special_attention_message && (
          <SpecialAttention {...this.props.styles}>
            {specialAttentionMessageHeader}
            <AttentionIcon
              onClick={() => this.props.toggleModalDisplay(modalTypes.SPECIAL_ATTENTION_MESSAGE)}
              data-test-id="lp-special-attention"
              className="fa fa-info-circle"
              aria-hidden="true"
              aria-label="special attention message"
            />
          </SpecialAttention>
        )}

        <Main data-test-id="sr-landing-page-main" {...this.props.styles}>
          <Content {...this.props.styles}>
            {this.props.data.show_header_text && (
              <Section>
                <HeaderText {...this.props.styles} data-test-id="lp-header-text">
                  {this.props.data.header_text}
                </HeaderText>
              </Section>
            )}

            <Section>
              {primaryButtons.map(b => (
                <LandingPageButton key={this.props.venue} button={b} venue={this.props.venue} styles={this.props.styles} />
              ))}
            </Section>

            <div>
              {secondaryButtons.map(b => (
                <LandingPageButton key={this.props.venue} button={b} venue={this.props.venue} styles={this.props.styles} />
              ))}
            </div>
          </Content>
        </Main>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const styles = {
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorLines: state.widgetSettings.colorLines,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
  }
  const languageStrings = selectLanguageStrings(state)

  return {
    venue: state.venueInfo,
    data: state.landingPage.data,
    styles,
    specialAttentionMessageHeader: languageStrings.specialAttentionMessageHeader,
  }
}

const mapDispatchToProps = {
  tryLoadLandingPage,
  toggleModalDisplay,
}

const buttonTypes = {
  widget: 'widget',
  customLink: 'customLink',
  contactUs: 'contactUs',
  menu: 'menu',
}

const contactUsActions = {
  call: 'call',
  email: 'email',
}

const LandingPageButton = props => {
  const Button = props.button.primary ? PrimaryButton : SecondaryButton

  const href = getHref(props.button, props.venue)
  let testId = `lp-button-${props.button.primary ? 'primary' : 'secondary'}`
  testId += `-${props.button.type}`

  if (props.button.action) {
    testId += `-${props.button.action}`
  }

  return (
    <Button href={href} {...props.styles} data-test-id={testId}>
      {props.button.button_text}
    </Button>
  )
}

function getHref(button, venue) {
  switch (button.type) {
    case buttonTypes.widget:
      // eslint-disable-next-line no-restricted-globals
      return `${location.origin}/${button.action}/${venue.urlKey}`
    case buttonTypes.contactUs:
      return getContactUs(button, venue)
    case buttonTypes.customLink:
      return button.custom_link
    case buttonTypes.menu:
      return venue.menuLink
    default:
      return ''
  }
}

function getContactUs(button, venue) {
  switch (button.action) {
    case contactUsActions.call:
      return `tel:${venue.phoneNumber}`
    case contactUsActions.email:
      return `mailto:${venue.email}`
    default:
      return ''
  }
}

const VenueName = styled('h1')`
  margin: 0;
  background: ${props => props.colorSummaryBar};
  color: white;
  text-align: center;
  font-size: 18px;
  padding: 15px;
`

const SpecialAttention = styled('h3')`
  margin: 0;
  background: ${props => props.colorActionBarBackground};
  color: ${props => props.fontsColorPrimary};
  text-align: center;
  font-size: 14px;
  padding: 15px;
  font-weight: normal;
`

const AttentionIcon = styled('button')`
  font-size: 14px;
  margin-left: 8px;
  background: transparent;
  border: none;
  cursor: pointer;

  &:before {
    content: '\f008';
  }
`

const Main = styled('div')`
  background-color: ${props => props.colorWidgetBackground};
  padding: 20px;
`

const Content = styled('div')`
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: ${props => props.colorCheckoutCellBackground};
`

const HeaderText = styled('h2')`
  margin: 0;
  font-size: 14px;
  color: ${props => props.fontsColorPrimary};
  padding: 18px 0;
  font-weight: normal;
  text-align: center;
  white-space: pre-wrap;
`

const Section = styled('div')`
  padding: 0 24px;
  overflow: hidden;
`

const Button = styled('a')`
  display: block;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
`

const PrimaryButton = styled(Button)`
  color: ${props => props.fontsColorButton};
  background-color: ${props => props.colorPrimary};
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`

const SecondaryButton = styled(Button)`
  color: ${props => props.fontsColorSecondary};
  border-top: 1px solid ${props => props.colorLines};
`

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
