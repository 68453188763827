const styles = {
  pointer: {
    cursor: 'pointer',
  },
  calContainer: {
    position: 'relative',
    height: '0px',
    fontWeight: '300',
  },
  calContianerAspectRatioShort: {
    '@media (max-width: 399px)': {
      paddingBottom: '86%',
    },
    '@media (min-width: 400px) and (max-width: 499px)': {
      paddingBottom: '72%',
    },
    '@media (min-width: 500px) and (max-width: 599px)': {
      paddingBottom: '65%',
    },
    '@media (min-width: 600px)': {
      paddingBottom: '57%',
    },
  },
  calContianerAspectRatioLong: {
    '@media (max-width: 399px)': {
      paddingBottom: '100%',
    },
    '@media (min-width: 400px) and (max-width: 499px)': {
      paddingBottom: '85%',
    },
    '@media (min-width: 500px) and (max-width: 599px)': {
      paddingBottom: '76%',
    },
    '@media (min-width: 600px)': {
      paddingBottom: '67%',
    },
  },
  calWrapper: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  calTable: {
    width: '100%',
    height: '100%',
    fontSize: '10px',
    textAlign: 'center',
    tableLayout: 'fixed',
  },
  dayHeader: {
    fontSize: '1.0em',
    letterSpacing: '1px',
    textAlign: 'center',
    '@media (max-width: 399px)': {
      height: '28px',
      lineHeight: '28px',
    },
    '@media (min-width: 400px) and (max-width: 499px)': {
      height: '32px',
      lineHeight: '32px',
    },
    '@media (min-width: 500px) and (max-width: 599px)': {
      height: '36px',
      lineHeight: '36px',
    },
    '@media (min-width: 600px)': {
      height: '40px',
      lineHeight: '40px',
    },
  },
  dayLabel: {
    display: 'table-cell',
    width: '14.28571%',
    fontWeight: 'normal',
  },
  cellWrapper: {
    verticalAlign: 'middle',
    cursor: 'default',
    fontSize: '1.5em',
  },
  availCell: {
    cursor: 'pointer',
  },
  selectedDate: {
    color: 'white',
  },
  cellContainer: {
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    fontSize: '1.5em',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '50%',
    '@media (max-width: 399px)': {
      width: '37px',
      height: '37px',
      lineHeight: '37px',
    },
    '@media (min-width: 400px) and (max-width: 499px)': {
      width: '45px',
      height: '45px',
      lineHeight: '45px',
    },
    '@media (min-width: 500px) and (max-width: 599px)': {
      width: '51px',
      height: '51px',
      lineHeight: '51px',
    },
    '@media (min-width: 600px)': {
      width: '55px',
      height: '55px',
      lineHeight: '55px',
    },
  },
  todayCell: {
    fontWeight: 'bold',
    fontSize: '1.6em',
  },
  fontRoboto: {
    fontFamily: 'Roboto, sans-serif',
  },
}

export default styles
