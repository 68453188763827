const styles = {
  viewWrapper: {
    height: '100%',
    minHeight: '500px',
    paddingTop: '68px',
  },
  leadingText: {
    width: '304px',
    height: '38px',
    opacity: '0.7',
    fontWeight: '300',
    fontSize: '14px',
    textAlign: 'center',
    margin: '17px auto 24px',
  },
  socialButton: {
    width: '90%',
    maxWidth: '330px',
    height: '40px',
    margin: '10px auto',
    cursor: 'pointer',
    textAlign: 'center',
    position: 'relative',
    outline: 'none',
    borderWidth: '0px',
    fontFamily: 'inherit',
    display: 'block',
    padding: '0',
    ':focus': {
      opacity: '0.8',
    },
  },
  socialBox: {
    width: '40px',
    height: '40px',
    float: 'left',
    lineHeight: '36px',
    textAlign: 'center',
    color: 'white',
    fontSize: '16px',
    position: 'absolute',
  },
  facebookIcon: {
    height: '26px',
    verticalAlign: 'middle',
  },
  socialText: {
    fontFamily: 'Roboto',
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px',
    letterSpacing: '1px',
    color: '#fdfeff',
    fontWeight: '400',
    display: 'inline-block',
  },
  loginSeperator: {
    position: 'relative',
    margin: '20px auto',
    width: '80%',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
  },
  spaceBottom: {
    marginBottom: '11px',
  },
  lineBreak: {
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    width: '40%',
    display: 'inline-block',
    padding: '2px',
  },
  lineBreakText: {
    fontSize: '12px',
    fontWeight: '500',
    display: 'inline-block',
    margin: '5px 6.239%',
  },
  guestButton: {
    textAlign: 'center',
  },
  guestText: {
    height: '24px',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '1px',
    borderWidth: '0px',
    padding: '0px',
    outline: 'none',
    fontFamily: 'inherit',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    ':focus': {
      opacity: '0.8',
    },
  },
}

export default styles
