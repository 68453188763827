import { decodeJwt } from 'jose'
import { SET_LOGIN_INFO, SET_USER_DATA_FB, SET_USER_DATA_GP } from 'widget/dining/actions/ActionTypes'
import * as AnalyticsEvents from 'widget/dining/utils/analyticsEvents'

export const setLoginInfo = (status, site) => ({
  type: SET_LOGIN_INFO,
  status,
  site,
})

export const setUserDataFB = response => ({ type: SET_USER_DATA_FB, response })

export const loginUserFB =
  (isUsingAnalytics = false) =>
  dispatch => {
    FB.getLoginStatus(statusResponse => {
      if (statusResponse.status === 'connected') {
        dispatch(setLoginInfo(statusResponse.status, 'facebook'))
        return FB.api('/me', { fields: 'first_name, last_name, email, location, birthday, picture' }, profileDataResponse => {
          dispatch(setUserDataFB(profileDataResponse))
          if (isUsingAnalytics) {
            AnalyticsEvents.login('facebook')
          }
        })
      }
      return FB.login(
        loginResponse => {
          if (loginResponse.status === 'connected') {
            dispatch(setLoginInfo(loginResponse.status, 'facebook'))
            FB.api(
              '/me',
              {
                fields: 'first_name, last_name, email, location, birthday, picture',
              },
              profileDataResponse => {
                dispatch(setUserDataFB(profileDataResponse))
                if (isUsingAnalytics) {
                  AnalyticsEvents.login('facebook')
                }
              }
            )
          }
        },
        { scope: 'public_profile, email, user_location, user_birthday' }
      )
    })
  }

export const setUserDataGP = response => ({ type: SET_USER_DATA_GP, response })

export const loginUserGP =
  (response, isUsingAnalytics = false) =>
  dispatch => {
    const { credential } = response

    const decodedData = decodeJwt(credential)

    dispatch(setLoginInfo('connected', 'google'))
    dispatch(
      setUserDataGP({
        firstName: decodedData.given_name,
        lastName: decodedData.family_name,
        email: decodedData.email,
        picture: decodedData.picture,
        id: decodedData.sub,
      })
    )
    if (isUsingAnalytics) {
      AnalyticsEvents.login('google')
    }
  }

export const loginAsGuest = () => dispatch => {
  dispatch(setLoginInfo('guest'))
  AnalyticsEvents.login('guest')
}
