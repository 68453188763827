import { Buffer } from 'buffer'
import aesjs from 'aes-js'
import creditCardType from 'credit-card-type'
import _ from 'lodash'
import randomString from 'random-string'

export class AESEncrypt {
  // iv indicates NI's odd implementation,
  // used only in vms
  constructor(key, iv) {
    this.chunk = 16
    this.key = this.bytes(key, 'base64')
    this.iv = this.bytes(this.ivgen())
    this.niiv = iv
  }

  ivgen() {
    const res = randomString({ length: this.chunk, letters: true, numeric: true })
    return res
  }

  pad(text) {
    const len = text.length
    const pad = this.chunk - (len % this.chunk)
    return _.padEnd(text, len + pad, String.fromCharCode(pad))
  }

  bytes(text, enc) {
    return [...Buffer.from(text, enc)]
  }

  encrypt(text) {
    text = aesjs.util.convertStringToBytes(this.pad(text))
    const cbc = new aesjs.ModeOfOperation.cbc(this.key, this.niiv || this.iv)
    const encrypted = cbc.encrypt(text)

    let _encrypted = encrypted
    if (!this.niiv) {
      const concat = new Uint8Array(this.iv.length + encrypted.length)
      concat.set(this.iv)
      concat.set(encrypted, this.iv.length)
      _encrypted = concat
    }
    return Buffer.from(_encrypted).toString('base64')
  }
}

export class PipeFormat {
  constructor() {
    this.FIELD = '|'
    this.BLOCK = '||'

    // These are assumed value at the moment
    this.requestMap = '1001000'
    this.transactionMap = '11111111'
    this.cardDataMap = '11111100000'

    this.currency = 'AED'
    this.method = 'CC'
    this.type = 'INTERNET'

    this.domain = `${document.location.protocol}\/\/${document.location.host}`
  }

  joinFields(arry) {
    return arry.join(this.FIELD)
  }

  joinBlocks(arry) {
    return arry.join(this.BLOCK)
  }

  formatCard(number, expMonth, expYear, ccv, name, type) {
    expMonth = String(expMonth)
    expMonth = expMonth.length === 1 ? `0${expMonth}` : expMonth
    return this.joinFields([
      this.cardDataMap,
      number,
      expMonth, // 2 digits
      expYear, // 4 digits
      ccv,
      name,
      type,
    ])
  }

  formatTransaction(amount, number, action, venueKey, urlAddendum) {
    const ts = new Date().getTime()
    const processId = `${ts}_${String(Math.random()).substring(2)}_${number.substr(-4)}`

    this.processId = processId

    const returnUrl = `${this.domain}/booking/${venueKey}/3d_secure_receiver/${urlAddendum}`

    return this.joinFields([
      this.transactionMap,
      processId,
      amount, // decimal
      returnUrl,
      returnUrl,
      this.type,
      this.method,
      action, // SALE, REFUND
      this.currency,
    ])
  }

  buildRequest(number, expMonth, expYear, ccv, name, amount, action, venueKey, urlAddendum) {
    const type = creditCardType(number)[0].niceType
    const actionMap = {
      SALE: '01',
    }

    return this.joinBlocks([
      this.requestMap,
      this.formatTransaction(amount, number, actionMap[action], venueKey, urlAddendum),
      this.formatCard(number, expMonth, expYear, ccv, name, type),
    ])
  }
}
