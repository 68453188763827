import { CHANGE_COUNTRY_FLAG } from 'widget/events/actions/ActionTypes'
import {
  CHANGE_PHONE_NUM,
  VALIDATE_ALL_FIELDS,
  CHANGE_FORM_FIELD,
  PASS_STRIPE_CARD_ELEMENT,
  CHANGE_GRATUITY_PERCENTAGE,
} from './ActionTypes'

export const changePhoneNum = (changeTo, selectedCountry, dialCode) => ({
  type: CHANGE_PHONE_NUM,
  changeTo,
  selectedCountry,
  dialCode,
})

export const changeFlag = (changeTo, selectedCountry) => ({
  type: CHANGE_COUNTRY_FLAG,
  changeTo,
  selectedCountry,
})

export const validateAllFields = formErrors => ({
  type: VALIDATE_ALL_FIELDS,
  formErrors,
})

export const changeFormField = (field, changeTo) => ({
  type: CHANGE_FORM_FIELD,
  field,
  changeTo,
})

export const changeGratuityPercentage = (field, changeTo) => ({
  type: CHANGE_GRATUITY_PERCENTAGE,
  field,
  changeTo,
})

export const passCardElement = element => ({
  type: PASS_STRIPE_CARD_ELEMENT,
  element,
})
