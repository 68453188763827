import { Map } from 'immutable'
import { SET_LOGIN_INFO, SET_USER_DATA_FB, SET_USER_DATA_GP } from '../actions/ActionTypes'

const initialState = Map({ status: 'unknown', isFetching: false })
const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_INFO:
      return state.merge({
        status: action.status,
        isFetching: false,
        site: action.site || null,
      })
    case SET_USER_DATA_FB:
      return state.merge({
        birthday: action.response.birthday,
        email: action.response.email,
        firstName: action.response.first_name,
        lastName: action.response.last_name,
        location: (action.response.location || {}).name,
        picture: action.response.picture.data.url,
        userId: action.response.id,
      })
    case SET_USER_DATA_GP:
      return state.mergeWith((prev, next) => prev || next, {
        email: action.response.email || '',
        firstName: action.response.firstName || '',
        lastName: action.response.lastName || '',
        picture: action.response.picture || '',
        birthday: action.response.birthday || '',
        userId: action.response.id || '',
      })
    default:
      return state
  }
}

export default user
