import { Map } from 'immutable'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'

const initialState = Map({
  actualId: null,
  actual: null,
  venueUrlKey: null,
  isModifyResMode: false,
})

const modifyReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ACTUAL_SUCCESS: {
      return state.merge({ actual: action.actual, actualId: action.actual.id })
    }
    case ActionTypes.SET_HAS_CREDIT_CARD_ON_FILE: {
      return state.merge({ hasCreditCardOnFile: action.hasCreditCardOnFile })
    }
    case ActionTypes.SET_ACCESS_PERSISTENT_ID: {
      return state.update('actual', actual => actual.set('access_persistent_id', action.accessPersistentId))
    }
    case ActionTypes.SET_AGREED_TO_GDPR_DIETARY_OPT_IN: {
      return state.merge({ agreedToDietaryGdprOptinOn: action.agreedToGdprDietaryOptin })
    }
    default:
      return state
  }
}

export default modifyReservationReducer
