import Radium from 'radium'
import React from 'react'
import styles from './styles'

const Modal = ({ body, modalType, dismissModal, backgroundColor, zIndex, overflowX, overflowY, customStyles }) => {
  const clickPrevent = e => e.stopPropagation()

  return (
    <div style={[styles.cover, { zIndex }]} onClick={dismissModal}>
      <div style={styles.hiddenAlert} role="alert">
        {modalType}
      </div>
      <div style={[styles.wrapper, { backgroundColor, overflowX, overflowY }, customStyles]} onClick={clickPrevent}>
        {body}
      </div>
    </div>
  )
}

Modal.propTypes = {
  body: React.PropTypes.element.isRequired,
  dismissModal: React.PropTypes.func,
  backgroundColor: React.PropTypes.string,
  overflowX: React.PropTypes.string.isRequired,
  overflowY: React.PropTypes.string.isRequired,
  customStyles: React.PropTypes.object,
}

Modal.defaultProps = {
  backgroundColor: 'white',
  zIndex: '25',
  dismissModal: () => {},
  overflowX: 'auto',
  overflowY: 'auto',
  customStyles: {},
}

export default Radium(Modal)
