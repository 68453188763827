import priceFormatter from 'currency-formatter'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { WidgetButton } from '../../../../../lib/Buttons/WidgetButton'
import { doShowDiningAdditionalSelectionModal } from '../../utils/conditions'
import media from '../../utils/mediaTemplates'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const TimeSlotButton = styled(WidgetButton)`
  position: relative;
  font-size: 16px;
  height: 56px;
  line-height: 42px;
  padding: 7px 0;
  color: ${props => props.theme.fontsColorButton};
  background-color: ${props => props.theme.colorPrimary};
  overflow: hidden;

  width: ${props => (props.foundNearbyResult ? '45%' : '18%')};
  margin: ${props => (props.foundNearbyResult ? '8px 2.5%' : '0 1%')};
  ${props =>
    !props.foundNearbyResult &&
    css`
      cursor: ${props => (props.disabled ? 'default' : 'pointer')};
      opacity: ${props => (props.disabled ? 0.3 : 1)};
      ${media.small`
        width: 105px;
        margin: 0 3px;
      `};
    `};
`

const TimeSlotText = styled.div`
  position: absolute;
  width: 100%;
  top: ${props => (props.isPrimary ? '9px' : '0')};
  height: ${props => (props.isPrimary ? '24px' : '100%')};
  line-height: ${props => (props.isPrimary ? '24px' : '56px')};
`

const TimeSlotSecondaryText = styled.div`
  position: absolute;
  width: 100%;
  height: 16px;
  line-height: 16px;
  font-size: 13px;
  opacity: 0.65;
  bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const InfoIcon = styled.i`
  font-size: 14px !important;
  display: inline-block;
  padding: 0 5px;
`

class TimeSlot extends Component {
  constructor(props) {
    super(props)
    const {
      timeSlotMoment,
      onTimeSlotClick,
      venueKey,
      accessPersistentId,
      publicDescriptionTitle,
      publicLongFormDescription,
      publicPhoto,
      publicPhotoSizes,
      cost,
      reservationCost,
      selectedAutomaticUpsells,
      reservationFees,
    } = this.props
    this.onClick = timeSlotMoment
      ? onTimeSlotClick.bind(this, {
          timeSlotMoment,
          venueKey,
          accessPersistentId,
          publicDescriptionTitle,
          publicLongFormDescription,
          publicPhoto,
          publicPhotoSizes,
          cost,
          reservationCost,
          selectedAutomaticUpsells,
          reservationFees,
        })
      : null
  }

  render() {
    const {
      timeSlotMoment,
      requirePayment,
      chargeType,
      cost,
      duration,
      foundNearbyResult,
      publicDescriptionTitle,
      publicTimeSlotDescription,
      publicLongFormDescription,
      publicPhoto,
      selectedAutomaticUpsells,
      currencyCode,
      textPerPerson,
      textPerReservation,
      locale,
      reservationFees,
    } = this.props
    if (!timeSlotMoment) {
      return (
        <TimeSlotButton disabled withDefaultOutline>
          <TimeSlotText />
        </TimeSlotButton>
      )
    }
    const fees = reservationFees ?? 0
    const timeDisplayFormat = locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
    const timeLabel = timeSlotMoment.format(timeDisplayFormat)
    const showCost = requirePayment || selectedAutomaticUpsells
    const showInfoIcon = doShowDiningAdditionalSelectionModal(
      publicLongFormDescription,
      publicDescriptionTitle,
      publicPhoto,
      selectedAutomaticUpsells
    )
    const slotSubtotal = (cost && chargeType && chargeType.endsWith('slot') ? (cost * duration) / 15 : cost) + fees
    const formattedCost = priceFormatter.format(slotSubtotal, {
      code: currencyCode,
    })
    return (
      <TimeSlotButton foundNearbyResult={foundNearbyResult} withDefaultOutline onClick={this.onClick} data-test="sr-timeslot-button">
        <TimeSlotText isPrimary={(showCost && cost) || publicTimeSlotDescription}>{timeLabel}</TimeSlotText>
        {/* eslint-disable-next-line no-nested-ternary */}
        {publicTimeSlotDescription ? (
          <TimeSlotSecondaryText>
            {`${publicTimeSlotDescription}`}
            {showInfoIcon && <InfoIcon className="fa fa-info-circle fa-fw" aria-hidden="true" />}
          </TimeSlotSecondaryText>
        ) : showCost && cost ? (
          <TimeSlotSecondaryText>
            {chargeType && chargeType.startsWith('person') // handles 'person_slot' as well
              ? `${formattedCost}/${textPerPerson}`
              : `${formattedCost}/${textPerReservation}`}
          </TimeSlotSecondaryText>
        ) : null}
      </TimeSlotButton>
    )
  }
}

export default TimeSlot
