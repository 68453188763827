import { Map } from 'immutable'
import moment from 'moment-timezone'
import * as ActionTypes from 'widget/dining/actions/ActionTypes'

const initialState = Map({
  reservationHoldId: null,
  holdDurationSeconds: null,
  expirationMoment: null,
  venueKey: null,
  shiftPersistentId: null,
  selectedTimeSlot: null,

  timeSlotMoment: null,
  accessPersistentId: null,
})

const reservationHoldReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HOLD_RESERVATION_SUCCESS: {
      const { reservationHoldId, holdDurationSeconds, venueKey, shiftPersistentId, selectedTimeSlot } = action
      const expirationMoment = moment().add(holdDurationSeconds, 'second')
      return state.merge({
        reservationHoldId,
        holdDurationSeconds,
        expirationMoment,
        venueKey,
        shiftPersistentId,
        selectedTimeSlot,
      })
    }
    case ActionTypes.CLEAR_HOLD_RESERVATION: {
      return state.merge(initialState)
    }
    case ActionTypes.SELECT_TIME_SLOT: {
      // Typically we Hold before this gets invoked, but some access rules have a Confirm step first, so we
      // store these, and use them to fire the Hold only upon confirming the selection
      const { timeSlotMoment, venueKey, accessPersistentId } = action
      return state.merge({
        timeSlotMoment,
        venueKey,
        accessPersistentId,
      })
    }
    default:
      return state
  }
}

export default reservationHoldReducer
