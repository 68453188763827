import Radium from 'radium'
import React, { Component } from 'react'
import styled from 'styled-components'
import { getStyles, color } from './styles'
/* eslint-disable max-len */
const INPUT_LIMIT_TYPES = {
  none: /.+/g, // all characters
  // Need to support unicode chars (not just A-Za-z) and /p does not work in JS regex, so went with exclusive rather than inclusive
  // Source: https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
  name: /(^$)|(^([^!#$%&()*,./:;?@[\\\]_{|}¨ˇ""€+<=>§°\s¤®™©]| )+$)/g,
  email: /.+/g,
  number: /[0-9 ]+/g, // digits and space
  birthday: /\d{0,2}(\/\d{0,2})?/, // birthday - mm/dd
  onlynumbers: /[0-9]+/g, // only numbers no space
}
/* eslint-enable max-len */
const Input = styled.input`
  &::placeholder {
    font-family: inherit;
    color: ${props => (!props.isValid ? props.errorColor : props.placeholderColor)};
  }
`

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFieldFocused: false,
    }
    const formatPlaceholder = this.props.placeholder.toLowerCase().replace(' ', '-')
    this.inputId = this.props.inputId || `sr-${formatPlaceholder}`
    this.limitInput = this.limitInput.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  onInputChange(e) {
    const newVal = this.limitInput(e.target.value)
    if (newVal !== this.state.value) {
      this.props.onChange(newVal)
    }
  }

  limitInput(input) {
    const { limitType, charLimit } = this.props
    if (input === '') {
      return ''
    }
    const match = input.match(INPUT_LIMIT_TYPES[limitType])
    if (!match) {
      return ''
    } else if (charLimit) {
      return match[0].substring(0, charLimit)
    }
    return match[0]
  }

  validate() {
    return this.props.validator(this.props.value)
  }

  handleFocus = () => {
    this.setState({
      isFieldFocused: true,
    })
    this.props.onFocus()
  }

  handleBlur = () => {
    this.setState({
      isFieldFocused: false,
    })
    this.props.onBlur()
  }

  render() {
    const {
      value,
      isValid,
      placeholder,
      label,
      onFocusPlaceholder,
      placeholderColor,
      textColor,
      errorColor,
      style,
      disabled,
      readOnly,
      inputStyle,
      icon,
      iconStyle,
      ariaLabel,
      testId,
    } = this.props
    const isFieldFocused = this.state.isFieldFocused || value
    const styles = getStyles()
    return (
      <div style={[styles.inputWrapper, !isValid && { borderColor: errorColor }, style]}>
        {icon ? <i style={iconStyle} className={icon} aria-hidden="true" /> : null}
        <label
          htmlFor={this.inputId}
          style={[
            styles.label,
            { color: isValid ? placeholderColor : errorColor },
            !label && !isFieldFocused && styles.labelHide,
            icon && { padding: '2px 0 0 10px' },
          ]}
        >
          {label || placeholder}
        </label>
        <Input
          id={this.inputId}
          aria-required={this.props.ariaRequired === true ? true : ''}
          key={this.inputId}
          type="text"
          value={this.props.value}
          className={!isValid && 'sr-invalid-input'}
          onChange={this.onInputChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={isFieldFocused ? onFocusPlaceholder : placeholder}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={this.props.autoComplete}
          aria-label={ariaLabel}
          aria-invalid={!isValid}
          data-test={testId}
          isValid={isValid}
          placeholderColor={placeholderColor}
          errorColor={errorColor}
          style={{
            ...styles.inputBox,
            ...((label || isFieldFocused) && styles.fillBox),
            ...(disabled && styles.defaultCursor),
            color: isValid ? textColor : errorColor,
            fontFamily: 'inherit',
            width: icon ? 'auto' : '100%',
            ...inputStyle,
          }}
        />
      </div>
    )
  }
}
TextInput.propTypes = {
  value: React.PropTypes.string.isRequired,
  placeholder: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  onFocusPlaceholder: React.PropTypes.string,
  onChange: React.PropTypes.func,
  onFocus: React.PropTypes.func,
  onBlur: React.PropTypes.func,
  validator: React.PropTypes.func,
  isValid: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  limitType: React.PropTypes.oneOf(['none', 'name', 'email', 'number', 'birthday']),
  charLimit: React.PropTypes.number,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  placeholderColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
  errorColor: React.PropTypes.string,
  autoComplete: React.PropTypes.string,
  readOnly: React.PropTypes.bool,
  inputStyle: React.PropTypes.objectOf(React.PropTypes.string),
  testId: React.PropTypes.string,
}
TextInput.defaultProps = {
  label: '',
  onFocusPlaceholder: '',
  isValid: true,
  disabled: false,
  readOnly: false,
  limitType: 'none',
  fontFamily: 'Roboto',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  validator: () => true,
  charLimit: null,
  style: {},
  inputStyle: {},
  placeholderColor: color.placeholder,
  textColor: color.text,
  errorColor: color.error,
}
export default Radium(TextInput)
