// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled, { css } from 'styled-components'
import WidgetList from 'svr/component-lib/Widget/Layouts/WidgetList'
import { getCategoryMinQuantity, getCategoryMaxQuantity } from '../utils/upsellsHelpers'
import CategoryHeader from './CategoryHeader'
import InventoryItem from './InventoryItem'
/* import type {
  UpsellCategoryEntity,
  UpsellInventoryEntity,
} from '../reducers/entities' */
/* import type { InventoryEditState } from '../reducers/inventoryEdits' */
/* import type {
  ClickInventoryAction,
  ChangeInventoryQuantityAction,
} from '../flowTypes' */

/* type Props = {
  partySize: number,
  categoryEntity: UpsellCategoryEntity,
  inventoryEntities: { [string]: UpsellInventoryEntity },
  inventoryEdits: InventoryEditState,
  selectedInventory: string,
  currencySymbol: string,
  clickInventory: string => ClickInventoryAction,
  clickInventoryDescription: (string, boolean) => ClickInventoryAction,
  changeInventoryQuantity: (string, number) => ChangeInventoryQuantityAction,
} */

class UpsellCategory extends React.Component /* <Props> */ {
  minQuantity /* : number */

  maxQuantity /* : number */

  constructor(props /* : Props */) {
    super(props)
    const { minQuantityType, minQuantityInt, maxQuantityType, maxQuantityInt } = this.props.categoryEntity
    this.minQuantity = getCategoryMinQuantity(minQuantityType, minQuantityInt, this.props.partySize)
    this.maxQuantity = getCategoryMaxQuantity(maxQuantityType, maxQuantityInt, this.props.partySize)
  }

  getTotalQuantitySelected = () =>
    _.reduce(this.props.categoryEntity.inventories, (sum, inventoryId) => sum + this.props.inventoryEdits[inventoryId].quantity, 0)

  getMaxQuantityReached = () => this.getTotalQuantitySelected() === this.maxQuantity

  clickInventoryMap = _.reduce(
    this.props.categoryEntity.inventories,
    (actionMap, inventoryId) => ({
      ...actionMap,
      [inventoryId]: this.props.clickInventory.bind(this, inventoryId),
    }),
    {}
  )

  clickInventoryDescriptionMap = _.reduce(
    this.props.categoryEntity.inventories,
    (actionMap, inventoryId) => ({
      ...actionMap,
      [inventoryId]: this.props.clickInventoryDescription.bind(this, inventoryId),
    }),
    {}
  )

  changeInventoryQuantityMap = _.reduce(
    this.props.categoryEntity.inventories,
    (actionMap, inventoryId) => ({
      ...actionMap,
      [inventoryId]: this.props.changeInventoryQuantity.bind(this, inventoryId),
    }),
    {}
  )

  shouldComponentUpdate = (nextProps /* : Props */) =>
    this.props.selectedInventory !== nextProps.selectedInventory || !_.isEqual(this.props.inventoryEdits, nextProps.inventoryEdits)

  getInventoryItem = inventoryId => (
    <InventoryItem
      key={inventoryId}
      inventoryEntity={this.props.inventoryEntities[inventoryId]}
      inventoryEdit={this.props.inventoryEdits[inventoryId]}
      isSelected={this.props.selectedInventory === inventoryId}
      isMobile={this.props.isMobile}
      maxQuantityReached={this.getMaxQuantityReached()}
      currencySymbol={this.props.currencySymbol}
      onInventoryClick={this.clickInventoryMap[inventoryId]}
      onInventoryDescriptionClick={this.clickInventoryDescriptionMap[inventoryId]}
      onQuantityChange={this.changeInventoryQuantityMap[inventoryId]}
      textUpgradeEachLabel={this.props.textUpgradeEachLabel}
      isFeesInPriceDisplayed={this.props.isFeesInPriceDisplayed}
      categoryEntity={this.props.categoryEntity}
      defaultServiceCharge={this.props.defaultServiceCharge}
      defaultGratuity={this.props.defaultGratuity}
    />
  )

  render() {
    return (
      <CategoryWrapper>
        {(this.props.categoryEntity.showCategoryFlag || this.props.index !== 0) && (
          <CategoryHeader
            name={this.props.categoryEntity.name}
            minQuantity={this.minQuantity}
            maxQuantity={this.maxQuantity}
            showCategoryFlag={this.props.categoryEntity.showCategoryFlag}
            textUpgradeRequiredLabel={this.props.textUpgradeRequiredLabel}
            textUpgradeMustAtLeastLabel={this.props.textUpgradeMustAtLeastLabel}
          />
        )}
        <WidgetList
          customBorder={css`
            border: 1px solid ${props => props.theme.colorLines};
          `}
        >
          {_.map(this.props.categoryEntity.inventories, this.getInventoryItem)}
        </WidgetList>
      </CategoryWrapper>
    )
  }
}
const CategoryWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export default UpsellCategory
