import { css } from 'styled-components'

const sizes = {
  large: 600, // min-width
  medium: 600, // others are max-width
  small: 500,
  mobile: 400,
}

// Iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((acc, label) => {
  if (label === 'large') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `
  }
  return acc
}, {})
