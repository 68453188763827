import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const Checkbox = ({ testId, name, label, on, onChange, style, enabled = true, value = '1', isInvalid = false }) => {
  const onClick = e => {
    if (enabled) {
      onChange(!on)
    }
  }

  const CheckWrapper = styled.div`
    font-size: 14px;
  `

  const SpanStyled = styled.span`
    margin-left: 10px;
  `

  const LabelStyled = styled.label`
    color: ${props => (props.isInvalid ? props.theme.colorError : props.theme.fontsColorPrimary)};
    display: inline-flex;
    align-items: start;
  `

  return (
    <CheckWrapper {...{ style }}>
      <LabelStyled isInvalid={isInvalid} {...{ style }}>
        {name ? (
          <input onChange={onClick} data-test={testId} type="checkbox" value={value} name={name} defaultChecked={on} disabled={!enabled} />
        ) : null}
        <SpanStyled>{label}</SpanStyled>
      </LabelStyled>
    </CheckWrapper>
  )
}

Checkbox.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  on: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  isInvalid: PropTypes.bool,
}

Checkbox.defaultProps = {
  on: false,
  onChange: () => {},
  enabled: true,
  isInvalid: false,
}

export default Checkbox
