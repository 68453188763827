export const CHANGE_BOOKING_CHANNEL_FIELD = 'CHANGE_BOOKING_CHANNEL_FIELD'

export const LOAD_BOOKING_CHANNEL_SETTINGS_START = 'LOAD_BOOKING_CHANNEL_SETTINGS_START'
export const LOAD_BOOKING_CHANNEL_SETTINGS_SUCCESS = 'LOAD_BOOKING_CHANNEL_SETTINGS_SUCCESS'
export const LOAD_BOOKING_CHANNEL_SETTINGS_FAIL = 'LOAD_BOOKING_CHANNEL_SETTINGS_FAIL'

export const SELECT_BOOKING_CHANNEL_EDIT = 'SELECT_BOOKING_CHANNEL_EDIT'
export const CANCEL_BOOKING_CHANNEL_EDIT = 'CANCEL_BOOKING_CHANNEL_EDIT'

export const DEACTIVATE_BOOKING_CHANNEL_START = 'DEACTIVATE_BOOKING_CHANNEL_START'
export const DEACTIVATE_BOOKING_CHANNEL_SUCCESS = 'DEACTIVATE_BOOKING_CHANNEL_SUCCESS'
export const DEACTIVATE_BOOKING_CHANNEL_FAIL = 'DEACTIVATE_BOOKING_CHANNEL_FAIL'

export const SAVE_BOOKING_CHANNEL_SETTINGS_START = 'SAVE_BOOKING_CHANNEL_SETTINGS_START'
export const SAVE_BOOKING_CHANNEL_SETTINGS_SUCCESS = 'SAVE_BOOKING_CHANNEL_SETTINGS_SUCCESS'
export const SAVE_BOOKING_CHANNEL_SETTINGS_FAIL = 'SAVE_BOOKING_CHANNEL_SETTINGS_FAIL'

export const BOOKING_CHANNEL_FORM_VALIDATED = 'BOOKING_CHANNEL_FORM_VALIDATED'
export const BOOKING_CHANNEL_DISMISS_ERROR_DISPLAY = 'BOOKING_CHANNEL_DISMISS_ERROR_DISPLAY'

export const BOOKING_CHANNEL_IMAGE_CROPPING = 'BOOKING_CHANNEL_IMAGE_CROPPING'
export const BOOKING_CHANNEL_IMAGE_UPLOAD_SUCCESS = 'BOOKING_CHANNEL_IMAGE_UPLOAD_SUCCESS'
export const BOOKING_CHANNEL_IMAGE_UPLOAD_START = 'BOOKING_CHANNEL_IMAGE_UPLOAD_START'
export const BOOKING_CHANNEL_IMAGE_UPLOAD_ERROR = 'BOOKING_CHANNEL_IMAGE_UPLOAD_ERROR'
export const BOOKING_CHANNEL_IMAGE_EDIT_PHOTO = 'BOOKING_CHANNEL_IMAGE_EDIT_PHOTO'
export const BOOKING_CHANNEL_IMAGE_CROP_PHOTO = 'BOOKING_CHANNEL_IMAGE_CROP_PHOTO'
export const BOOKING_CHANNEL_IMAGE_DELETE_PHOTO = 'BOOKING_CHANNEL_IMAGE_DELETE_PHOTO'

export const GET_VENUE_LANGUAGES_START = 'GET_VENUE_LANGUAGES_START'
export const GET_VENUE_LANGUAGES_SUCCESS = 'GET_VENUE_LANGUAGES_SUCCESS'
export const GET_VENUE_LANGUAGES_FAIL = 'GET_VENUE_LANGUAGES_FAIL'
export const CHANGE_VENUE_LANGUAGE = 'CHANGE_VENUE_LANGUAGE'
export const CLIENT_CHANGE_PREFERRED_LANGUAGE = 'CLIENT_CHANGE_PREFERRED_LANGUAGE'

export const GET_LANGUAGE_STRINGS_START = 'GET_LANGUAGE_STRINGS_START'
export const GET_LANGUAGE_STRINGS_SUCCESS = 'GET_LANGUAGE_STRINGS_SUCCESS'
export const GET_LANGUAGE_STRINGS_FAIL = 'GET_LANGUAGE_STRINGS_FAIL'

export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE'
export const TOGGLE_LANGUAGE_ENABLED = 'TOGGLE_LANGUAGE_ENABLED'
export const SAVE_VENUE_LANGUAGE_SETTINGS_START = 'SAVE_VENUE_LANGUAGE_SETTINGS_START'
export const SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS = 'SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS'
export const SAVE_VENUE_LANGUAGE_SETTINGS_FAIL = 'SAVE_VENUE_LANGUAGE_SETTINGS_FAIL'
export const RESET_VENUE_LANGUAGE_TO_INITIAL = 'RESET_VENUE_LANGUAGE_TO_INITIAL'

export const UPDATE_LANGUAGE_STRING = 'UPDATE_LANGUAGE_STRING'
export const SAVE_LANGUAGE_STRINGS_START = 'SAVE_LANGUAGE_STRINGS_START'
export const SAVE_LANGUAGE_STRINGS_SUCCESS = 'SAVE_LANGUAGE_STRINGS_SUCCESS'
export const SAVE_LANGUAGE_STRINGS_FAIL = 'SAVE_LANGUAGE_STRINGS_FAIL'

export const RESET_LANGUAGE_SETTINGS = 'RESET_LANGUAGE_SETTINGS'

// Ordering Checkout Settings
export const LOAD_ORDERING_CHECKOUT_SETTINGS_START = 'LOAD_ORDERING_CHECKOUT_SETTINGS_START'
export const LOAD_ORDERING_CHECKOUT_SETTINGS_SUCCESS = 'LOAD_ORDERING_CHECKOUT_SETTINGS_SUCCESS'
export const LOAD_ORDERING_CHECKOUT_SETTINGS_FAIL = 'LOAD_ORDERING_CHECKOUT_SETTINGS_FAIL'
export const CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD = 'CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD'
export const CHANGE_ORDERING_CHECKOUT_POS_FIELD = 'CHANGE_ORDERING_CHECKOUT_POS_FIELD'
export const SAVE_ORDERING_CHECKOUT_SETTINGS_START = 'SAVE_ORDERING_CHECKOUT_SETTINGS_START'
export const SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS = 'SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS'
export const SAVE_ORDERING_CHECKOUT_SETTINGS_FAIL = 'SAVE_ORDERING_CHECKOUT_SETTINGS_FAIL'

// Ordering Delivery Integrations
export const CHANGE_DELIVERY_INTEGRATION_FIELD = 'CHANGE_DELIVERY_INTEGRATION_FIELD'

export const LOAD_DELIVERY_INTEGRATION_SETTINGS_START = 'LOAD_DELIVERY_INTEGRATION_SETTINGS_START'
export const LOAD_DELIVERY_INTEGRATION_SETTINGS_SUCCESS = 'LOAD_DELIVERY_INTEGRATION_SETTINGS_SUCCESS'
export const LOAD_DELIVERY_INTEGRATION_SETTINGS_FAIL = 'LOAD_DELIVERY_INTEGRATION_SETTINGS_FAIL'

export const SELECT_DELIVERY_INTEGRATION_EDIT = 'SELECT_DELIVERY_INTEGRATION_EDIT'
export const CANCEL_DELIVERY_INTEGRATION_EDIT = 'CANCEL_DELIVERY_INTEGRATION_EDIT'

export const DEACTIVATE_DELIVERY_INTEGRATION_START = 'DEACTIVATE_DELIVERY_INTEGRATION_START'
export const DEACTIVATE_DELIVERY_INTEGRATION_SUCCESS = 'DEACTIVATE_DELIVERY_INTEGRATION_SUCCESS'
export const DEACTIVATE_DELIVERY_INTEGRATION_FAIL = 'DEACTIVATE_DELIVERY_INTEGRATION_FAIL'

export const SAVE_DELIVERY_INTEGRATION_SETTINGS_START = 'SAVE_DELIVERY_INTEGRATION_SETTINGS_START'
export const SAVE_DELIVERY_INTEGRATION_SETTINGS_SUCCESS = 'SAVE_DELIVERY_INTEGRATION_SETTINGS_SUCCESS'
export const SAVE_DELIVERY_INTEGRATION_SETTINGS_FAIL = 'SAVE_DELIVERY_INTEGRATION_SETTINGS_FAIL'

export const DELIVERY_INTEGRATION_FORM_VALIDATED = 'DELIVERY_INTEGRATION_FORM_VALIDATED'
export const DELIVERY_INTEGRATION_DISMISS_ERROR_DISPLAY = 'DELIVERY_INTEGRATION_DISMISS_ERROR_DISPLAY'

export const GET_ORDERING_CHECKOUT_FEE_LIST_SUCCESS = 'GET_ORDERING_CHECKOUT_FEE_LIST_SUCCESS'

export const GET_ORDERING_MENUS_LIST_START = 'GET_ORDERING_MENUS_LIST_START'
export const GET_ORDERING_MENUS_LIST_SUCCESS = 'GET_ORDERING_MENUS_LIST_SUCCESS'
export const GET_ORDERING_MENUS_LIST_FAIL = 'GET_ORDERING_MENUS_LIST_FAIL'

export const GET_ORDERING_MENU_SUCCESS = 'GET_ORDERING_MENU_SUCCESS'
export const GET_ORDERING_MENU_ITEMS_SUCCESS = 'GET_ORDERING_MENU_ITEMS_SUCCESS'
export const CLEAR_ORDERING_MENU = 'CLEAR_ORDERING_MENU'
export const UPDATE_ORDERING_MENU_NAME = 'UPDATE_ORDERING_MENU_NAME'
export const UPDATE_ORDERING_MENU_PREP_TIME_MINS = 'UPDATE_ORDERING_MENU_PREP_TIME_MINS'
export const UPDATE_ORDERING_MENU_SUCCESS = 'UPDATE_ORDERING_MENU_SUCCESS'
export const CREATE_ORDERING_MENU_SUCCESS = 'CREATE_ORDERING_MENU_SUCCESS'
export const CREATE_ORDERING_MENU_CATEGORY = 'CREATE_ORDERING_MENU_CATEGORY'
export const UPDATE_ORDERING_MENU_CATEGORY = 'UPDATE_ORDERING_MENU_CATEGORY'
export const DELETE_ORDERING_MENU_CATEGORY = 'DELETE_ORDERING_MENU_CATEGORY'
export const DELETE_ORDERING_MENU_CATEGORY_ITEM = 'DELETE_ORDERING_MENU_CATEGORY_ITEM'
export const UPDATE_ORDERING_MENU_CATEGORY_ITEMS = 'UPDATE_ORDERING_MENU_CATEGORY_ITEMS'
export const UPDATE_ORDERING_MENU_CATEGORY_ORDER = 'UPDATE_ORDERING_MENU_CATEGORY_ORDER'
export const OPEN_CATEGORY_MODAL = 'OPEN_CREATE_CATEGORY_MODAL'
export const CLOSE_CATEGORY_MODAL = 'CLOSE_CREATE_CATEGORY_MODAL'
export const OPEN_CONFIRM_DELETE_MODAL = 'OPEN_CONFIRM_DELETE_MODAL'
export const CLOSE_CONFIRM_DELETE_MODAL = 'CLOSE_CONFIRM_DELETE_MODAL'
export const OPEN_ADD_MENU_ITEM_MODAL = 'OPEN_ADD_MENU_ITEM_MODAL'
export const CLOSE_ADD_MENU_ITEM_MODAL = 'CLOSE_ADD_MENU_ITEM_MODAL'
export const UPDATE_ORDERING_MENU_DATE_RANGE_FROM = 'UPDATE_ORDERING_MENU_DATE_RANGE_FROM'
export const UPDATE_ORDERING_MENU_DATE_RANGE_TO = 'UPDATE_ORDERING_MENU_DATE_RANGE_TO'
export const ADD_MENU_AVAILABILITY = 'ADD_MENU_AVAILABILITY'
export const REMOVE_MENU_AVAILABILITY = 'REMOVE_MENU_AVAILABILITY'
export const COPY_MENU_AVAILABILITY_TO_ALL = 'COPY_MENU_AVAILABILITY_TO_ALL'
export const UPDATE_MENU_AVAILABILITY = 'UPDATE_MENU_AVAILABILITY'
export const REMOVE_MENU_START = 'REMOVE_MENU_START'
export const REMOVE_MENU_SUCCESS = 'REMOVE_MENU_SUCCESS'
export const REMOVE_MENU_FAIL = 'REMOVE_MENU_FAIL'
export const ADD_MENU_EXCLUDED_DATE_RANGE = 'ADD_MENU_EXCLUDED_DATE_RANGE'
export const UPDATE_MENU_EXCLUDED_DATE_RANGE = 'UPDATE_MENU_EXCLUDED_DATE_RANGE'
export const REMOVE_MENU_EXCLUDED_DATE_RANGE = 'REMOVE_MENU_EXCLUDED_DATE_RANGE'

export const GET_ORDERING_PACING_RULE_LIST_START = 'GET_ORDERING_PACING_RULE_LIST_START'
export const GET_ORDERING_PACING_RULE_LIST_FAIL = 'GET_ORDERING_PACING_RULE_LIST_FAIL'
export const GET_ORDERING_PACING_RULE_LIST_SUCCESS = 'GET_ORDERING_PACING_RULE_LIST_SUCCESS'
export const SET_PACING_RULE_LIST_FILTER_SEARCH_VALUE = 'SET_PACING_RULE_LIST_FILTER_SEARCH_VALUE'
export const SET_PACING_RULE_LIST_SORTED_COLUMNS = 'SET_PACING_RULE_LIST_SORTED_COLUMNS'
export const GET_ORDERING_PACING_RULE_SUCCESS = 'GET_ORDERING_PACING_RULE_SUCCESS'
export const GET_ORDERING_PACING_RULE_RESOURCES_SUCCESS = 'GET_ORDERING_PACING_RULE_RESOURCES_SUCCESS'
export const CLEAR_PACING_RULE_DATA = 'CLEAR_PACING_RULE_DATA'
export const UPDATE_PACING_RULE_NAME = 'UPDATE_PACING_RULE_NAME'
export const UPDATE_PACING_RULE_DATE_RANGE_FROM = 'UPDATE_PACING_RULE_DATE_RANGE_FROM'
export const UPDATE_PACING_RULE_DATE_RANGE_TO = 'UPDATE_PACING_RULE_DATE_RANGE_TO'
export const UPDATE_PACING_ORDER_METHODS = 'UPDATE_PACING_ORDER_METHODS'
export const UPDATE_PACING_RULE_ORDERING_SITE_IDS = 'UPDATE_PACING_RULE_ORDERING_SITE_IDS'
export const UPDATE_PACING_RULE_TIME_RANGE_FROM = 'UPDATE_PACING_RULE_TIME_RANGE_FROM'
export const UPDATE_PACING_RULE_TIME_RANGE_TO = 'UPDATE_PACING_RULE_TIME_RANGE_TO'
export const UPDATE_PACING_RULE_TIME_INTERVAL_MINS = 'UPDATE_PACING_RULE_TIME_INTERVAL_MINS'
export const UPDATE_PACING_RULE_DAY_TIME_LIMIT_MATRIX = 'UPDATE_PACING_RULE_DAY_TIME_LIMIT_MATRIX'
export const REMOVE_PACING_RULE_START = 'REMOVE_PACING_RULE_START'
export const REMOVE_PACING_RULE_FAIL = 'REMOVE_PACING_RULE_FAIL'
export const REMOVE_PACING_RULE_SUCCESS = 'REMOVE_PACING_RULE_SUCCESS'

export const GET_ORDERING_SITES_START = 'GET_ORDERING_SITES_START'
export const GET_ORDERING_SITES_SUCCESS = 'GET_ORDERING_SITES_SUCCESS'
export const GET_ORDERING_SITES_FAIL = 'GET_ORDERING_SITES_FAIL'

export const GET_ORDERING_SITE_START = 'GET_ORDERING_SITE_START'
export const GET_ORDERING_SITE_SUCCESS = 'GET_ORDERING_SITE_SUCCESS'
export const GET_ORDERING_SITE_FAIL = 'GET_ORDERING_SITE_FAIL'

export const SAVE_ORDERING_SITE_START = 'SAVE_ORDERING_SITE_START'
export const SAVE_ORDERING_SITE_SUCCESS = 'SAVE_ORDERING_SITE_SUCCESS'
export const SAVE_ORDERING_SITE_FAIL = 'SAVE_ORDERING_SITE_FAIL'

export const GET_ORDERING_SITE_SYNCED_POS_TABLES_START = 'GET_ORDERING_SITE_SYNCED_POS_TABLES_START'
export const GET_ORDERING_SITE_SYNCED_POS_TABLES_SUCCESS = 'GET_ORDERING_SITE_SYNCED_POS_TABLES_SUCCESS'
export const GET_ORDERING_SITE_SYNCED_POS_TABLES_FAIL = 'GET_ORDERING_SITE_SYNCED_POS_TABLES_FAIL'

export const DELETE_ORDERING_SITE_START = 'DELETE_ORDERING_SITE_START'
export const DELETE_ORDERING_SITE_SUCCESS = 'DELETE_ORDERING_SITE_SUCCESS'
export const DELETE_ORDERING_SITE_FAIL = 'DELETE_ORDERING_SITE_FAIL'

export const SET_ORDERING_SITE_DEFAULT_3RD_PARTY_START = 'SET_ORDERING_SITE_DEFAULT_3RD_PARTY_START'
export const SET_ORDERING_SITE_DEFAULT_3RD_PARTY_SUCCESS = 'SET_ORDERING_SITE_DEFAULT_3RD_PARTY_SUCCESS'
export const SET_ORDERING_SITE_DEFAULT_3RD_PARTY_FAIL = 'SET_ORDERING_SITE_DEFAULT_3RD_PARTY_FAIL'

export const UPDATE_ORDERING_FIELD = 'UPDATE_ORDERING_FIELD'
export const TOGGLE_DISPLAY_FIELD = 'TOGGLE_DISPLAY_FIELD'
export const TOGGLE_ORDERING_FIELD = 'TOGGLE_ORDERING_FIELD'
export const UPDATE_ORDERING_FIELDS = 'UPDATE_ORDERING_FIELDS'
export const UPDATE_ORDERING_SITE_MENUS = 'UPDATE_ORDERING_SITE_MENUS'
export const UPDATE_FULFILLMENT_OPTION = 'UPDATE_FULFILLMENT_OPTION'
export const UPDATE_ORDERING_SITE_POS_TABLES = 'UPDATE_ORDERING_SITE_POS_TABLES'
export const REMOVE_ORDERING_SITE_POS_TABLE = 'REMOVE_ORDERING_SITE_POS_TABLE'

export const UPDATE_BRAND_SETTINGS_FIELD = 'UPDATE_BRAND_SETTINGS_FIELD'
export const GET_ORDERING_BRAND_SETTINGS = 'GET_ORDERING_BRAND_SETTINGS'
export const SAVE_ORDERING_BRAND_SETTINGS_SUCCESS = 'SAVE_ORDERING_BRAND_SETTINGS_SUCCESS'
export const UPLOAD_PHOTO_PREVIEW = 'UPLOAD_PHOTO_PREVIEW'
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'
export const CROP_OPEN = 'CROP_OPEN'
export const CROP_CLOSE = 'CROP_CLOSE'
export const CROP_PHOTO = 'CROP_PHOTO'
export const CROP_DELETE = 'CROP_DELETE'

export const GET_ORDERING_PRODUCTS_LIST_START = 'GET_ORDERING_PRODUCTS_LIST_START'
export const GET_ORDERING_PRODUCTS_LIST_FAIL = 'GET_ORDERING_PRODUCTS_LIST_FAIL'
export const GET_ORDERING_PRODUCTS_LIST_SUCCESS = 'GET_ORDERING_PRODUCTS_LIST_SUCCESS'
export const SET_ORDERING_PRODUCT_SORTED_COLUMNS = 'SET_ORDERING_PRODUCT_SORTED_COLUMNS'

export const GET_ORDERING_PRODUCT_INVENTORY_START = 'GET_ORDERING_PRODUCT_INVENTORY_START'
export const GET_ORDERING_PRODUCT_INVENTORY_FAIL = 'GET_ORDERING_PRODUCT_INVENTORY_FAIL'
export const GET_ORDERING_PRODUCT_INVENTORY_SUCCESS = 'GET_ORDERING_PRODUCT_INVENTORY_SUCCESS'
export const SELECT_PRODUCT_INVENTORY_STATE_TAB = 'SELECT_PRODUCT_INVENTORY_STATE_TAB'
export const SET_PRODUCT_INVENTORY_FILTER_SEARCH_VALUE = 'SET_PRODUCT_INVENTORY_FILTER_SEARCH_VALUE'
export const SET_PRODUCT_INVENTORY_SORTED_COLUMNS = 'SET_PRODUCT_INVENTORY_SORTED_COLUMN'
export const GET_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS = 'GET_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS'
export const GET_ORDERING_PRODUCT_INVENTORY_RESOURCES_SUCCESS = 'GET_ORDERING_PRODUCT_INVENTORY_RESOURCES_SUCCESS'
export const UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUP = 'UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUP'
export const UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUPS = 'UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUPS'
export const UPDATE_PRODUCT_INVENTORY_TAX_RATE = 'UPDATE_PRODUCT_INVENTORY_TAX_RATE'
export const UPDATE_PRODUCT_INVENTORY_TAX_GROUP_ID = 'UPDATE_PRODUCT_INVENTORY_TAX_GROUP_ID'
export const UPDATE_PRODUCT_INVENTORY_MIN_AMOUNT = 'UPDATE_PRODUCT_INVENTORY_MIN_AMOUNT'
export const UPDATE_PRODUCT_INVENTORY_MAX_AMOUNT = 'UPDATE_PRODUCT_INVENTORY_MAX_AMOUNT'
export const UPDATE_PRODUCT_INVENTORY_MAX_SAME_MODIFIERS_AMOUNT = 'UPDATE_PRODUCT_INVENTORY_MAX_SAME_MODIFIERS_AMOUNT'
export const UPDATE_PRODUCT_INVENTORY_ALLOW_ORDER_SAME_MODIFIERS = 'UPDATE_PRODUCT_INVENTORY_ALLOW_ORDER_SAME_MODIFIERS'
export const UPDATE_PRODUCT_INVENTORY_VERIFY_AGE = 'UPDATE_PRODUCT_INVENTORY_VERIFY_AGE'
export const CLEAR_PRODUCT_INVENTORY_DATA = 'CLEAR_PRODUCT_INVENTORY_DATA'
export const UPDATE_PRODUCT_INVENTORY_NAME = 'UPDATE_PRODUCT_INVENTORY_NAME'
export const UPDATE_PRODUCT_INVENTORY_DESCRIPTION = 'UPDATE_PRODUCT_INVENTORY_DESCRIPTION'
export const UPDATE_PRODUCT_INVENTORY_PRICE = 'UPDATE_PRODUCT_INVENTORY_PRICE'
export const UPDATE_PRODUCT_INVENTORY_PRODUCT_TAGS = 'UPDATE_PRODUCT_INVENTORY_PRODUCT_TAGS'
export const UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS = 'UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS'
export const CREATE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS = 'CREATE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS'
export const SAVE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS = 'SAVE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS'
export const REMOVE_PRODUCT_INVENTORY_SELECTED_MODIFIER = 'REMOVE_PRODUCT_INVENTORY_SELECTED_MODIFIER'
export const OPEN_CREATE_PRODUCT_MODAL = 'OPEN_CREATE_PRODUCT_MODAL'
export const CLOSE_CREATE_PRODUCT_MODAL = 'CLOSE_CREATE_PRODUCT_MODAL'
export const REMOVE_PRODUCT_INVENTORY_START = 'REMOVE_PRODUCT_INVENTORY_START'
export const REMOVE_PRODUCT_INVENTORY_SUCCESS = 'REMOVE_PRODUCT_INVENTORY_SUCCESS'
export const REMOVE_PRODUCT_INVENTORY_FAIL = 'REMOVE_PRODUCT_INVENTORY_FAIL'

export const SELECT_SNOOZE_OPTION = 'SELECT_SNOOZE_OPTION'
export const SELECT_PRODUCT_ITEM = 'SELECT_PRODUCT_ITEM'

export const SUBMIT_SNOOZE_PRODUCT_START = 'SUBMIT_SNOOZE_PRODUCT_START'
export const SUBMIT_SNOOZE_PRODUCT_FAIL = 'SUBMIT_SNOOZE_PRODUCT_FAIL'
export const SUBMIT_SNOOZE_PRODUCT_SUCCESS = 'SUBMIT_SNOOZE_PRODUCT_SUCCESS'

export const SELECT_PRODUCT_STATE_TAB = 'SELECT_PRODUCT_STATE_TAB'
export const SET_PRODUCT_FILTER_SEARCH_VALUE = 'SET_PRODUCT_FILTER_SEARCH_VALUE'
export const SET_PRODUCT_TYPE_FILTER = 'SET_PRODUCT_TYPE_FILTER'
export const SET_SPECIFIC_SNOOZE_DATE = 'SET_SPECIFIC_SNOOZE_DATE'

export const ADD_CUSTOM_POLICY = 'ADD_CUSTOM_POLICY'
export const GET_POLICY_STRINGS_START = 'GET_POLICY_STRINGS_START'
export const GET_POLICY_STRINGS_SUCCESS = 'GET_POLICY_STRINGS_SUCCESS'
export const GET_POLICY_STRINGS_FAIL = 'GET_POLICY_STRINGS_FAIL'
export const UPDATE_POLICY_STRING = 'UPDATE_POLICY_STRING'
export const SAVE_POLICY_STRINGS_START = 'SAVE_POLICY_STRINGS_START'
export const SAVE_POLICY_STRINGS_SUCCESS = 'SAVE_POLICY_STRINGS_SUCCESS'
export const SAVE_POLICY_STRINGS_FAIL = 'SAVE_POLICY_STRINGS_FAIL'
