import { Map } from 'immutable'
import _ from 'lodash'
import {
  GET_INITIAL_AVAILABILITY_SUCCESS,
  GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
  GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  TRY_GET_INITIAL_AVAILABILITY,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
} from '../../actions/ActionTypes'

const availabilityLookup = (state = Map(), action) => {
  switch (action.type) {
    case TRY_GET_INITIAL_AVAILABILITY:
    case TRY_GET_MULTI_VENUE_AVAILABILITY:
      return Map()
    case GET_MULTI_VENUE_AVAILABILITY_SUCCESS: {
      let nextState = state
      _.each(action.data, result => {
        nextState = nextState.merge(result.availabilityLookup)
      })
      return nextState
    }
    case GET_INITIAL_AVAILABILITY_SUCCESS:
    case GET_ADDITIONAL_AVAILABILITY_SUCCESS:
      return state.mergeDeep(action.data.availabilityLookup)
    default:
      return state
  }
}

export default availabilityLookup
