import Radium from 'radium'
import React, { Component } from 'react'
import { camelCaseString } from '../../common/StringUtils'
import { getCardType } from '../../common/StripeUtils'
import { validateCardNumber } from '../../common/Validators'
import { CreditCardImages } from '../Payments/Constants'
import { getCardStyle } from './cardStyles'
import { getStyles, color } from './styles'
import TextInput from './TextInput'

const MAX_DIGITS_IN_CARD_NUMBER = 19

class CardNumberInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidKeyPress: true,
      cardType: 'Unknown',
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.setCreditCardAttribs = this.setCreditCardAttribs.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  validate(val = this.props.value) {
    const isValid = validateCardNumber(val)
    this.setState({ isValidKeyPress: isValid })
    return isValid
  }

  getCardType(val = this.props.value) {
    return getCardType(val)
  }

  getCardImg(cardType = this.props.cardType) {
    if (cardType === 'Unknown') {
      return ''
    }
    return `${this.props.mediaUrl}images/widget/${CreditCardImages[camelCaseString(cardType)]}`
  }

  setCreditCardAttribs(cardType) {
    this.setState({
      cardType,
      cardImg: this.getCardImg(cardType),
      cardImgStyle: getCardStyle(cardType),
    })
  }

  handleInputChange(val = this.props.value) {
    const cardType = this.getCardType(val)
    this.validate(val)
    this.setCreditCardAttribs(cardType)
    this.props.onChange(val)
  }

  onFocus() {
    this.setState({ ccFieldFocus: true })
  }

  onBlur() {
    if (this.state.ccFieldFocus && this.props.enablePromoCodes) {
      this.checkForApplicablePromoCodes()
      this.setState({ ccFieldFocus: false })
    }
  }

  checkForApplicablePromoCodes() {
    const cardType = camelCaseString(this.state.cardType)
    const { promoCodes } = this.props
    if (cardType !== 'unknown' && promoCodes && cardType in promoCodes && promoCodes[cardType] && this.state.isValidKeyPress) {
      this.props.setPromoCode(promoCodes[cardType])
      this.setState({ lastApplicableCardType: this.state.cardType })
    } else if (this.props.triggeredValidPromoCode && this.state.lastApplicableCardType !== this.state.cardType) {
      const lastCardType = camelCaseString(this.state.lastApplicableCardType)
      this.props.removePromoCode(promoCodes[lastCardType].ccDisplay)
      this.setState({ lastApplicableCardType: null })
    }
  }

  render() {
    const { style, value, placeholder, isValid, placeholderColor, textColor, errorColor, testId } = this.props
    const styles = getStyles(this.props.textColor)
    return (
      <div style={[styles.inputWrapper, styles.flexContainer, !isValid && { borderColor: errorColor }, style]}>
        <TextInput
          limitType="number"
          charLimit={MAX_DIGITS_IN_CARD_NUMBER}
          style={[styles.flexBox, { borderColor: 'transparent' }]}
          onChange={this.handleInputChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          value={value}
          isValid={this.state.isValidKeyPress}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          textColor={textColor}
          errorColor={errorColor}
          ariaLabel="Credit Card Number"
          testId={testId}
        />
        <span>
          <img src={this.state.cardImg} style={this.state.cardImgStyle} aria-hidden="true" />
        </span>
      </div>
    )
  }
}

CardNumberInput.propTypes = {
  value: React.PropTypes.string.isRequired,
  onChange: React.PropTypes.func.isRequired,
  placeholder: React.PropTypes.string,
  isValid: React.PropTypes.bool,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  placeholderColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
  errorColor: React.PropTypes.string,
  mediaUrl: React.PropTypes.string,
  testId: React.PropTypes.string,
}

CardNumberInput.defaultProps = {
  placeholder: 'credit card number',
  isValid: true,
  validator: () => true,
  style: {},
  placeholderColor: color.placeholder,
  textColor: color.text,
  errorColor: color.error,
  promoCodes: {},
  ccFieldFocus: false,
  enablePromoCodes: false,
  triggeredValidPromoCode: false,
  displayModal: false,
}

export default Radium(CardNumberInput)
