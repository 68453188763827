import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { selectLanguageStrings, selectCalculatedLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { selectTimeSlot } from '../../actions/navigation'
import { ALL_LOCATIONS, calculateSelectedUpsells } from '../../utils/convertData'
import TimeSlot from '../components/TimeSlot'
import { selectInitialResultsFormat } from '../selectors'
import { calculateFeesForTimeSlot } from '../../utils/calculateFees'

const HiddenAriaAlert = styled.div`
  position: absolute;
  top: -9999px;
  left: -9999px;
`

const MainResultsWrapper = styled.div`
  width: 100%;
`

const MainResultsLabel = styled.div`
  padding: 20px 4%;
  text-align: center;
  line-height: 24px;
  width: 100%;
  color: ${props => (props.noResults ? props.theme.fontsColorSecondary : props.theme.fontsColorPrimary)};
  background-color: ${props => props.theme.colorWidgetBackground};
`

const MainResultsBlock = styled.div`
  width: 100%;
  padding: 20px 4%;
  background-color: ${props => props.theme.colorCellBackground};
`

const mapResultsToTimeSlot = (
  initialResults,
  availabilityTimeLookup,
  partySize,
  foundNearbyResult,
  selectTimeSlot,
  venueKey,
  currencyCode,
  textPerPerson,
  textPerReservation,
  upsellInventories,
  locale,
  upsellCategories = [],
  isFeesInPriceDisplayed = false
) =>
  _.map(initialResults, (result, idx) => {
    if (!result) {
      return <TimeSlot key={`sr-timeslot-empty-${idx}`} timeSlotMoment={null} />
    }
    const timeSlotMoment = moment(result.timeIso)
    const dateKey = timeSlotMoment.format('Y-MM-DD')
    const timeKey = timeSlotMoment.format('h:mm A')
    const {
      requireCreditCard,
      cost = 0,
      duration,
      chargeType,
      ccPartySizeMin,
      publicDescriptionTitle,
      publicTimeSlotDescription,
      publicLongFormDescription,
      publicPhoto,
      publicPhotoSizes,
      accessPersistentId,
      selectedAutomaticUpsells,
    } = result
    const requirePayment = requireCreditCard && partySize > ccPartySizeMin
    const totalCost =
      cost +
      (selectedAutomaticUpsells && selectedAutomaticUpsells.length > 0
        ? calculateSelectedUpsells(selectedAutomaticUpsells, chargeType, partySize, upsellInventories, duration)
        : 0)
    const fees = calculateFeesForTimeSlot(isFeesInPriceDisplayed, result, upsellInventories, upsellCategories, partySize)
    return (
      <TimeSlot
        key={`sr-timeslot-${dateKey}-${timeKey}-${accessPersistentId}`}
        timeSlotMoment={timeSlotMoment}
        accessPersistentId={accessPersistentId}
        requirePayment={requirePayment}
        chargeType={chargeType}
        cost={totalCost}
        duration={duration}
        reservationCost={cost}
        reservationFees={fees}
        publicDescriptionTitle={publicDescriptionTitle}
        publicTimeSlotDescription={publicTimeSlotDescription}
        publicLongFormDescription={publicLongFormDescription}
        publicPhoto={publicPhoto}
        publicPhotoSizes={publicPhotoSizes}
        selectedAutomaticUpsells={selectedAutomaticUpsells}
        foundNearbyResult={foundNearbyResult}
        onTimeSlotClick={selectTimeSlot}
        venueKey={venueKey}
        currencyCode={currencyCode}
        textPerPerson={textPerPerson}
        textPerReservation={textPerReservation}
        locale={locale}
      />
    )
  })

const MainResultsDisplay = ({
  initialResults,
  availabilityTimeLookup,
  noAvailability,
  partySize,
  isValidDate,
  selectTimeSlot,
  venueKey,
  currencyCode,
  textSelectATime,
  textNoAvailabilityForAccessRules,
  textRestaurantClosed,
  textBlackoutOn,
  textPerPerson,
  textPerReservation,
  upsellInventories,
  locale,
  isExperienceMode,
  isSearching,
  isFeesInPriceDisplayed,
  upsellCategories,
}) => {
  if (isExperienceMode && isSearching) {
    return <MainResultsWrapper />
  } else if (_.isEmpty(initialResults)) {
    let noAvailabilityMessage = textNoAvailabilityForAccessRules

    if (noAvailability.isBlackout) {
      noAvailabilityMessage = textBlackoutOn
    } else if (!isValidDate && !isExperienceMode) {
      noAvailabilityMessage = textRestaurantClosed
    }

    return (
      <MainResultsWrapper>
        <MainResultsLabel dangerouslySetInnerHTML={{ __html: noAvailabilityMessage }} />
      </MainResultsWrapper>
    )
  }
  return (
    <MainResultsWrapper>
      <HiddenAriaAlert role="alert" aria-label="Availability found">
        {textSelectATime}
      </HiddenAriaAlert>
      <MainResultsLabel data-test="sr-main-result-block">{textSelectATime}</MainResultsLabel>
      <MainResultsBlock>
        {mapResultsToTimeSlot(
          initialResults,
          availabilityTimeLookup,
          partySize,
          true,
          selectTimeSlot,
          venueKey,
          currencyCode,
          textPerPerson,
          textPerReservation,
          upsellInventories,
          locale,
          upsellCategories,
          isFeesInPriceDisplayed
        )}
      </MainResultsBlock>
    </MainResultsWrapper>
  )
}

const mapStateToProps = state => {
  const dateMoment = state.search.get('dateMoment')
  const dateKey = dateMoment.format('Y-MM-DD')
  const selectedVenue = state.search.get('selectedVenue')
  const venueKey = selectedVenue === ALL_LOCATIONS ? state.venueInfo.urlKey : selectedVenue
  const { currencyCode } = state.venueInfo
  const noAvailability = state.availability.get('noAvailability').get(dateKey)
  const availabilityTimeLookupRaw = state.availabilityLookup.size && state.availabilityLookup.get(venueKey).get(dateKey)
  const languageStrings = selectLanguageStrings(state)
  const placeholderLanguageStrings = selectCalculatedLanguageStrings(state)

  return {
    initialResults: selectInitialResultsFormat(state),
    availabilityTimeLookup: availabilityTimeLookupRaw && availabilityTimeLookupRaw.toJS(),
    partySize: state.search.get('partySize'),
    venueKey,
    currencyCode,
    isValidDate: state.availability.get('validDates').get(dateKey),
    noAvailability: noAvailability ? noAvailability.toJS() : {},

    // text
    textSelectATime: placeholderLanguageStrings.textSelectATime,
    textNoAvailabilityForAccessRules: languageStrings.textNoAvailabilityForAccessRules,
    textRestaurantClosed: placeholderLanguageStrings.textRestaurantClosed,
    textBlackoutOn: languageStrings.textBlackoutOn,
    textPerPerson: languageStrings.textPerPerson,
    textPerReservation: languageStrings.textPerReservation,
    isFeesInPriceDisplayed: state.widgetSettings.isFeesInPriceDisplayed,
    upsellInventories: state.upsells.entities.inventories,
    upsellCategories: state.upsells.entities.categories,
    locale: state.venueInfo.locale,
    isExperienceMode: state.experience.get('isExperienceMode'),
    isSearching: state.ui.get('displayModalType') === 'SPINNER',
  }
}

const mapDispatchToProps = {
  selectTimeSlot,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainResultsDisplay)
