import _ from 'lodash'
import Radium, { Style } from 'radium'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { keyDownHandlerWrapper } from 'svr/common/A11y'
import { ALL_LOCATIONS } from '../utils/convertData'

const styles = {
  comboBox: {
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    height: '40px',
    lineHeight: '24px',
    padding: '8px 20px',
    position: 'relative',
    width: '100%',
  },
  dropdownLabel: {
    float: 'left',
  },
  dropdownArrow: {
    float: 'right',
    fontSize: '23px',
  },
  listboxWrapper: {
    backgroundColor: 'white',
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '2px',
    position: 'absolute',
    right: 0,
    top: '35px',
    width: '100%',
    zIndex: '1',
  },
  listbox: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '5px',
    position: 'absolute',
    top: '-12px',
  },
  dropdownLine: {
    cursor: 'pointer',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    lineHeight: '24px',
    padding: '6px 10px',
    width: '100%',
  },
  divider: {
    borderStyle: 'solid',
    borderWidth: '1px 0',
  },
  dropdownCheck: {
    float: 'right',
    fontSize: '20px',
    height: '24px',
    lineHeight: '24px',
  },
}

class VenueDropdown extends Component {
  constructor(props) {
    super(props)
    const { venueMap, venueSortOrders, onVenueClick } = this.props
    this.state = { open: false }
    this.onDropdownClick = this.onDropdownClick.bind(this)
    this.venueRow = this.venueRow.bind(this)
    this.divider = this.divider.bind(this)
    this.venueKeyList = _.sortBy(
      _.remove(_.keys(venueMap), key => key !== ALL_LOCATIONS),
      urlkey => venueSortOrders[urlkey] || 0
    )
    this.venueKeyList.unshift(ALL_LOCATIONS)
    this.venueRowClickHandler = _.mapValues(venueMap, (val, key) => onVenueClick.bind(this, key, val))
  }

  handleClickOutside() {
    this.setState({ open: false })
  }

  onDropdownClick() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ open: !this.state.open })
  }

  interleave(arr, compA, compB) {
    const newArr = arr.join('+~+').split('+')
    let keyCtr = 0
    return _.map(newArr, key => {
      if (key === '~') {
        keyCtr += 1
        return compB(keyCtr)
      }
      return compA(key)
    })
  }

  divider(key) {
    return <div key={`dd-divider-${key}`} style={[styles.divider, { borderColor: this.props.lineColor }]} />
  }

  venueRow(key) {
    const { venueMap, selectedVenue, listFontColor } = this.props
    const venue = venueMap[key]
    const isVenueSelected = key === selectedVenue

    return (
      <li
        role="option"
        tabIndex={0}
        data-test={`sr-location-option-${key}`}
        aria-selected={isVenueSelected}
        key={`dd-${venue}`}
        name={venue}
        className="venue-row"
        onClick={this.venueRowClickHandler[key]}
        onKeyDown={keyDownHandlerWrapper.bind(this, this.venueRowClickHandler[key])}
        style={[
          styles.dropdownLine,
          {
            color: listFontColor,
          },
        ]}
      >
        {venue}
        {isVenueSelected && <i style={styles.dropdownCheck} className="fa fa-check fa-fw" aria-hidden="true" />}
      </li>
    )
  }

  render() {
    const { venueMap, selectedVenue, backgroundColor, dropdownFontColor, modalBackground, lineColor } = this.props
    return (
      <button
        data-test="sr-location-combobox"
        id="select-venue-combobox"
        role="combobox"
        aria-expanded={this.state.open}
        aria-controls="select-venue-listbox"
        type="button"
        onClick={this.onDropdownClick}
        aria-label="Select venue"
        aria-activedescendant={venueMap[selectedVenue]}
        onKeyDown={keyDownHandlerWrapper.bind(this, this.onDropdownClick)}
        style={[
          styles.comboBox,
          {
            backgroundColor,
            color: dropdownFontColor,
            width: '100%',
          },
        ]}
      >
        <span id="select-venue-label" style={styles.dropdownLabel}>
          {venueMap[selectedVenue]}
        </span>
        <i style={styles.dropdownArrow} className="fa fa-angle-down fa-fw" aria-hidden="true" />
        {this.state.open && (
          <div
            id="select-venue-listbox-wrapper"
            style={[styles.listboxWrapper, { backgroundColor: modalBackground, borderColor: lineColor, width: '100%' }]}
            data-test="sr-location-listbox-wrapper"
          >
            <Style scopeSelector=".venue-row:hover,.venue-row:focus" rules={{ 'background-color': this.props.hoverColor }} />
            <ul
              id="select-venue-listbox"
              data-test="sr-location-listbox"
              role="listbox"
              aria-label="Venues"
              style={[
                styles.listbox,
                {
                  backgroundColor: modalBackground,
                  width: '100%',
                  padding: 0,
                },
              ]}
            >
              {this.interleave(this.venueKeyList, this.venueRow, this.divider)}
            </ul>
          </div>
        )}
      </button>
    )
  }
}

export default onClickOutside(Radium(VenueDropdown))
