// /* @flow */
import * as React from 'react'
import styled, { css } from 'styled-components'
import TextInput from './TextInput'

/* type Props = {
  onBlur: string => mixed,
  onChange: (string, string) => mixed,
  onFocus: void => mixed,
  charLimit: number,
  customInput: Array<string | (() => mixed)>,
  customInputLabel: Array<string | (() => mixed)>,
  customInputWrapper: Array<string | (() => mixed)>,
  customInputStyles: Array<string | (() => mixed)>,
  fontsColorCheckoutActive: string,
  fontsColorCheckoutInactive: string,
  fontFamily: string,
  isFocused: boolean,
  isValid?: boolean,
  selectedDialCode: string,
  selectedNumberFormat: string,
  value: string,
  placeholderText: string,
  autoComplete: string,
  labelText: string,
} */

class NumberInput extends React.Component /* <Props> */ {
  static defaultProps = {
    customInput: [],
    customInputLabel: [],
    customInputWrapper: [],
    customInputStyles: [],
    labelText: 'Phone Number *',
  }

  handleNumberInputChange = (value /* : string */) => {
    // Strip punctuation and dial code from value
    const phoneNumber = value.replace(/[^0-9]/g, '').substr(this.props.selectedDialCode.length)
    this.props.onChange(value, phoneNumber)
  }

  inputValue = () => {
    if (this.notInPhoneNumberState()) {
      return `${this.props.placeholderText || 'Phone Number'} *`
    }
    return String(this.props.value)
  }

  customPlaceholderColor = () => {
    let textColor = this.props.fontsColorCheckoutInactive
    if (this.props.isValid === false) {
      textColor = this.props.errorColor || `red`
    }
    return textColor
  }

  // State when placeholder is down
  notInPhoneNumberState = () => (!this.props.isFocused && this.props.value) === `+${this.props.selectedDialCode}`

  customInputStyle = () => {
    let textColor = this.notInPhoneNumberState() ? this.props.fontsColorCheckoutInactive : this.props.fontsColorCheckoutActive
    if (!this.props.isValid) {
      textColor = this.props.errorColor || `red`
    }
    return css`
      color: ${textColor};
      font-family: ${this.props.fontFamily};
      ${props => this.props.customInputStyles};
    `
  }

  render() {
    const phoneNumberPadding = this.notInPhoneNumberState()
    return (
      <TextWrapper phoneNumberPadding={phoneNumberPadding} fontFamily={this.props.fontFamily}>
        {!this.notInPhoneNumberState() && !this.props.readOnly ? (
          <PlaceHolder fontColor={this.customPlaceholderColor()} fontFamily={this.props.fontFamily}>
            {this.props.labelText}
          </PlaceHolder>
        ) : null}
        <TextInput
          ariaRequired={this.props.ariaRequired}
          ariaLabel={this.props.ariaLabel}
          onBlur={this.props.onBlur}
          onChange={this.handleNumberInputChange}
          onFocus={this.props.onFocus}
          isFocused={this.props.isFocused}
          isValid={this.props.isValid}
          value={this.inputValue()}
          charLimit={this.props.charLimit}
          autoComplete={this.props.autoComplete}
          customInput={this.customInputStyle()}
          customInputWrapper={this.props.customInputWrapper}
          selectedDialCode={this.props.selectedDialCode}
          selectedNumberFormat={this.props.selectedNumberFormat}
          textColor={this.notInPhoneNumberState() ? this.props.fontsColorCheckoutInactive : this.props.fontsColorCheckoutActive}
          errorColor={this.props.errorColor}
          placeholder={this.props.placeholderText}
          readOnly={this.props.readOnly}
          testId={this.props.testId}
        />
      </TextWrapper>
    )
  }
}

const TextWrapper = styled.div`
  padding-top: ${props => (props.fontFamily === 'Palanquin' ? '4px' : props.phoneNumberPadding ? '7px' : '0px')};
  padding-left: 20px;
`

const PlaceHolder = styled.div`
  padding-left: 16px;
  font-size: 10px;
  color: ${props => props.fontColor};
  font-family: ${props => props.fontFamily};
`

export default NumberInput
