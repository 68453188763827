/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import * as Validators from 'svr/common/Validators'

export function loadScript(url, callback) {
  const script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url

  const head = document.getElementsByTagName('head')

  if (head && head[0]) {
    head[0].appendChild(script)
  }

  return script
}

export const validatorFuncs = Object.freeze({
  notEmpty: Validators.validateNotEmpty,
})

const buildValidatorTypes = {}
for (const k of Object.keys(validatorFuncs)) {
  buildValidatorTypes[k] = k
}
export const ValidatorTypes = Object.freeze(buildValidatorTypes)

const requiredValidators = Object.freeze([ValidatorTypes.notEmpty])

const callValidator = (value, vtype) => validatorFuncs[vtype](value)

const StyledInputWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`

const BorderedArea = styled.div`
  min-height: 42px;
  position: relative;
  padding: 0 10px 0 10px;
  box-sizing: content-box !important;
  border-radius: 4px;
  border-width: ${props => (props.hideBorder ? '0' : '1px')};
  border-style: solid;
  border-color: ${props => (props.isValid ? props.borderColor || props.theme.lightGrey : props.errorColor || props.theme.error)};
  background-color: ${props => (props.disabled ? props.theme.lighterSilver : props.backgroundColor || props.theme.background)};
  overflow: ${props => (props.showOverflow ? 'visible' : 'hidden')};
  :hover {
    border-color: ${props => (props.disabled ? props.theme.lightGrey : props.borderColor || props.theme.darkGrey)};
    transition: border-color 0.2s ease-out;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: '${props => props.icon || ''}';
    color: ${props => (props.isValid ? props.theme.primary : props.errorColor || props.theme.error)};
  }
`

const NameOutside = styled.label`
  color: ${props => (props.isValid === false ? props.errorColor || props.theme.error : props.theme.darkGrey)};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
  box-sizing: inherit;
  display: block;
  ${props => props.labelStyle};
`

export const TextInputLabel = NameOutside

const NameInside = styled.label`
  position: absolute;
  top: 1px;
  color: ${props => (props.isValid ? props.theme.darkGrey : props.errorColor || props.theme.error)};
  font-weight: 500;
  font-size: 11px;
`

// Ugly to have all these !important decorators, but needed for production.css overrides on input fields
const StyledInput = styled.input.attrs({
  type: 'text',
})`
  font-size: 14px !important;
  line-height: 19px;
  width: 100% !important;
  min-width: 60px;
  ${props => (props.indentField ? '' : 'min-height: 42px;')} border: 0 !important;
  position: relative;
  outline: none;
  overflow: hidden;
  background-color: ${props => (props.disabled ? props.theme.lighterSilver : props.backgroundColor || props.theme.white)} !important;
  padding: ${props => (props.indentField ? '11px' : props.useOutsideLabel ? '0' : '2px')} 0 0 0 !important;
  cursor: ${props => (props.disabled ? 'default' : 'auto')};
  color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)} !important;
  :focus: {
  }
  ::-webkit-input-placeholder {
    color: ${props => (props.isValid ? props.placeholderColor || props.theme.darkGrey : props.errorColor || props.theme.error)};
    font-family: inherit;
  }
  ::-moz-placeholder {
    color: ${props => (props.isValid ? props.placeholderColor || props.theme.darkGrey : props.errorColor || props.theme.error)};
    font-family: inherit;
  }
  :-ms-input-placeholder {
    color: ${props => (props.isValid ? props.placeholderColor || props.theme.darkGrey : props.errorColor || props.theme.error)};
    font-family: inherit;
  }
  ${props => props.inputStyles};
`
/* eslint-disable no-param-reassign */
const selectFirstOnEnter = function (input) {
  /* Store original event listener */
  const _addEventListener = input.addEventListener

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener
      listener = event => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
          _listener.apply(input, [e])
        }
        _listener.apply(input, [event])
      }
    }
    _addEventListener.apply(input, [type, listener])
  }

  input.addEventListener = addEventListenerWrapper
}
/* eslint-enable no-param-reassign */

class TextInput extends Component {
  constructor(props) {
    super(props)
    const { label, placeholder, value } = this.props
    this.state = { focused: false, value: value || '', previousPlace: null, place: null }
    const formatPlaceholder = _.toLower(label || placeholder).replace(/[^\w]+/g, '-')
    this.inputId = this.props.inputId || `sr-${formatPlaceholder}`
    this.input = null
    this.onInputChange = this.onInputChange.bind(this)
    this.onInputKeyDown = this.onInputKeyDown.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.isValid = this.isValid.bind(this)
    this.isDebouncing = false
    this.autoCompleteWidget = null
  }

  componentWillMount() {
    this.createNotifier(this.props.debounceTimeout)
  }

  componentDidMount() {
    this.loadGooglePlacesScript()
  }

  loadGooglePlacesScript() {
    const callback = () => this.handleScriptLoad()
    if (_.get(window, 'google.maps.places')) {
      callback()
    } else {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.props.googlePlacesApiKey}&libraries=places`, callback)
    }
  }

  handleScriptLoad() {
    const { latitude, longitude, boundsDistanceKm } = this.props
    let origin = null
    let bounds = null
    if (!_.isNil(latitude) && !_.isNil(longitude)) {
      origin = { lat: latitude, lng: longitude }
      // 1 lat/long is approx 111 kms
      const latLngBoundsDiff = boundsDistanceKm / 111
      bounds = {
        north: origin.lat + latLngBoundsDiff,
        south: origin.lat - latLngBoundsDiff,
        east: origin.lng + latLngBoundsDiff,
        west: origin.lng - latLngBoundsDiff,
      }
    }
    const autocompleteOptions = {
      bounds,
      fields: ['address_components', 'formatted_address', 'place_id', 'geometry'],
      origin,
      strictBounds: false,
      types: ['address'],
    }
    this.autoCompleteWidget = new window.google.maps.places.Autocomplete(this.input, autocompleteOptions)
    this.autoCompleteWidget.addListener('place_changed', () => this.onPlaceSelect(this.autoCompleteWidget.getPlace()))
    selectFirstOnEnter(this.input)
  }

  componentWillReceiveProps({ value, debounceTimeout }) {
    if (this.isDebouncing) {
      return
    }
    if (typeof value !== 'undefined' && this.state.value !== value) {
      this.setState({ value })
    }
    if (debounceTimeout !== this.props.debounceTimeout) {
      this.createNotifier(debounceTimeout)
    }
  }

  componentWillUnmount() {
    if (this.flush) {
      this.flush()
    }
  }

  onPlaceSelect(place) {
    if (this.props.disabled) {
      return
    }
    this.setState({ value: place.formatted_address, previousPlace: place, place }, () => {
      this.notify()
    })
  }

  onInputChange(e) {
    e.persist()
    if (this.props.disabled) {
      return
    }
    this.setState({ value: e.target.value, place: null }, () => {
      this.notify()
    })
  }

  onInputKeyDown(e) {
    const { onKeyDown } = this.props
    onKeyDown(e)
  }

  createNotifier(debounceTimeout) {
    if (debounceTimeout < 0) {
      this.notify = () => null
    } else if (debounceTimeout === 0) {
      this.notify = this.doNotify
    } else {
      const debouncedChangeFunc = _.debounce(() => {
        this.isDebouncing = false
        this.doNotify()
      }, debounceTimeout)

      this.notify = () => {
        this.isDebouncing = true
        debouncedChangeFunc()
      }

      this.flush = () => debouncedChangeFunc.flush()

      this.cancel = () => {
        this.isDebouncing = false
        debouncedChangeFunc.cancel()
      }
    }
  }

  doNotify() {
    const { onChange } = this.props
    const { value, place } = this.state
    if (place) {
      onChange(value, place)
    }
  }

  forceNotify() {
    if (!this.isDebouncing) {
      return
    }

    if (this.cancel) {
      this.cancel()
    }

    this.doNotify()
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid() {
    const { validator, customValidator, overrideDefaultError } = this.props
    const customIsValid = customValidator(this.state.value)
    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }
    if (overrideDefaultError) {
      return callValidator(this.state.value || '', validator)
    }
    return callValidator(this.state.value || '', validator) || this.buildInvalidDisplayText()
  }

  buildInvalidDisplayText() {
    const { label, placeholder, invalidDisplayText, validator, value } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !value) || _.isEmpty(value.trim())
    return _.startCase(_.toLower(label || placeholder)) + (showRequiredMsg ? ' is required' : '  is not valid')
  }

  focus() {
    ReactDOM.findDOMNode(this.input).focus()
    this.setState({ focused: true })
  }

  blur() {
    ReactDOM.findDOMNode(this.input).blur()
    this.setState({ focused: false })
  }

  onFocus() {
    this.setState({ focused: true })
  }

  onBlur(e) {
    const { onBlur } = this.props
    if (this.state.previousPlace) {
      this.setState({ focused: false, value: this.state.previousPlace.formatted_address })
    }

    this.forceNotify()
    onBlur(e)
  }

  isInFocus() {
    return this.state.focused
  }

  setInputRef(e) {
    if (e) {
      this.props.inputRef(e)
      this.input = e
      this.input.setAttribute('autocomplete', 'off')
    }
  }

  render() {
    const {
      testId,
      isValid,
      placeholder,
      label,
      disabled,
      showLabel,
      hideBorder,
      useOutsideLabel,
      style,
      labelStyle,
      forceIndent,
      resizable,
      inputStyles,
      borderColor,
      backgroundColor,
      icon,
      placeholderColor,
      errorColor,
    } = this.props
    const { value } = this.state
    const isFieldFocused = this.isInFocus() || value
    const labelShown = showLabel && (label || isFieldFocused)
    const indentField = (!isFieldFocused && !useOutsideLabel && _.isEmpty(label)) || forceIndent

    return (
      <StyledInputWrapper {...{ style }}>
        {useOutsideLabel && labelShown && (
          <NameOutside {...{ isValid, labelStyle, errorColor }} htmlFor={this.inputId}>
            {label || placeholder}
          </NameOutside>
        )}
        <BorderedArea {...{ isValid, disabled, hideBorder, borderColor, backgroundColor, icon, errorColor }}>
          {!useOutsideLabel && labelShown && (
            <NameInside {...{ isValid, errorColor }} htmlFor={this.inputId}>
              {label || placeholder}
            </NameInside>
          )}
          <StyledInput
            data-test={testId}
            ref={e => this.setInputRef(e)}
            id={this.inputId}
            key={this.inputId}
            value={value || ''}
            onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            minRows={3}
            inputStyles={inputStyles}
            placeholder={isFieldFocused ? '' : placeholder}
            {...{
              isValid,
              disabled,
              indentField,
              resizable,
              useOutsideLabel,
              placeholderColor,
              errorColor,
              backgroundColor,
            }}
          />
        </BorderedArea>
      </StyledInputWrapper>
    )
  }
}

TextInput.propTypes = {
  testId: React.PropTypes.string,
  googlePlacesApiKey: React.PropTypes.string.isRequired,
  value: React.PropTypes.string,
  placeholder: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  useOutsideLabel: React.PropTypes.bool,
  placeholderColor: React.PropTypes.string,
  errorColor: React.PropTypes.string,
  invalidDisplayText: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  onKeyDown: React.PropTypes.func.isRequired,
  onBlur: React.PropTypes.func.isRequired,
  validator: React.PropTypes.oneOf(_.values(ValidatorTypes)),
  customValidator: React.PropTypes.func.isRequired,
  isValid: React.PropTypes.bool.isRequired,
  disabled: React.PropTypes.bool.isRequired,
  hideBorder: React.PropTypes.bool.isRequired,
  debounceTimeout: React.PropTypes.number,
  style: React.PropTypes.object,
  labelStyle: React.PropTypes.object,
  forceIndent: React.PropTypes.bool.isRequired,
  showLabel: React.PropTypes.bool.isRequired,
  resizable: React.PropTypes.bool.isRequired,
  inputRef: React.PropTypes.func,
  latitude: React.PropTypes.number,
  longitude: React.PropTypes.number,
  boundsDistanceKm: React.PropTypes.number.isRequired,
}

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  useOutsideLabel: true,
  placeholderColor: '',
  isValid: true,
  disabled: false,
  hideBorder: false,
  onChange: () => {},
  onKeyDown: () => {},
  inputRef: () => {},
  onBlur: () => {},
  validator: null,
  customValidator: () => true,
  debounceTimeout: 150,
  forceIndent: false,
  showLabel: true,
  resizable: true,
  latitude: null,
  longitude: null,
  boundsDistanceKm: 100, // Default creates a bounding box with sides ~100km away from the center point
}

export default TextInput
