export const AccountTypes = {
  STRIPE: 'STRIPE',
  CYBERSOURCE: 'CYBERSOURCE',
  CYBERSOURCE_REDUX: 'CYBERSOURCE_REDUX',
  CYBERSOURCE_3DS_REDUX: 'CYBERSOURCE_3DS_REDUX',
  FREEDOMPAY: 'FREEDOMPAY',
  NETWORK: 'NETWORK',
  NETWORK_REDUX: 'NETWORK_REDUX',
  NETWORK_REDUX_DIRECT_PURCHASE: 'NETWORK_REDUX_DIRECT_PURCHASE',
  ADYEN: 'ADYEN',
  SAFERPAY: 'SAFERPAY',
  SHIFT_4: 'SHIFT_4',
  PLACEHOLDER: 'PLACEHOLDER',
}

export const TransactionTypes = {
  WEB: 'transaction_type_web',
  INTERNAL: 'transaction_type_internal',
  AUTO_INTERNAL: 'transaction_type_auto_internal',
  SUBSCRIPTION: 'transaction_type_subscription',
  REQUEST: 'transaction_type_request_info',
  REQUEST_REMOVED: 'transaction_type_request_removed',
  REQUEST_FULFILLED: 'transaction_type_request_fulfilled',
  REFUND: 'transaction_type_refund',
  AUTO_REFUND: 'transaction_type_auto_refund',
  SAVED: 'transaction_type_save',
  REMOVED: 'transaction_type_remove',

  WEB_PAYLINK_CHARGE: 'transaction_type_web_paylink_charge',
  WEB_PAYLINK_SAVE: 'transaction_type_web_paylink_save',
  WEB_DINING_CHARGE: 'transaction_type_web_dining_charge',
  WEB_DINING_SAVE: 'transaction_type_web_dining_save',
  WEB_EVENT_CHARGE: 'transaction_type_web_event_charge',
  WEB_EVENT_SAVE: 'transaction_type_web_event_save',
  WEB_ORDER_CHARGE: 'transaction_type_web_order_charge',
}

export const CreditCardImages = {
  masterCard: 'icon-mastercard.png',
  visa: 'icon-visa.png',
  americanExpress: 'icon-amex.png',
  discover: 'icon-discover.png',
  dinersClub: 'icon-dinersclub.png',
  jcb: 'icon-jcb.png',
}

export const PAYMENT_CHANNELS = {
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY: 'APPLE_PAY',
  NEW_CREDIT_CARD: 'NEW_CREDIT_CARD',
  PAY_LATER: 'PAY_LATER',
}

export const PaymentChannelMapping = {
  [PAYMENT_CHANNELS.GOOGLE_PAY]: { label: 'Google Pay', icon: 'google_pay_logo.svg' },
  [PAYMENT_CHANNELS.APPLE_PAY]: { label: 'Apple Pay', icon: 'apple_pay_logo.svg' },
  [PAYMENT_CHANNELS.NEW_CREDIT_CARD]: { label: 'Credit Card', icon: 'credit_card_logo.svg' },
}

export const CURRENCIES = [
  { code: 'USD', label: 'USD', symbol: '$' }, // US Dollar
  { code: 'AED', label: 'AED', symbol: 'د.إ' }, // United Arab Emirates Dirham
  { code: 'AFN', label: 'AFN', symbol: '؋' }, // Afghanistan Afghani
  { code: 'ALL', label: 'ALL', symbol: 'L' }, // Albanian Lek
  { code: 'AMD', label: 'AMD', symbol: '֏' }, // Armenia Dram
  { code: 'ANG', label: 'ANG', symbol: 'NAƒ' }, // Netherlands Antilles Guilder
  { code: 'AOA', label: 'AOA', symbol: 'Kz' }, // Angola Kwanza
  { code: 'ARS', label: 'ARS', symbol: '$' }, // Argentine Peso
  { code: 'AUD', label: 'AUD', symbol: '$' }, // Australian Dollar
  { code: 'AWG', label: 'AWG', symbol: 'ƒ' }, // Aruban Dollar
  { code: 'AZN', label: 'AZN', symbol: '₼' }, // Azerbaijan Manat
  { code: 'BAM', label: 'BAM', symbol: 'KM' }, // Bosnia and Herzegovina Convertible Marka
  { code: 'BBD', label: 'BBD', symbol: '$' }, // Barbados Dollar
  { code: 'BDT', label: 'BDT', symbol: '৳' }, // Bangladesh Taka
  { code: 'BGN', label: 'BGN', symbol: 'Лв.' }, // Bulgaria Lev
  { code: 'BHD', label: 'BHD', symbol: '.د.ب' }, // Bahraini Dinars
  { code: 'BIF', label: 'BIF', symbol: 'FBu' }, // Burundi Franc
  { code: 'BMD', label: 'BMD', symbol: '$' }, // Bermuda Dollar
  { code: 'BND', label: 'BND', symbol: 'B$' }, // Brunei Darussalam Dollar
  { code: 'BOB', label: 'BOB', symbol: 'Bs' }, // Bolivian Boliviano
  { code: 'BRL', label: 'BRL', symbol: 'R$' }, // Brazilian Real
  { code: 'BSD', label: 'BSD', symbol: '$' }, // Bahamian Dollar
  { code: 'BTN', label: 'BTN', symbol: 'Nu.' }, // Bhutan Ngultrum
  { code: 'BWP', label: 'BWP', symbol: 'P' }, // Botswana Pula
  { code: 'BYN', label: 'BYN', symbol: 'BYN' }, // Belarus Ruble
  { code: 'BZD', label: 'BZD', symbol: '$' }, // Belize Dollar
  { code: 'CAD', label: 'CAD', symbol: '$' }, // Canadian Dollar
  { code: 'CDF', label: 'CDF', symbol: 'FC' }, // Congo/Kinshasa Franc
  { code: 'CHF', label: 'CHF', symbol: 'SFr.' }, // Swiss Franc
  { code: 'CLP', label: 'CLP', symbol: '$' }, // Chilean Peso
  { code: 'CNY', label: 'CNY', symbol: '¥' }, // China Yuan Renminbi
  { code: 'COP', label: 'COP', symbol: '$' }, // Colombia Peso
  { code: 'CRC', label: 'CRC', symbol: '₡' }, // Costa Rica Colon
  { code: 'CUP', label: 'CUP', symbol: '$' }, // Cuba Peso
  { code: 'CVE', label: 'CVE', symbol: '$' }, // Cape Verde Escudo
  { code: 'CZK', label: 'CZK', symbol: 'Kčs' }, // Czech Koruna
  { code: 'DJF', label: 'DJF', symbol: 'Fdj' }, // Djibouti Franc
  { code: 'DKK', label: 'DKK', symbol: 'kr' }, // Danish Krone
  { code: 'DOP', label: 'DOP', symbol: 'RD$' }, // Dominican Peso
  { code: 'DZD', label: 'DZD', symbol: 'دج' }, // Algeria Dinar
  { code: 'EGP', label: 'EGP', symbol: 'ج.م' }, // Egyptian Pound
  { code: 'ETB', label: 'ETB', symbol: 'Br' }, // Ethiopia Birr
  { code: 'EUR', label: 'EUR', symbol: '€' }, // Euro
  { code: 'FJD', label: 'FJD', symbol: 'FJ$' }, // Fiji Dollar
  { code: 'GBP', label: 'GBP', symbol: '£' }, // British Pound
  { code: 'GEL', label: 'GEL', symbol: 'ლ' }, // Georgia Lari
  { code: 'GHS', label: 'GHS', symbol: '¢' }, // Ghanaian Cedi
  { code: 'GMD', label: 'GMD', symbol: 'D' }, // Gambia Dalasi
  { code: 'GNF', label: 'GNF', symbol: 'FG' }, // Guinea Franc
  { code: 'GTQ', label: 'GTQ', symbol: 'Q' }, // Guatemala Quetzal
  { code: 'GYD', label: 'GYD', symbol: '$' }, // Guyana Dollar
  { code: 'HKD', label: 'HKD', symbol: '$' }, // Hong Kong Dollar
  { code: 'HNL', label: 'HNL', symbol: 'L' }, // Honduras Lempira
  { code: 'HRK', label: 'HRK', symbol: 'kn' }, // Croatian Kuna
  { code: 'HTG', label: 'HTG', symbol: 'G' }, // Haiti Gourde
  { code: 'HUF', label: 'HUF', symbol: 'Ft' }, // Hungarian Forint
  { code: 'IDR', label: 'IDR', symbol: 'Rp' }, // Indonesian rupiah
  { code: 'ILS', label: 'ILS', symbol: '₪' }, // Israeli New Shekel
  { code: 'INR', label: 'INR', symbol: '₹' }, // Indian Rupee
  { code: 'IQD', label: 'IQD', symbol: 'ع.د' }, // Iraq Dinar
  { code: 'IRR', label: 'IRR', symbol: '﷼' }, // Iran Rial
  { code: 'ISK', label: 'ISK', symbol: 'kr' }, // Icelandic krona
  { code: 'JMD', label: 'JMD', symbol: '$' }, // Jamaican Dollar
  { code: 'JOD', label: 'JOD', symbol: 'د.ا' }, // Jordanian Dinar
  { code: 'JPY', label: 'JPY', symbol: '¥' }, // Japanese Yen
  { code: 'KES', label: 'KES', symbol: 'KSh' }, // Kenya Shilling
  { code: 'KGS', label: 'KGS', symbol: 'Лв' }, // Kyrgyzstan Som
  { code: 'KHR', label: 'KHR', symbol: '៛' }, // Cambodia Riel
  { code: 'KMF', label: 'KMF', symbol: 'CF' }, // Comorian Franc
  { code: 'KRW', label: 'KRW', symbol: '₩' }, // Korea (South) Won
  { code: 'KWD', label: 'KWD', symbol: 'د.ك' }, // Kuwaiti Dinar
  { code: 'KYD', label: 'KYD', symbol: '$' }, // Cayman Islands Dollar
  { code: 'KZT', label: 'KZT', symbol: '₸' }, // Kazakhstan Tenge
  { code: 'LAK', label: 'LAK', symbol: '₭' }, // Laos Kip
  { code: 'LBP', label: 'LBP', symbol: 'ل.ل.' }, // Lebanese Pound
  { code: 'LKR', label: 'LKR', symbol: 'රු' }, // Sri Lanka Rupee
  { code: 'LRD', label: 'LRD', symbol: 'L$' }, // Liberia Dollar
  { code: 'LSL', label: 'LSL', symbol: 'L' }, // Lesotho Loti
  { code: 'LYD', label: 'LYD', symbol: 'ل.د' }, // Libya Dinar
  { code: 'MAD', label: 'MAD', symbol: 'DH' }, // Moroccan Dirham
  { code: 'MDL', label: 'MDL', symbol: 'L' }, // Moldova Leu
  { code: 'MGA', label: 'MGA', symbol: 'Ar' }, // Madagascar Ariary
  { code: 'MKD', label: 'MKD', symbol: 'Ден' }, // Macedonia Denar
  { code: 'MMK', label: 'MMK', symbol: 'K' }, // Myanmar Kyat
  { code: 'MOP', label: 'MOP', symbol: 'MOP$' }, // Macanese Pataca
  { code: 'MUR', label: 'MUR', symbol: '₨' }, // Mauritian rupee
  { code: 'MVR', label: 'MVR', symbol: 'Rf' }, // Maldives (Maldive Islands) Rufiyaa
  { code: 'MWK', label: 'MWK', symbol: 'MK' }, // Malawi Kwacha
  { code: 'MXN', label: 'MXN', symbol: '$' }, // Mexican Peso
  { code: 'MYR', label: 'MYR', symbol: 'RM' }, // Malaysian Ringgit
  { code: 'MZN', label: 'MZN', symbol: 'MT' }, // Mozambique Metical
  { code: 'NAD', label: 'NAD', symbol: 'N$' }, // Namibia Dollar
  { code: 'NGN', label: 'NGN', symbol: '₦' }, // Nigerian Naira
  { code: 'NIO', label: 'NIO', symbol: 'C$' }, // Nicaragua Cordoba
  { code: 'NOK', label: 'NOK', symbol: 'kr' }, // Norwegian Krone
  { code: 'NPR', label: 'NPR', symbol: 'रू' }, // Nepal Rupee
  { code: 'NZD', label: 'NZD', symbol: '$' }, // New Zealand dollar
  { code: 'OMR', label: 'OMR', symbol: 'ر.ع.' }, // Omani Rial
  { code: 'PAB', label: 'PAB', symbol: 'B/.' }, // Panama Balboa
  { code: 'PEN', label: 'PEN', symbol: 'S/' }, // Peru Sol
  { code: 'PGK', label: 'PGK', symbol: 'K' }, // Papua New Guinea Kina
  { code: 'PHP', label: 'PHP', symbol: '₱' }, // Philippine Peso
  { code: 'PKR', label: 'PKR', symbol: '₨' }, // Pakistan Rupee
  { code: 'PLN', label: 'PLN', symbol: 'zł' }, // Polish Zloty
  { code: 'PYG', label: 'PYG', symbol: '₲' }, // Paraguay Guarani
  { code: 'QAR', label: 'QAR', symbol: 'ر.ق' }, // Qatari Rial
  { code: 'RON', label: 'RON', symbol: 'L' }, // Romanian leu
  { code: 'RSD', label: 'RSD', symbol: 'din' }, // Serbia Dinar
  { code: 'RUB', label: 'RUB', symbol: '₽' }, // Russia Ruble
  { code: 'RWF', label: 'RWF', symbol: 'FRw' }, // Rwanda Franc
  { code: 'SAR', label: 'SAR', symbol: '﷼' }, // Saudi Riyal
  { code: 'SBD', label: 'SBD', symbol: 'Si$' }, // Solomon Islands Dollar
  { code: 'SCR', label: 'SCR', symbol: '₨' }, // Seychellois Rupee
  { code: 'SDG', label: 'SDG', symbol: 'ج.س.' }, // Sudan Pound
  { code: 'SEK', label: 'SEK', symbol: 'kr' }, // Swedish Krona
  { code: 'SGD', label: 'SGD', symbol: '$' }, // Singapore Dollar
  { code: 'SLL', label: 'SLL', symbol: 'Le' }, // Sierra Leone Leone
  { code: 'SOS', label: 'SOS', symbol: 'Sh.So.' }, // Somalia Shilling
  { code: 'SRD', label: 'SRD', symbol: '$' }, // Suriname Dollar
  { code: 'SVC', label: 'SVC', symbol: 'SVC' }, // El Salvador Colon
  { code: 'SZL', label: 'SZL', symbol: 'L' }, // Swaziland Lilangeni
  { code: 'THB', label: 'THB', symbol: '฿' }, // Thai Baht
  { code: 'TJS', label: 'TJS', symbol: 'ЅM' }, // Tajikistan Somoni
  { code: 'TMT', label: 'TMT', symbol: 'T' }, // Turkmenistan Manat
  { code: 'TND', label: 'TND', symbol: 'د.ت' }, // Tunisian Dinar
  { code: 'TOP', label: 'TOP', symbol: 'T$' }, // Tonga Pa'anga
  { code: 'TRY', label: 'TRY', symbol: '₺' }, // Turkish Lira
  { code: 'TTD', label: 'TTD', symbol: 'TT$' }, // Trinidad and Tobago Dollar
  { code: 'TWD', label: 'TWD', symbol: 'NT$' }, // Taiwan New Dollar
  { code: 'TZS', label: 'TZS', symbol: 'TSh' }, // Tanzania Shilling
  { code: 'UAH', label: 'UAH', symbol: '₴' }, // Ukraine Hryvnia
  { code: 'UGX', label: 'UGX', symbol: 'USh' }, // Uganda Shilling
  { code: 'UYU', label: 'UYU', symbol: '$' }, // Uruguayan Peso
  { code: 'UZS', label: 'UZS', symbol: "so'm" }, // Uzbekistan Som
  { code: 'VND', label: 'VND', symbol: '₫' }, // Vietnamese Dong
  { code: 'XAF', label: 'XAF', symbol: 'FCFA' }, // Communauté Financière Africaine (BEAC) CFA Franc BEAC
  { code: 'XCD', label: 'XCD', symbol: '$' }, // East Caribbean Dollar
  { code: 'XOF', label: 'XOF', symbol: 'CFA' }, // West African CFA Franc
  { code: 'XPF', label: 'XPF', symbol: '₣' }, // Comptoirs Français du Pacifique (CFP) Franc
  { code: 'YER', label: 'YER', symbol: '﷼' }, // Yemen Rial
  { code: 'ZAR', label: 'ZAR', symbol: 'R' }, // South African Rand
  { code: 'ZMW', label: 'ZMW', symbol: 'ZK' }, // Zambia Kwacha
]
