export const CYBERSOURCE_PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE = 'CYBERSOURCE_PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE'
export const CYBERSOURCE_PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE = 'CYBERSOURCE_PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE'
export const CYBERSOURCE_CLEAN_PAYMENT_INFORMATION = 'CYBERSOURCE_CLEAN_PAYMENT_INFORMATION'

export const PayerAuthStep = {
  SETUP: 'SETUP',
  ENROLLMENT: 'ENROLLMENT',
}

export const cleanCybersourcePaymentInformation = () => ({
  type: CYBERSOURCE_CLEAN_PAYMENT_INFORMATION,
})

export const payerAuthenticationSetupComplete = data => ({
  type: CYBERSOURCE_PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE,
  data: {
    payerAuthenticationServiceData: data.consumer_authentication_information,
    token: data.token,
    cardData: data.card_data,
    url: data.url,
  },
})

export const payerAuthenticationCheckEnrollmentComplete = data => ({
  type: CYBERSOURCE_PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE,
  data: data.enrollment_result,
})
