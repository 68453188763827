import _ from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'
import { WidgetLine } from '../../uiKit/WidgetView'

const DropdownButton = styled.button`
  width: 100%;
  height: 48px;
  line-height: 36px;
  padding: 7px 3.2345%;
  font-family: inherit;
  border: none;
  outline: none;
  background-color: ${props => props.theme.colorActionBarBackground};
  :focus-visible {
    opacity: 0.8;
    outline: 5px auto Highlight;
    outline: -webkit-focus-ring-color auto 1px;
  }
`

const DropdownLabel = styled.span`
  font-size: 15px;
  float: left;
  color: ${props => props.theme.fontsColorActionBar};
`

const DropdownArrow = styled.div`
  float: right;
  height: 100%;
  line-height: 38px;
  font-size: 20px;
  color: ${props => props.theme.fontsColorActionBar};
`

const SpinnerWrapper = styled.div`
  height: 60px;
  position: relative;
  background-color: ${props => props.theme.colorCellBackground};
  overflow: hidden;
`

const SpinnerContainer = styled.div`
  height: 100%;
`

const SpinnerCell = styled.div`
  position: absolute;
  top: -8px;
  left: 50%;
`

const SpinnerImage = styled.img.attrs(props => ({
  alt: 'loading-spinner',
  src: `${props.mediaUrl}images/icons/loading.gif`,
}))`
  height: 80px;
  position: relative;
  left: -50%;
  display: block;
`

const NoResultsText = styled.span``

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { open: this.props.open }
    this.onDropdownClick = this.onDropdownClick.bind(this)
  }

  onDropdownClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { label, mediaUrl, isFetching, children } = this.props
    return (
      <div>
        <DropdownButton onClick={this.onDropdownClick} data-test="sr-other-dates-collapsed">
          <DropdownLabel>{label}</DropdownLabel>
          <DropdownArrow>
            <i className={`fa fa-angle-${this.state.open ? 'down' : 'up'} fa-fw`} aria-hidden="true" />
          </DropdownArrow>
        </DropdownButton>
        {this.state.open ? (
          <div>
            {children}
            {isFetching && (
              <SpinnerContainer>
                <SpinnerWrapper>
                  <SpinnerCell>
                    <SpinnerImage mediaUrl={mediaUrl} />
                    <WidgetLine />
                  </SpinnerCell>
                </SpinnerWrapper>
              </SpinnerContainer>
            )}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Dropdown
