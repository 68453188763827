import moment from 'moment-timezone'
import Radium from 'radium'
import React, { Component, ReactDOM } from 'react'
import { connect } from 'react-redux'
import { tryAgain } from '../actions/navigation'
import styles from '../assets/styles/confirmation'

class Error extends Component {
  // eslint-disable-line react/prefer-stateless-function

  render() {
    const { colorWidgetBackground, colorPrimary, fontsColorPrimary, fontsColorButton, tryAgain } = this.props

    const buttonProps = {
      innerElement: 'Try again',
      onClick: tryAgain,
    }
    let errorMessage
    if (widgetInit.errorMessage) {
      errorMessage = widgetInit.errorMessage
    } else {
      errorMessage = widgetInit.errorCodes[widgetInit.errorDisplay] || 'There was an error processing your payment.'
    }

    return (
      <div style={[styles.successWraper, { backgroundColor: colorWidgetBackground }]}>
        <div style={styles.successBody}>
          <div style={[styles.bodyText, { color: fontsColorPrimary }]}>
            <span style={{ ...styles.confirmation, fontWeight: 'bold' }}>{errorMessage}</span>
          </div>
          <div style={[styles.bodyText, { color: fontsColorPrimary }]}>
            <div style={styles.buttonWrapper}>
              <button style={[styles.tryAgainButton, { backgroundColor: colorPrimary, color: fontsColorButton }]} onClick={tryAgain}>
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // colors
  colorPrimary: state.widgetSettings.colorPrimary,
  colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
  fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
  fontsColorButton: state.widgetSettings.fontsColorButton,
  // text
})

const mapDispatchToProps = dispatch => ({
  tryAgain: () => {
    dispatch(tryAgain())
  },
})

Error = connect(mapStateToProps, mapDispatchToProps)(Radium(Error))

export default Error
