import { AccountTypes } from 'svr/lib/Payments/Constants'

export const isNetworkRedux = venueInfo =>
  [AccountTypes.NETWORK_REDUX, AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE].includes(venueInfo.paymentType)

export const handleNetworkPayment = (response, postCheckoutFailure) => dispatch => {
  if (response.payload && response.payload.errors) {
    dispatch(postCheckoutFailure(response.payload.errors[0]))
    return
  }

  const rawResponse = response.extra_data.raw_response
  let url = response.extra_data.metadata.return_url
  if (!url) {
    // we are not able to proceed with payment so don't confirm it
    dispatch(postCheckoutFailure('Unable to process payment!'))
    return
  }
  window.NI.handlePaymentResponse(rawResponse, {
    mountId: 'cc3dsMountId',
    style: {
      width: 500,
      height: 500,
    },
  }).then(niResponse => {
    const { status } = niResponse
    url += `&status=${status}`

    // redirect manually to complete paylink processing
    window.location = url
  })
}
