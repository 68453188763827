import { Map } from 'immutable'
import moment from 'moment-timezone'
import {
  INCREMENT_ITEM_DATE,
  DECREMENT_ITEM_DATE,
  INCREMENT_ITEM_PARTY_SIZE,
  DECREMENT_ITEM_PARTY_SIZE,
  INCREMENT_ITEM_DURATION,
  DECREMENT_ITEM_DURATION,
  INCREMENT_ITEM_TIME,
  DECREMENT_ITEM_TIME,
  LOAD_ACTUAL_SUCCESS,
  SELECT_TIME_SLOT,
  SELECT_QUERY_DATE,
  SELECT_QUERY_PARTY_SIZE,
  SELECT_QUERY_DURATION,
  SELECT_QUERY_TIME,
  SELECT_VENUE,
  REVERT_STAGE,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
  TRY_GET_INITIAL_AVAILABILITY,
  GET_EXPERIENCE_AVAILABILITY_SUCCESS,
  GET_EXPERIENCE_SUCCESS,
  SET_PAGE_TITLE,
  SET_ACCESS_PERSISTENT_ID,
  INITIALIZE_SEARCH,
} from '../actions/ActionTypes'
import * as AnalyticsEvents from '../utils/analyticsEvents'
import { viewTypes } from '../utils/constantTypes'

const search = (state = Map(), action) => {
  switch (action.type) {
    case INITIALIZE_SEARCH:
      return state.merge(action.search)
    case INCREMENT_ITEM_DATE: {
      const incrementDateMoment = state.get('dateMoment').clone().add(1, 'day')
      let disableIncrement = state.get('disableIncrement')
      if (
        (state.get('bookingEnd') && incrementDateMoment.isSame(state.get('bookingEnd'), 'day')) ||
        incrementDateMoment.isSame(state.get('saleEndDate'), 'day')
      ) {
        disableIncrement = disableIncrement.set('dateMoment', true)
      }
      AnalyticsEvents.selectDate(incrementDateMoment.format('Y-MM-DD'), action.selectedVenueFullName)

      return state.merge({
        dateMoment: incrementDateMoment,
        disableDecrement: state.get('disableDecrement').delete('dateMoment'),
        disableIncrement,
      })
    }
    case DECREMENT_ITEM_DATE: {
      const nextMoment = state.get('dateMoment').clone().subtract(1, 'day')
      const nextDecrement = nextMoment.clone().subtract(1, 'day')
      let disableDecrement = state.get('disableDecrement')
      if (nextDecrement < state.get('venueToday') || nextMoment.isSame(state.get('saleStartDate'), 'day')) {
        disableDecrement = disableDecrement.set('dateMoment', true)
      }
      AnalyticsEvents.selectDate(nextMoment.format('Y-MM-DD'), action.selectedVenueFullName)

      return state.merge({
        dateMoment: nextMoment,
        disableIncrement: state.get('disableIncrement').delete('dateMoment'),
        disableDecrement,
      })
    }
    case SELECT_QUERY_DATE: {
      const selectedMoment = moment(action.dateKey)
      let disableDecrement = state.get('disableDecrement')
      AnalyticsEvents.selectDate(action.dateKey, action.selectedVenueFullName)
      disableDecrement = disableDecrement.set(
        'dateMoment',
        selectedMoment.isSame(state.get('venueToday'), 'day') ||
          (state.get('saleStartDate') && selectedMoment.isSame(state.get('saleStartDate'), 'day'))
      )
      let disableIncrement = state.get('disableIncrement')
      disableIncrement = disableIncrement.set(
        'dateMoment',
        (state.get('bookingEnd') && selectedMoment.isSame(state.get('bookingEnd'), 'day')) ||
          (state.get('saleEndDate') && selectedMoment.isSame(state.get('saleEndDate'), 'day'))
      )
      return state.merge({
        dateMoment: selectedMoment,
        disableDecrement,
        disableIncrement,
      })
    }
    case SELECT_QUERY_PARTY_SIZE: {
      const { partySize } = action
      let disableDecrement = state.get('disableDecrement')
      if (partySize === state.get('minPartySize')) {
        disableDecrement = disableDecrement.set('partySize', true)
      } else {
        disableDecrement = disableDecrement.delete('partySize')
      }

      let disableIncrement = state.get('disableIncrement')
      if (partySize === state.get('maxPartySize')) {
        disableIncrement = disableIncrement.set('partySize', true)
      } else {
        disableIncrement = disableIncrement.delete('partySize')
      }

      return state.merge({ partySize, disableDecrement, disableIncrement })
    }
    case SELECT_QUERY_DURATION: {
      const { duration } = action
      let disableDecrement = state.get('disableDecrement')
      if (duration === state.get('minDuration')) {
        disableDecrement = disableDecrement.set('duration', true)
      } else {
        disableDecrement = disableDecrement.delete('duration')
      }

      let disableIncrement = state.get('disableIncrement')
      if (duration === state.get('maxDuration')) {
        disableIncrement = disableIncrement.set('duration', true)
      } else {
        disableIncrement = disableIncrement.delete('duration')
      }

      return state.merge({ duration, disableDecrement, disableIncrement })
    }
    case SELECT_QUERY_TIME: {
      if (action.isModifyResUpgradesMode) {
        return state.merge({
          selectedTimeSlot: action.bookingDateTimeMoment,
          dateMoment: action.bookingDateTimeMoment,
          timeMoment: action.bookingDateTimeMoment,
        })
      }
      const customTimeSlots = state.get('customTimeSlots')

      if (customTimeSlots) {
        const customTimeSlotCurrentIndex = customTimeSlots.indexOf(
          action.timeMoment.format(state.get('initialLocale') === 'en_GB' ? 'HH:mm' : 'h:mm a')
        )
        return state.merge({
          disableIncrement: state
            .get('disableIncrement')
            .set('timeMoment', customTimeSlotCurrentIndex + 1 >= Object.keys(customTimeSlots).length),
          disableDecrement: state.get('disableDecrement').set('timeMoment', customTimeSlotCurrentIndex - 1 < 0),
          customTimeSlotCurrentIndex,
          timeMoment: action.timeMoment,
        })
      }
      return state.set('timeMoment', action.timeMoment)
    }
    case SELECT_VENUE:
      const { venue } = action
      window.restaurant_data.id = venue
      window.restaurant_data.name = state.get('venueMap').get(venue)
      return state.set('selectedVenue', venue)
    case INCREMENT_ITEM_PARTY_SIZE: {
      const maxPartySize = state.get('maxPartySize')
      const nextPartySize = Math.min(maxPartySize, state.get('partySize') + 1)
      let disableIncrement = state.get('disableIncrement')
      if (nextPartySize === maxPartySize) {
        disableIncrement = disableIncrement.set('partySize', true)
      }
      return state.merge({
        partySize: nextPartySize,
        disableDecrement: state.get('disableDecrement').delete('partySize'),
        disableIncrement,
      })
    }
    case DECREMENT_ITEM_PARTY_SIZE: {
      const minPartySize = state.get('minPartySize')
      const nextPartySize = Math.max(minPartySize, state.get('partySize') - 1)
      let disableDecrement = state.get('disableDecrement')
      if (nextPartySize === minPartySize) {
        disableDecrement = disableDecrement.set('partySize', true)
      }
      return state.merge({
        partySize: nextPartySize,
        disableIncrement: state.get('disableIncrement').delete('partySize'),
        disableDecrement,
      })
    }
    case INCREMENT_ITEM_DURATION: {
      const maxDuration = state.get('maxDuration')
      const nextDuration = Math.min(maxDuration, state.get('duration') + state.get('durationInterval'))
      let disableIncrement = state.get('disableIncrement')
      if (nextDuration === maxDuration) {
        disableIncrement = disableIncrement.set('duration', true)
      }
      return state.merge({
        duration: nextDuration,
        disableDecrement: state.get('disableDecrement').delete('duration'),
        disableIncrement,
      })
    }
    case DECREMENT_ITEM_DURATION: {
      const minDuration = state.get('minDuration')
      const nextDuration = Math.max(minDuration, state.get('duration') - state.get('durationInterval'))
      let disableDecrement = state.get('disableDecrement')
      if (nextDuration === minDuration) {
        disableDecrement = disableDecrement.set('duration', true)
      }
      return state.merge({
        duration: nextDuration,
        disableIncrement: state.get('disableIncrement').delete('duration'),
        disableDecrement,
      })
    }
    case REVERT_STAGE:
      return action.nextView === viewTypes.SEARCH_RESULTS ? state.merge({ selectedTimeSlot: null, accessPersistentId: null }) : state
    case INCREMENT_ITEM_TIME: {
      const customTimeSlots = state.get('customTimeSlots')
      const customTimeSlotCurrentIndex = state.get('customTimeSlotCurrentIndex') + 1

      if (customTimeSlots) {
        return state.merge({
          disableIncrement: state
            .get('disableIncrement')
            .set('timeMoment', customTimeSlotCurrentIndex + 1 >= Object.keys(customTimeSlots).length),
          disableDecrement: state.get('disableDecrement').set('timeMoment', customTimeSlotCurrentIndex - 1 < 0),
          customTimeSlotCurrentIndex,
          timeMoment: moment(customTimeSlots[customTimeSlotCurrentIndex], state.get('initialLocale') === 'en_GB' ? 'HH:mm' : 'h:mm a'),
        })
      }
      return state.update('timeMoment', timeMoment => timeMoment.clone().add(state.get('timeSlotInterval'), 'minutes'))
    }
    case DECREMENT_ITEM_TIME: {
      const customTimeSlots = state.get('customTimeSlots')
      const customTimeSlotCurrentIndex = state.get('customTimeSlotCurrentIndex') - 1

      if (customTimeSlots) {
        return state.merge({
          disableIncrement: state
            .get('disableIncrement')
            .set('timeMoment', customTimeSlotCurrentIndex + 1 > Object.keys(customTimeSlots).length),
          disableDecrement: state.get('disableDecrement').set('timeMoment', customTimeSlotCurrentIndex - 1 < 0),
          customTimeSlotCurrentIndex,
          timeMoment: moment(customTimeSlots[customTimeSlotCurrentIndex], state.get('initialLocale') === 'en_GB' ? 'HH:mm' : 'h:mm a'),
        })
      }
      return state.update('timeMoment', timeMoment => timeMoment.clone().subtract(state.get('timeSlotInterval'), 'minutes'))
    }
    case LOAD_ACTUAL_SUCCESS: {
      return state.merge({
        enableDurationPicker: Boolean(action.actual.is_duration_picked),
        accessPersistentId: action.actual.access_persistent_id,
      })
    }

    case SET_ACCESS_PERSISTENT_ID: {
      return state.merge({ accessPersistentId: action.accessPersistentId })
    }

    case SELECT_TIME_SLOT:
      window.restaurant_data.dine_date = action.timeSlotMoment.format('ddd, MMMM D')
      window.restaurant_data.dine_time_category = action.timeSlotMoment.format('H:mm')

      return state.merge({
        selectedTimeSlot: action.timeSlotMoment,
        accessPersistentId: action.accessPersistentId,
        cost: action.cost,
        fees: action.fees,
        reservationCost: action.reservationCost,
        selectedAutomaticUpsells: action.selectedAutomaticUpsells,
      })

    case TRY_GET_INITIAL_AVAILABILITY:
    case TRY_GET_MULTI_VENUE_AVAILABILITY:
      const _state = state.toJS()
      window.restaurant_data.dine_date = _state.timeMoment.format('ddd, MMMM D')
      window.restaurant_data.dine_time_category = _state.timeMoment.format('H:mm')
      window.restaurant_data.quantity = _state.partySize

      return state

    case GET_EXPERIENCE_AVAILABILITY_SUCCESS:
      if (action.firstAvailableExperienceDate) {
        return state
      }
      return action.data.firstAvailable ? state.set('dateMoment', moment(action.data.firstAvailable)) : state
    case GET_EXPERIENCE_SUCCESS:
      const defaultPartySize = action.experience.experience.default_party_size
      if (state.get('minPartySize') > defaultPartySize || state.get('maxPartySize') < defaultPartySize) {
        return state
      }
      return state.set('partySize', defaultPartySize)
    case SET_PAGE_TITLE:
      return state.set('pageTitle', action.title)
    default:
      return state
  }
}

export default search
