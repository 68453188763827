import { SET_DATA_FOR_3D_SECURE, SET_PAYMENT_ENGINE } from 'svr/component-lib/Widget/Payments/ActionTypes'

export const setDataFor3Dsecure = dataFor3Dsecure => ({
  type: SET_DATA_FOR_3D_SECURE,
  dataFor3Dsecure,
})

export const setPaymentEngine = paymentEngine => ({
  type: SET_PAYMENT_ENGINE,
  paymentEngine,
})
