import Radium from 'radium'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { VmsIcons } from 'svr/common/VmsIcons'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'
import { changePhoneNum, changeFormField, changeFlag, validateAllFields } from 'widget/dining/actions/forms'
import { venueLanguageChanged } from 'widget/dining/actions/language'
import {
  incrementWaitlistPartySize,
  decrementWaitlistPartySize,
  toggleField,
  toggleModalDisplay,
  goToWaitingRoom,
} from 'widget/dining/actions/navigation'
import { tryGetWaitlistEtas, trySubmitWaitlist } from 'widget/dining/actions/services'
import { VenueBanner } from 'widget/dining/components/Banner'
import WaitlistForm from 'widget/dining/components/WaitlistForm'
import WaitlistPartySize from 'widget/dining/components/WaitlistPartySize'
import { selectCalculatedStrings, selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import styles from '../assets/styles/checkout'
import searchStyles from '../assets/styles/search'
import { modalTypes } from '../utils/constantTypes'
import { parseBoolean } from 'widget/dining/utils/preloadedState'

const WaitlistErrorBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fed0d0;
  padding: 20px 40px;

  @media (max-width: 500px) {
    padding: 10px 8%;
  }
`

const WaitlistErrorText = styled.div`
  padding-left: 15px;
`

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.colorWidgetBackground};
`

const HideQuoteTimeBanner = styled.div`
  text-align: center;
  font-size: 23px;
  padding: 40px 40px 0 40px;
`

class CheckoutWaitlist extends React.PureComponent {
  constructor(props) {
    super(props)

    this.infoFields = {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
    }
    this.toggleSpecialAttentionMessage = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_ATTENTION_MESSAGE)

    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    const { waitlistId, tryGetWaitlistEtas, goToWaitingRoom } = this.props

    if (waitlistId) {
      goToWaitingRoom()
    } else {
      tryGetWaitlistEtas()
    }
  }

  submitForm() {
    const fields = this.infoFields
    let valid = true

    const validateFields = mappedFields =>
      mappedFields.reduce((acc, fieldName) => {
        const isValid = fields[fieldName].validate()
        // eslint-disable-next-line no-param-reassign
        acc[fieldName] = isValid

        if (!isValid) {
          valid = false
        }
        return acc
      }, {})

    const invalidInformationFields = validateFields(Object.keys(fields))
    const agreedToWaitlistPolicy = this.props.agreedToWaitlistPolicy ? {} : { agreedToWaitlistPolicy: true }

    this.props.validateAllFields({ ...invalidInformationFields, ...agreedToWaitlistPolicy })

    if (valid && this.props.agreedToWaitlistPolicy) {
      this.props.trySubmitWaitlist()
    }
  }

  render() {
    const {
      enabledLanguages,
      selectedLanguage,
      colorWidgetBackground,
      fontsColorSummaryBar,
      showVenueBanner,
      colorSummaryBar,
      venue,
      showWaitlistSpecialAttentionMessage,
      colorActionBarBackground,
      fontsColorActionBar,
      colorLines,
      hideQuoteTimes,
      textHideQuoteTimesHeader,
      incrementWaitlistPartySize,
      decrementWaitlistPartySize,
      waitlist,
      colorCheckoutCellBackground,
      fontsColorCheckoutActive,
      fontsColorCheckoutInactive,
      textSelectPartySize,
      textEstimatedWaitTime,
      minGuests,
      maxGuests,
      minimumWaitShown,
      waitTimeBuffer,
      textNoAvailabilityPartySize,
      fontsColorPrimary,
      fontsColorLinks,
      colorError,
      formErrors,
      fontFamily,
      firstName,
      lastName,
      phoneNumber,
      phoneNumberLocale,
      dialCode,
      email,
      note,
      agreedToWaitlistPolicy,
      agreedToVenueSpecificMarketingOptIn,
      agreedToVenueGroupMarketingOptIn,
      agreedToVenueSmsMarketingOptIn,
      agreedToTailoredCommunicationOptIn,
      venueSpecificMarketingOn,
      venueGroupMarketingOn,
      tailoredCommunicationOn,
      venueSmsMarketingOn,
      textFirstName,
      textLastName,
      textEmailAddress,
      textPhoneNumber,
      textButton,
      textAdditionalNotes,
      textAgreeToWaitlistPolicy,
      textVenueSpecificMarketingOptIn,
      textVenueGroupMarketingOptIn,
      textVenueSmsMarketingOptIn,
      textCustomPolicyHolderName,
      textCustomPrivacyPolicyLink,
      textCustomPrivacyPolicyLinkLabel,
      textTailoredCommunicationOptInLabel,
      termsPolicyHolderName,
      termsOfServiceUrl,
      termsOfServiceText,
      privacyPolicyUrl,
      privacyPolicyText,
      gdprPolicyText,
      textCustomGdprPolicyLink,
      textCustomGdprPolicyLinkLabel,
      colorPrimary,
      fontsColorButton,
      widgetTemplateTheme,
      colorCellBackground,
      colorBackground,
      changePhoneNum,
      changeFormField,
      changeFlag,
      toggleField,
      toggleModalDisplay,
      venueLanguageChanged,
      waitlistTextWidgetAgreeToTermsLabel,
      textAnd,
      waitlistWidgetBlankErrorText,
      waitlistWidgetInvalidErrorText,
      waitlistWidgetMinsText,
      waitlistWidgetSpecialAttentionLabel,
      waitlistWidgetPrivacyPolicyLabel,
      policyUsTwilioSmsOptInSubfooter,
      hideCountryFlags,
    } = this.props
    const showLanguageList = enabledLanguages != null && selectedLanguage != null && enabledLanguages.length > 1
    return (
      <CheckoutContainer colorWidgetBackground={colorWidgetBackground}>
        {showLanguageList && (
          <div
            style={{
              ...searchStyles.viewRow,
              borderBottomColor: 'transparent',
              backgroundColor: colorSummaryBar,
              color: fontsColorPrimary,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '5px 0px 4px 0px',
            }}
          >
            <VenueSupportedLanguageDropDown
              id="waitlist-widget-supported-languages-picker"
              languages={enabledLanguages}
              selectedLanguage={selectedLanguage}
              onChange={venueLanguageChanged}
              style={{ textAlign: 'left' }}
              customStyle={{
                colorLines,
                colorBackground,
                fontsColorPrimary,
                colorWidgetBackground,
                colorPrimary,
                widgetTemplateTheme,
                colorCellBackground,
              }}
              height={31}
              showCaret
              noHeader
              leftSideIcon={VmsIcons.Translate}
            />
          </div>
        )}
        {showVenueBanner && (
          <VenueBanner fontsColorSummaryBar={fontsColorSummaryBar} colorSummaryBar={colorSummaryBar}>
            {venue.name}
          </VenueBanner>
        )}
        {showWaitlistSpecialAttentionMessage && (
          <div
            style={{
              ...searchStyles.viewRow,
              backgroundColor: colorActionBarBackground,
              color: fontsColorActionBar,
              fontSize: '13px',
              borderBottomColor: colorLines,
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span>{waitlistWidgetSpecialAttentionLabel}</span>
              <button
                type="button"
                style={{ color: fontsColorActionBar, ...searchStyles.infoIcon, ...searchStyles.buttonInfoIcon }}
                onClick={this.toggleSpecialAttentionMessage}
                className="fa fa-info-circle"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        {hideQuoteTimes && <HideQuoteTimeBanner>{textHideQuoteTimesHeader}</HideQuoteTimeBanner>}
        <WaitlistPartySize
          incrementPartySize={incrementWaitlistPartySize}
          decrementPartySize={decrementWaitlistPartySize}
          waitlist={waitlist}
          colorCheckoutCellBackground={colorCheckoutCellBackground}
          fontsColorCheckoutActive={fontsColorCheckoutActive}
          fontsColorCheckoutInactive={fontsColorCheckoutInactive}
          textSelectPartySize={textSelectPartySize}
          textEstimatedWaitTime={textEstimatedWaitTime}
          minGuests={minGuests}
          maxGuests={maxGuests}
          minimumWaitShown={minimumWaitShown}
          waitTimeBuffer={waitTimeBuffer}
          hideQuoteTimes={hideQuoteTimes}
          waitlistWidgetMinsText={waitlistWidgetMinsText}
        />
        {waitlist.quoted_wait_time === -1 && (
          <WaitlistErrorBanner>
            <div
              style={{
                ...styles.infoIcon,
                ...styles.buttonInfoIcon,
              }}
              className="fa fa-info-circle fa-fw"
            />
            <WaitlistErrorText>{textNoAvailabilityPartySize}</WaitlistErrorText>
          </WaitlistErrorBanner>
        )}
        <WaitlistForm
          fontsColorPrimary={fontsColorPrimary}
          fontsColorCheckoutInactive={fontsColorCheckoutInactive}
          fontsColorCheckoutActive={fontsColorCheckoutActive}
          fontsColorLinks={fontsColorLinks}
          colorError={colorError}
          colorCheckoutCellBackground={colorCheckoutCellBackground}
          colorLines={colorLines}
          formErrors={formErrors}
          fontFamily={fontFamily}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          phoneNumberLocale={phoneNumberLocale}
          dialCode={dialCode}
          email={email}
          note={note}
          agreedToWaitlistPolicy={agreedToWaitlistPolicy}
          agreedToVenueSpecificMarketingOptIn={agreedToVenueSpecificMarketingOptIn}
          agreedToVenueGroupMarketingOptIn={agreedToVenueGroupMarketingOptIn}
          agreedToVenueSmsMarketingOptIn={agreedToVenueSmsMarketingOptIn}
          agreedToTailoredCommunicationOptIn={agreedToTailoredCommunicationOptIn}
          venueSpecificMarketingOn={venueSpecificMarketingOn}
          venueGroupMarketingOn={venueGroupMarketingOn}
          tailoredCommunicationOn={tailoredCommunicationOn}
          venueSmsMarketingOn={venueSmsMarketingOn}
          textFirstName={textFirstName}
          textLastName={textLastName}
          textEmailAddress={textEmailAddress}
          textPhoneNumber={textPhoneNumber}
          textButton={textButton}
          textAdditionalNotes={textAdditionalNotes}
          textAgreeToWaitlistPolicy={textAgreeToWaitlistPolicy}
          textVenueSpecificMarketingOptIn={textVenueSpecificMarketingOptIn}
          textVenueGroupMarketingOptIn={textVenueGroupMarketingOptIn}
          textVenueSmsMarketingOptIn={textVenueSmsMarketingOptIn}
          textCustomPolicyHolderName={textCustomPolicyHolderName}
          textCustomPrivacyPolicyLink={textCustomPrivacyPolicyLink}
          textCustomPrivacyPolicyLinkLabel={textCustomPrivacyPolicyLinkLabel}
          termsPolicyHolderName={termsPolicyHolderName}
          termsOfServiceUrl={termsOfServiceUrl}
          termsOfServiceText={termsOfServiceText}
          privacyPolicyUrl={privacyPolicyUrl}
          privacyPolicyText={privacyPolicyText}
          textCustomGdprPolicyLink={textCustomGdprPolicyLink}
          textCustomGdprPolicyLinkLabel={textCustomGdprPolicyLinkLabel}
          waitlistTextWidgetAgreeToTermsLabel={waitlistTextWidgetAgreeToTermsLabel}
          gdprPolicyText={gdprPolicyText}
          textAnd={textAnd}
          changePhoneNum={changePhoneNum}
          changeFormField={changeFormField}
          changeFlag={changeFlag}
          toggleField={toggleField}
          toggleModalDisplay={toggleModalDisplay}
          infoFields={this.infoFields}
          waitlistWidgetBlankErrorText={waitlistWidgetBlankErrorText}
          waitlistWidgetInvalidErrorText={waitlistWidgetInvalidErrorText}
          waitlistWidgetPrivacyPolicyLabel={waitlistWidgetPrivacyPolicyLabel}
          policyUsTwilioSmsOptInSubfooter={policyUsTwilioSmsOptInSubfooter}
          hideCountryFlags={hideCountryFlags}
          textTailoredCommunicationOptInLabel={textTailoredCommunicationOptInLabel}
        />
        <div style={styles.buttonWrapper}>
          <button
            type="button"
            className="contact-button"
            style={{ ...styles.submitButton, backgroundColor: colorPrimary, color: fontsColorButton, fontSize: '22px', padding: 0 }}
            disabled={!waitlist.canBook}
            onClick={this.submitForm}
          >
            {textButton}
          </button>
        </div>
      </CheckoutContainer>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const calculatedStrings = selectCalculatedStrings(state)
  const { venueInfo } = state
  return {
    waitlistId: state.waitlist.get('id'),
    venue: venueInfo,
    baseUrl: state.widgetSettings.baseUrl,
    venueKey: venueInfo.venueUrlKey,
    waitlist: state.waitlist.toJS(),
    firstName: state.formFields.get('firstName'),
    lastName: state.formFields.get('lastName'),
    email: state.formFields.get('email'),
    phoneNumber: state.formFields.get('phoneNumber'),
    phoneNumberLocale: state.formFields.get('phoneNumberLocale'),
    dialCode: state.formFields.get('dialCode'),
    note: state.formFields.get('note'),
    minGuests: parseInt(state.widgetSettings.minGuests, 10),
    maxGuests: parseInt(state.widgetSettings.maxGuests, 10),
    minimumWaitShown: parseInt(state.widgetSettings.minimumWaitShown, 10),
    waitTimeBuffer: parseInt(state.widgetSettings.waitTimeBuffer, 10),
    venueGroupMarketingOn: state.widgetSettings.venueGroupMarketingOn,
    tailoredCommunicationOn: state.widgetSettings.tailoredCommunicationOn,
    venueSpecificMarketingOn: state.widgetSettings.venueSpecificMarketingOn,
    venueSmsMarketingOn: state.widgetSettings.venueSmsMarketingOn,
    showVenueBanner: state.widgetSettings.showVenueBanner,
    agreedToWaitlistPolicy: state.formFields.get('agreedToWaitlistPolicy'),
    agreedToVenueGroupMarketingOptIn: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    agreedToVenueSpecificMarketingOptIn: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    agreedToVenueSmsMarketingOptIn: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    agreedToTailoredCommunicationOptIn: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    fontFamily: state.widgetSettings.font,
    fontsColorLinks: state.widgetSettings.fontsColorLinks,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
    fontsColorActionBar: state.widgetSettings.fontsColorActionBar,
    colorError: state.widgetSettings.colorError,
    colorActionBarBackground: state.widgetSettings.colorActionBarBackground,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    colorLines: state.widgetSettings.colorLines,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    colorBackground: state.widgetSettings.colorBackground,
    formErrors: state.formFields.get('formErrors').toJS(),
    textButton: languageStrings.waitlistTextButton,
    textFirstName: languageStrings.waitlistFirstNameLabel,
    textLastName: languageStrings.waitlistLastNameLabel,
    textEmailAddress: languageStrings.waitlistEmailAddressLabel,
    textPhoneNumber: languageStrings.waitlistTextPhoneNumberLabel,
    textAdditionalNotes: languageStrings.waitlistTextReservationNotes,
    textNoAvailabilityPartySize: languageStrings.waitlistNoAvailabilityLabel,
    textAgreeToWaitlistPolicy: languageStrings.waitlistTextAgreeToWaitlistPolicy,
    textTermsOfServiceLinkLabel: languageStrings.textTermsOfServiceLinkLabel,
    textVenueGroupMarketingOptIn: languageStrings.waitlistTextVenueGroupMarketingOptIn,
    textVenueSpecificMarketingOptIn: calculatedStrings.waitlistTextVenueSpecificMarketingOptIn,
    textVenueSmsMarketingOptIn: calculatedStrings.waitlistWidgetVenueSmsMarketingOptIn,
    termsPolicyHolderName: state.widgetSettings.termsPolicyHolderName,
    termsOfServiceUrl: state.widgetSettings.termsOfServiceUrl,
    termsOfServiceText: languageStrings.waitlistTermsOfServiceText,
    privacyPolicyUrl: state.widgetSettings.privacyPolicyUrl,
    privacyPolicyText: languageStrings.waitlistPrivacyPolicyText,
    gdprPolicyText: languageStrings.waitlistGdprPolicyText,
    textCustomPolicyHolderName: state.widgetSettings.textCustomPolicyHolderName,
    textCustomPrivacyPolicyLink: state.widgetSettings.textCustomPrivacyPolicyLink,
    textCustomPrivacyPolicyLinkLabel: languageStrings.textCustomPrivacyPolicyLinkLabel,
    textCustomGdprPolicyLink: state.widgetSettings.textCustomGdprPolicyLink,
    textCustomGdprPolicyLinkLabel: languageStrings.textCustomGdprPolicyLinkLabel,
    waitlistTextWidgetAgreeToTermsLabel: languageStrings.waitlistWidgetAgreeToTermsLabel,
    textAnd: languageStrings.waitlistWidgetAndText,
    waitlistWidgetBlankErrorText: languageStrings.waitlistWidgetBlankErrorText,
    waitlistWidgetInvalidErrorText: languageStrings.waitlistWidgetInvalidErrorText,
    waitlistWidgetMinsText: languageStrings.waitlistWidgetMinsText,
    showWaitlistSpecialAttentionMessage: parseBoolean(state.widgetSettings.showWaitlistSpecialAttentionMessage),
    specialAttentionMessageHeader: venueInfo.specialAttentionMessageHeader,
    hideQuoteTimes: state.widgetSettings.hideQuoteTimes,
    textHideQuoteTimesHeader: languageStrings.waitlistWidgetQuoteTimeHeaderLabel,
    textSelectPartySize: languageStrings.waitlistTextSelectPartySize,
    textEstimatedWaitTime: languageStrings.waitlistTextEstimatedWaitTime,
    policyUsTwilioSmsOptInSubfooter: languageStrings.policyUsTwilioSmsOptInSubfooter,
    enabledLanguages: state.languages.venueLanguages,
    selectedLanguage: state.languages.selectedLanguage,
    widgetTemplateTheme: state.widgetSettings.widgetTemplateTheme,
    waitlistWidgetSpecialAttentionLabel: languageStrings.waitlistWidgetSpecialAttentionLabel,
    waitlistWidgetPrivacyPolicyLabel: languageStrings.waitlistWidgetPrivacyPolicyLabel,
    hideCountryFlags: state.venueEntities[venueInfo.urlKey].hideCountryFlags,
    textTailoredCommunicationOptInLabel: languageStrings.textTailoredCommunicationOptInLabel,
  }
}

const mapDispatchToProps = {
  incrementWaitlistPartySize,
  decrementWaitlistPartySize,
  changePhoneNum,
  changeFormField,
  changeFlag,
  toggleField,
  toggleModalDisplay,
  tryGetWaitlistEtas,
  trySubmitWaitlist,
  goToWaitingRoom,
  validateAllFields,
  venueLanguageChanged,
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutWaitlist))
