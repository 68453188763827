/* eslint no-alert: 0, no-shadow: 0, react/prop-types: 0,
  jsx-a11y/no-static-element-interactions: 0 */
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import GoogleSignIn from 'svr/widget/common/GoogleSignIn'
import { ErrorBoundary } from '@sevenrooms/core/ui-kit/core/ErrorBoundary'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import FacebookIconSvg from '../../../../../images/facebook.svg'
import { loginUserFB, loginUserGP, loginAsGuest } from '../../../common/oauth'
import { revertStage } from '../actions/navigation'
import styles from '../assets/styles/login'
import Banner from '../components/Banner'
import { getBannerContents } from '../utils/selectors'

class Login extends Component {
  static getiOSVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const ver = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      return [parseInt(ver[1], 10), parseInt(ver[2], 10), parseInt(ver[3] || 0, 10)]
    }
    return null
  }

  componentDidMount() {
    if (this.initialFocusComponent) {
      this.initialFocusComponent.focus()
    }
  }

  render() {
    const {
      colorWidgetBackground,
      fontsColorPrimary,
      loginUserFB,
      loginUserGP,
      loginAsGuest,
      bannerInfo,
      revertStage,
      colorSummaryBar,
      fontsColorSummaryBar,
      colorLines,
      textCreateAccount,
      textLoginWithFacebook,
      textContinueAsGuest,
      textHoldingTable,
      textHoldExpired,
      textLoginOrLabel,
    } = this.props
    const socialButtonStyle = { ...styles.socialButton }
    const guestTextStyle = { ...styles.guestText }
    delete socialButtonStyle.outline
    delete guestTextStyle.outline

    return (
      <div style={[styles.viewWrapper, { backgroundColor: colorWidgetBackground }]}>
        <Banner
          info={bannerInfo}
          onBackClick={revertStage}
          colorSummaryBar={colorSummaryBar}
          fontsColorSummaryBar={fontsColorSummaryBar}
          colorLines={colorLines}
          textHoldingTable={textHoldingTable}
          textHoldExpired={textHoldExpired}
        />
        <div dangerouslySetInnerHTML={{ __html: textCreateAccount }} style={[styles.leadingText, { color: fontsColorPrimary }]} />
        <button
          type="button"
          key="login-button-facebook"
          onClick={loginUserFB}
          tabIndex="0"
          ref={comp => (this.initialFocusComponent = comp)}
          style={[socialButtonStyle, { backgroundColor: 'rgb(60, 114, 234)', borderRadius: '5px' }]}
        >
          <div style={[styles.socialBox]}>
            <img src={FacebookIconSvg} alt="Facebook Icon" style={styles.facebookIcon} />
          </div>
          <span style={styles.socialText}>Login with Facebook</span>
        </button>
        {/* eslint-disable-next-line no-console */}
        <ErrorBoundary fallback={<div />} onError={error => console.error(error)}>
          <GoogleSignIn handleCallback={loginUserGP} style={socialButtonStyle} />
        </ErrorBoundary>
        <div style={styles.loginSeperator}>
          <div style={styles.spaceBottom}>
            <div style={[styles.lineBreak, { borderColor: fontsColorPrimary }]} />
            <span style={[styles.lineBreakText, { color: fontsColorPrimary }]}>{textLoginOrLabel}</span>
            <div style={[styles.lineBreak, { borderColor: fontsColorPrimary }]} />
          </div>
          <div style={styles.guestButton}>
            <button
              type="button"
              key="login-button-guest"
              onClick={loginAsGuest}
              style={[
                guestTextStyle,
                {
                  color: fontsColorPrimary,
                  borderBottomColor: fontsColorPrimary,
                },
              ]}
              data-test="sr-login-button-guest"
            >
              {textContinueAsGuest}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  return {
    bannerInfo: getBannerContents(state),
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorSummaryBar: state.widgetSettings.colorSummaryBar,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSummaryBar: state.widgetSettings.fontsColorSummaryBar,
    colorLines: state.widgetSettings.colorLines,

    // text
    textCreateAccount: languageStrings.textCreateAccount,
    textLoginWithFacebook: languageStrings.textLoginWithFacebook,
    textContinueAsGuest: languageStrings.textContinueAsGuest,
    textHoldingTable: languageStrings.textHoldingTable,
    textHoldExpired: languageStrings.textHoldExpired,
    textLoginOrLabel: languageStrings.textLoginOrLabel.toUpperCase(),
  }
}

const mapDispatchToProps = dispatch => ({
  revertStage: () => {
    dispatch(revertStage(false, 1))
  },
  loginUserFB: () => {
    dispatch(loginUserFB(true))
  },
  loginAsGuest: () => {
    dispatch(loginAsGuest())
  },
  loginUserGP: res => {
    dispatch(loginUserGP(res, true))
  },
})

Login = connect(mapStateToProps, mapDispatchToProps)(Radium(Login))

export default Login
