const styles = {
  banner: {
    color: 'white',
    backgroundColor: 'rgba(130, 138, 139, 1)',
    textAlign: 'center',
    padding: '8px',
  },
  bannerChild: {
    display: 'inline-block',
  },
  separator: {
    display: 'inline-block',
    margin: '0 8px',
  },
}

export default styles
