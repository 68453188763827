import React, { useEffect, Component, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { useGetLatest } from '@sevenrooms/core/ui-kit/hooks'
import { PayerAuthStep } from '../actions/cybersourceThreeDs'
import { paymentEnrollmentCybersourceThreeDs } from '../actions/services'

const PayerAuthenticationDeviceDataCollection = ({ payerAuthenticationServiceData, cardToken, originUrl, cardData, callback }) => {
  const { accessToken, deviceDataCollectionUrl, referenceId } = payerAuthenticationServiceData
  const formElement = useRef(null)

  const getLatestCallbackAndArgs = useGetLatest([callback, cardToken, cardData, referenceId])
  const cybersource3dsEventCallback = useCallback(
    event => {
      if (event.origin === originUrl) {
        const [callback, cardToken, cardData, referenceId] = getLatestCallbackAndArgs()
        callback(cardToken, { ...cardData, referenceId })
      }
    },
    [originUrl, getLatestCallbackAndArgs]
  )

  useEffect(() => {
    window.addEventListener('message', cybersource3dsEventCallback)
    return () => {
      window.removeEventListener('message', cybersource3dsEventCallback)
    }
  }, [cybersource3dsEventCallback])

  useEffect(() => {
    if (formElement.current) {
      formElement.current.submit()
    }
  })
  return (
    <div>
      <iframe
        id="cardinal_collection_iframe"
        style={{ display: 'none' }}
        name="collectionIframe"
        height="1"
        width="1"
        title="Device data collection"
      />
      <form id="cardinal_collection_form" ref={formElement} target="collectionIframe" method="POST" action={deviceDataCollectionUrl}>
        <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={accessToken} />
      </form>
    </div>
  )
}

const PayerAuthenticationEnrollment = ({ stepUpUrl, accessToken, cardToken }) => {
  const formElement = useRef(null)
  useEffect(() => {
    if (formElement.current) {
      formElement.current.submit()
    }
  })
  return (
    <div>
      <form id="step_up_form" ref={formElement} method="POST" action={stepUpUrl}>
        <input type="hidden" name="JWT" value={accessToken} />
        <input type="hidden" name="MD" value={cardToken} />
      </form>
    </div>
  )
}

class CheckoutCybersourceThreeDsPayment extends Component {
  constructor(props) {
    super(props)
    const { step, payerAuthenticationSetupResult } = props.cybersourceThreeds
    this.step = step
    if (this.step === PayerAuthStep.SETUP) {
      const { payerAuthenticationServiceData, token, cardData, url } = payerAuthenticationSetupResult
      this.payerAuthenticationSetupResult = payerAuthenticationServiceData
      this.cardToken = token
      this.cardData = cardData
      this.originUrl = url
    }
    this.payerAuthenticationCheckEnrollment = this.props.payerAuthenticationCheckEnrollment
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.cybersourceThreeds.step === PayerAuthStep.SETUP && nextProps.cybersourceThreeds.step === PayerAuthStep.ENROLLMENT) {
      this.step = nextProps.cybersourceThreeds.step
      const { stepUpUrl, accessToken } = nextProps.cybersourceThreeds.payerAuthenticationEnrollmentResult
      this.stepUpUrl = stepUpUrl
      this.accessToken = accessToken
    }
  }

  render() {
    if (this.step === PayerAuthStep.SETUP) {
      return (
        <PayerAuthenticationDeviceDataCollection
          payerAuthenticationServiceData={this.payerAuthenticationSetupResult}
          cardToken={this.cardToken}
          originUrl={this.originUrl}
          cardData={this.cardData}
          callback={this.payerAuthenticationCheckEnrollment}
        />
      )
    }
    if (this.step === PayerAuthStep.ENROLLMENT) {
      return <PayerAuthenticationEnrollment stepUpUrl={this.stepUpUrl} accessToken={this.accessToken} cardToken={this.cardToken} />
    }
    return null
  }
}

const mapStateToProps = state => ({
  cybersourceThreeds: state.payment.cybersourceThreeds,
})

const mapDispatchToProps = dispatch => ({
  payerAuthenticationCheckEnrollment: (cardToken, cardData) => {
    dispatch(paymentEnrollmentCybersourceThreeDs(cardToken, cardData))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCybersourceThreeDsPayment)
