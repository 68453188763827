import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { scroller } from 'react-scroll'

const styles = {
  pickerWrapper: {
    position: 'absolute',
    height: '340px',
    top: '-148px',
    right: '2.5%',
    left: '2.5%',
    bottom: '-148px',
    minWidth: '284px',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '40px',
    zIndex: '10',
    boxShadow: '0px 0px 12px 3px rgb(153, 153, 153, 0.5)',
  },
  headerText: {
    position: 'relative',
    maxWidth: '662px',
    width: '100%',
  },
  cellContainer: {
    position: 'relative',
    height: '300px',
    overflow: 'auto',
  },
  cellItem: {
    height: '40px',
    cursor: 'pointer',
    border: '0',
    width: '100%',
    outline: 'none',
  },
}

class ItemPicker extends Component {
  constructor(props) {
    super(props)

    const { valueSet, onItemClick, headerText } = this.props
    this.clickMap = _.reduce(
      valueSet,
      (map, value) => {
        const clickFunc = e => {
          e.stopPropagation()
          onItemClick(value)
        }
        return _.assign(map, { [value]: clickFunc })
      },
      {}
    )

    this.itemPickerId = 'sr-item-picker'

    this.clickPrevent = e => e.stopPropagation()
    this.allRows = _.fill(Array(3), '')
      .concat(valueSet)
      .concat(_.fill(Array(2), ''))
    this.buttonIDPrefix = `sr-${headerText.replace(/ /g, '').toLowerCase()}-`
    this.firstElementId = `${this.buttonIDPrefix}header`
    this.lastElementId = `${this.buttonIDPrefix}${valueSet.length + 2}`
    this.focusedElBeforeOpen = null
  }

  componentDidMount() {
    this.focusFirstModalEl()
    document.addEventListener('keydown', this._handleTabKeyDown, false)
    if (this.props.imgSrc) {
      scroller.scrollTo(`sr-${this.selectedValueNum - 3}`, {
        containerId: this.itemPickerId,
      })
    } else {
      scroller.scrollTo(`sr-${this.selectedValueNum - 1}`, {
        containerId: this.itemPickerId,
      })
    }
  }

  componentWillUnmount() {
    this.focusedElBeforeOpen.focus()
    this.focusedElBeforeOpen = null
    document.removeEventListener('keydown', this._handleTabKeyDown, false)
  }

  handleClickOutside = () => this.props.onOutsideClick()

  focusFirstModalEl = () => {
    this.focusedElBeforeOpen = document.activeElement
    document.getElementById(this.firstElementId).focus()
  }

  _handleTabKeyDown = event => {
    if (!(event.keyCode === 9)) {
      return
    }
    const firstFocusableEl = document.getElementById(this.firstElementId)
    const lastFocusableEl = document.getElementById(this.lastElementId)
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        event.preventDefault()
        lastFocusableEl.focus()
      }
    } else if (document.activeElement === lastFocusableEl) {
      event.preventDefault()
      firstFocusableEl.focus()
    }
  }

  render() {
    const {
      headerText,
      selectedValue,
      style,
      fontsColorPrimary,
      colorCellBackground,
      colorHighlightRow,
      colorHighlightRowText,
      colorHover,
      imgSrc,
    } = this.props

    const pickerCells = _.map(this.allRows, (value, idx) => {
      const isSelected = selectedValue === value
      if (isSelected) {
        this.selectedValueNum = idx
      }

      return (
        <div key={`sr-${idx}`} id={`sr-${idx}`}>
          <button
            key={`sr-radium-${idx}`}
            id={`${this.buttonIDPrefix}${idx}`}
            style={[
              styles.cellItem,
              { color: fontsColorPrimary },
              isSelected && {
                color: colorHighlightRowText,
                backgroundColor: colorHighlightRow,
              },
              !isSelected && { backgroundColor: colorCellBackground },
              !isSelected &&
                value && {
                  ':hover': { backgroundColor: colorHover },
                  ':focus': { backgroundColor: colorHover },
                },
            ]}
            onClick={this.clickMap[value]}
            aria-label={value}
            tabIndex={value ? '0' : '-1'}
          >
            {value}
          </button>
        </div>
      )
    })

    const cellContainerCSS = imgSrc ? null : { height: '190px' }
    const pickerWrapperCSS = imgSrc ? null : { height: '230px', top: '-65px' }
    const cells = imgSrc ? pickerCells : pickerCells.splice(2)
    return (
      <div style={[styles.pickerWrapper, pickerWrapperCSS, { color: fontsColorPrimary, backgroundColor: colorCellBackground }, style]}>
        <div
          id={this.firstElementId}
          tabIndex="0"
          style={[
            styles.headerText,
            {
              backgroundColor: colorCellBackground,
              outline: 'none',
            },
          ]}
        >
          {headerText}
        </div>
        <div id={this.itemPickerId} style={[styles.cellContainer, cellContainerCSS]}>
          {cells}
        </div>
      </div>
    )
  }
}

ItemPicker.propTypes = {
  headerText: React.PropTypes.string,
  selectedValue: React.PropTypes.node.isRequired,
  valueSet: React.PropTypes.arrayOf(React.PropTypes.node).isRequired,
  onItemClick: React.PropTypes.func.isRequired,
  onOutsideClick: React.PropTypes.func.isRequired,
  style: React.PropTypes.objectOf(React.PropTypes.string),
  fontsColorPrimary: React.PropTypes.string,
  colorCellBackground: React.PropTypes.string,
  colorHighlightRow: React.PropTypes.string,
  colorHighlightRowText: React.PropTypes.string,
  colorHover: React.PropTypes.string,
  imgSrc: React.PropTypes.string,
}

ItemPicker.defaultProps = {
  headerText: '',
  style: {},
  fontsColorPrimary: 'rgba(39, 39, 39, 1.00)',
  colorCellBackground: 'rgba(39, 39, 39, 1.00)',
  colorHighlightRow: 'rgba(39, 39, 39, 1.00)',
  colorHighlightRowText: 'rgba(39, 39, 39, 1.00)',
  colorHover: 'rgba(39, 39, 39, 1.00)',
}

export default onClickOutside(Radium(ItemPicker))
