// /* @flow */
import _ from 'lodash'

let memoizedGetDOMData = _.memoize(() => widgetInit.upsells)

export function getMemoizedDOMData() {
  return memoizedGetDOMData()
}

export function resetMemoization() {
  memoizedGetDOMData = _.memoize(() => widgetInit.upsells)
}
