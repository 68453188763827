export const shortDayFormat = {
  en_US: 'ddd, MMM D',
  default: 'ddd, D MMM',
}

export const LongDayFormat = {
  en_US: 'ddd, MMMM D',
  default: 'ddd, D MMMM',
}

export const LongWeekDayFormat = {
  en_US: 'dddd, MMM D',
  default: 'dddd, D MMM',
}

export const timeFormat = {
  en_GB: 'HH:mm',
  default: 'h:mm a',
}
