// /* @flow */
import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import FlagDropDownList from './FlagDropDownList'
import FlagListItem from './FlagListItem'

/* type Props = {
  onDropDownItemClick: string => mixed,
  onDropDownListClick: void => mixed,
  countryCode: string,
  dialCode: string,
  isDropDown: boolean,
  isDropDownOpen: boolean,
  preferredCountries: Array<string>,
  selectedCountryCode: string,
  hideFlags: boolean,
} */

class FlagButton extends React.Component /* <Props> */ {
  generateSelectedFlag = selectedCountryCode => {
    if (selectedCountryCode !== undefined) {
      return (
        <FlagListItem
          onDropDownItemClick={this.props.onDropDownItemClick}
          onDropDownListClick={this.props.onDropDownListClick}
          countryCode={allCountries[iso2Lookup[selectedCountryCode]].iso2}
          countryName={allCountries[iso2Lookup[selectedCountryCode]].name}
          dialCode={allCountries[iso2Lookup[selectedCountryCode]].dialCode}
          isDropDown={this.props.isDropDown}
          isDropDownOpen={this.props.isDropDownOpen}
          selectedCountryCode={this.props.selectedCountryCode}
          hideFlag={this.props.hideFlags}
        />
      )
    }

    return (
      <FlagListItem
        onDropDownItemClick={this.props.onDropDownItemClick}
        onDropDownListClick={this.props.onDropDownListClick}
        countryCode={allCountries[iso2Lookup.us].iso2}
        countryName={allCountries[iso2Lookup.us].name}
        dialCode={allCountries[iso2Lookup.us].dialCode}
        isDropDown={this.props.isDropDown}
        isDropDownOpen={this.props.isDropDownOpen}
        selectedCountryCode="us"
        hideFlag={this.props.hideFlags}
      />
    )
  }

  handleClickOutside() {
    if (this.props.isDropDownOpen) {
      this.props.onDropDownListClick()
    }
  }

  render() {
    return (
      <FlagDropDownWrapper>
        {this.generateSelectedFlag(this.props.selectedCountryCode)}
        <DropDownList>
          {this.props.isDropDownOpen ? (
            <FlagDropDownList
              onDropDownItemClick={this.props.onDropDownItemClick}
              onDropDownListClick={this.props.onDropDownListClick}
              countryCode={this.props.countryCode}
              dialCode={this.props.dialCode}
              preferredCountries={this.props.preferredCountries}
              selectedCountryCode={this.props.selectedCountryCode}
              hideFlags={this.props.hideFlags}
            />
          ) : null}
        </DropDownList>
      </FlagDropDownWrapper>
    )
  }
}

const FlagDropDownWrapper = styled.div`
  width: 30px;
  height: 20px;
  float: left;
  padding-top: 9px;
  :hover {
    cursor: pointer;
  }
`

const DropDownList = styled.div`
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  position: relative;
`

export default onClickOutside(FlagButton)
