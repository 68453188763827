const stripeCardPolyfill = {}
const c = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
const utils_trim = function (a) {
  return a === null ? '' : `${a}`.replace(c, '')
}
const utils_parseExpString = function (a) {
  let c
  let d
  let e
  let f
  let g
  let h
  let i
  let j
  g = function (b) {
    throw new Error(
      `You passed an invalid expiration date \`${a}\`. ${
        b || ''
      }Please pass a string containing a numeric month and year such as \`01-17\` or \`2015 / 05\` For more info, see https://stripe.com/docs/stripe.js`
    )
  }
  typeof a !== 'string' && g()
  f = a.split(/[\.\-\/\s]+/g)
  f.length !== 2 && g()
  j = f.length
  for (let b = (i = 0); i < j; b = ++i) {
    e = f[b]
    d = parseInt(e)
    isNaN(d) && g(`${f} is not a number. `)
    d < 1 && g(`${d} is less than one. `)
    f[b] = d
  }
  if (f[0] > 12) {
    h = f[0]
    c = f[1]
  } else {
    c = f[0]
    h = f[1]
  }
  c > 12 && g(`Month must be a number 1-12, not ${c}. `)
  h < 100 && (h += 2e3)
  return [c, h]
}

stripeCardPolyfill.validateCardNumber = function (a) {
  a = `${a}`.replace(/\s+|-/g, '')
  return a.length >= 10 && a.length <= 16 && stripeCardPolyfill.luhnCheck(a)
}
stripeCardPolyfill.validateCVC = function (b) {
  b = utils_trim(b)
  return /^\d+$/.test(b) && b.length >= 3 && b.length <= 4
}
stripeCardPolyfill.validateExpiry = function (b, c) {
  let d
  let e
  let f
  let g
  if (c != null) {
    f = utils_trim(b)
    c = utils_trim(c)
  } else {
    try {
      g = utils_parseExpString(b)
      f = g[0]
      c = g[1]
    } catch (h) {
      return !1
    }
    f += ''
    c += ''
  }
  return (
    !!/^\d+$/.test(f) &&
    !!/^\d+$/.test(c) &&
    f >= 1 &&
    f <= 12 &&
    (c.length === 2 && (c = c < 70 ? `20${c}` : `19${c}`),
    c.length === 4 && ((e = new Date(c, f)), (d = new Date()), e.setMonth(e.getMonth() - 1), e.setMonth(e.getMonth() + 1, 1), e > d))
  )
}
stripeCardPolyfill.luhnCheck = function (a) {
  let b
  let c
  let d
  let e
  let g
  d = !0
  e = 0
  c = `${a}`.split('').reverse()
  g = c.length
  for (let f = 0; f < g; f++) {
    b = c[f]
    b = parseInt(b, 10)
    ;(d = !d) && (b *= 2)
    b > 9 && (b -= 9)
    e += b
  }
  return e % 10 === 0
}
stripeCardPolyfill.cardType = function (a) {
  return stripeCardPolyfill.cardTypes[a.slice(0, 2)] || 'Unknown'
}
stripeCardPolyfill.cardBrand = function (a) {
  return stripeCardPolyfill.cardType(a)
}
stripeCardPolyfill.cardTypes = (function () {
  let a
  let b
  let c
  let d
  b = {}
  for (a = c = 40; c <= 49; a = ++c) {
    b[a] = 'Visa'
  }
  for (a = d = 50; d <= 59; a = ++d) {
    b[a] = 'MasterCard'
  }
  b[34] = b[37] = 'American Express'
  b[60] = b[62] = b[64] = b[65] = 'Discover'
  b[35] = 'JCB'
  b[30] = b[36] = b[38] = b[39] = 'Diners Club'
  return b
})()

export const stripeCard = window.Stripe ? window.Stripe.card : stripeCardPolyfill
