import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'svr/lib/Buttons/Button'
import { selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { selectRequest } from '../actions/navigation'
import { tryGetInitialAvailability, tryGetMultiVenueAvailability } from '../actions/services'
import styles from '../assets/styles/footer'
import { viewTypes } from '../utils/constantTypes'
import { ALL_LOCATIONS } from '../utils/convertData'
import { getCurrentView, getQueryMoment } from '../utils/selectors'

class Footer extends Component {
  buttonPropsSwitch() {
    switch (this.props.currentView) {
      case viewTypes.SEARCH:
        return {
          buttonProps: {
            innerElement: this.props.textSearch,
            onClick: this.props.isSingleVenueSearch ? this.props.tryGetInitialAvailability : this.props.tryGetMultiVenueAvailability,
            testId: 'sr-search-button',
          },
          buttonStyles: styles.relativeFooter,
        }
      case viewTypes.SEARCH_RESULTS:
        return {
          buttonProps: {
            innerElement: this.props.textSubmitRequest,
            onClick: this.props.selectRequest,
          },
          buttonStyles: styles.relativeFooter,
        }
      default:
        return {
          buttonProps: {
            innerElement: '',
            onClick: () => {},
          },
          buttonStyles: styles.relativeFooter,
        }
    }
  }

  render() {
    const { buttonProps, buttonStyles } = this.buttonPropsSwitch()
    const { colorCellBackground, colorPrimary, fontsColorButton, fontsColorPrimary, textCantFind } = this.props
    return (
      <div style={[styles.footerButtonWrapper, buttonStyles, { backgroundColor: colorCellBackground }]}>
        {this.props.currentView === viewTypes.SEARCH_RESULTS && (
          <span style={[styles.leadText, { color: fontsColorPrimary }]}>{textCantFind}</span>
        )}
        <Button
          {...buttonProps}
          withDefaultOutline
          style={_.assign({}, styles.footerButton, {
            backgroundColor: colorPrimary,
            color: fontsColorButton,
          })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  return {
    currentView: getCurrentView(state),
    queryMoment: getQueryMoment(state),
    isSingleVenueSearch: state.search.get('selectedVenue') !== ALL_LOCATIONS,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorCellBackground: state.widgetSettings.colorCellBackground,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    // text
    textSearch: languageStrings.textSearch,
    textCantFind: languageStrings.textCantFind,
    textSubmitRequest: languageStrings.textSubmitRequest,
  }
}

const mapDispatchToProps = dispatch => ({
  tryGetInitialAvailability: queryMoment => dispatch(tryGetInitialAvailability(queryMoment)),
  tryGetMultiVenueAvailability: queryMoment => dispatch(tryGetMultiVenueAvailability(queryMoment)),
  selectRequest: () => {
    dispatch(selectRequest())
  },
})

const mergeProps = (stateProps, dispatchProps) =>
  _.assign(stateProps, dispatchProps, {
    tryGetInitialAvailability: dispatchProps.tryGetInitialAvailability.bind(this, stateProps.queryMoment),
    tryGetMultiVenueAvailability: dispatchProps.tryGetMultiVenueAvailability.bind(this, stateProps.queryMoment),
  })

Footer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Radium(Footer))

export default Footer
